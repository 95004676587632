import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSpecificationContext } from './SpecificationContext'
import { getLatestRequirement } from '../api/v2/context'
import { Evidence, getEvidenceRecord } from '../api/v2/evidence'
import * as linkApi from '../api/v2/links'
import { EntityLink } from '../api/v2/links'
import * as requirementApi from '../api/v2/requirements'
import { getSpecification, Specification } from '../api/v2/specifications'
import { EvidenceType } from '../types/enums.ts'

export interface RequirementLink {
  linkId: number
  parent: requirementApi.Requirement & { specification: Specification }
  child: requirementApi.Requirement & { specification: Specification }
}

export interface EvidenceLink {
  linkId: number
  contextId: string
}

export interface EntityLinkWithEvidence {
  linkId: number
  contextId: string
  evidence: Evidence
}

export const filterLinksWRecords = (
  linksWRecords: EntityLinkWithEvidence[],
  contextId: string,
  evidenceType?: EvidenceType,
  linkIds?: number[],
) => {
  let filteredEvidences = evidenceType
    ? linksWRecords.filter(
        (link) =>
          link.contextId === contextId && link.evidence.type === evidenceType,
      )
    : linksWRecords.filter((link) => link.contextId === contextId)

  filteredEvidences =
    linkIds && linkIds.length > 1
      ? linksWRecords.filter((link) => linkIds.includes(link.linkId))
      : filteredEvidences

  return filteredEvidences
}

interface MatrixContext {
  requirements: requirementApi.Requirement[]
  requirementLinks: RequirementLink[]
  updateRequirementRow: (
    requirementId: string,
    update: Partial<requirementApi.Requirement>,
  ) => void
  updateListOfRequirementRows: (
    requirementId: string[],
    update: Partial<requirementApi.Requirement>,
  ) => void
  linkRequirements: (
    parent: {
      specificationId: string
      documentId: string
      blockId: string
    },
    child: {
      specificationId: string
      documentId: string
      blockId: string
    },
  ) => void
  unlinkRequirements: (linkId: number) => void
  validationLinks: EvidenceLink[]
  verificationLinks: EvidenceLink[]
  hasLoadedEntityLinks: boolean
  fetchedEvidenceRecords: EntityLinkWithEvidence[]
  getEntityLinksWithRecords: (
    id: string,
    evidenceType: EvidenceType,
    onlyFirst: boolean,
    linkIds?: number[],
  ) => Promise<EntityLinkWithEvidence[] | void>
  linkEvidence: (
    requirementId: string,
    evidenceId: string,
    specificationId: string,
  ) => Promise<EntityLinkWithEvidence | void>
  unlinkEvidence: (linkId: number) => void
  blockOrder: string[]
  bodySectionId: string
}

const MatrixCtx = createContext<MatrixContext>({
  requirements: [],
  requirementLinks: [],
  updateRequirementRow: () => {},
  updateListOfRequirementRows: () => {},
  linkRequirements: () => {},
  unlinkRequirements: () => {},
  validationLinks: [],
  verificationLinks: [],
  hasLoadedEntityLinks: false,
  getEntityLinksWithRecords: () => Promise.resolve(),
  fetchedEvidenceRecords: [],
  linkEvidence: () => Promise.resolve(),
  unlinkEvidence: () => {},
  blockOrder: [],
  bodySectionId: '',
})

export const useMatrixContext = () => useContext(MatrixCtx)

export const blockLinkToRequirementLink = async (
  blockLink: linkApi.BlockLink,
) => {
  try {
    const childReq = await getLatestRequirement(
      blockLink.data.childSpecificationId,
      blockLink.to.contextId,
    )
    const parentReq = await getLatestRequirement(
      blockLink.data.parentSpecificationId,
      blockLink.from.contextId,
    )

    if (
      blockLink.to.blockId === childReq.id &&
      blockLink.from.blockId === parentReq.id
    ) {
      const childSpec = await getSpecification(
        blockLink.data.childSpecificationId,
      )
      const parentSpec = await getSpecification(
        blockLink.data.parentSpecificationId,
      )

      return {
        linkId: blockLink.id,
        parent: { ...parentReq, specification: parentSpec },
        child: { ...childReq, specification: childSpec },
      }
    }
    return null
  } catch (e) {
    return null
  }
}

const entityLinkToEvidenceLink = async (
  entityLink: linkApi.EntityLink,
): Promise<EntityLinkWithEvidence> => {
  const evidence = await getEvidenceRecord(entityLink.entityId)
  return {
    linkId: entityLink.id,
    contextId: entityLink.documentBlockContextId,
    evidence,
  }
}

export const MatrixContextProvider = (props) => {
  const {
    specification,
    revision,
    requirementIds,
    sections,
    updateRequirement,
    publicTenant,
  } = useSpecificationContext()
  const [requirements, setRequirements] = useState<
    requirementApi.Requirement[]
  >([])
  const [requirementLinks, setRequirementLinks] = useState<RequirementLink[]>(
    [],
  )
  const [fetchedEvidenceRecords, setFetchedEvidenceRecords] = useState<
    EntityLinkWithEvidence[]
  >([])

  const [validationLinks, setValidationLinks] = useState<EntityLink[]>([])
  const [verificationLinks, setVerificationLinks] = useState<EntityLink[]>([])
  const [hasLoadedEntityLinks, setHasLoadedEntityLinks] =
    useState<boolean>(false)
  const { blockOrder, bodySectionId } = useMemo(() => {
    const bodySection = sections.find((sec) => sec.type === 'BODY')
    const blockOrder = bodySection?.elements.map((block) => block.id) ?? []
    return { bodySectionId: bodySection?.id ?? '', blockOrder }
  }, [sections])

  useEffect(() => {
    const { getRequirements } = publicTenant
      ? requirementApi.publicTenantMethods
      : requirementApi

    const fetchRequirements = async () => {
      const reqs = await getRequirements(specification.id, {
        revisionIds: [revision.id],
      })
      if (reqs.length !== requirementIds.size) {
        return
      }

      const orderedReqs = blockOrder
        .filter((id) => requirementIds.has(id))
        .map((r) => reqs.find((req) => req.id === r)!)
      setRequirements(orderedReqs)
    }
    fetchRequirements()
  }, [revision, specification, requirementIds, blockOrder, publicTenant])

  useEffect(() => {
    const fetchRequirementLinks = async () => {
      const blockLinks = await linkApi.getBlockLinks({
        documentId: [revision.documentId],
        link: [linkApi.LinkType.ParentChild],
      })
      const links = await Promise.all(
        blockLinks
          .sort((a, b) => b.id - a.id)
          .reduce((prevLinks, link) => {
            if (
              prevLinks.some(
                (l) =>
                  l.to.contextId === link.to.contextId &&
                  l.from.contextId === link.from.contextId,
              )
            ) {
              return prevLinks
            }
            return [...prevLinks, link]
          }, [] as linkApi.BlockLink[])
          .map(blockLinkToRequirementLink),
      )
      setRequirementLinks(links.filter((l) => l !== null) as RequirementLink[])
    }
    fetchRequirementLinks()
  }, [revision])

  const getEntityLinksWithRecords = useCallback(
    async (
      contextId: string,
      evidenceType?: EvidenceType,
      onlyFirst?: boolean,
      linkIds?: number[],
    ): Promise<EntityLinkWithEvidence[] | void> => {
      if (!contextId || !hasLoadedEntityLinks) {
        return
      }

      // Check if we've already fetched the record, return it
      const prefetchedLinksWRecords = filterLinksWRecords(
        fetchedEvidenceRecords,
        contextId,
        evidenceType,
        linkIds,
      )

      if (onlyFirst && prefetchedLinksWRecords.length > 0) {
        return prefetchedLinksWRecords
      }

      if (
        linkIds &&
        linkIds.length > 0 &&
        prefetchedLinksWRecords.length === linkIds.length
      ) {
        return prefetchedLinksWRecords
      }

      // If not, go fetch it...
      let entityLinks = [] as EntityLink[]

      if (evidenceType === EvidenceType.Verification) {
        entityLinks = verificationLinks
      } else if (evidenceType === EvidenceType.Validation) {
        entityLinks = validationLinks
      } else {
        entityLinks = [...verificationLinks, ...validationLinks]
      }

      entityLinks = entityLinks.filter((link) => link.contextId === contextId)

      if (entityLinks.length < 1) {
        // console.warn(`No entity links found for context id`, contextId)
        return
      }

      entityLinks = onlyFirst ? [entityLinks[0]] : entityLinks

      const linkWRecords = await Promise.all(
        entityLinks.map(entityLinkToEvidenceLink),
      )

      if (!linkWRecords) {
        // console.warn(`Unable to fetch record for entity link`, entityLinks)
        return
      }

      setFetchedEvidenceRecords((prev) => {
        const newState = [...prev]

        linkWRecords.forEach((lwr) => {
          if (newState.findIndex((e) => e.linkId === lwr.linkId) < 0) {
            newState.push(lwr)
          }
        })

        return newState
      })

      return filterLinksWRecords(linkWRecords, contextId, evidenceType, linkIds)
    },
    [
      fetchedEvidenceRecords,
      hasLoadedEntityLinks,
      validationLinks,
      verificationLinks,
    ],
  )

  useEffect(() => {
    const fetchEvidenceLinks = async () => {
      setHasLoadedEntityLinks(false)
      const uniqueValidationLinks = (
        await linkApi.getEntityLinks({
          documentId: [revision.documentId],
          link: [linkApi.LinkType.Evidence],
          limit: 10_000,
          evidenceType: EvidenceType.Validation,
        })
      )
        .reduce(
          (prevLinks, link) =>
            prevLinks.some(
              (l) =>
                l.documentBlockContextId === link.documentBlockContextId &&
                l.entityId === link.entityId,
            )
              ? prevLinks
              : [...prevLinks, link],
          [] as linkApi.EntityLink[],
        )
        .map((l) => ({
          ...l,
          linkId: l.id,
          contextId: l.documentBlockContextId,
        }))

      const uniqueVerificationLinks = (
        await linkApi.getEntityLinks({
          documentId: [revision.documentId],
          link: [linkApi.LinkType.Evidence],
          limit: 10_000,
          evidenceType: EvidenceType.Verification,
        })
      )
        .reduce(
          (prevLinks, link) =>
            prevLinks.some(
              (l) =>
                l.documentBlockContextId === link.documentBlockContextId &&
                l.entityId === link.entityId,
            )
              ? prevLinks
              : [...prevLinks, link],
          [] as linkApi.EntityLink[],
        )
        .map((l) => ({
          ...l,
          linkId: l.id,
          contextId: l.documentBlockContextId,
        }))

      setVerificationLinks(uniqueVerificationLinks)
      setValidationLinks(uniqueValidationLinks)
      setHasLoadedEntityLinks(true)
    }

    fetchEvidenceLinks()
  }, [revision])

  const updateRequirementRow = useCallback(
    async (
      requirementId: string,
      update: Partial<requirementApi.Requirement>,
    ) => {
      const updatedReq = await updateRequirement(requirementId, update)
      setRequirements((reqs) =>
        reqs.map((r) => (r.id === requirementId ? updatedReq! : r)),
      )
    },
    [updateRequirement],
  )

  const updateListOfRequirementRows = useCallback(
    async (
      requirementIds: string[],
      update: Partial<requirementApi.Requirement>,
    ) => {
      const updatedRequirements: (requirementApi.Requirement | void)[] = []

      // this loop is the naive solution to get the functionality live. it will be replaced
      // by a single API call once the backend work is finished
      for (let i = 0; i < requirementIds.length; i++) {
        const requirementId = requirementIds[i]
        const updatedResponse = await updateRequirement(requirementId, update)
        updatedRequirements.push(updatedResponse)
      }

      const mappedRequirements = updatedRequirements.reduce((acc, cur) => {
        if (!cur) {
          return acc
        }
        acc[cur.id] = cur
        return acc
      }, {})

      setRequirements((reqs) => {
        const mapped = reqs.map((req) => {
          const newReq = requirementIds.includes(req.id)
            ? mappedRequirements[req.id]
            : req
          return newReq
        })
        return mapped
      })
    },
    [updateRequirement],
  )
  const linkRequirements = useCallback(async (parent, child) => {
    const { id: linkId } = await linkApi.createParentChildLink(parent, child)
    const [link] = await linkApi.getBlockLinks({ id: [linkId] })
    const reqLink = await blockLinkToRequirementLink(link)
    setRequirementLinks((links) => [...links, reqLink!])
  }, [])

  const unlinkRequirements = useCallback(async (linkId: number) => {
    await linkApi.deleteBlockLink(linkId)
    setRequirementLinks((links) =>
      links.filter((link) => link.linkId !== linkId),
    )
  }, [])

  const linkEvidence = useCallback(
    async (
      requirementId: string,
      evidenceId: string,
      specificationId: string,
    ) => {
      const { id: linkId } = await linkApi.createEvidenceLink(
        revision.documentId,
        requirementId,
        evidenceId,
        specificationId,
      )

      const [entityLink] = await linkApi.getEntityLinks({ id: [linkId] })
      const evidence = await getEvidenceRecord(entityLink.entityId)
      const newLinkWRecord = {
        ...entityLink,
        linkId: entityLink.linkId,
        contextId: entityLink.documentBlockContextId,
        evidence,
      } as EntityLinkWithEvidence

      if (evidence.type === EvidenceType.Validation) {
        setValidationLinks((links) => [...links, entityLink])
      } else if (evidence.type === EvidenceType.Verification) {
        setVerificationLinks((links) => [...links, entityLink])
      }

      setFetchedEvidenceRecords([...fetchedEvidenceRecords, newLinkWRecord])
      return newLinkWRecord
    },
    [fetchedEvidenceRecords, revision.documentId],
  )

  const unlinkEvidence = useCallback(async (linkId: number) => {
    await linkApi.deleteEntityLink(linkId)
    setVerificationLinks((links) =>
      links.filter((link) => link.linkId !== linkId),
    )
    setValidationLinks((links) =>
      links.filter((link) => link.linkId !== linkId),
    )
    setFetchedEvidenceRecords((links) =>
      links.filter((link) => link.linkId !== linkId),
    )
  }, [])

  return (
    <MatrixCtx.Provider
      value={{
        requirements,
        requirementLinks,
        updateRequirementRow,
        updateListOfRequirementRows,
        linkRequirements,
        unlinkRequirements,
        linkEvidence,
        unlinkEvidence,
        blockOrder,
        bodySectionId,
        getEntityLinksWithRecords,
        verificationLinks,
        validationLinks,
        fetchedEvidenceRecords,
        hasLoadedEntityLinks,
      }}
    >
      {props.children}
    </MatrixCtx.Provider>
  )
}
