import { RowDelete, TrashCan } from '@carbon/icons-react'
import { Requirement } from '../../api/v2/requirements'
import { useMatrixContext } from '../../context/MatrixContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext'
import { useModals } from '../../hooks/useModals'
import { toastError, toastSuccess } from '../toast'

interface DeleteRequirementMenuButtonProps {
  specificationId: string
  revisionId: string
  requirement: Requirement
  onDelete?: () => void
  className?: string
}

const DeleteRequirementMenuButton = (
  props: DeleteRequirementMenuButtonProps,
) => {
  const { requirement, onDelete, className = '' } = props
  const { openConfirmationModal } = useModals()
  const { deleteOrArchiveRequirement, isDeleteDisabled } =
    useSpecificationContext()
  const { bodySectionId } = useMatrixContext()

  return (
    <button
      className={className}
      disabled={isDeleteDisabled(
        requirement.id,
        requirement.context.requirementVersionCount,
      )}
      onClick={() => {
        openConfirmationModal({
          title: 'Delete Confirmation',
          titleIcon: <TrashCan size={24} />,
          promptText: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ marginBottom: '12px' }}>
                Are you sure you want to delete this requirement?
              </span>
              <span style={{ marginBottom: '24px' }}>
                Deleting will permanently remove its draft version. Note that
                any linked requirements will be un-linked through this action.
              </span>
            </div>
          ),
          onConfirm: async () => {
            try {
              if (!bodySectionId) {
                throw new Error('No sectionId found')
              }
              await deleteOrArchiveRequirement(bodySectionId, requirement.id)
              toastSuccess('Successfully deleted ')
            } catch (e) {
              console.error('Failed to delete requirement', e)
              toastError(
                'Failed to delete requirement.',
                'Please refresh and try again',
              )
            }
          },
        })
        if (typeof onDelete === 'function') {
          onDelete()
        }
      }}
    >
      <RowDelete />
      Delete requirement
    </button>
  )
}

export default DeleteRequirementMenuButton
