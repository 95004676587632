export enum FilterKey {
  SpecificationCategory = 'specificationCategory',
  SpecificationProgram = 'specificationProgram',
  SpecificationPhase = 'specificationPhase',
  ReportSource = 'reportSource',
  RequirementStatus = 'requirementStatus',
  RequirementType = 'requirementType',
  SpecificationName = 'specificationName',
  ChangeRequestSpecificationName = 'changeRequestSpecificationName',
  EvidenceType = 'evidenceType',
  EvidenceMethod = 'evidenceMethod',
  EvidenceCadence = 'evidenceCadence',
  EvidenceProgram = 'evidenceProgram',
  Compliance = 'compliance',
  ValidationMethod = 'evidenceValidationMethods',
  VerificationMethod = 'evidenceVerificationMethods',
  EvidenceActivityType = 'evidenceActivityType',
  EvidenceActivityParentRecord = 'evidenceActivityParentRecord',
  EvidenceActivityStatus = 'evidenceActivityStatus',
  EvidenceActivityOwner = 'evidenceActivityOwner',
  SharedRequirementType = 'sharedRequirementType',
}

export type FilterSet = Record<FilterKey, Array<any>>

export type FilterData = Partial<Record<FilterKey, Array<any>>>

export interface AppliedFilterProps {
  onRemove: (key: FilterKey, value: any) => void
  value: any
  filterData?: FilterData
}

export interface FilterMenuProps {
  filters?: FilterSet
  activeFilters: FilterSet
  onSelectFilter: (key: FilterKey, value: any) => void
  filterData?: FilterData
}

export interface FilterDropdownProps extends FilterMenuProps {
  menus: Array<FilterKey>
}
