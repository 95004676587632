import api from './api'

const createUrl: (tenantId?: string, withContacts?: boolean) => string = (
  tenantId,
  withContacts,
) =>
  `/api/v2/tenants${tenantId ? `/${tenantId}` : ''}${
    withContacts ? `/contacts` : ''
  }`

const tenantContactWithDates: (tenantContact: any) => TenantContact = (
  tenantContact,
) => ({
  ...tenantContact,
  connectedOn: new Date(tenantContact.connectedOn),
})

export enum TenantTier {
  Full = 'FULL',
  Basic = 'BASIC',
}

export interface Tenant {
  id: string
  name: string
  active: boolean
  tier: TenantTier
}

export interface TenantOverview {
  requirements: {
    counts: {
      incomplete: number
      partial: number
      complete: number
    }
  }
}
export const getTenant: (tenantId: string) => Promise<Tenant> = (tenantId) =>
  api.get(createUrl(tenantId))

export const getTenants: () => Promise<Tenant[]> = async () => {
  const { tenants } = await api.get(createUrl())
  return tenants
}

export interface TenantContact extends Tenant {
  connectedOn: Date
}

export const getTenantContacts: (
  tenantId: string,
) => Promise<TenantContact[]> = async (tenantId) => {
  const { contactTenants } = await api.get(createUrl(tenantId, true))
  return contactTenants.map(tenantContactWithDates)
}

export const getTenantOverview: (
  programId?: string | undefined | null,
) => Promise<TenantOverview> = async (programId) => {
  return await api.get(
    `${createUrl()}/overview${programId ? `?program=${programId}` : ''}`,
  )
}

export type TenantProfile = {
  id: string
  tenantId: string
  displayName: string
  assetId?: string
}

export const getTenantProfile: (id?: string) => Promise<TenantProfile> = async (
  id,
) => {
  return await api.get(`/api/v2/tenants/profile${id ? `/${id}` : ''}`)
}

export const patchTenantProfile: (
  body: Pick<TenantProfile, 'displayName' | 'assetId'>,
) => Promise<TenantProfile> = async (body) => {
  return await api.patch(`/api/v2/tenants/profile`, { body })
}
