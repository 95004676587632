import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { PdfDocument } from './PdfDocument.tsx'
import * as assetApi from '../../../api/v2/assets.ts'
import {
  AttributeValueResponse,
  getAttributes,
  getAttributeValues,
} from '../../../api/v2/attributes.ts'
import * as blockApi from '../../../api/v2/blocks'
import {
  Block,
  BlockData,
  BlockType,
  ImageBlockData,
} from '../../../api/v2/blocks.ts'
import { getDocument, getSection } from '../../../api/v2/documents.ts'
import * as requirementApi from '../../../api/v2/requirements'
import { Revision } from '../../../api/v2/revisions.ts'
import {
  getSpecificationOrigin,
  Specification,
  SpecificationOriginResponse,
} from '../../../api/v2/specifications.ts'
import { AttributeName } from '../../../types/enums.ts'

const fetchDataForPdf = async (
  specification: Specification,
  revision: Revision,
  isHistoricalRevision: boolean,
  publicTenant: boolean,
) => {
  const doc = await getDocument(specification.id, revision.documentId)
  const sects = await Promise.all(
    doc.sections.map(
      async (sectionId) =>
        await getSection(specification.id, doc.id, sectionId),
    ),
  )

  const allBlockIds = sects.reduce((prev, curr) => {
    return [
      ...prev,
      ...(curr.elements
        ? curr.elements
            .filter((el) => el.type === 'BLOCK')
            .map((block) => block.id)
        : []),
    ]
  }, [] as string[])

  const CHUNK_REQUEST_SIZE = 175

  const createIdChunks = (allIds: string[]): string[][] =>
    Array.from(
      { length: Math.ceil(allIds.length / CHUNK_REQUEST_SIZE) },
      (_, i) =>
        allIds.slice(
          i * CHUNK_REQUEST_SIZE,
          i * CHUNK_REQUEST_SIZE + CHUNK_REQUEST_SIZE,
        ),
    )

  const { getFilteredBlocks, getRequirements } = publicTenant
    ? {
        ...blockApi.publicTenantMethods,
        ...requirementApi.publicTenantMethods,
      }
    : { ...blockApi, ...requirementApi }

  console.debug(`${new Date().toLocaleTimeString()} Fetching blocks...`)
  const blockIdChunks = createIdChunks(allBlockIds)

  const allBlocks = (
    await Promise.all(
      blockIdChunks.map(async (chunkOfIds) => {
        const { blocks } = await getFilteredBlocks(
          specification.id,
          revision.documentId,
          {
            id: chunkOfIds,
          },
        )
        return blocks
      }) as [],
    )
  ).flatMap((resp) => resp) as Block<BlockData>[]

  const requirementIds = allBlocks
    .filter((b) => b.type === BlockType.Requirement)
    .map((b) => b.id)

  const requirementIdChunks = createIdChunks(requirementIds)

  const allRequirements = (
    await Promise.all(
      requirementIdChunks.map(async (chunkOfIds) => {
        const requirements = await getRequirements(specification.id, {
          ids: chunkOfIds,
          revisionIds: [revision.id],
        })

        return requirements
      }),
    )
  ).flatMap((resp) => resp)

  console.debug(`${new Date().toLocaleTimeString()} Fetching requirements...`)
  const isExternal = specification.external
  let origin = { version: '1' } as SpecificationOriginResponse
  if (isExternal) {
    origin = await getSpecificationOrigin(specification.id)
  }

  const version = isExternal ? origin.version : 'V' + revision?.version

  const blocksWithData = {}

  for (const block of allBlocks) {
    const blockProps = {
      specification,
      isExternal,
      version,
      isHistoricalRevision,
    }

    if (block.type === BlockType.Image) {
      const assetId = (block as Block<ImageBlockData>).data.assetId
      const blob = await assetApi.getSpecificationAsset(
        specification.id,
        assetId,
      )
      const imageSrc = URL.createObjectURL(blob || '')
      blocksWithData[block.id] = {
        block,
        imageSrc,
        ...blockProps,
      }
    } else if (block.type === BlockType.Requirement) {
      blocksWithData[block.id] = {
        block,
        requirement: allRequirements.find((r) => r.id === block.id),
        ...blockProps,
      }
    } else {
      blocksWithData[block.id] = {
        block,
        ...blockProps,
      }
    }
  }

  let specificationProgram = undefined as AttributeValueResponse | undefined

  if (specification.program) {
    const { attributes } = await getAttributes()
    const programAttribute = attributes.find(
      (val) => val.name === AttributeName.SpecificationProgram,
    )

    const { values } = await getAttributeValues(programAttribute?.id || '')

    specificationProgram = values.find(
      (val) => specification.program === val.id,
    )
  }

  return {
    isExternal,
    version,
    isHistoricalRevision,
    blocks: blocksWithData,
    specificationProgram,
  }
}

interface GeneratePdfParams {
  specification: Specification
  revision: Revision
  isHistoricalRevision: boolean
  publicTenant: boolean
  onStart?: () => void
  onComplete?: () => void
  onFailure?: (e: any) => void
}

export const generatePdf = async (params: GeneratePdfParams) => {
  const {
    specification,
    revision,
    isHistoricalRevision,
    publicTenant,
    onStart,
    onComplete,
    onFailure,
  } = params

  try {
    if (onStart) {
      onStart()
    }

    const { blocks, isExternal, version, specificationProgram } =
      await fetchDataForPdf(
        specification,
        revision,
        isHistoricalRevision,
        publicTenant,
      )

    console.debug(
      `${new Date().toLocaleTimeString()} Generating pdf document...`,
    )
    const document = (
      <PdfDocument
        specification={specification}
        specificationProgram={specificationProgram}
        revision={revision}
        blocks={blocks}
        version={version}
        isHistoricalRevision={isHistoricalRevision}
        isExternal={isExternal}
      />
    )

    console.debug(`${new Date().toLocaleTimeString()} Saving pdf document...`)
    const blob = await pdf(document).toBlob()
    await saveAs(blob, `Specification_${version}_${specification.name}`)

    console.debug(`${new Date().toLocaleTimeString()} Finished pdf download`)
    if (onComplete) {
      onComplete()
    }
  } catch (e) {
    if (onFailure) {
      onFailure(e)
    }
  }
}
