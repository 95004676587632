import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import * as evidenceApi from '../api/v2/evidence'
import * as linkApi from '../api/v2/links'
import { LoadingState } from '../types/enums'

export interface EvidenceWithLinks extends evidenceApi.Evidence {
  links: linkApi.EvidenceRequirementLink[]
}

type EvidencesCtx = {
  evidences: EvidenceWithLinks[]
  deleteEvidenceRecord: (id: string) => Promise<void>
  updateEvidenceRecord: (
    id: string,
    update: Partial<evidenceApi.Evidence>,
  ) => Promise<void>
  unlinkEvidence: (linkId: number, evidenceId: string) => void
  addEvidence: (evidence: evidenceApi.Evidence) => void
  evidenceRecordLoading: LoadingState
}

const EvidencesContext = createContext<EvidencesCtx>({
  evidences: [],
  deleteEvidenceRecord: () => Promise.resolve(),
  updateEvidenceRecord: () => Promise.resolve(),
  unlinkEvidence: () => {},
  addEvidence: () => {},
  evidenceRecordLoading: LoadingState.Loading,
})

const EvidencesContextProvider = (props) => {
  const [evidences, setEvidences] = useState<EvidenceWithLinks[]>([])
  const [evidenceRecordLoading, setEvidenceRecordLoading] =
    useState<LoadingState>(LoadingState.Loading)

  useEffect(() => {
    const fetchEvidences = async () => {
      setEvidenceRecordLoading(LoadingState.Loading)
      try {
        const evidenceList = await evidenceApi.getEvidenceRecords()

        setEvidences(evidenceList as EvidenceWithLinks[])
        setEvidenceRecordLoading(LoadingState.Loaded)
      } catch (error) {
        setEvidenceRecordLoading(LoadingState.Failed)
        console.error('Unable to load evidence records', error)
      }
    }
    fetchEvidences()
  }, [])

  const updateEvidenceRecord = useCallback(
    async (id: string, update: Partial<evidenceApi.Evidence>) => {
      await evidenceApi.updateEvidenceRecord(id, update)
      setEvidences((prev) =>
        prev.map((e) => (e.id === id ? { ...e, ...update } : e)),
      )
    },
    [],
  )

  const deleteEvidenceRecord = useCallback(async (id: string) => {
    await evidenceApi.deleteEvidenceRecord(id)
    setEvidences((e) => e.filter((e) => e.id !== id))
  }, [])

  const unlinkEvidence = useCallback(
    async (linkId: number, evidenceId: string) => {
      await linkApi.deleteEntityLink(linkId)

      setEvidences((currentEvidences) =>
        currentEvidences.map((evidence) => {
          if (evidence.id === evidenceId) {
            const updatedLinks = evidence.links.filter(
              (link) => link.link_id !== linkId,
            )
            return { ...evidence, links: updatedLinks }
          }
          return evidence
        }),
      )
    },
    [],
  )

  const addEvidence = useCallback((evidence: evidenceApi.Evidence) => {
    const evidenceWithLinks = {
      ...evidence,
      links: [],
    }
    setEvidences((prevEvidences) => [...prevEvidences, evidenceWithLinks])
  }, [])

  return (
    <EvidencesContext.Provider
      value={{
        evidences,
        deleteEvidenceRecord,
        updateEvidenceRecord,
        unlinkEvidence,
        addEvidence,
        evidenceRecordLoading,
      }}
    >
      {props.children}
    </EvidencesContext.Provider>
  )
}

const useEvidencesContext = () => {
  const ctx = useContext(EvidencesContext)
  if (!ctx) {
    console.error('EvidencesContext has no provider')
  }
  return ctx
}

export { EvidencesContextProvider, useEvidencesContext }
