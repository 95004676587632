import { ArrowUp, Keyboard } from '@carbon/icons-react'
import {
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useOutletContext } from 'react-router-dom'
import styles from './DocumentView.module.css'
import {
  Block,
  BlockData,
  BlockType,
  createBlock,
  deleteBlock,
  getBlock,
} from '../../api/v2/blocks.ts'
import {
  DocumentElement,
  reorderSectionElements,
} from '../../api/v2/documents.ts'
import { getRequirement, Requirement } from '../../api/v2/requirements.ts'
import BlockEditor, {
  ALL_BLOCKS,
} from '../../components/block-editor/BlockEditor.tsx'
import CollapsableBlockEditor from '../../components/block-editor/CollapsableBlockEditor.tsx'
import IconButton from '../../components/icon-button/IconButton.tsx'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'
import { toastError } from '../../components/toast'
import { useDragDropContext } from '../../context/DragAndDropContext.tsx'
import { SectionContextProvider } from '../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext.tsx'
import { useModals } from '../../hooks/useModals.ts'

const SECTION_ELEMENT_BY_TYPE = {
  BODY: BlockEditor,
  FRONTMATTER: CollapsableBlockEditor,
}

const DocumentView = () => {
  const { sections, setSections, specification, document } =
    useSpecificationContext()
  const { setActiveDnDId, activeDnDId } = useDragDropContext()
  const { scrollerRef } = useOutletContext<{
    scrollerRef: React.RefObject<HTMLDivElement>
  }>()
  const { openKeyboardShortcutsModal } = useModals()
  const [dndBlockData, setDndBlockData] = useState<{
    block: Block<BlockData>
    requirement?: Requirement
  } | null>(null)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const SCROLL_TO_TOP_THRESHOLD = 400

  useEffect(() => {
    const currentScroller = scrollerRef.current
    if (!currentScroller) {
      return
    }
    const handleScroll = () => {
      setShowScrollToTop(currentScroller.scrollTop > SCROLL_TO_TOP_THRESHOLD)
    }
    currentScroller.addEventListener('scroll', handleScroll)
    return () => {
      currentScroller.removeEventListener('scroll', handleScroll)
    }
  }, [scrollerRef])

  const scrollToTop = () => {
    if (scrollerRef?.current) {
      scrollerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const findSectionId = (id: string): string | undefined =>
    sections.find((section) => section.elements.some((el) => el.id === id))?.id

  const findIndex = (id: string) => {
    const section = findSectionId(id)
    if (section) {
      return sections
        .find((s) => s.id === section)
        ?.elements.findIndex((el) => el.id === id)
    }
    return -1
  }

  useEffect(() => {
    const getActiveBlockData = async () => {
      if (activeDnDId) {
        const block = await getBlock(
          specification.id,
          document!.id,
          activeDnDId,
        )
        let requirement
        if (block.type === BlockType.Requirement) {
          requirement = await getRequirement(specification.id, activeDnDId)
        }

        setDndBlockData({ block, requirement })
      } else {
        setDndBlockData(null)
      }
    }
    getActiveBlockData()
  }, [activeDnDId, document, specification])

  const getOverlayEl = () => {
    if (!dndBlockData) {
      return null
    }

    const Component = ALL_BLOCKS[dndBlockData.block.type]
    // @ts-expect-error This is how we pass blockData
    return <Component {...dndBlockData} />
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    // TODO keyboard nav
  )

  return (
    <div className={styles.container}>
      <IconButton
        className={styles.keyboardShortcuts}
        onClick={() => openKeyboardShortcutsModal()}
      >
        <Keyboard size={20} />
      </IconButton>
      {sections.length === 0 ? (
        <div className={styles.loadingWrapper}>
          <LoadingIndicator />
        </div>
      ) : (
        <DndContext
          sensors={sensors}
          onDragCancel={() => setActiveDnDId(null)}
          onDragStart={({ active }) => setActiveDnDId(active.id as string)}
          onDragEnd={async ({ active, over }) => {
            const activeSection = findSectionId(active.id as string)
            const overSection = findSectionId(over?.id as string)
            if (!activeSection || !overSection) {
              setActiveDnDId(null)
              return
            }

            if (activeSection === overSection) {
              const activeIndex = findIndex(active.id as string)!
              const overIndex = findIndex(over!.id as string)!
              if (activeIndex === overIndex) {
                setActiveDnDId(null)
                return
              }

              setSections((sects) =>
                sects.map((section) => {
                  reorderSectionElements(
                    specification.id,
                    document!.id,
                    section.id,
                    arrayMove(
                      section.elements.map((el) => el.id),
                      activeIndex,
                      overIndex,
                    ),
                  )
                  return section.id === overSection
                    ? {
                        ...section,
                        elements: arrayMove(
                          section.elements,
                          activeIndex,
                          overIndex,
                        ),
                      }
                    : section
                }),
              )
              setActiveDnDId(null)
            } else {
              const copiedBlock = await getBlock(
                specification.id,
                document!.id,
                active.id as string,
              )
              // Currently cannot move requirements between sections
              if (copiedBlock.type === BlockType.Requirement) {
                toastError('Cannot move requirements across sections', '')
                setActiveDnDId(null)
                return
              }

              const newBlock = await createBlock(
                specification.id,
                document!.id,
                overSection,
                { type: copiedBlock.type, data: copiedBlock.data },
              )

              await deleteBlock(
                specification.id,
                document!.id,
                active.id as string,
              )

              setSections((sects) =>
                sects.map((section) => {
                  if (section.id === activeSection) {
                    return {
                      ...section,
                      elements: section.elements.filter(
                        (el) => el.id !== active.id,
                      ),
                    }
                  }
                  if (section.id === overSection) {
                    const insertIndex = section.elements.findIndex(
                      (el) => el.id === over!.id,
                    )
                    const insertEl: DocumentElement = {
                      id: newBlock.id,
                      type: 'BLOCK',
                    }

                    reorderSectionElements(
                      specification.id,
                      document!.id,
                      overSection,
                      [
                        ...section.elements
                          .map((el) => el.id)
                          .slice(0, insertIndex),
                        insertEl.id,
                        ...section.elements
                          .map((el) => el.id)
                          .slice(insertIndex),
                      ],
                    )

                    return {
                      ...section,
                      elements: [
                        ...section.elements.slice(0, insertIndex),
                        insertEl!,
                        ...section.elements.slice(insertIndex),
                      ],
                    }
                  }

                  return section
                }),
              )
              setActiveDnDId(null)
            }
          }}
        >
          <SortableContext
            items={sections.flatMap((s) => s.elements).map((el) => el.id)}
          >
            {sections.map((section) => {
              const Editor =
                SECTION_ELEMENT_BY_TYPE[section.type] || BlockEditor
              return (
                <SectionContextProvider
                  sectionId={section.id}
                  allowEmpty={section.type !== 'BODY'}
                >
                  <Editor />
                </SectionContextProvider>
              )
            })}
          </SortableContext>
          {createPortal(
            <DragOverlay
              style={{ cursor: 'grabbing' }}
              dropAnimation={{
                sideEffects: defaultDropAnimationSideEffects({
                  styles: { active: { opacity: '0.4' } },
                }),
              }}
            >
              {getOverlayEl()}
            </DragOverlay>,
            window.document.body,
          )}
        </DndContext>
      )}
      {showScrollToTop && (
        <button className={styles.scrollToTopButton} onClick={scrollToTop}>
          <ArrowUp size={24} />
        </button>
      )}
    </div>
  )
}

export default DocumentView
