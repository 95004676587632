import { useEffect, useRef } from 'react'

const useClickOutside = (
  onClickOutside: () => void,
  attach: boolean = true,
  delayAttach: number = 0,
) => {
  const ref = useRef<any>(null)
  const listener = useRef<any>(null)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (attach) {
      listener.current = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target)) {
          onClickOutside()
        }
      }

      // Add a small delay, if provided, before attaching the click outside
      // listener to wait for render to complete
      timeoutId = setTimeout(
        () => document.addEventListener('mousedown', listener.current),
        delayAttach,
      )
    }

    return () => {
      if (listener.current) {
        document.removeEventListener('mousedown', listener.current)
        listener.current = null
      }
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [onClickOutside, attach, delayAttach])

  return ref
}

export default useClickOutside
