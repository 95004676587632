import { RuleTest } from '@carbon/icons-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import SharedRequirementItemView from './SharedRequirementItemView.tsx'
import styles from './SharedRequirementsView.module.css'
import { BlockType } from '../../api/v2/blocks'
import * as api from '../../api/v2/sharedSpecifications.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator'
import { useSharedSpecificationContext } from '../../context/SharedSpecificationContext'
import { useModals } from '../../hooks/useModals.ts'
import {
  LoadingState,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums'

const FILTER_KEYS = [FilterKey.SharedRequirementType]

const SharedRequirementsView = () => {
  const {
    specificationSnapshot,
    getRequirementById,
    review,
    reviewLoading,
    requirementTypes,
    userIsSnapshotOwner,
    userIsSnapshotReviewer,
    updateReviewStatus,
  } = useSharedSpecificationContext()
  const { requirementId: urlRequirementId } = useParams()
  const { openShareReviewModal } = useModals()
  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)
  const specificationId = specificationSnapshot.contents.specification.id
  const [commentsByRequirementId, setCommentsByRequirementId] = useState<
    Record<string, api.ReviewRequirementComment[]>
  >({})
  const [commentsLoadingState, setCommentsLoadingState] =
    useState<LoadingState>(LoadingState.Loading)

  useEffect(() => {
    const loadComments = async () => {
      if (!review || commentsLoadingState === LoadingState.Loaded) {
        return
      }

      setCommentsLoadingState(LoadingState.Loading)
      try {
        const comments = await api.getReviewRequirementComments(
          specificationSnapshot.specificationId,
          specificationSnapshot.id,
          review.id,
        )
        setCommentsLoadingState(LoadingState.Loaded)
        setCommentsByRequirementId(comments.requirementComments)
      } catch (error) {
        setCommentsLoadingState(LoadingState.Failed)
        console.error('Unable to load comments for requirement', error)
      }
    }

    loadComments()
  }, [
    commentsLoadingState,
    review,
    review?.id,
    specificationSnapshot.id,
    specificationSnapshot.specificationId,
  ])

  const requirementIds = specificationSnapshot.contents.documentBlocks
    .filter((block) => block.type === BlockType.Requirement)
    .map((req) => req.id)

  const requirements = requirementIds
    .map((id) => getRequirementById(id))
    .filter((req) => req !== null)
    .map((req) => req as api.SharedSpecificationRequirement)

  const filterData = {
    [FilterKey.SharedRequirementType]: requirementTypes,
  }

  const filteredRequirements = useMemo(() => {
    return requirements.filter(
      (r) =>
        filters[FilterKey.SharedRequirementType].length === 0 ||
        r.types.some((type) =>
          filters[FilterKey.SharedRequirementType].includes(type),
        ),
    )
  }, [requirements, filters])

  const addCommentToRequirement = useCallback(
    async (requirementId: string, comment: string) => {
      if (review == null) {
        console.error('Review must exist to submit comment')
        return false
      }

      try {
        const commentResponse = await api.addReviewRequirementComment(
          specificationId,
          specificationSnapshot.id,
          review.id,
          requirementId,
          comment,
        )

        setCommentsByRequirementId((prev) => {
          return {
            ...prev,
            [requirementId]: [...(prev[requirementId] || []), commentResponse],
          }
        })
      } catch (error) {
        console.error('Unable to add comment', error)
        return false
      }

      return true
    },
    [review, specificationId, specificationSnapshot.id],
  )

  const deleteRequirementComment = useCallback(
    async (requirementId: string, commentId: string) => {
      if (review == null) {
        console.error('Review must exist to delete comment')
        return false
      }

      try {
        await api.deleteReviewRequirementComment(
          specificationId,
          specificationSnapshot.id,
          review.id,
          requirementId,
          commentId,
        )

        const updatedComments = commentsByRequirementId[requirementId].filter(
          (comment) => comment.id !== commentId,
        )

        setCommentsByRequirementId((prev) => ({
          ...prev,
          [requirementId]: updatedComments,
        }))
      } catch (error) {
        console.error('Unable to delete comment', error)
        return false
      }
      return true
    },
    [
      commentsByRequirementId,
      review,
      specificationId,
      specificationSnapshot.id,
    ],
  )

  return (
    <div className={styles.content}>
      <div className={styles.actions}>
        <div className={styles.filters}>
          <FilterDropdown
            menus={FILTER_KEYS}
            activeFilters={filters}
            onSelectFilter={toggleFilter}
            filterData={filterData}
          />
          <AppliedFilters
            filterKeys={FILTER_KEYS}
            filters={filters}
            onRemove={toggleFilter}
            filterData={filterData}
          />
        </div>
        {userIsSnapshotReviewer && review && (
          <Button
            text="Complete"
            endIcon={<RuleTest />}
            color={BUTTON_COLORS.PRIMARY}
            onClick={() =>
              openShareReviewModal({
                review,
                specificationId,
                specificationSnapshotId: specificationSnapshot.id,
                updateReviewStatus,
              })
            }
            disabled={
              review?.status !== SpecificationSnapshotReviewStatus.Draft
            }
          />
        )}
      </div>
      <div className={styles.requirements}>
        {userIsSnapshotReviewer && reviewLoading === LoadingState.Loading && (
          <div className={styles.center}>
            <LoadingIndicator />
          </div>
        )}
        {userIsSnapshotReviewer && reviewLoading === LoadingState.Failed && (
          <div className={styles.center}>
            An error occurred while loading the data. Try again later.
          </div>
        )}
        {(userIsSnapshotOwner || reviewLoading === LoadingState.Loaded) &&
          (filteredRequirements.length > 0 ? (
            filteredRequirements.map((requirement) => {
              return (
                <SharedRequirementItemView
                  key={requirement.id}
                  requirement={requirement}
                  review={review}
                  comments={commentsByRequirementId[requirement.id] || []}
                  commentsLoadingState={commentsLoadingState}
                  addCommentToRequirement={addCommentToRequirement}
                  deleteRequirementComment={deleteRequirementComment}
                  scrollToOnLoad={urlRequirementId === requirement.id}
                />
              )
            })
          ) : (
            <div className={styles.center}>No results</div>
          ))}
      </div>
    </div>
  )
}

export default SharedRequirementsView
