import {
  CheckmarkFilled,
  CircleFill,
  Edit,
  IncompleteCancel,
} from '@carbon/icons-react'
import { useState } from 'react'
import styles from './AttributeInput.module.css'
import * as api from '../../api/v2/attributes.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import Button, { BUTTON_COLORS } from '../button'
import Dropdown from '../dropdown'
import { toastError, toastSuccess } from '../toast'

export const NEW_ATTRIBUTE_NAME = 'UNTITLED'

const TEMP_ID_PREFIX = 'TEMP-'
export const getTempAttributeId = (prefix = TEMP_ID_PREFIX) =>
  prefix + (Math.random() + 1).toString(36).substring(0, 11).replace('.', '')

interface AttributeInputType {
  attribute: api.AttributeValueResponse
  attributeId: string
  isAdmin: boolean
  entityCountLabel: string
  onAttributeArchive: (attribute: api.AttributeValueResponse) => void
  onAttributeCreate: (
    prevId: string,
    newAttribute: api.AttributeValueResponse,
  ) => void
  onAttributeUpdate: (attribute: api.UpdateAttributeResponse) => void
  maxCharLimit?: number
}

const AttributeInput = (props: AttributeInputType) => {
  const {
    attribute,
    attributeId,
    isAdmin,
    entityCountLabel,
    onAttributeArchive,
    onAttributeCreate,
    onAttributeUpdate,
    maxCharLimit = 20,
  } = props
  const [newAttributeName, setNewAttributeName] = useState(attribute.name)
  const [isEditing, setIsEditing] = useState(
    attribute.name === NEW_ATTRIBUTE_NAME,
  )
  const [displaySaveConfirmation, setDisplaySaveConfirmation] = useState(false)
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false)

  const deleteRef = useClickOutside(() => {
    setDisplayDeleteConfirmation(false)
  })

  const saveRef = useClickOutside(() => {
    setDisplaySaveConfirmation(false)
  })

  const resetEditRef = useClickOutside(() => {
    if (isEditing) {
      setNewAttributeName(attribute.name)
      setIsEditing(false)
    }
  })

  const isValidAttribute = (
    originalAttribute: string,
    newAttribute: string,
  ): boolean => {
    if (newAttribute === null || newAttribute.length < 1) {
      return false
    }

    if (originalAttribute === newAttribute) {
      return false
    }

    return true
  }

  const onSave = async (isArchiving?: boolean) => {
    try {
      let successMsg: string

      if (isArchiving) {
        await api.archiveAttribute(attributeId, attribute.id, {
          name: newAttributeName,
          metadata: attribute.metadata,
        })
        onAttributeArchive(attribute)
        successMsg = 'Successfully archived tag'
      } else if (attribute.id.includes(TEMP_ID_PREFIX)) {
        const newAttribute = await api.createAttribute(attributeId, {
          name: newAttributeName,
          metadata: attribute.metadata,
        })
        onAttributeCreate(attribute.id, newAttribute)
        successMsg = 'Successfully created tag'
      } else {
        const updatedAttr = await api.updateAttribute(
          attributeId,
          attribute.id,
          {
            name: newAttributeName,
            status: attribute.status,
            metadata: attribute.metadata,
          },
        )
        onAttributeUpdate(updatedAttr)
        successMsg = 'Successfully updated tag'
      }

      setDisplayDeleteConfirmation(false)
      setDisplaySaveConfirmation(false)
      setIsEditing(false)
      toastSuccess(successMsg)
    } catch (e) {
      setDisplaySaveConfirmation(false)
      setDisplayDeleteConfirmation(false)
      toastError('Failed to update tag', 'Please refresh and try again')
    }
  }

  const canSave = isValidAttribute(attribute.name, newAttributeName)

  return (
    <div ref={resetEditRef} className={styles.attribute}>
      <div className={styles.attributeInput}>
        <div className={styles.inputWrapper}>
          <input
            className={styles.name}
            placeholder={newAttributeName}
            value={newAttributeName}
            disabled={!isEditing}
            maxLength={maxCharLimit}
            onChange={(e) => {
              const newName = e.target.value.toUpperCase()
              setNewAttributeName(newName)
            }}
            onClick={() => {
              if (newAttributeName === NEW_ATTRIBUTE_NAME) {
                setNewAttributeName('')
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (attribute.entityCount > 0) {
                  setDisplaySaveConfirmation(true)
                } else {
                  onSave()
                }
              }
            }}
          />
        </div>

        <CircleFill
          className={styles.color}
          fill={attribute?.metadata?.STYLES?.COLOR_BG}
          size={8}
        />
        {attribute.entityCount > 0 && (
          <span className={styles.entityCount}>
            {attribute.entityCount} {entityCountLabel}
          </span>
        )}
      </div>
      <div className={styles.adminActions}>
        {isAdmin && isEditing && (
          <div ref={saveRef} className={styles.saveAction}>
            <button
              className={styles.editBtn}
              disabled={!canSave}
              onClick={(e) => {
                e.preventDefault()
                if (attribute.entityCount > 0) {
                  setDisplaySaveConfirmation(true)
                } else {
                  onSave()
                }
              }}
            >
              <CheckmarkFilled
                className={`${styles.saveBtn} ${canSave ? styles.canSave : ''}`}
              />
            </button>
            <Dropdown
              className={styles.saveConfirmation}
              isOpen={displaySaveConfirmation}
            >
              <div>
                Are you sure you want to edit this tag? Editing will change{' '}
                {attribute.entityCount}{' '}
                {attribute.entityCount === 1 ? 'item' : 'items'}.
              </div>
              <Button
                text="Update"
                color={BUTTON_COLORS.PRIMARY}
                onClick={() => onSave()}
              />
            </Dropdown>
          </div>
        )}
        {isAdmin && !isEditing && (
          <>
            <button
              className={styles.editBtn}
              onClick={() => {
                setIsEditing(true)
              }}
            >
              <Edit />
            </button>
            <div ref={deleteRef} className={styles.deleteAction}>
              <button
                className={styles.deleteBtn}
                onClick={() => {
                  setDisplayDeleteConfirmation(true)
                }}
              >
                <IncompleteCancel />
              </button>
              <Dropdown
                className={styles.deleteConfirmation}
                isOpen={displayDeleteConfirmation}
              >
                <div>
                  Are you sure you want to archive this tag? Archiving will
                  restrict future use by members.
                </div>
                <Button
                  text="Archive"
                  onClick={() => onSave(true)}
                  frontIcon={<IncompleteCancel />}
                />
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AttributeInput
