import { useEffect, useMemo, useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import SharedSpecificationsTableColumns from './SharedSpecificationsTableColumns'
import styles from './SharedWorkspacePage.module.css'
import SpecificationReviewsTableColumns from './SpecificationReviewsTableColumns.tsx'
import * as api from '../../api/v2/sharedWorkspaces.ts'
import { SharedProjectWorkspace } from '../../api/v2/sharedWorkspaces.ts'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator'
import Table from '../../components/table/Table'
import Tag, { stylesToTagColor } from '../../components/tag'
import { useAuth } from '../../context/AuthContext.tsx'
import { LoadingState } from '../../types/enums'

const SharedWorkspacePage = () => {
  const { userTenant } = useAuth()
  const navigate = useNavigate()
  const sharedProjectWorkspace = useLoaderData() as SharedProjectWorkspace
  const [sharedSpecSnapshots, setSharedSpecSnapshots] =
    useState<api.SharedSpecificationSnapshotData[]>()
  const [specsLoading, setSpecsLoading] = useState<LoadingState>()
  const [reviews, setReviews] = useState<
    api.SpecificationSnapshotReviewMetadata[]
  >([])
  const [reviewsLoading, setReviewsLoading] = useState<LoadingState>()
  const { workspaceId, projectId } = useParams()

  useEffect(() => {
    setSpecsLoading(LoadingState.Loading)
    const getSharedSpecSnapshots = async () => {
      try {
        const snapshots = await api.getWorkspaceProjectSnapshots(
          workspaceId!,
          projectId!,
        )
        const sortedSnapshots = snapshots.sort(
          (a, b) => b.createdOn.getTime() - a.createdOn.getTime(),
        )
        setSharedSpecSnapshots(sortedSnapshots)
        setSpecsLoading(LoadingState.Loaded)
      } catch (error) {
        console.error('Unable to load shared specification snapshots', error)
        setSpecsLoading(LoadingState.Failed)
      }
    }
    getSharedSpecSnapshots()
  }, [projectId, workspaceId])

  const snapshotIdToSnapshot = useMemo(
    () =>
      sharedSpecSnapshots?.reduce(
        (acc, snapshot) => ({ ...acc, [snapshot.id]: snapshot }),
        {},
      ),
    [sharedSpecSnapshots],
  )

  useEffect(() => {
    setReviewsLoading(LoadingState.Loading)
    const getSubmittedReviews = async () => {
      try {
        const reviews = await api.getWorkspaceProjectReviews(
          workspaceId!,
          projectId!,
        )
        const sortedReviews = reviews.sort(
          (a, b) => b.submittedOn.getTime() - a.submittedOn.getTime(),
        )
        setReviews(sortedReviews)
        setReviewsLoading(LoadingState.Loaded)
      } catch (error) {
        console.error('Unable to load reviews', error)
        setReviewsLoading(LoadingState.Failed)
      }
    }
    getSubmittedReviews()
  }, [projectId, workspaceId])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <Tag
            text={sharedProjectWorkspace.project.name}
            color={{
              fontColor:
                sharedProjectWorkspace.project.metadata.STYLES.COLOR_FONT,
              backgroundColor:
                sharedProjectWorkspace.project.metadata.STYLES.COLOR_BG,
            }}
          />
          <h1>
            {sharedProjectWorkspace.contact.name} · {userTenant?.name}
          </h1>
        </div>
        <div className={styles.headerRight}>
          <div className={styles.otherProjects}>Other projects</div>
          {sharedProjectWorkspace.otherProjects.length > 0 ? (
            sharedProjectWorkspace.otherProjects.map((project) => (
              <Tag
                key={project.id}
                text={project.name}
                color={stylesToTagColor(project.metadata.STYLES)}
                onClick={() =>
                  navigate(
                    `/workspaces/${sharedProjectWorkspace.id}/projects/${project.id}`,
                  )
                }
              />
            ))
          ) : (
            <div>No other projects</div>
          )}
        </div>
      </div>
      <h2>Shared specifications</h2>
      <div className={styles.table}>
        {specsLoading === LoadingState.Loading && <LoadingIndicator />}
        {specsLoading === LoadingState.Failed && (
          <div>Unable to load shared specifications</div>
        )}
        {specsLoading === LoadingState.Loaded &&
          sharedSpecSnapshots &&
          (sharedSpecSnapshots.length > 0 ? (
            <Table
              rowItems={sharedSpecSnapshots}
              columns={SharedSpecificationsTableColumns}
              onRowClick={(rowData) => {
                navigate(
                  `/workspaces/${sharedProjectWorkspace.id}/projects/${projectId}/specifications/${rowData.specificationId}/snapshots/${rowData.id}`,
                )
              }}
            />
          ) : (
            <div>No shared specifications</div>
          ))}
      </div>
      <h2>Reviews</h2>
      <div className={styles.table}>
        {reviewsLoading === LoadingState.Loading && <LoadingIndicator />}
        {reviewsLoading === LoadingState.Failed && (
          <div>Unable to load reviews</div>
        )}
        {reviewsLoading === LoadingState.Loaded &&
          reviews &&
          (reviews.length > 0 ? (
            <Table
              rowItems={reviews.map((review) => ({
                ...review,
                requirementCount:
                  snapshotIdToSnapshot?.[review.snapshotId]?.requirementCount,
              }))}
              columns={SpecificationReviewsTableColumns}
              onRowClick={(rowData) => {
                navigate(
                  `/workspaces/${sharedProjectWorkspace.id}/projects/${projectId}/specifications/${rowData.specificationId}/snapshots/${rowData.snapshotId}/reviews/${rowData.id}`,
                )
              }}
            />
          ) : (
            <div>No outstanding or complete reviews</div>
          ))}
      </div>
    </div>
  )
}

export default SharedWorkspacePage
