import api from './api'
import { CreateExceptionLinkRequest } from './links'

const createUrl = (exceptionId?: string) =>
  `/api/v2/exceptions${exceptionId ? `/${exceptionId}` : ''}`

export enum ExceptionType {
  Deviation = 'DEVIATION',
  Waiver = 'WAIVER',
}

export enum ExceptionStatus {
  Requested = 'REQUESTED',
  InReview = 'IN_REVIEW',
  Accepted = 'ACCEPTED',
}

type ExceptionResponse = {
  /** @format guid */
  id: string
  title: string
  type: ExceptionType
  status: ExceptionStatus
  variance?: string
  rationale?: string
  /** @format guid */
  program?: string
  createdOn: string
  /** @format guid */
  createdBy: string
  lastModifiedOn: string
  /** @format guid */
  lastModifiedBy: string
}

export interface Exception
  extends Omit<
    ExceptionResponse,
    'createdOn' | 'lastModifiedOn' | 'deletedOn'
  > {
  createdOn: Date
  lastModifiedOn: Date
}

export type RequirementExceptionLink = CreateExceptionLinkRequest & {
  linkId: number
  title: string
  specificationTitle: string
  text: string
}

export interface ExtendedRequirementExceptionLink
  extends RequirementExceptionLink {
  isSpecificationDeleted?: boolean
  isRequirementDeleted?: boolean
  error?: string | null
}

const withDates = (exception: ExceptionResponse): Exception => ({
  ...exception,
  createdOn: new Date(exception.createdOn),
  lastModifiedOn: new Date(exception.lastModifiedOn),
})

export const getAllExceptions = async (): Promise<Exception[]> => {
  const res = await api.get(createUrl())
  return res.exceptions.map(withDates)
}

export const getException = async (exceptionId: string): Promise<Exception> => {
  const res = await api.get(createUrl(exceptionId))
  return withDates(res)
}
export interface CreateExceptionRequest {
  title: string
  type: ExceptionType
  status?: ExceptionStatus
  variance?: string
  rationale?: string
  /** @format guid */
  program?: string
}

export const createException = async (
  request: CreateExceptionRequest,
): Promise<Exception> => {
  const res = await api.post(createUrl(), { body: request })
  return withDates(res)
}

export const updateException = async (
  id: string,
  request: Partial<CreateExceptionRequest>,
): Promise<Exception> => {
  const res = await api.patch(createUrl(id), { body: request })
  return withDates(res)
}

export const deleteException = async (
  exceptionId: string,
): Promise<{ id: string }> => {
  const res = await api.delete(createUrl(exceptionId))
  return res
}
