import {
  CellClickedEvent,
  CellMouseOutEvent,
  CellMouseOverEvent,
  ColDef,
  Column,
  ColumnEvent,
  DragStoppedEvent,
  RowDragEvent,
  SuppressHeaderKeyboardEventParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import {
  CELL_EDITOR_CLASSNAME,
  COLUMN_HEADER_CLASSNAME,
} from './TableBlock.tsx'
import {
  Block,
  ColumnData,
  RowData,
  TableBlockData,
} from '../../../../api/v2/blocks.ts'
import { SharedBlock } from '../../../../api/v2/sharedSpecifications.ts'
import { useSectionContext } from '../../../../context/SectionContext.tsx'
import debounce from '../../../../lib/debounce.ts'
import { deltaToPlaintext } from '../../../../lib/string.ts'

const toTableBlockData = (
  name: string,
  colDefs: ColDef<RowData, string>[],
  rows: RowData[],
): TableBlockData => {
  return {
    name,
    _data: {
      columns: colDefs.map(
        (colDef) =>
          ({
            id: colDef.field,
            title: colDef.headerName,
            width: colDef.width,
          }) as ColumnData,
      ),
      rows,
    },
  }
}

const toTableState = (
  block: TableBlockData,
): {
  colDefs: ColDef<RowData, string>[]
  rowData: RowData[]
} => {
  const colDefs = (block?._data?.columns || []).map((col, idx) =>
    createColumn(col.id, col.width, col.title, idx === 0),
  ) as ColDef<RowData>[]

  return {
    colDefs,
    rowData: block?._data?.rows || [],
  }
}

function suppressHeaderKeyboardEvent({
  event,
}: SuppressHeaderKeyboardEventParams) {
  const { key, target } = event

  if (key === 'Enter') {
    const headerInput = (target as HTMLElement)?.querySelector(
      `.${COLUMN_HEADER_CLASSNAME}`,
    )?.firstChild as HTMLInputElement

    if (headerInput) {
      headerInput.focus()
      event.preventDefault()
      return true
    }
  }

  return false
}

const onCellClicked = ({ event }: CellClickedEvent) => {
  const { target } = event || {}

  const cellInput = (target as HTMLElement)?.querySelector(
    `.${CELL_EDITOR_CLASSNAME}`,
  )?.firstChild as HTMLInputElement

  if (cellInput) {
    cellInput.focus()
    return true
  }
}

export const DEFAULT_COLUMN_WIDTH_PX = 124
const createColumn = (
  field?: string,
  width?: number,
  headerName?: string,
  rowDrag?: boolean,
): ColDef & { field: string } => ({
  field: field || uuidV4(),
  width: width || DEFAULT_COLUMN_WIDTH_PX,
  rowDrag,
  headerName,

  editable: true,
  wrapText: true,
  autoHeight: true,
  autoHeaderHeight: true,
  suppressHeaderKeyboardEvent,
  onCellClicked,
  singleClickEdit: true,
  sortable: false,
  rowDragText: (params) => {
    return params.rowNode?.data[0]
      ? deltaToPlaintext(params.rowNode?.data[0])
      : `Row ${params.rowNode?.rowIndex}`
  },
})

const createRow = (columns: ColDef<RowData, string>[]): RowData =>
  columns.reduce(
    (prev, col) =>
      ({
        ...prev,
        content: { ...prev.content, [col.field || '']: '' },
      }) as RowData,
    { content: {} } as RowData,
  )

const DEFAULT_COLUMN_COUNT = 2
const DEFAULT_ROW_COUNT = 1

const DEFAULT_COLUMNS = Array.from(Array(DEFAULT_COLUMN_COUNT)).map(() =>
  createColumn(),
) as ColDef[]

const DEFAULT_ROWS = Array.from(Array(DEFAULT_ROW_COUNT)).map(() =>
  createRow(DEFAULT_COLUMNS),
) as RowData[]

export const createNewTableBlockData = () => {
  return toTableBlockData('', DEFAULT_COLUMNS, DEFAULT_ROWS)
}

const useTableBlock = (
  block: Block<TableBlockData> | SharedBlock<TableBlockData>,
) => {
  const { updateTableBlock } = useSectionContext()

  const { rowData, colDefs } = useMemo(
    () => toTableState(block.data),
    [block.data],
  )

  const [isHoveringLastRow, setIsHoveringLastRow] = useState<boolean>(false)
  const [isHoveringLastCol, setIsHoveringLastCol] = useState<boolean>(false)

  const agGridRef = useRef<AgGridReact>(null)

  const onAddRow = useCallback(() => {
    const newRowData = [...rowData, createRow(colDefs)]
    updateTableBlock(
      block.id,
      toTableBlockData(block.data.name, colDefs, newRowData),
    )
  }, [block, colDefs, rowData, updateTableBlock])

  const onAddColumn = useCallback(() => {
    const newCol = createColumn()
    const newRowData = rowData.map((row) => ({
      ...row,
      content: {
        ...(row.content || {}),
        [newCol.field]: '',
      },
    }))

    const newColDefs = [...colDefs, newCol] as ColDef<RowData, string>[]

    updateTableBlock(
      block.id,
      toTableBlockData(block.data.name, newColDefs, newRowData),
    )
  }, [block, colDefs, rowData, updateTableBlock])

  const onDeleteColumn = useCallback(
    (field: string) => {
      if (!field) {
        return
      }

      const deleteIdx = colDefs.findIndex((colDef) => colDef.field === field)

      if (deleteIdx < 0) {
        return colDefs
      }

      const newColDefs = [...colDefs]
      newColDefs.splice(deleteIdx, 1)

      const newRowData = [...rowData].map((row) => {
        if (row.content) {
          delete row.content[field]
        }
        return row
      })

      updateTableBlock(
        block.id,
        toTableBlockData(block.data.name, newColDefs, newRowData),
      )
    },
    [block, colDefs, rowData, updateTableBlock],
  ) as (field: string) => void

  const onDuplicateColumn = useCallback(
    (field: string) => {
      if (!field) {
        return
      }

      const duplicateIdx = colDefs.findIndex((colDef) => colDef.field === field)

      if (duplicateIdx < 0) {
        return colDefs
      }

      const newCol = createColumn(
        undefined,
        colDefs[duplicateIdx].width,
        colDefs[duplicateIdx].headerName,
        false,
      )

      const newColDefs = [
        ...[...colDefs].splice(0, duplicateIdx + 1),
        newCol,
        ...[...colDefs].splice(duplicateIdx + 1),
      ] as ColDef<RowData, string>[]

      const newRowData = rowData.map(
        (rowData) =>
          ({
            ...rowData,
            content: {
              ...(rowData.content || {}),
              [newCol.field]: (rowData.content || {})[
                colDefs[duplicateIdx].field || ''
              ],
            },
          }) as RowData,
      )

      updateTableBlock(
        block.id,
        toTableBlockData(block.data.name, newColDefs, newRowData),
      )
    },
    [block, colDefs, rowData, updateTableBlock],
  )

  const onHeaderValueChange = useCallback(
    (field: string, val?: string) => {
      const newColDefs = colDefs.map((col) => {
        return col.field === field
          ? {
              ...col,
              headerName: val,
            }
          : col
      })

      updateTableBlock(
        block.id,
        toTableBlockData(block.data.name, newColDefs, rowData),
      )
    },
    [block, colDefs, rowData, updateTableBlock],
  )

  const onCellValueChange = useCallback(
    ({ rowIndex, field, newValue }): void => {
      const newRowData = rowData.map((row, idx) => {
        return idx === rowIndex
          ? ({
              ...row,
              content: {
                ...row.content,
                [field]: newValue,
              },
            } as RowData)
          : row
      })

      updateTableBlock(
        block.id,
        toTableBlockData(block.data.name, colDefs, newRowData),
      )
    },
    [block, colDefs, rowData, updateTableBlock],
  )

  const onCellMouseOverOrOut = useCallback(
    (e: CellMouseOverEvent<string> | CellMouseOutEvent<string>) => {
      if (agGridRef.current) {
        const columns = agGridRef.current.api.getAllDisplayedColumns()
        const lastColId = columns[columns.length - 1].getColDef().field
        setIsHoveringLastCol(() => lastColId === e.column.getColDef().field)
      }
      setIsHoveringLastRow(() => e.rowIndex === rowData.length - 1)
    },
    [rowData.length],
  )

  const onColumnDragEnd = useCallback(
    (e: DragStoppedEvent) => {
      const columns = e.api.getAllDisplayedColumns()
      if (!columns) {
        return
      }

      const newColDefs = columns.map((col: Column) => col.getColDef())

      const isSame = colDefs.every(
        (col, idx) => col.field === newColDefs[idx].field,
      )

      if (!isSame) {
        updateTableBlock(
          block.id,
          toTableBlockData(block.data.name, newColDefs, rowData),
        )
      }
    },
    [block, colDefs, rowData, updateTableBlock],
  )

  const onDeleteRow = useCallback(
    (deleteIdx: number) => {
      const newRowData = [...rowData]
      newRowData.splice(deleteIdx, 1)

      updateTableBlock(
        block.id,
        toTableBlockData(block.data.name, colDefs, newRowData),
      )
    },
    [block, colDefs, rowData, updateTableBlock],
  ) as (deleteIdx: number) => void

  const onDuplicateRow = useCallback(
    (duplicateIdx: number) => {
      const newRow = { ...rowData[duplicateIdx] }

      const newRowData = [
        ...[...rowData].splice(0, duplicateIdx + 1),
        newRow,
        ...[...rowData].splice(duplicateIdx + 1),
      ] as RowData[]

      updateTableBlock(
        block.id,
        toTableBlockData(block.data.name, colDefs, newRowData),
      )
    },
    [block, colDefs, rowData, updateTableBlock],
  )

  const onRowDragEnd = useCallback(
    (e: RowDragEvent) => {
      const rows = e.api.getRenderedNodes()
      if (!rows) {
        return
      }
      const newRowData = rows.map((row) => {
        return {
          content: row.data,
        } as RowData
      })

      updateTableBlock(
        block.id,
        toTableBlockData(block.data.name, colDefs, newRowData),
      )
    },
    [block, colDefs, updateTableBlock],
  )

  const onTableNameChange = useCallback(
    (name?: string | null) => {
      updateTableBlock(block.id, toTableBlockData(name || '', colDefs, rowData))
    },
    [block, colDefs, rowData, updateTableBlock],
  )

  const columnWidthListener = useCallback(
    (e: ColumnEvent) => {
      const field = e.column?.getColDef().field
      const newWidth = e.column?.getActualWidth()
      const newColDefs = colDefs.map((colDef) => ({
        ...colDef,
        ...(field === colDef.field ? { width: newWidth } : {}),
      }))

      debounce(
        updateTableBlock(
          block.id,
          toTableBlockData(block.data.name, newColDefs, rowData),
        ),
        8000,
      )
    },
    [block, colDefs, rowData, updateTableBlock],
  )

  const onGridMouseLeave = useCallback(() => {
    setIsHoveringLastRow(false)
    setIsHoveringLastCol(false)
  }, [])

  useEffect(() => {
    const gridApiRef = agGridRef?.current?.api

    if (!gridApiRef) {
      return
    }

    gridApiRef.getColumns()?.forEach((col) => {
      col.addEventListener('widthChanged', columnWidthListener)
    })

    return () => {
      if (!gridApiRef) {
        return
      }

      gridApiRef.getColumns()?.forEach((col) => {
        col.removeEventListener('widthChanged', columnWidthListener)
      })
    }
  }, [colDefs, columnWidthListener])

  return {
    agGridRef,
    colDefs,
    rowData,
    isHoveringLastCol,
    isHoveringLastRow,
    onTableNameChange,
    onHeaderValueChange,
    onCellValueChange,
    onCellMouseOverOrOut,
    onGridMouseLeave,
    onAddRow,
    onAddColumn,
    onDeleteColumn,
    onDuplicateColumn,
    onColumnDragEnd,
    onDeleteRow,
    onDuplicateRow,
    onRowDragEnd,
  }
}

export default useTableBlock
