import { Close } from '@carbon/icons-react'
import { MouseEventHandler, ReactNode, Ref } from 'react'

import AddLinkedRequirementExceptionModal from './AddLinkedRequirementExceptionModal'
import AddLinkedRequirementModal from './AddLinkedRequirementModal'
import ConfirmationModal from './ConfirmationModal'
import CreateEvidenceModal from './CreateEvidenceModal'
import DuplicateSpecificationModal from './DuplicateSpecificationModal'
import GenerateReportModal from './GenerateReportModal'
import ImportExcelModal from './ImportExcelModal'
import ImportPDFModal from './ImportPdfModal'
import ImportWordModal from './ImportWordModal'
import styles from './index.module.css'
import KeyboardShortcutsModal from './KeyboardShortcutsModal'
import LinkedEvidenceViewModal from './LinkedEvidenceViewModal'
import LinkedRequirementViewModal from './LinkedRequirementViewModal'
import LinkEvidenceModal from './LinkEvidenceModal'
import NewSpecificationModal from './NewSpecificationModal'
import RemoveStellUserModal from './RemoveStellUserModal'
import ShareReviewModal from './ShareReviewModal'
import ShareSpecificationModal from './ShareSpecificationModal'
import SpecificationHistoryModal from './SpecificationHistoryModal'
import SpecificationPdfViewerModal from './SpecificationPdfViewerModal'
import SpecificationReviewersModal from './SpecificationReviewersModal'
import SpecificationTreeBuilderModal from './SpecificationTreeBuilderModal'
import ChangeRequestDrawer from '../drawer/ChangeRequestDrawer'
import EvidenceActivityDrawer from '../drawer/EvidenceActivityDrawer'
import EvidenceRecordDrawer from '../drawer/EvidenceRecordDrawer'
import RequirementDrawer from '../drawer/RequirementDrawer'
import SearchRequirementDrawer from '../drawer/SearchRequirementDrawer'

interface ModalProps {
  title: string
  subtitle?: string
  icon?: ReactNode
  onClose?: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
  innerRef?: Ref<any>
}

const Modal = (props: ModalProps) => {
  const { title, subtitle, icon, onClose, children, innerRef } = props

  return (
    <div className={styles.container}>
      <div ref={innerRef} className={styles.content}>
        <div className={styles.header}>
          <div className={styles.titleSection}>
            <div className={styles.title}>
              {icon && <>{icon}</>}
              <span>{title}</span>
            </div>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
          {onClose && (
            <button className={styles.closeBtn} onClick={(e) => onClose(e)}>
              <Close size={20} />
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal

export {
  ImportPDFModal,
  ImportExcelModal,
  ImportWordModal,
  RequirementDrawer,
  SearchRequirementDrawer,
  ChangeRequestDrawer,
  GenerateReportModal,
  DuplicateSpecificationModal,
  ConfirmationModal,
  RemoveStellUserModal,
  SpecificationHistoryModal,
  SpecificationReviewersModal,
  CreateEvidenceModal,
  LinkEvidenceModal,
  AddLinkedRequirementModal,
  AddLinkedRequirementExceptionModal,
  LinkedRequirementViewModal,
  LinkedEvidenceViewModal,
  EvidenceRecordDrawer,
  EvidenceActivityDrawer,
  ShareSpecificationModal,
  SpecificationPdfViewerModal,
  ShareReviewModal,
  NewSpecificationModal,
  SpecificationTreeBuilderModal,
  KeyboardShortcutsModal,
}
