import { TextScale } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { TextBlockData } from '../../../api/v2/blocks'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'
import { EMPTY_DELTA } from '../../../lib/string'
import { toastError } from '../../toast'

const ConvertToHeadingBlockAction = (props: BlockActionItemProps) => {
  const { block, closeMenu } = props
  const { contentIsEditable } = useSpecificationContext()
  const { convertBlockToHeadingBlock } = useSectionContext()
  const { quillDelta } = (block?.data as TextBlockData)?._data ?? {
    quillDelta: EMPTY_DELTA,
  }

  return (
    contentIsEditable && (
      <button
        className={styles.actionItem}
        onClick={async () => {
          try {
            await convertBlockToHeadingBlock(block?.id, quillDelta)
            closeMenu()
          } catch (e) {
            toastError(
              'Failed to convert text block into heading block',
              'Please refresh the page and try again.',
            )
          }
        }}
      >
        <TextScale />
        Convert to heading
      </button>
    )
  )
}

export default ConvertToHeadingBlockAction
