import { CaretDown, OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './EvidencesTableColumns.module.css'
import { EvidenceRequirementLink } from '../../api/v2/links.ts'
import { Specification, getSpecification } from '../../api/v2/specifications'
import Dropdown from '../../components/dropdown'
import { TableColumn } from '../../components/table/Table'
import Tag, { TAG_COLORS } from '../../components/tag'
import { EVIDENCE_ACTIVITY_TAG_COLORS } from '../../components/tag/EvidenceActivityTypeTag.tsx'
import EvidenceTypeTag from '../../components/tag/EvidenceTypeTag.tsx'
import ProgramTag from '../../components/tag/ProgramTag.tsx'
import Tooltip from '../../components/tooltip/Tooltip'
import { useAttributesContext } from '../../context/AttributesContext'
import { useAuth } from '../../context/AuthContext.tsx'
import { useEvidenceActivitiesContext } from '../../context/EvidenceActivitiesContext.tsx'
import { useEvidencesContext } from '../../context/EvidencesContext'
import useClickOutside from '../../hooks/useClickOutside'
import { useModals } from '../../hooks/useModals'
import useUsers from '../../hooks/useUsers.ts'
import { monthDayYear } from '../../lib/date'
import { EvidenceCadence } from '../../types/enums'

const DateCreated = ({ data }) => {
  return (
    <div className={styles.dateCreated}>{monthDayYear(data.createdOn)}</div>
  )
}

const RecordTitle = ({ data }) => {
  return (
    <div className={styles.recordTitle}>
      <span>{data.title}</span>
    </div>
  )
}

const Type = ({ data }) => {
  return (
    <div>
      <EvidenceTypeTag type={data.type} />
    </div>
  )
}

const Method = ({ data }) => {
  const { getEvidenceMethodById } = useAttributesContext()
  const method = getEvidenceMethodById(data.method)

  const tagColor = method
    ? {
        fontColor: method.metadata.STYLES.COLOR_FONT,
        backgroundColor: method.metadata.STYLES.COLOR_BG,
      }
    : TAG_COLORS.gray3NoHover

  return (
    <div className={styles.method}>
      <Tag text={method?.name || ''} color={tagColor} />
    </div>
  )
}

const Program = ({ data }) => {
  return (
    <>
      {data.program && (
        <div className={styles.program}>
          <ProgramTag programId={data.program} />
        </div>
      )}
    </>
  )
}

const LinkedRequirements = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(() => setIsOpen(false), isOpen)
  const { openLinkedRequirementViewModal } = useModals()
  const { unlinkEvidence } = useEvidencesContext()
  const { userIsEditor } = useAuth()
  const { links }: { links: EvidenceRequirementLink[] } = data

  const pips = links.slice(0, 6)
  const hiddenPips = links.slice(6).length

  return (
    <div ref={ref} className={styles.linkedRequirements}>
      {links && links.length > 0 && (
        <>
          <button onClick={() => setIsOpen(!isOpen)}>
            {pips.map((link) => (
              <div
                key={link.link_id}
                className={`${styles.pip} ${styles.requirements}`}
              />
            ))}
            {hiddenPips > 0 && (
              <div className={styles.hiddenCount}>+{hiddenPips}</div>
            )}
            <CaretDown size={20} />
          </button>
          <Dropdown className={styles.dropdown} isOpen={isOpen}>
            <span className={styles.dropdownLabel}>Linked requirements</span>
            <div className={styles.linkedItems}>
              {links.map((link) => {
                return (
                  <button
                    key={`${link.link_id}`}
                    onClick={() => {
                      openLinkedRequirementViewModal({
                        linkedRequirementData: {
                          requirementId: link.requirement_id,
                          specificationId: link.specification_id,
                        },
                        userCanEdit: userIsEditor(link.specification_id),
                        onUnlink: async () =>
                          unlinkEvidence(link.link_id, data.id),
                      })
                      setIsOpen(!isOpen)
                    }}
                  >
                    <div className={styles.linkedItem}>
                      <span className={styles.title}>
                        {link.requirement_title || 'No Title'}
                      </span>
                      <span className={styles.linkedId}>{`${
                        link.section_number || 'No Section Number'
                      }`}</span>
                    </div>
                  </button>
                )
              })}
            </div>
          </Dropdown>
        </>
      )}
    </div>
  )
}

const LinkedActivities = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(() => setIsOpen(false), isOpen)
  const { openEvidenceActivityDrawer } = useModals()
  const { evidenceActivities } = useEvidenceActivitiesContext()
  const { getEvidenceMethodById } = useAttributesContext()
  const { getUserById } = useUsers()
  const { id } = data
  const filteredActivities = evidenceActivities.filter((e) => e.recordId === id)

  const pips = filteredActivities.slice(0, 6)
  const hiddenPips = filteredActivities.slice(6).length
  const method = data?.method ? getEvidenceMethodById(data?.method) : undefined
  const parentRecordColor = method?.metadata.STYLES.COLOR_BG
  return (
    <div ref={ref} className={styles.linkedRequirements}>
      {filteredActivities && filteredActivities.length > 0 && (
        <>
          <button onClick={() => setIsOpen(!isOpen)}>
            {pips.map((evidenceActivity) => (
              <div
                key={`pip-${evidenceActivity.id}`}
                className={`${styles.pip} ${styles.activities}`}
              />
            ))}
            {hiddenPips > 0 && (
              <div className={styles.hiddenCount}>+{hiddenPips}</div>
            )}
            <CaretDown size={20} />
          </button>
          <Dropdown className={styles.dropdown} isOpen={isOpen}>
            <span className={styles.dropdownLabel}>Linked activites</span>
            <div className={styles.linkedItems}>
              {filteredActivities.map((evidenceActivity) => {
                const owners = evidenceActivity.owners.map((o) =>
                  getUserById(o),
                )
                return (
                  <button
                    key={`dropdown-${evidenceActivity.id}`}
                    onClick={() => {
                      openEvidenceActivityDrawer({
                        activity: { ...evidenceActivity, owners },
                        parentRecord: data,
                        parentRecordColor: parentRecordColor,
                        jiraConnectionUrl: evidenceActivity.jiraIssueId,
                      })
                      setIsOpen(!isOpen)
                    }}
                  >
                    <div className={styles.linkedItem}>
                      <Tag
                        text={evidenceActivity.title || 'No Title'}
                        color={
                          EVIDENCE_ACTIVITY_TAG_COLORS[evidenceActivity.type]
                        }
                      />
                    </div>
                  </button>
                )
              })}
            </div>
          </Dropdown>
        </>
      )}
    </div>
  )
}

const LinkedSpecifications = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(() => setIsOpen(false), isOpen)
  const [specifications, setSpecifications] = useState<Specification[]>([])
  const { links }: { links: EvidenceRequirementLink[] } = data

  useEffect(() => {
    const loadSpecificationDetails = async () => {
      try {
        const uniqueSpecIds = new Set<string>(
          links.map((link) => link.specification_id),
        )
        const specs = await Promise.all(
          Array.from(uniqueSpecIds).map((id) => getSpecification(id)),
        )
        setSpecifications(specs)
      } catch (error) {
        console.error('Unable to get linked specification details', error)
      }
    }
    loadSpecificationDetails()
  }, [links])

  return (
    <div ref={ref} className={styles.linkedSpecifications}>
      {specifications.length > 0 && (
        <>
          <button onClick={() => setIsOpen(!isOpen)}>
            {`${specifications.length} specification${
              specifications.length !== 1 ? 's' : ''
            }`}
            <CaretDown size={20} />
          </button>
          <Dropdown className={styles.dropdown} isOpen={isOpen}>
            <span className={styles.dropdownLabel}>Linked specifications</span>
            <div className={styles.linkedItems}>
              {specifications.map((spec) => (
                <Link
                  to={`/specifications/${spec.id}`}
                  target="_blank"
                  key={spec.id}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className={styles.linkedItem}>
                    <span className={styles.title}>
                      {spec.name || 'No Name'}
                    </span>
                    <span className={styles.linkedId}>
                      {`${spec.specificationIdentifier}` ||
                        'No Document Number'}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </Dropdown>
        </>
      )}
    </div>
  )
}

const Cadence = ({ data }) => {
  const { getEvidenceCadenceById } = useAttributesContext()
  return (
    <>
      <div className={styles.cadence}>
        {data.cadences.map((cadence) => {
          const cadenceName = getEvidenceCadenceById(cadence)?.name
          return (
            <Tag
              key={cadence}
              text={cadenceName ? cadenceName : ''}
              color={
                cadenceName === EvidenceCadence.Production
                  ? TAG_COLORS.purple2NoHover
                  : TAG_COLORS.green3NoHover
              }
            />
          )
        })}
      </div>
    </>
  )
}

const Actions = ({ data }) => {
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const { openConfirmationModal } = useModals()
  const { deleteEvidenceRecord } = useEvidencesContext()

  const closeMenu = () => {
    if (open) {
      setOpen(false)
    }
  }

  const ref = useClickOutside(closeMenu)

  useEffect(() => {
    setDisabled(data.links.length > 0)
  }, [data.links])

  const onDelete = () => {
    closeMenu()
    openConfirmationModal({
      title: 'Delete Evidence',
      titleIcon: <TrashCan size={20} />,
      promptText: `Are you sure you want to delete evidence with record title '${data.title}'?`,
      onConfirm: () => {
        deleteEvidenceRecord(data.id)
      },
    })
  }
  return (
    <div ref={ref} className={styles.actions}>
      <button onClick={() => setOpen(!open)}>
        <OverflowMenuVertical size={24} />
      </button>
      <Dropdown className={styles.dropdown} isOpen={open}>
        <Tooltip
          content={
            disabled
              ? `You can't delete this evidence because it is linked to one or more requirements`
              : undefined
          }
          placement="right"
        >
          <button
            className={`${styles.actionItem} ${styles.deleteButton}`}
            onClick={onDelete}
            disabled={disabled}
          >
            Delete
          </button>
        </Tooltip>
      </Dropdown>
    </div>
  )
}

export enum EvidenceRecordColumnLabels {
  ACTIONS = '',
  TITLE = 'Record',
  TYPE = 'Type',
  DATE_CREATED = 'Date Created',
  LINKED_REQUIREMENTS = 'Linked Requirements',
  LINKED_SPECIFICATIONS = 'Linked Specifications',
  LINKED_ACTIVITES = 'Linked Activities',
  METHOD = 'Method',
  CADENCE = 'Cadence',
  PROGRAM = 'Program',
}

export type ERCL =
  | ''
  | 'Record'
  | 'Type'
  | 'Date Created'
  | 'Linked Requirements'
  | 'Linked Specifications'
  | 'Linked Activities'
  | 'Method'
  | 'Cadence'
  | 'Program'

const EvidencesTableColumns: TableColumn[] = [
  {
    label: EvidenceRecordColumnLabels.ACTIONS,
    Component: Actions,
    transparent: true,
  },
  {
    label: EvidenceRecordColumnLabels.TITLE,
    Component: RecordTitle,
  },
  {
    label: EvidenceRecordColumnLabels.TYPE,
    Component: Type,
  },
  {
    label: EvidenceRecordColumnLabels.DATE_CREATED,
    Component: DateCreated,
  },
  {
    label: EvidenceRecordColumnLabels.LINKED_REQUIREMENTS,
    Component: LinkedRequirements,
  },
  {
    label: EvidenceRecordColumnLabels.LINKED_SPECIFICATIONS,
    Component: LinkedSpecifications,
  },
  {
    label: EvidenceRecordColumnLabels.LINKED_ACTIVITES,
    Component: LinkedActivities,
  },
  {
    label: EvidenceRecordColumnLabels.METHOD,
    Component: Method,
  },
  {
    label: EvidenceRecordColumnLabels.CADENCE,
    Component: Cadence,
  },
  {
    label: EvidenceRecordColumnLabels.PROGRAM,
    Component: Program,
  },
]

export default EvidencesTableColumns
