import styles from './SpecificationReviewsTableColumns.module.css'
import { TableColumn } from '../../components/table/Table'
import Tag, { TAG_COLORS } from '../../components/tag'
import { monthDayYear } from '../../lib/date'
import {
  SNAPSHOT_REVIEW_STATUS_TO_NAME,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums.ts'

const ReviewName = ({ data }) => {
  return (
    <div className={`${styles.name} ${styles.cell}`}>
      {data.name || 'Untitled'}
    </div>
  )
}

const SpecificationName = ({ data }) => {
  return (
    <div className={`${styles.name} ${styles.cell}`}>
      {data.specificationName || 'Untitled'}
    </div>
  )
}

export const REVIEW_STATUS_COLOR = {
  [SpecificationSnapshotReviewStatus.Submitted]: TAG_COLORS.green12NoHover,
  [SpecificationSnapshotReviewStatus.Complete]: TAG_COLORS.gray3NoHover,
}

const Status = ({ data }) => {
  return (
    <div>
      <Tag
        text={SNAPSHOT_REVIEW_STATUS_TO_NAME[data.status]}
        color={REVIEW_STATUS_COLOR[data.status]}
      />
    </div>
  )
}

const CreatedBy = ({ data }) => {
  return (
    <div className={styles.cell}>
      {data.submittedByFirstName} {data.submittedByLastName}
    </div>
  )
}

const DateShared = ({ data }) => {
  return <div className={styles.cell}>{monthDayYear(data.submittedOn)}</div>
}

const RequirementCount = ({ data }) => {
  return <div>{data.requirementCount}</div>
}

enum SpecificationReviewsColumnLabels {
  SpecificationName = 'Spec Name',
  ReviewName = 'Review Name',
  Status = 'Status',
  DateShared = 'Date Shared',
  CreatedBy = 'Created By',
  RequirementCount = '# Requirements',
}

const SpecificationReviewsTableColumns: Array<TableColumn> = [
  {
    label: SpecificationReviewsColumnLabels.ReviewName,
    Component: ReviewName,
    width: 'minmax(auto, 235px)',
  },
  {
    label: SpecificationReviewsColumnLabels.SpecificationName,
    Component: SpecificationName,
    width: 'minmax(auto, 340px)',
  },
  {
    label: SpecificationReviewsColumnLabels.Status,
    Component: Status,
  },
  {
    label: SpecificationReviewsColumnLabels.CreatedBy,
    Component: CreatedBy,
  },
  {
    label: SpecificationReviewsColumnLabels.DateShared,
    Component: DateShared,
  },
  {
    label: SpecificationReviewsColumnLabels.RequirementCount,
    Component: RequirementCount,
  },
]

export default SpecificationReviewsTableColumns
