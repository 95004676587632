import { RuleTest, Time } from '@carbon/icons-react'
import { useMemo, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import styles from './SharedSpecificationReviewPage.module.css'
import { BlockType } from '../../api/v2/blocks.ts'
import * as sharedSpecificationApi from '../../api/v2/sharedSpecifications.ts'
import * as api from '../../api/v2/sharedSpecifications.ts'
import {
  completeSharedSpecificationSnapshotReview,
  SharedSpecificationAttributeValue,
} from '../../api/v2/sharedSpecifications.ts'
import { SpecificationSnapshotPermission } from '../../api/v2/users.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types.ts'
import Tag from '../../components/tag'
import { toastError, toastSuccess } from '../../components/toast'
import { useAuth } from '../../context/AuthContext.tsx'
import { monthDayYear } from '../../lib/date.ts'
import {
  AttributeName,
  SNAPSHOT_REVIEW_STATUS_TO_NAME,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums.ts'
import SharedSpecificationReviewRequirementView from '../shared-specification-review/SharedSpecificationReviewRequirementView.tsx'
import { REVIEW_STATUS_COLOR } from '../shared-workspace/SpecificationReviewsTableColumns.tsx'

const mapTagsById = (
  tags: SharedSpecificationAttributeValue[] | undefined,
): Record<string, SharedSpecificationAttributeValue> => {
  if (!tags) {
    return {}
  }

  return tags.reduce((acc, value) => {
    return {
      ...acc,
      [value.id]: value,
    }
  }, {})
}

const FILTER_KEYS = [FilterKey.SharedRequirementType]

const SharedSpecificationReviewPage = () => {
  const {
    snapshot,
    review: loaderReview,
    commentsByRequirementId: initialCommentsByRequirementId,
  } = useLoaderData() as {
    snapshot: sharedSpecificationApi.SharedSpecificationSnapshot
    review: sharedSpecificationApi.SharedSpecificationSnapshotReview
    commentsByRequirementId: Record<
      string,
      sharedSpecificationApi.ReviewRequirementComment[]
    >
  }
  const { userPermissions } = useAuth()
  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)
  const [commentsByRequirementId, setCommentsByRequirementId] = useState<
    Record<string, sharedSpecificationApi.ReviewRequirementComment[]>
  >(initialCommentsByRequirementId)
  const [review, setReview] =
    useState<sharedSpecificationApi.SharedSpecificationSnapshotReview>(
      loaderReview,
    )
  const [completingReview, setCompletingReview] = useState(false)

  const userIsSnapshotOwner = useMemo(
    () =>
      userPermissions?.specificationSnapshots?.[snapshot.id]?.role ===
      SpecificationSnapshotPermission.Owner,
    [userPermissions?.specificationSnapshots, snapshot],
  )

  const requirementsById: Record<
    string,
    sharedSpecificationApi.SharedSpecificationRequirement
  > = snapshot.contents.requirements.reduce(
    (acc, req) => ({ ...acc, [req.id]: req }),
    {},
  )
  const orderedRequirements = snapshot.contents.documentBlocks
    .filter((block) => block.type === BlockType.Requirement)
    .map((block) => requirementsById[block.id])
    .filter((block) => !!block)

  const requirementTypes = snapshot.contents.customAttributes.find(
    (attr) => attr.name === AttributeName.RequirementType,
  )?.values

  const requirementTypesById = mapTagsById(requirementTypes)

  const filterData = {
    [FilterKey.SharedRequirementType]: requirementTypes,
  }

  const filteredRequirements = useMemo(() => {
    return orderedRequirements.filter(
      (r) =>
        filters[FilterKey.SharedRequirementType].length === 0 ||
        r.types.some((type) =>
          filters[FilterKey.SharedRequirementType].includes(type),
        ),
    )
  }, [orderedRequirements, filters])

  const addCommentToRequirement = async (
    requirementId: string,
    comment: string,
  ) => {
    try {
      const commentResponse = await api.addReviewRequirementComment(
        snapshot.specificationId,
        snapshot.id,
        review.id,
        requirementId,
        comment,
      )

      setCommentsByRequirementId((prev) => {
        return {
          ...prev,
          [requirementId]: [...(prev[requirementId] || []), commentResponse],
        }
      })
    } catch (error) {
      console.error('Unable to add comment', error)
      return false
    }

    return true
  }

  const completeReview = async () => {
    setCompletingReview(true)
    try {
      await completeSharedSpecificationSnapshotReview(
        snapshot.specificationId,
        snapshot.id,
        review.id,
      )
      setReview((prev) => ({
        ...prev,
        status: SpecificationSnapshotReviewStatus.Complete,
      }))
      toastSuccess('Review completed')
    } catch (error) {
      console.error('Unable to complete review')
      toastError('Unable to complete the review', 'Try again later')
    } finally {
      setCompletingReview(false)
    }
  }

  return (
    <div>
      <div className={styles.headerSection}>
        <div className={styles.leftHeader}>
          <div className={styles.pageDescription}>Review</div>
          <div className={styles.title}>
            {snapshot?.specificationName || 'Untitled'}
          </div>
        </div>
        <div className={styles.rightHeader}>
          <div className={styles.info}>
            {review && (
              <Tag
                text={SNAPSHOT_REVIEW_STATUS_TO_NAME[review.status]}
                color={REVIEW_STATUS_COLOR[review.status]}
              />
            )}
            <div className={styles.dateTime}>
              <Time size={16} />
              {snapshot?.createdOn && monthDayYear(snapshot.createdOn)}
            </div>
          </div>
          {userIsSnapshotOwner && (
            <Button
              text="Complete"
              endIcon={<RuleTest />}
              color={BUTTON_COLORS.PRIMARY}
              onClick={completeReview}
              disabled={
                review.status === SpecificationSnapshotReviewStatus.Complete ||
                completingReview
              }
            />
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.filters}>
          <FilterDropdown
            menus={FILTER_KEYS}
            activeFilters={filters}
            onSelectFilter={toggleFilter}
            filterData={filterData}
          />
          <AppliedFilters
            filterKeys={FILTER_KEYS}
            filters={filters}
            onRemove={toggleFilter}
            filterData={filterData}
          />
        </div>
        <div className={styles.requirements}>
          {filteredRequirements.length > 0 ? (
            filteredRequirements.map((requirement) => (
              <SharedSpecificationReviewRequirementView
                key={requirement.id}
                review={review}
                reviewRequirement={review.requirements[requirement.id]}
                requirement={requirement}
                requirementTypesById={requirementTypesById}
                comments={commentsByRequirementId[requirement.id] || []}
                addCommentToRequirement={addCommentToRequirement}
              />
            ))
          ) : (
            <div className={styles.center}>No results</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SharedSpecificationReviewPage
