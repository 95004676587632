import { useParams } from 'react-router-dom'
import SharedHeadingBlock from './shared-blocks/SharedHeadingBlock.tsx'
import SharedImageBlock from './shared-blocks/SharedImageBlock.tsx'
import SharedRequirementBlock from './shared-blocks/SharedRequirementBlock.tsx'
import SharedTableBlock from './shared-blocks/SharedTableBlock.tsx'
import SharedTextBlock from './shared-blocks/SharedTextBlock.tsx'
import styles from './SharedDocumentView.module.css'
import {
  BlockType,
  HeadingBlockData,
  ImageBlockData,
  RequirementBlockData,
  TableBlockData,
  TextBlockData,
} from '../../api/v2/blocks'
import { SharedBlock } from '../../api/v2/sharedSpecifications'
import { useSharedSpecificationContext } from '../../context/SharedSpecificationContext'

const SharedDocumentView = () => {
  const { requirementId } = useParams()
  const { specificationSnapshot } = useSharedSpecificationContext()
  const specificationId = specificationSnapshot?.contents.specification.id
  const specificationIdentifier =
    specificationSnapshot?.contents.specification.identifier || ''

  return (
    <div className={styles.document}>
      <div className={styles.blocks}>
        {specificationSnapshot?.contents.documentBlocks.map((block) => {
          switch (block.type) {
            case BlockType.Text:
              return (
                <SharedTextBlock
                  key={block.id}
                  block={block as SharedBlock<TextBlockData>}
                />
              )
            case BlockType.Requirement:
              return (
                <SharedRequirementBlock
                  key={block.id}
                  block={block as SharedBlock<RequirementBlockData>}
                  specificationIdentifier={specificationIdentifier}
                  scrollToOnLoad={requirementId === block.id}
                />
              )
            case BlockType.Heading:
              return (
                <SharedHeadingBlock
                  key={block.id}
                  block={block as SharedBlock<HeadingBlockData>}
                />
              )
            case BlockType.Table:
              return (
                <SharedTableBlock
                  key={block.id}
                  block={block as SharedBlock<TableBlockData>}
                />
              )
            case BlockType.Image:
              return (
                <SharedImageBlock
                  key={block.id}
                  specificationId={specificationId}
                  specificationSnapshotId={specificationSnapshot.id}
                  block={block as SharedBlock<ImageBlockData>}
                />
              )
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

export default SharedDocumentView
