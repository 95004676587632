import { WatsonHealthCdCreateExchange } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import {
  BlockType,
  HeadingBlockData,
  TextBlockData,
} from '../../../api/v2/blocks'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'
import { EMPTY_DELTA } from '../../../lib/string'
import { toastError } from '../../toast'

const ConvertToRequirementAction = (props: BlockActionItemProps) => {
  const { block, closeMenu } = props
  const { contentIsEditable } = useSpecificationContext()
  const { convertBlockToRequirement } = useSectionContext()
  const valueToConvert =
    block.type === BlockType.Heading
      ? (block.data as HeadingBlockData)?._data?.text ?? ''
      : (block.data as TextBlockData)?._data?.quillDelta ?? EMPTY_DELTA

  return (
    <>
      {contentIsEditable && (
        <button
          className={styles.actionItem}
          onClick={async () => {
            try {
              await convertBlockToRequirement(
                block.id,
                block.type,
                valueToConvert,
              )
              closeMenu()
            } catch (e) {
              toastError(
                'Failed to convert text block into requirement block',
                'Please refresh the page and try again.',
              )
            }
          }}
        >
          <WatsonHealthCdCreateExchange />
          Convert to requirement
        </button>
      )}
    </>
  )
}

export default ConvertToRequirementAction
