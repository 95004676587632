import api, { usingPublicTenant } from './api'

const createUrl: (
  specificationId: string,
  documentId: string,
  withSections?: boolean,
  sectionId?: string,
) => string = (specificationId, documentId, withSections, sectionId) =>
  `/api/v2/specifications/${specificationId}/documents/${documentId}${
    withSections ? '/sections' : ''
  }${sectionId ? `/${sectionId}` : ''}`

const docWithDates: (doc: DocumentResponse) => SpecificationDocument = (
  doc,
) => ({
  ...doc,
  createdOn: new Date(doc.createdOn),
  lastModifiedOn: new Date(doc.lastModifiedOn),
})

const sectionWithDates: (section: SectionResponse) => Section = (section) => ({
  ...section,
  createdOn: new Date(section.createdOn),
  lastModifiedOn: new Date(section.lastModifiedOn),
})

interface DocumentResponse {
  /** @format guid */
  id: string
  name: string
  sections: string[]
  sourceAssetId?: string
  /** @format guid */
  createdBy: string
  createdOn: string
  /** @format guid */
  lastModifiedBy: string
  lastModifiedOn: string
}

export interface SpecificationDocument
  extends Omit<DocumentResponse, 'createdOn' | 'lastModifiedOn'> {
  createdOn: Date
  lastModifiedOn: Date
}

export type DocumentElement = {
  type: 'BLOCK' | 'SECTION'
  /** @format guid */
  id: string
}

interface SectionResponse {
  /** @format guid */
  id: string
  type: 'BODY'
  /** @format guid */
  parentSectionId: string
  /** @format guid */
  documentId: string
  elements: Array<DocumentElement>
  /** @format guid */
  createdBy: string
  createdOn: string
  /** @format guid */
  lastModifiedBy: string
  lastModifiedOn: string
}

export interface Section
  extends Omit<SectionResponse, 'createdOn' | 'lastModifiedOn'> {
  createdOn: Date
  lastModifiedOn: Date
}

export const getDocument: (
  specificationId: string,
  documentId: string,
) => Promise<SpecificationDocument> = async (specificationId, documentId) => {
  const res = await api.get(createUrl(specificationId, documentId))
  return docWithDates(res)
}

export const createDocumentSection: (
  specificationId: string,
  documentId: string,
  parentSectionId: string | null,
  type?: 'BODY',
) => Promise<Section> = async (
  specificationId,
  documentId,
  parentSectionId,
  type = 'BODY',
) => {
  const res = await api.post(createUrl(specificationId, documentId, true), {
    body: { parentSectionId, type },
  })
  return sectionWithDates(res)
}

export const reorderDocumentSections: (
  specificationId: string,
  documentId: string,
  sections: Array<string>,
) => Promise<SpecificationDocument> = async (
  specificationId,
  documentId,
  sections,
) => {
  const res = await api.patch(createUrl(specificationId, documentId, true), {
    body: { sections },
  })
  return docWithDates(res)
}

export const getSection: (
  specificationId: string,
  documentId: string,
  sectionId: string,
) => Promise<Section> = (specificationId, documentId, sectionId) =>
  api.get(createUrl(specificationId, documentId, true, sectionId))

export const reorderSectionElements: (
  /** @format guid */
  specificationId: string,
  /** @format guid */
  documentId: string,
  /** @format guid */
  sectionId: string,
  elements: Array<string>,
) => Promise<Section> = async (
  specificationId,
  documentId,
  sectionId,
  elements,
) => {
  const res = await api.patch(
    createUrl(specificationId, documentId, true, sectionId),
    {
      body: { elements },
    },
  )
  return sectionWithDates(res)
}

/** Note: Section must be empty to be deleted */
export const deleteSection: (
  /** @format guid */
  specificationId: string,
  /** @format guid */
  documentId: string,
  /** @format guid */
  sectionId: string,
) => Promise<{ id: string }> = (specificationId, documentId, sectionId) =>
  api.delete(createUrl(specificationId, documentId, true, sectionId))

export const publicTenantMethods = {
  getDocument: async (
    specificationId: string,
    documentId: string,
  ): Promise<SpecificationDocument> => {
    const res = await usingPublicTenant(api.get)(
      createUrl(specificationId, documentId),
    )
    return docWithDates(res)
  },
  getSection: (
    specificationId: string,
    documentId: string,
    sectionId: string,
  ): Promise<Section> =>
    usingPublicTenant(api.get)(
      createUrl(specificationId, documentId, true, sectionId),
    ),
}
