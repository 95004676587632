import api from './api.ts'
import { AttributeValueResponse } from './attributes.ts'
import {
  AttributeValueStatus,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums.ts'

const createUrl = (workspaceId?: string, projectId?: string) =>
  `/api/v2/shared_workspaces${workspaceId ? `/${workspaceId}` : ''}${
    projectId ? `/projects/${projectId}` : ''
  }`

interface Contact {
  id: string
  name: string
  connectedOn: Date
}

export interface Project extends Omit<AttributeValueResponse, 'entityCount'> {}

export interface SharedWorkspace {
  /** @format guid */
  id: string
  contact: Contact
  projects: Project[]
}

export interface SharedProjectWorkspace {
  /** @format guid */
  id: string
  contact: Contact
  project: Project
  otherProjects: Project[]
}

export const getSharedWorkspaces: () => Promise<
  SharedWorkspace[]
> = async () => {
  const { sharedWorkspaces } = await api.get(createUrl())
  return sharedWorkspaces.map((workspace: SharedWorkspace) => ({
    ...workspace,
    contact: {
      ...workspace.contact,
      connectedOn: new Date(workspace.contact.connectedOn),
    },
  }))
}

export const getWorkspaceProject: (
  workspaceId: string,
  projectId: string,
) => Promise<SharedProjectWorkspace> = async (workspaceId, projectId) => {
  const sharedWorkspace = await api.get(createUrl(workspaceId, projectId))
  return {
    ...sharedWorkspace,
    contact: {
      ...sharedWorkspace.contact,
      connectedOn: new Date(sharedWorkspace.contact.connectedOn),
    },
  }
}

export interface SharedSpecificationSnapshotData {
  /** @format guid */
  id: string
  specificationName: string
  revisionVersion: number
  requirementCount: number
  createdOn: Date
  /** @format guid */
  specificationId: string
  project: {
    id: string
    name: string
    status: AttributeValueStatus
    metadata: {
      STYLES: {
        COLOR_FONT: string
        COLOR_BG: string
        COLOR_BG_HOVER: string
      }
    }
  }
}

export const getWorkspaceProjectSnapshots: (
  workspaceId: string,
  projectId: string,
) => Promise<SharedSpecificationSnapshotData[]> = async (
  workspaceId,
  projectId,
) => {
  const res = await api.get(`${createUrl(workspaceId, projectId)}/snapshots`)
  return res.snapshots.map((snapshot: SharedSpecificationSnapshotData) => ({
    ...snapshot,
    createdOn: new Date(snapshot.createdOn),
  }))
}

export interface SpecificationSnapshotReviewMetadataResponse {
  /** @format guid */
  id: string
  name: string
  /** @format guid */
  snapshotId: string
  /** @format guid */
  specificationId: string
  specificationName: string
  submittedByFirstName: string
  submittedByLastName: string
  submittedOn: string
  status: SpecificationSnapshotReviewStatus
}

export interface SpecificationSnapshotReviewMetadata
  extends Omit<SpecificationSnapshotReviewMetadataResponse, 'submittedOn'> {
  submittedOn: Date
}

export const getWorkspaceProjectReviews: (
  workspaceId: string,
  projectId: string,
) => Promise<SpecificationSnapshotReviewMetadata[]> = async (
  workspaceId,
  projectId,
) => {
  const res = await api.get(`${createUrl(workspaceId, projectId)}/reviews`)
  return res.reviews.map(
    (review: SpecificationSnapshotReviewMetadataResponse) => ({
      ...review,
      submittedOn: new Date(review.submittedOn),
    }),
  )
}
