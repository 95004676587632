import api from './api.ts'
import { AttributeValueResponse } from './attributes.ts'
import { BlockData, BlockType } from './blocks.ts'
import { RequirementData } from './requirements.ts'
import { EntityType } from '../../types/enums.ts'

const createUrl = (projectId?: string, snapshotId?: string) =>
  `/api/v2/projects${projectId ? `/${projectId}` : ''}${
    snapshotId ? `/snapshots/${snapshotId}` : ''
  }`

export type ProjectMetadataStylesResponse = {
  COLOR_FONT: string
  COLOR_BG: string
  COLOR_BG_HOVER: string
}

export interface ProjectMetadataResponse {
  STYLES: ProjectMetadataStylesResponse
}

interface ProjectResponse {
  id: string
  name: string
  createdByTenant: { id: string; name: string }
  createdOn: string
  sharedWithTenant: { id: string; name: string }
  metadata?: ProjectMetadataResponse
}

export interface Project extends Omit<ProjectResponse, 'createdOn'> {
  createdOn: Date
}

const withDates = (proj: ProjectResponse): Project => ({
  ...proj,
  createdOn: new Date(proj.createdOn),
})

export const getProjects = async (): Promise<Project[]> => {
  const { projects } = await api.get(createUrl())
  return projects.map(withDates)
}

export const getProject = async (projectId: string): Promise<Project> => {
  const project = await api.get(createUrl(projectId))
  return withDates(project)
}

export type SpecificationSnapshotAttributeValue = Omit<
  AttributeValueResponse,
  'entityCount'
>

interface SpecificationSnapshotData {
  /** @format guid */
  id: string
  specificationName: string
  revisionVersion: number
  requirementCount: number
  createdOn: Date
  /** @format guid */
  specificationId: string
  project: {
    id: string
    name: string
    metadata?: ProjectMetadataResponse
  }
  specificationProgram: SpecificationSnapshotAttributeValue
}

interface SpecificationSnapshotCustomAttribute {
  /** @format guid */
  id: string
  name: string
  entityTypes: EntityType[]
  values: SpecificationSnapshotAttributeValue[]
}

interface SharedBlock<D extends BlockData> {
  /** @format guid */
  id: string
  type: BlockType
  data: D
}

interface SpecificationSnapshotRequirement
  extends Omit<RequirementData, 'data' | 'exportControlled'> {
  /** @format guid */
  id: string
  identifier: number
}

interface SpecificationSnapshotContent {
  /** @format guid */
  id: string
  customAttributes: SpecificationSnapshotCustomAttribute[]
  documentBlocks: SharedBlock<BlockData>[]
  requirements: SpecificationSnapshotRequirement[]
  revision: {
    /** @format guid */
    id: string
    version: number
  }
  specification: {
    /** @format guid */
    id: string
    name: string
    identifier: string
    externalOrigin: {
      organizationName: string
      version: string | null
    } | null
  }
  version: number
}

export interface SpecificationSnapshot extends SpecificationSnapshotData {
  contents: SpecificationSnapshotContent
}

export const getSpecificationSnapshot: (
  projectId: string,
  snapshotId: string,
) => Promise<SpecificationSnapshot> = async (projectId, snapshotId) => {
  const snapshot = await api.get(createUrl(projectId, snapshotId))
  return {
    ...snapshot,
    createdOn: new Date(snapshot.createdOn),
  }
}
