import { Document, ListChecked, Time } from '@carbon/icons-react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import styles from './SharedSpecificationPage.module.css'
import Tag, { TAG_COLORS } from '../../components/tag'
import { useSharedSpecificationContext } from '../../context/SharedSpecificationContext'
import { monthDayYear } from '../../lib/date'
import {
  SNAPSHOT_REVIEW_STATUS_TO_NAME,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums'

const SharedSpecificationPage = () => {
  const { pathname } = useLocation()
  const { specificationSnapshot, review } = useSharedSpecificationContext()

  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.rightHeader}>
          {review && (
            <Tag
              text={SNAPSHOT_REVIEW_STATUS_TO_NAME[review.status]}
              color={
                review.status === SpecificationSnapshotReviewStatus.Submitted
                  ? TAG_COLORS.green12NoHover
                  : TAG_COLORS.gray3NoHover
              }
            />
          )}
          <div className={styles.dateTime}>
            <Time size={16} />
            {specificationSnapshot?.createdOn &&
              monthDayYear(specificationSnapshot.createdOn)}
          </div>
        </div>
        <div className={styles.leftHeader}>
          <div className={styles.pageDescription}>Shared specification</div>
          <div className={styles.title}>
            {specificationSnapshot?.specificationName || 'Untitled'}
          </div>
        </div>
      </div>
      <div className={styles.tabs}>
        <Link to={'./document'} draggable="false">
          <div
            className={`${styles.tab} ${
              pathname.includes('document') ? styles.active : ''
            }`}
          >
            <Document size={20} />
            Document
          </div>
        </Link>
        <Link to={`./requirements`} draggable="false">
          <div
            className={`${styles.tab} ${
              pathname.includes('requirements') ? styles.active : ''
            }`}
          >
            <ListChecked size={20} />
            Requirements
          </div>
        </Link>
      </div>
      <Outlet />
    </>
  )
}

export default SharedSpecificationPage
