import { ImportExport, Upload } from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback, useRef, useState } from 'react'
import Modal from '.'
import styles from './ImportPdfModal.module.css'
import { importPdfToSpecification } from '../../api/v2/specifications'
import Button, { BUTTON_COLORS } from '../button'
import Checkbox from '../input/Checkbox'
import { toastError } from '../toast'

const ImportPdfModal = (props) => {
  const { closeModal } = props
  const [useLlm, setUseLlm] = useState<boolean>(true)
  const llmImportEnabled = useFeatureFlagEnabled('llm-import')

  const fileInput = useRef<HTMLInputElement>(null)
  const openFilePicker = () => {
    fileInput.current?.click()
  }

  const importPDF = useCallback(async () => {
    const [pdfFile] = fileInput.current?.files ?? []
    if (pdfFile?.size > 26214400) {
      toastError('File too large', 'Only PDFs less than 25MB can be imported')
      return
    }

    await importPdfToSpecification(pdfFile, llmImportEnabled ? useLlm : false)
    closeModal()
  }, [closeModal, llmImportEnabled, useLlm])

  return (
    <Modal title="Import PDF" icon={<ImportExport />} onClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.info}>
          <span className={styles.label}>Instructions</span>
          <p className={styles.instructions}>
            Stell supports importing PDFs and will attempt to parse
            requirements. Large files will import in the background. You will
            receive a notification when your import is complete.
          </p>
        </div>
        {llmImportEnabled && (
          <div className={styles.useAiSwitch}>
            <h4>NEW: Stell AI import</h4>
            <div className={`${styles.toggle} ${useLlm ? styles.on : ''}`}>
              <Checkbox
                checked={useLlm}
                onChange={() => setUseLlm(!useLlm)}
                size={14}
              />{' '}
              {useLlm ? 'AI import enabled' : 'AI import disabled'}
            </div>
            <p>
              Use Stell’s new AI importer to better parse your requirements. All
              your data stays within the Stell ecosystem.
            </p>
          </div>
        )}
        <div className={styles.actions}>
          <Button
            text="Import PDF"
            endIcon={<Upload />}
            onClick={openFilePicker}
            color={BUTTON_COLORS.PRIMARY}
          />
          <input
            ref={fileInput}
            style={{ display: 'none' }}
            type="file"
            accept=".pdf"
            onChange={importPDF}
          />
        </div>
        <p className={styles.sizeWarning}>
          Maximum import size for PDFs is 25mb
        </p>
      </div>
    </Modal>
  )
}

export default ImportPdfModal
