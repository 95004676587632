import { TextAlignJustify } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { BlockType, HeadingBlockData } from '../../../api/v2/blocks'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'
import { plaintextToDelta } from '../../../lib/string'
import { toastError } from '../../toast'
import Tooltip from '../../tooltip/Tooltip'

const ConvertBlockToTextBlockAction = (props: BlockActionItemProps) => {
  const { block, requirement, closeMenu } = props
  const { contentIsEditable, isDeleteDisabled } = useSpecificationContext()
  const { convertBlockToTextBlock } = useSectionContext()
  const text =
    block.type === BlockType.Heading
      ? (block.data as HeadingBlockData)?._data?.text
      : [
          requirement?.sectionNumber,
          requirement?.title,
          requirement?.shallStatement,
          requirement?.rationale,
        ]
          .filter((value) => value)
          .join(' ')

  const disabled = isDeleteDisabled(
    block.id,
    requirement?.context.requirementVersionCount,
  )

  return (
    contentIsEditable && (
      <Tooltip
        content={
          disabled
            ? 'Requirements that have been active cannot be converted to text'
            : ''
        }
        placement="right"
      >
        <button
          className={styles.actionItem}
          onClick={async () => {
            try {
              await convertBlockToTextBlock(block?.id, plaintextToDelta(text))
              closeMenu()
            } catch (e) {
              toastError(
                'Failed to convert heading block into text block',
                'Please refresh the page and try again.',
              )
            }
          }}
          disabled={disabled}
        >
          <TextAlignJustify />
          Convert to text
        </button>
      </Tooltip>
    )
  )
}

export default ConvertBlockToTextBlockAction
