import api from './api.ts'
import { RevisionStatus } from './revisions.ts'
import {
  AttributeName,
  EvidenceType,
  ReportColumnId,
  ReportSource,
} from '../../types/enums'

const createUrl = (reportId?: string) =>
  `/api/v2/reports${reportId ? `/${reportId}` : ''}`

const withDates: (report: ReportResponse) => Report = (report) => ({
  ...report,
  createdOn: new Date(report.createdOn),
})

type ReportContentAttributeValueMetadataStylesResponse = {
  COLOR_FONT: string
  COLOR_BG: string
  COLOR_BG_HOVER: string
}

type ReportContentAttributeValueMetadataResponse = {
  STYLES: ReportContentAttributeValueMetadataStylesResponse
}

type ReportContentAttributeResponse = {
  name: string
  metadata: ReportContentAttributeValueMetadataResponse
}

type ReportContentSpecificationAttributesResponse = {
  PROGRAM: ReportContentAttributeResponse[]
}

type ReportContentSpecificationResponse = {
  /** @format guid */
  id: string
  name: string
  attributes: ReportContentSpecificationAttributesResponse
}

type ReportContentRevisionResponse = {
  /** @format guid */
  id: string
  version: string
}

type ReportContentRequirementAttributesResponse = {
  TYPE: ReportContentAttributeResponse[]
}

type ReportContentRequirementResponse = {
  /** @format guid */
  id: string
  title: string
  shallStatement: string
  sectionNumber: string
  status: string
  rationale: string
  /** @format guid */
  contextId: string
  rootRequirementIdentifier: number
  attributes: ReportContentRequirementAttributesResponse
}

export type ReportContentEvidenceResponse = {
  /** @format guid */
  id: string
  title: string
  description: string
  type: string
  method: string
  cadences: string[]
  attributes: Map<
    AttributeName,
    { name: string; metadata: ReportContentAttributeValueMetadataResponse }
  >
}

export type ReportContentLinkedRequirementResponse = {
  /** @format guid */
  id: string
  name: string
  shallStatement: string
}

type ReportContentResponse = {
  specification: ReportContentSpecificationResponse
  revision: ReportContentRevisionResponse
  requirement: ReportContentRequirementResponse
  'evidence[]': ReportContentEvidenceResponse[]
  'parentRequirement[]': ReportContentLinkedRequirementResponse[]
  'childRequirement[]': ReportContentLinkedRequirementResponse[]
}

type ReportMetadataResponse = {
  columnsToDisplay: ReportColumnId[]
  specificationStatus?: RevisionStatus
  evidence?: {
    /** @format guid */
    id: string
    title: string
    description: string
    compliance: string
    /** @format guid */
    method: string
    /** @format guid */
    program: string
    type: EvidenceType
  }
}

type ReportQueryResponse = {
  /** @format guid */
  specificationId: string
  /** @format guid */
  revisionId: string
}

export interface ReportResponse {
  /** @format guid */
  id: string
  name: string
  source: ReportSource
  createdOn: string
  createdBy: string
  schema: any // unused
  query: ReportQueryResponse
  contents: ReportContentResponse[]
  metadata: ReportMetadataResponse
}

export interface Report extends Omit<ReportResponse, 'createdOn'> {
  createdOn: Date
}

export const getReport: (id: string) => Promise<Report> = async (id) => {
  const reportResponse = await api.get(createUrl(id))
  return withDates(reportResponse)
}

export interface BasicReportResponse {
  /** @format guid */
  id: string
  name: string
  source: ReportSource
  createdOn: string
  createdBy: string
}

export const getReports: (search?: {
  sources?: ReportSource[]
  specificationIds?: string[]
}) => Promise<BasicReportResponse[]> = async (search = {}) => {
  const { sources = [], specificationIds = [] } = search
  const queryParams = [
    ...sources.map((source) => ['source', source]),
    ...specificationIds.map((id) => ['specificationId', id]),
  ]
  const queryString = new URLSearchParams(queryParams).toString()
  const res = await api.get(
    `${createUrl()}${queryString ? `?${queryString}` : ''}`,
  )
  const reports = res?.reports || []
  reports.sort(
    (a: BasicReportResponse, b: BasicReportResponse) =>
      new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
  )
  return reports
}

export const updateReportName: (
  reportId: string,
  newReportName: string,
) => Promise<{ id: string; name: string }> = (reportId, newReportName) =>
  api.patch(createUrl(reportId), { body: { name: newReportName } })

export const deleteReport: (
  reportId: string,
) => Promise<{ reportId: string }> = (reportId) =>
  api.delete(createUrl(reportId))
