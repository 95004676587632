import { Link } from 'react-router-dom'
import styles from './ChangeRequestsTableColumns.module.css'
import ChangeRequestStatusSelect from '../../components/change-requests/ChangeRequestStatusSelect'
import { DRAWER_TAB } from '../../components/drawer/ChangeRequestDrawer'
import { TableColumn, TableItem } from '../../components/table/Table'
import { toastError } from '../../components/toast'
import { useChangeRequestListContext } from '../../context/ChangeRequestListContext'
import { useModals } from '../../hooks/useModals'
import { monthLongDayYear } from '../../lib/date'
import { ChangeRequestStatus } from '../../types/enums'

const RecordId = ({ data }) => {
  const { openChangeRequestDrawer } = useModals()

  const openDrawerToTab = (initialTab: DRAWER_TAB) => {
    openChangeRequestDrawer({
      initialTab,
      changeRequest: data,
    })
  }

  return (
    <div className={styles.recordId}>
      <button onClick={() => openDrawerToTab(DRAWER_TAB.DETAILS)}>
        {data.displayId}
      </button>
    </div>
  )
}

const DateCreated = ({ data }) => {
  return (
    <div className={styles.dateCreated}>{monthLongDayYear(data.createdOn)}</div>
  )
}

const Requestor = ({ data }) => {
  return <div className={styles.requestor}>{data.createdByName}</div>
}

const RequirementTitle = ({ data }) => {
  return (
    <div>
      <Link
        className={styles.requirementTitle}
        to={`/specifications/${data.specificationId}/matrix/${data.requirementId}`}
      >
        {data.requirementTitle}
      </Link>
    </div>
  )
}

const Status = ({ data }) => {
  const { specificationId, requirementId, id, status } = data
  const { updateChangeRequest } = useChangeRequestListContext()
  const onSelect = (status: ChangeRequestStatus) => {
    try {
      updateChangeRequest(specificationId, requirementId, id, { status })
    } catch (e) {
      toastError('Error updating status', 'Please refresh and try again')
    }
  }

  return <ChangeRequestStatusSelect value={status} onSelect={onSelect} />
}

const Current = ({ data, isExpanded, onExpand }: TableItem) => {
  return (
    <button
      onClick={onExpand}
      className={`${styles.current} ${isExpanded ? styles.expanded : ''}`}
    >
      {data.current}
    </button>
  )
}

const Proposed = ({ data, isExpanded, onExpand }: TableItem) => {
  return (
    <button
      onClick={onExpand}
      className={`${styles.proposed} ${isExpanded ? styles.expanded : ''}`}
    >
      {data.proposed}
    </button>
  )
}

const Specification = ({ data }) => {
  return <div className={styles.specification}>{data.specificationName}</div>
}

const ChangeRequestsTableColumns: Array<TableColumn> = [
  {
    label: 'Record ID',
    Component: RecordId,
  },
  {
    label: 'Date Created',
    Component: DateCreated,
  },
  {
    label: 'Requestor',
    Component: Requestor,
  },
  {
    label: 'Requirement Title',
    Component: RequirementTitle,
  },
  {
    label: 'Status',
    Component: Status,
    width: '182px',
  },
  {
    label: 'WAS',
    Component: Current,
    width: 'minmax(280px, 1fr)',
  },
  {
    label: 'IS',
    Component: Proposed,
    width: 'minmax(280px, 1fr)',
  },
  {
    label: 'Specification',
    Component: Specification,
  },
]

export default ChangeRequestsTableColumns
