import { AddComment } from '@carbon/icons-react'
import { useEffect, useRef, useState } from 'react'
import SharedRequirementActions, {
  LinkTo,
} from './SharedRequirementActions.tsx'
import SharedRequirementCommentForm from './SharedRequirementCommentForm.tsx'
import SharedRequirementCommentsView from './SharedRequirementCommentsView.tsx'
import styles from './SharedRequirementItemView.module.css'
import * as api from '../../api/v2/sharedSpecifications.ts'
import Button from '../../components/button'
import Checkbox from '../../components/input/Checkbox.tsx'
import Tag, { TAG_COLORS } from '../../components/tag'
import { useSharedSpecificationContext } from '../../context/SharedSpecificationContext.tsx'
import globalStyles from '../../global.module.css'
import {
  LoadingState,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums.ts'

const SharedRequirementItemView = (props: {
  requirement: api.SharedSpecificationRequirement
  review?: api.SharedSpecificationSnapshotReview | null
  comments: api.ReviewRequirementComment[]
  commentsLoadingState: LoadingState
  addCommentToRequirement: (
    requirementId: string,
    comment: string,
  ) => Promise<boolean>
  deleteRequirementComment: (
    requirementId: string,
    commentId: string,
  ) => Promise<boolean>
  scrollToOnLoad?: boolean
}) => {
  const {
    requirement,
    review,
    comments,
    commentsLoadingState,
    addCommentToRequirement,
    deleteRequirementComment,
    scrollToOnLoad,
  } = props
  const {
    specificationSnapshot,
    getRequirementTypeById,
    updateRequirementStatus,
    userIsSnapshotReviewer,
  } = useSharedSpecificationContext()
  const [displayCommentForm, setDisplayCommentForm] = useState<boolean>(false)

  const types = (requirement?.types || []).map((typeGuid: string) => {
    const type = getRequirementTypeById(typeGuid)
    return {
      id: type?.id,
      text: type?.name ?? '',
      color: {
        fontColor:
          type?.metadata.STYLES.COLOR_FONT || TAG_COLORS.gray3.fontColor,
        backgroundColor:
          type?.metadata.STYLES.COLOR_BG || TAG_COLORS.gray3.backgroundColor,
      },
    }
  })

  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollToOnLoad) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView?.({ block: 'center' })
      }, 0)
    }
  })

  return (
    <div
      ref={scrollRef}
      className={`${styles.requirementCard} ${styles.basicElevation} ${
        scrollToOnLoad ? globalStyles.scrollHighlight : ''
      }`}
    >
      <div className={styles.titleRow}>
        <div className={styles.leftRow}>
          <span className={styles.title}>
            {requirement?.title || 'Untitled'}
          </span>
          <div className={styles.tags}>
            {types.map((type) => (
              <Tag key={type.id} text={type.text} color={type.color} />
            ))}
          </div>
        </div>
        <div className={styles.rightRow}>
          {review && userIsSnapshotReviewer && (
            <div>
              <Checkbox
                checked={review.requirements[requirement.id].complete}
                onChange={(isChecked: boolean) =>
                  updateRequirementStatus(
                    specificationSnapshot.contents.specification.id,
                    specificationSnapshot.id,
                    review.id,
                    requirement.id,
                    isChecked,
                  )
                }
                disabled={
                  review.status !== SpecificationSnapshotReviewStatus.Draft
                }
              >
                <span className={styles.complete}>Complete</span>
              </Checkbox>
            </div>
          )}
          <SharedRequirementActions
            requirement={requirement}
            linkTo={LinkTo.Document}
          />
        </div>
      </div>
      <div className={styles.requirementContent}>
        <div>{requirement?.shallStatement}</div>
        {requirement?.rationale && (
          <div className={styles.rationale}>
            <span className={styles.rationaleTitle}>Rationale: </span>
            <span>{requirement.rationale}</span>
          </div>
        )}
      </div>
      {review &&
        userIsSnapshotReviewer &&
        (review.status === SpecificationSnapshotReviewStatus.Draft ||
          comments.length > 0) && (
          <div className={styles.commentSection}>
            {(comments.length > 0 ||
              displayCommentForm ||
              commentsLoadingState === LoadingState.Failed) && (
              <div className={styles.horizontalRule} />
            )}
            {comments.length > 0 && (
              <div className={styles.comments}>
                <SharedRequirementCommentsView
                  comments={comments}
                  commentsLoadingState={commentsLoadingState}
                  requirementId={requirement.id}
                  review={review}
                  onDelete={deleteRequirementComment}
                />
              </div>
            )}
            {review?.status === SpecificationSnapshotReviewStatus.Draft && (
              <div>
                {displayCommentForm ? (
                  <SharedRequirementCommentForm
                    addComment={async (comment: string) => {
                      const added = await addCommentToRequirement(
                        requirement.id,
                        comment,
                      )
                      if (added) {
                        setDisplayCommentForm(false)
                      }

                      return added
                    }}
                  />
                ) : (
                  <Button
                    onClick={() => setDisplayCommentForm(true)}
                    text="Comment"
                    endIcon={<AddComment />}
                  />
                )}
              </div>
            )}
          </div>
        )}
    </div>
  )
}

export default SharedRequirementItemView
