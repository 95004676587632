import {
  Document,
  G,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer'
import {
  HeadingPdfBlock,
  ImagePdfBlock,
  RequirementPdfBlock,
  TablePdfBlock,
  TextPdfBlock,
} from './PdfBlocks.tsx'
import { AttributeValueResponse } from '../../../api/v2/attributes.ts'
import { BlockType } from '../../../api/v2/blocks.ts'
import { Revision } from '../../../api/v2/revisions.ts'
import { Specification } from '../../../api/v2/specifications.ts'
import { VisibleBlock } from '../../../components/block-editor/hooks/useVisibleBlocks.tsx'
import { CHIP_COLORS, LOCKED_STATES } from '../../../components/chip/Chip.tsx'
import { TAG_COLORS } from '../../../components/tag'
import { monthDayYear } from '../../../lib/date.ts'
import { getCssVar } from '../../../lib/string.ts'

export const BLOCK_TYPE_TO_PDF_BLOCK = {
  [BlockType.Text]: TextPdfBlock,
  [BlockType.Image]: ImagePdfBlock,
  [BlockType.Requirement]: RequirementPdfBlock,
  [BlockType.Table]: TablePdfBlock,
  [BlockType.Heading]: HeadingPdfBlock,
}

const DateIcon = () => (
  <Svg
    preserveAspectRatio="xMidYMid meet"
    fill={getCssVar('--text-color-gray-dark')}
    width="16"
    height="16"
    viewBox="0 0 32 32"
    aria-hidden="true"
  >
    <Path d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z"></Path>
    <Path d="M20.59 22L15 16.41 15 7 17 7 17 15.58 22 20.59 20.59 22z"></Path>
  </Svg>
)

const LockIcon = () => {
  return (
    <Svg
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      width="12"
      height="12"
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <Path d="M24,14H22V8A6,6,0,0,0,10,8v6H8a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V16A2,2,0,0,0,24,14ZM12,8a4,4,0,0,1,8,0v6H12ZM24,28H8V16H24Z"></Path>
    </Svg>
  )
}

const DocumentImportIcon = () => {
  return (
    <Svg
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <Path d="M28 19L14.83 19 17.41 16.41 16 15 11 20 16 25 17.41 23.59 14.83 21 28 21 28 19z"></Path>
      <Path d="M24,14V10a1,1,0,0,0-.29-.71l-7-7A1,1,0,0,0,16,2H6A2,2,0,0,0,4,4V28a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V26H22v2H6V4h8v6a2,2,0,0,0,2,2h6v2Zm-8-4V4.41L21.59,10Z"></Path>
    </Svg>
  )
}

const ItarIcon = () => {
  return (
    <Svg width="23" viewBox="0 0 23 27" fill="none">
      <G>
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5 4L5 7.64833C5.15943 12.8301 6.37271 19.1513 11.5 21C16.6837 19.2042 17.78 12.775 18 7.64833L11.5 4Z"
          fill="#DA1E28"
        ></Path>
      </G>
    </Svg>
  )
}

export const PdfDocument = (props: {
  specification: Specification
  specificationProgram?: AttributeValueResponse
  revision: Revision
  blocks: Record<string, VisibleBlock>
  version: number | string
  isHistoricalRevision: boolean
  isExternal: boolean
}) => {
  const {
    specification,
    specificationProgram,
    revision,
    blocks,
    version,
    isHistoricalRevision,
    isExternal,
  } = props

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      fontSize: '12px',
    },
    header: {
      flexDirection: 'column',
      width: '100%',
      gap: '8px',
      padding: '18px 36px',
    },
    headerRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    specificationIdentifier: {
      fontSize: '13.33px',
      color: getCssVar('--text-color-gray-light'),
    },
    createdOn: {
      color: getCssVar('--text-color-gray-dark'),
      marginLeft: '4px',
    },
    subrow: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    dateIconWrapper: {},
    status: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '24px',
      padding: '0 8px',
      marginRight: '12px',
      fontSize: '12px',
      borderRadius: '6px',
      backgroundColor: CHIP_COLORS[revision?.status || ''].backgroundColor,
    },
    statusText: {
      fontSize: '10px',
      fontWeight: 400,
      color: CHIP_COLORS[revision?.status || ''].color,
    },

    specificationName: {
      fontSize: '20px',
      width: '600px',
    },
    versionContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '22px',
      padding: '0 14px 0 11px',
      borderRadius: '6px',
      backgroundColor: '#a9ebd5',
    },
    version: {
      fontSize: '13px',
    },
    section: {
      flexDirection: 'column',
      gap: '15px',
      width: '514px',
    },
  })

  return (
    <Document>
      <Page
        size={{
          width: 2480,
          height: 3508,
        }}
        dpi={300}
      >
        <View
          style={[
            styles.page,
            {
              minHeight: '100%',
              margin: `-50px -30px`, // Negative margins to offset scaling factor + create page margins
              transform: 'scale(.85)',
            },
          ]}
        >
          <View style={styles.header}>
            <View style={styles.headerRow}>
              <Text style={styles.specificationIdentifier}>
                {specification?.specificationIdentifier ||
                  'Add Document Number'}
              </Text>
              <View style={styles.subrow}>
                {!isExternal && (
                  <View style={styles.status}>
                    {LOCKED_STATES.includes(revision.status) && <LockIcon />}
                    <Text style={styles.statusText}>{revision.status}</Text>
                  </View>
                )}
                {isExternal && (
                  <View style={[styles.status, { backgroundColor: '#8cd2b7' }]}>
                    <DocumentImportIcon />
                    <Text
                      style={
                        (styles.statusText,
                        { color: getCssVar('--text-color-black') })
                      }
                    >
                      EXTERNAL
                    </Text>
                  </View>
                )}
                <View style={styles.dateIconWrapper}>
                  <DateIcon />
                </View>
                <Text style={styles.createdOn}>
                  {monthDayYear(specification.createdOn)}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.headerRow,
                { justifyContent: 'flex-start', gap: '14px' },
              ]}
            >
              <Text style={styles.specificationName} wrap>
                {specification.name}
              </Text>
              <View style={styles.versionContainer}>
                <Text style={styles.version}>{version}</Text>
              </View>
              {revision?.exportControlled && <ItarIcon />}
            </View>
            {specificationProgram && (
              <View style={styles.subrow}>
                <View
                  style={[
                    styles.status,
                    {
                      backgroundColor:
                        specificationProgram.metadata.STYLES.COLOR_BG,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.statusText,
                      {
                        color: specificationProgram.metadata.STYLES.COLOR_FONT,
                      },
                    ]}
                  >
                    {specificationProgram.name}
                  </Text>
                </View>
              </View>
            )}
            {isHistoricalRevision && (
              <View style={styles.headerRow}>
                <View
                  style={[
                    styles.status,
                    { backgroundColor: TAG_COLORS.red2NoHover.backgroundColor },
                  ]}
                >
                  <Text
                    style={[
                      styles.statusText,
                      { color: TAG_COLORS.red2NoHover.fontColor },
                    ]}
                  >
                    OLDER VERSION
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={styles.section}>
            {Object.entries(blocks).map((entry) => {
              const PdfBlock =
                BLOCK_TYPE_TO_PDF_BLOCK[entry[1].block?.type || '']
              const { block, ...fetchedData } = entry[1]
              return (
                <PdfBlock
                  key={entry[0]}
                  block={block}
                  specification={specification}
                  {...fetchedData}
                />
              )
            })}
          </View>
        </View>
      </Page>
    </Document>
  )
}
