import { useCallback, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import styles from './SpecificationSettings.module.css'
import { getRequirements } from '../../api/v2/requirements.ts'
import {
  Revision,
  RevisionStatus,
  getRevisions,
  updateRevision,
} from '../../api/v2/revisions.ts'
import * as api from '../../api/v2/specifications.ts'
import ItarIcon from '../../assets/ItarIcon.tsx'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'
import { toastError, toastSuccess } from '../../components/toast'
import Tooltip from '../../components/tooltip/Tooltip.tsx'

const SpecificationSettings = () => {
  const [specification] = useOutletContext() as [api.Specification]
  const [internal, setInternal] = useState<boolean>(!specification.external)
  const [submittingOrigin, setSubmittingOrigin] = useState<boolean>()
  const [latestRevision, setLatestRevision] = useState<Revision>()
  const [origin, setOrigin] = useState<api.SpecificationOriginResponse>({
    organization: '',
    version: '',
  })
  const [inputExternalVersion, setInputExternalVersion] = useState<string>('')
  const [inputExternalOrganization, setInputExternalOrganization] =
    useState<string>('')
  const [submittingExportControl, setSubmittingExportControl] =
    useState<boolean>()
  const [originLoading, setOriginLoading] = useState<boolean>()
  const [anyExportControlledRequirement, setAnyExportControlledRequirement] =
    useState<boolean>()
  const [isRationaleEnabled, setIsRationaleEnabled] = useState<boolean>()
  const [submittingRationaleConfig, setSubmittingRationaleConfig] =
    useState<boolean>()
  const exportControlled = latestRevision?.exportControlled

  useEffect(() => {
    const getLatestRevision = async () => {
      try {
        const revisions = await getRevisions(specification.id)
        const latestRev = revisions.reduce((latest, current) =>
          latest.createdOn > current.createdOn ? latest : current,
        )
        setLatestRevision(latestRev)
      } catch (error) {
        console.error('Unable to get revisions', error)
      }
    }
    getLatestRevision()
  }, [specification.id])

  useEffect(() => {
    if (!specification.external) {
      return
    }

    const getOrigin = async () => {
      setOriginLoading(true)
      try {
        const origin = await api.getSpecificationOrigin(specification.id)
        setOrigin(origin)
        setInputExternalVersion(origin.version)
        setInputExternalOrganization(origin.organization)
      } catch (error) {
        console.error('Unable to get specification origin', error)
      } finally {
        setOriginLoading(false)
      }
    }
    getOrigin()
  }, [specification.external, specification.id])

  useEffect(() => {
    const getExportControlledRequirementState = async () => {
      if (!latestRevision) {
        return
      }

      try {
        const reqs = await getRequirements(specification.id, {
          revisionIds: [latestRevision.id],
        })
        const containsExportControlled = reqs.some(
          (req) => req.exportControlled,
        )
        setAnyExportControlledRequirement(containsExportControlled)
      } catch (error) {
        console.error('Unable to get requirements of revision', error)
      }
    }
    getExportControlledRequirementState()
  }, [latestRevision, specification.id])

  useEffect(() => {
    const getSpecificationConfiguration = async () => {
      try {
        const config = await api.getSpecificationConfiguration(specification.id)
        setIsRationaleEnabled(config.requirementRationaleEnabled)
      } catch (error) {
        console.error('Unable to get specification configuration', error)
      }
    }
    getSpecificationConfiguration()
  }, [specification.id])

  const updateInternalState = useCallback(
    async (selectedInternal: boolean) => {
      if (selectedInternal === internal) {
        return
      }

      const displayText = selectedInternal ? 'internal' : 'external'
      setSubmittingOrigin(true)

      try {
        if (selectedInternal) {
          await api.deleteSpecificationOrigin(specification.id)
          setInputExternalVersion('')
          setInputExternalOrganization('')
        } else {
          await api.createSpecificationOrigin(specification.id)
        }
        setInternal(selectedInternal)
        toastSuccess(`Specification origin changed to ${displayText}`)
      } catch (error) {
        toastError(
          `Unable to update specification origin to ${displayText}`,
          '',
        )
        console.error('Unable to update specification origin', error)
      } finally {
        setSubmittingOrigin(false)
      }
    },
    [internal, specification],
  )

  const updateExternalOrgName = useCallback(async () => {
    if (inputExternalOrganization === origin.organization) {
      return
    }

    if (internal) {
      toastError(
        'Cannot update organization name when specification is internal',
        '',
      )
      return
    }

    try {
      const updatedOrigin = await api.updateSpecificationOrigin({
        id: specification.id,
        organization: inputExternalOrganization,
      })
      setOrigin(updatedOrigin)
      toastSuccess('Specification organization updated')
    } catch (error) {
      toastError('Unable to update organization', '')
      console.error('Unable to update organization', error)
    }
  }, [inputExternalOrganization, specification, internal, origin.organization])

  const updateExternalVersion = useCallback(async () => {
    if (inputExternalVersion === origin.version) {
      return
    }

    if (internal) {
      toastError('Cannot update version when specification is internal', '')
      return
    }

    try {
      const updatedOrigin = await api.updateSpecificationOrigin({
        id: specification.id,
        version: inputExternalVersion,
      })
      setOrigin(updatedOrigin)
      toastSuccess('Specification version updated')
    } catch (error) {
      toastError('Unable to update organization', '')
      console.error('Unable to update organization', error)
    }
  }, [inputExternalVersion, specification, internal, origin.version])

  const updateExportControlled = useCallback(
    async (newExportControlledState: boolean) => {
      if (!latestRevision) {
        toastError('Unable to perform action', '')
        return
      }

      setSubmittingExportControl(true)
      try {
        await updateRevision(specification.id, latestRevision.id, {
          exportControlled: newExportControlledState,
        })

        setLatestRevision((prev) => ({
          ...prev!,
          exportControlled: newExportControlledState,
        }))

        toastSuccess(
          newExportControlledState
            ? 'Specification flagged for export controlled data'
            : 'Removed export controlled data flag from specification',
        )
      } catch (error) {
        toastError('There was an error setting the export controlled flag', '')
        console.error('Unable to update export control flag', error)
      } finally {
        setSubmittingExportControl(false)
      }
    },
    [specification, latestRevision],
  )

  const updateRationaleConfiguration = useCallback(
    async (selectedRationaleSetting: boolean) => {
      setSubmittingRationaleConfig(true)
      try {
        await api.updateSpecificationConfiguration({
          specificationId: specification.id,
          requirementRationaleEnabled: selectedRationaleSetting,
        })
        setIsRationaleEnabled(selectedRationaleSetting)
        toastSuccess('Updated requirement format')
      } catch (error) {
        toastError('Unable to update requirement format', '')
        console.error(
          'Unable to update specification configuration for rationale',
          error,
        )
      } finally {
        setSubmittingRationaleConfig(false)
      }
    },
    [specification.id],
  )

  return (
    <div className={styles.specSettings}>
      {originLoading || !latestRevision ? (
        <div className={styles.center}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className={styles.settingsSection}>
            <div className={styles.sectionHeader}>Origin</div>
            <div className={styles.formRow}>
              <button
                className={`${styles.toggleButton} ${
                  internal ? styles.selected : ''
                } ${internal || submittingOrigin ? styles.disabled : ''}`}
                onClick={() => updateInternalState(true)}
                disabled={internal}
              >
                Internal
              </button>
              <Tooltip
                content={
                  latestRevision.status !== RevisionStatus.DRAFT ||
                  latestRevision.version > 1
                    ? 'Specification must be in Version 1 draft state.'
                    : ''
                }
                placement="right"
              >
                <button
                  className={`${styles.toggleButton} ${
                    internal ? '' : styles.selected
                  } ${
                    !internal ||
                    latestRevision.status !== RevisionStatus.DRAFT ||
                    submittingOrigin
                      ? styles.disabled
                      : ''
                  }`}
                  onClick={() => updateInternalState(false)}
                  disabled={
                    !internal ||
                    latestRevision.status !== RevisionStatus.DRAFT ||
                    submittingOrigin ||
                    latestRevision.version > 1
                  }
                >
                  External
                </button>
              </Tooltip>
              {!internal && (
                <>
                  <input
                    className={styles.input}
                    type="text"
                    value={inputExternalOrganization}
                    placeholder="Organization name..."
                    maxLength={20}
                    onChange={(e) =>
                      setInputExternalOrganization(e.target.value)
                    }
                    onBlur={() => updateExternalOrgName()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        updateExternalOrgName()
                      }
                    }}
                  />
                  <input
                    className={styles.input}
                    type="text"
                    value={inputExternalVersion}
                    placeholder="Version number..."
                    maxLength={20}
                    onChange={(e) => setInputExternalVersion(e.target.value)}
                    onBlur={() => updateExternalVersion()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        updateExternalVersion()
                      }
                    }}
                  />
                </>
              )}
            </div>
            <div className={styles.description}>
              <p>
                External specifications are imported documents containing
                requirements from other organizations.
                <br />
                These specifications do not have approval or release
                functionality.
              </p>
            </div>
          </div>
          <div className={styles.settingsSection}>
            <div className={styles.sectionHeader}>
              Security
              <ItarIcon />
            </div>
            <div className={styles.formRow}>
              <Tooltip
                content={
                  anyExportControlledRequirement && exportControlled
                    ? 'One or more requirements on this specification are marked as export controlled.'
                    : ''
                }
                placement="right"
              >
                <button
                  className={`${styles.toggleButton} ${
                    !exportControlled ? styles.selected : ''
                  } ${
                    anyExportControlledRequirement ||
                    !exportControlled ||
                    submittingExportControl
                      ? styles.disabled
                      : ''
                  }`}
                  onClick={() => updateExportControlled(false)}
                  disabled={
                    anyExportControlledRequirement ||
                    !exportControlled ||
                    submittingExportControl
                  }
                >
                  No controlled data
                </button>
              </Tooltip>
              <button
                className={`${styles.toggleButton} ${styles.exportControlled} ${
                  exportControlled ? styles.selected : ''
                } ${
                  exportControlled || submittingExportControl
                    ? styles.disabled
                    : ''
                }`}
                onClick={() => updateExportControlled(true)}
                disabled={exportControlled || submittingExportControl}
              >
                Contains export controlled data
              </button>
            </div>
            <div className={styles.description}>
              Flag this specification for having export controlled data. This
              flag is informative and does not prevent users from releasing the
              specification.
            </div>
          </div>
          <div className={styles.settingsSection}>
            <div className={styles.sectionHeader}>Requirement format</div>
            <div className={styles.description}>
              <p>
                Requirement blocks are made up of:
                <br />
                a section number
                <br />
                a requirement title
                <br />a shall statement
              </p>
              <p>
                Optionally, requirements can also include a rationale field.
                This can be set on a per specification basis here.
              </p>
            </div>
            <div className={styles.formRow}>
              <button
                className={`${styles.toggleButton} ${
                  !isRationaleEnabled ? styles.selected : ''
                } ${
                  !isRationaleEnabled || submittingRationaleConfig
                    ? styles.disabled
                    : ''
                }`}
                onClick={() => updateRationaleConfiguration(false)}
                disabled={!isRationaleEnabled || submittingRationaleConfig}
              >
                Do not include rationale
              </button>
              <button
                className={`${styles.toggleButton} ${styles.rationaleEnabled} ${
                  isRationaleEnabled ? styles.selected : ''
                } ${
                  isRationaleEnabled || submittingRationaleConfig
                    ? styles.disabled
                    : ''
                }`}
                onClick={() => updateRationaleConfiguration(true)}
                disabled={isRationaleEnabled || submittingRationaleConfig}
              >
                Include rationale
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SpecificationSettings
