import { Blob } from 'buffer'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLoaderData } from 'react-router-dom'
import { useAuth } from './AuthContext.tsx'
import * as assetApi from '../api/v2/assets.ts'
import * as blockApi from '../api/v2/blocks.ts'
import * as docApi from '../api/v2/documents.ts'
import * as requirementApi from '../api/v2/requirements.ts'
import * as revisionApi from '../api/v2/revisions.ts'
import * as specApi from '../api/v2/specifications.ts'
import { MatrixColumnId, MatrixViewUIConfig } from '../api/v2/specifications.ts'
import { toastError, toastSuccess } from '../components/toast'
import {
  EntityReviewEventOperation,
  EntityReviewReviewerEventOperation,
  EntityReviewReviewerStatus,
  EntityReviewStatus,
} from '../types/api/v2/entity.ts'
import {
  RevisionReview,
  RevisionReviewReviewer,
} from '../types/api/v2/revisions.ts'
import { ApiError } from '../types/errors.ts'

interface SpecificationCtx {
  specification: specApi.Specification
  revision: revisionApi.Revision
  document: docApi.SpecificationDocument | null
  sections: docApi.Section[]
  setSections: React.Dispatch<React.SetStateAction<docApi.Section[]>>
  requirementIds: Set<string>
  setRequirementIds: React.Dispatch<React.SetStateAction<Set<string>>>
  isHistoricalRevision: boolean
  pendingReview?: RevisionReview
  pendingReviewReviewers: RevisionReviewReviewer[]
  matrixViewUIConfig: MatrixViewUIConfig[]
  updateMatrixViewUIConfig: (columnId: MatrixColumnId) => void
  updateSpecificationName: (name: string) => void
  updateSpecification: ({
    name,
    specificationIdentifier,
    category,
    program,
    phase,
  }: {
    name?: string
    specificationIdentifier?: string
    category?: string | null
    program?: string | null
    phase?: string | null
  }) => Promise<ApiError | undefined | void>
  createRequirement: (
    sectionId: string,
    insertAfterId: string | null,
    data: Partial<requirementApi.Requirement>,
  ) => Promise<string | void>
  updateRequirement: (
    requirementId: string,
    update: Partial<requirementApi.Requirement>,
  ) => Promise<requirementApi.Requirement | void>
  deleteOrArchiveRequirement: (
    sectionId: string,
    id: string,
  ) => Promise<requirementApi.Requirement | void>
  unarchiveRequirement: (
    id: string,
  ) => Promise<requirementApi.Requirement | void>
  createReview: (userIds: string[]) => Promise<void>
  updateReview: (action: EntityReviewEventOperation) => Promise<void>
  submitReview: (review: EntityReviewReviewerEventOperation) => Promise<void>
  createRevision: () => Promise<void>
  createNewVersion: () => Promise<void>
  submittingNewVersion: boolean
  isRationaleEnabled: boolean
  publicTenant?: boolean
  userIsEditor: boolean
  contentIsEditable: boolean
  isDeleteDisabled: (
    blockId: string,
    requirementVersionCount?: number,
  ) => boolean
  isArchiveDisabled: (requirementVersionCount?: number) => boolean
  getSpecificationAsset: (
    specId: string,
    assetId: string,
  ) => Promise<Blob | void>
  canEditSpecificationIdentifier: boolean
}

const SpecificationContext = createContext<SpecificationCtx>({
  specification: {
    createdOn: new Date(),
    lastModifiedOn: new Date(),
    id: '',
    name: '',
    specificationIdentifier: '',
    external: false,
    category: null,
    program: null,
    createdBy: '',
    lastModifiedBy: '',
    requirementCount: 0,
    status: undefined,
    organization: '',
    version: '',
    phase: null,
  },
  revision: {
    createdOn: new Date(),
    lastModifiedOn: new Date(),
    id: '',
    createdBy: '',
    lastModifiedBy: '',
    status: revisionApi.RevisionStatus.DRAFT,
    documentId: '',
    version: 0,
    exportControlled: false,
  },
  document: {
    createdOn: new Date(),
    lastModifiedOn: new Date(),
    id: '',
    name: '',
    createdBy: '',
    lastModifiedBy: '',
    sections: [],
  },
  sections: [],
  setSections: () => {},
  requirementIds: new Set(),
  setRequirementIds: () => {},
  isHistoricalRevision: false,
  pendingReviewReviewers: [],
  matrixViewUIConfig: [],
  updateMatrixViewUIConfig: () => {},
  updateSpecificationName: () => {},
  updateSpecification: () => Promise.resolve(),
  updateRequirement: () => Promise.resolve(),
  deleteOrArchiveRequirement: () => Promise.resolve(),
  unarchiveRequirement: () => Promise.resolve(),
  createRequirement: () => Promise.resolve(),
  createReview: () => Promise.resolve(),
  updateReview: () => Promise.resolve(),
  submitReview: () => Promise.resolve(),
  createRevision: () => Promise.resolve(),
  createNewVersion: () => Promise.resolve(),
  submittingNewVersion: false,
  isRationaleEnabled: false,
  publicTenant: false,
  userIsEditor: false,
  contentIsEditable: false,
  isDeleteDisabled: () => true,
  isArchiveDisabled: () => true,
  getSpecificationAsset: () => Promise.resolve(),
  canEditSpecificationIdentifier: false,
})

const useSpecificationContext = () => useContext(SpecificationContext)

const SpecificationContextProvider = (props: {
  children: ReactNode
  publicTenant?: boolean
}) => {
  const {
    specification: loaderSpec,
    revision: loaderRevision,
    isHistoricalRevision,
    matrixConfig: loaderMatrixConfig,
  } = useLoaderData() as any
  const { userDetails, userIsEditor: getUserIsEditor } = useAuth()
  const userId = userDetails?.id

  const [specification, setSpecification] =
    useState<specApi.Specification>(loaderSpec)
  const [revision, setRevision] = useState<revisionApi.Revision>(loaderRevision)
  const [document, setDocument] = useState<docApi.SpecificationDocument | null>(
    null,
  )
  const [sections, setSections] = useState<docApi.Section[]>([])
  const [requirementIds, setRequirementIds] = useState(new Set<string>())
  const [isRationaleEnabled, setIsRationaleEnabled] = useState<boolean>(false)

  const userIsEditor = getUserIsEditor(specification.id)

  const contentIsEditable =
    userIsEditor &&
    revision.status === revisionApi.RevisionStatus.DRAFT &&
    !isHistoricalRevision &&
    !props.publicTenant

  const isDeleteDisabled = useCallback(
    (blockId: string, requirementVersionCount?: number) => {
      const isRequirement = requirementIds.has(blockId)

      return (
        !contentIsEditable ||
        (isRequirement && (requirementVersionCount ?? 0) > 1)
      )
    },
    [contentIsEditable, requirementIds],
  )

  const isArchiveDisabled = useCallback(
    (requirementVersionCount?: number) =>
      (requirementVersionCount ?? 0) === 1 ||
      revision.status !== revisionApi.RevisionStatus.DRAFT,
    [revision.status],
  )

  useEffect(() => {
    setSpecification(loaderSpec)
  }, [loaderSpec])

  useEffect(() => {
    setRevision(loaderRevision)
  }, [loaderRevision])

  useEffect(() => {
    const loadData = async () => {
      const { getDocument, getSection, getFilteredBlocks } = props.publicTenant
        ? { ...docApi.publicTenantMethods, ...blockApi.publicTenantMethods }
        : { ...docApi, ...blockApi }

      const doc = await getDocument(specification.id, revision.documentId)

      const sects = await Promise.all(
        doc.sections.map(
          async (sectionId) =>
            await getSection(specification.id, doc.id, sectionId),
        ),
      )

      const { blocks: reqs } = await getFilteredBlocks(
        specification.id,
        doc.id,
        { type: [blockApi.BlockType.Requirement] },
      )

      setDocument(doc)
      setSections(sects)
      const reqIds = sects
        .map((s) => s.elements)
        .flat()
        .filter((block) => reqs.some((reqBlock) => block.id === reqBlock.id))
        .map((block) => block.id)
      setRequirementIds(new Set(reqIds))
    }
    loadData()
  }, [specification, revision, props.publicTenant])

  const [pendingReview, setPendingReview] = useState<
    RevisionReview | undefined
  >()
  useEffect(() => {
    const loadPendingRevisionReview = async () => {
      if (revision.status !== revisionApi.RevisionStatus.REVIEW) {
        setPendingReview(undefined)
        return
      }

      const reviews = await revisionApi.getRevisionReviews(
        specification.id,
        revision.id,
      )

      const pendingReview = reviews.find(
        (review) => review.status === EntityReviewStatus.PENDING,
      )

      setPendingReview(pendingReview)
    }

    loadPendingRevisionReview()
  }, [revision, specification])

  const [pendingReviewReviewers, setPendingReviewReviewers] = useState<
    RevisionReviewReviewer[]
  >([])
  useEffect(() => {
    const loadRevisionReviewers = async () => {
      if (!pendingReview?.id) {
        setPendingReviewReviewers([])
        return
      }

      const reviewers = await revisionApi.getRevisionReviewReviewers(
        specification.id,
        revision.id,
        pendingReview.id,
      )
      setPendingReviewReviewers(reviewers)
    }

    loadRevisionReviewers()
  }, [pendingReview, revision, specification])

  const [matrixViewUIConfig, setMatrixViewUIConfig] =
    useState<MatrixViewUIConfig[]>(loaderMatrixConfig)

  useEffect(() => {
    const getSpecificationConfigurationApi = props.publicTenant
      ? specApi.getPublicSpecificationConfiguration
      : specApi.getSpecificationConfiguration

    const getSpecificationConfiguration = async () => {
      try {
        const config = await getSpecificationConfigurationApi(specification.id)
        setIsRationaleEnabled(config.requirementRationaleEnabled)
      } catch (error) {
        console.error(
          'Unable to get specification rationale configuration',
          error,
        )
      }
    }
    getSpecificationConfiguration()
  }, [props.publicTenant, specification])

  const updateMatrixViewUIConfig: (columnId: MatrixColumnId) => void =
    useCallback(
      async (columnId) => {
        try {
          const currentConfig = matrixViewUIConfig.find(
            (item) => item.columnId === columnId,
          )
          const updatedHidden = !currentConfig?.hidden

          await specApi.updateMatrixViewUIConfig(specification.id, {
            columnId: columnId,
            hidden: updatedHidden,
          })

          setMatrixViewUIConfig((prev) =>
            prev.map((item) =>
              item.columnId === columnId
                ? { ...item, hidden: updatedHidden }
                : item,
            ),
          )
        } catch (e) {
          console.error('Could not update displayed column configuration', e)
          toastError('An error occurred', 'Try again')
        }
      },
      [specification, matrixViewUIConfig],
    )

  const updateSpecification: ({
    name,
    specificationIdentifier,
    category,
    program,
    phase,
  }: {
    name?: string
    specificationIdentifier?: string
    category?: string | null
    program?: string | null
    phase?: string | null
  }) => Promise<ApiError | void | undefined> = useCallback(
    async (updatedSpec) => {
      const { name, specificationIdentifier, category, program, phase } =
        updatedSpec

      let error: ApiError | undefined = undefined
      try {
        await specApi.updateSpecification(specification.id, {
          name,
          specificationIdentifier,
          category,
          program,
          phase,
        })

        setSpecification((prevSpec) => ({
          ...prevSpec,
          ...(name && { name }),
          ...(specificationIdentifier && { specificationIdentifier }),
          ...(category !== undefined && { category }),
          ...(program !== undefined && { program }),
          ...(phase !== undefined && { phase }),
        }))
      } catch (e: any) {
        error = e instanceof ApiError ? e.fetchResponse : e
      }

      return error
    },
    [specification],
  )

  const updateSpecificationName: (name: string) => void = useCallback(
    async (name) => {
      if (name !== specification.name) {
        await specApi.updateSpecification(specification.id, { name })
        setSpecification({ ...specification, name })
      }
    },
    [specification],
  )

  const updateRequirement: (
    requirementId: string,
    update: Partial<requirementApi.Requirement>,
  ) => Promise<requirementApi.Requirement | undefined> = useCallback(
    async (
      requirementId: string,
      update: Partial<requirementApi.RequirementData>,
    ) => {
      if (document) {
        return await requirementApi.updateRequirement(
          specification.id,
          requirementId,
          update,
        )
      }
    },
    [document, specification],
  )

  const createRequirement = useCallback<
    (
      sectionId: string,
      insertAfterId: string | null,
      data: Partial<requirementApi.RequirementData>,
    ) => Promise<string | void>
  >(
    async (sectionId, insertAfterId, data) => {
      if (document) {
        const { elements } = sections.find((sec) => sec.id === sectionId) ?? {
          elements: [],
        }
        const blockIds = elements.map((el) => el.id)
        const newReq = await requirementApi.createRequirement(
          specification.id,
          sectionId,
          { ...requirementApi.EMPTY_REQUIREMENT, ...data },
        )
        const insertIndex =
          insertAfterId === null ? 0 : blockIds.indexOf(insertAfterId) + 1
        const blockOrder = [...blockIds]
        blockOrder.splice(insertIndex, 0, newReq.id)

        await docApi.reorderSectionElements(
          specification.id,
          document.id,
          sectionId,
          blockOrder,
        )

        setSections((sects) =>
          sects.map((s) =>
            s.id === sectionId
              ? {
                  ...s,
                  elements: blockOrder.map((blockId) => ({
                    id: blockId,
                    type: 'BLOCK',
                  })),
                }
              : s,
          ),
        )

        setRequirementIds((rIds) => new Set(rIds.add(newReq.id)))
        return newReq.id
      }
    },
    [document, sections, specification],
  )

  const deleteOrArchiveRequirement = useCallback(
    async (sectionId: string, id: string) => {
      if (document) {
        await requirementApi.deleteRequirement(specification.id, id)
        try {
          const req = await requirementApi.getRequirement(specification.id, id)
          // Requirement was archived
          return req
        } catch (e) {
          // Requirement was deleted
          if (e instanceof ApiError && e.fetchResponse.status === 404) {
            setSections((sects) =>
              sects.map((s) =>
                s.id === sectionId
                  ? { ...s, elements: s.elements.filter((el) => el.id !== id) }
                  : s,
              ),
            )
            setRequirementIds((rIds) => {
              rIds.delete(id)
              return new Set(rIds)
            })
          }
        }
      }
    },
    [document, specification],
  )

  const unarchiveRequirement = useCallback(
    async (id) => {
      if (document) {
        const update = await requirementApi.updateRequirement(
          specification.id,
          id,
          {},
        )
        return update
      }
    },
    [document, specification],
  )

  const createReview = useCallback(
    async (userIds: string[]) => {
      if (
        revision.status !== revisionApi.RevisionStatus.DRAFT ||
        pendingReview
      ) {
        throw new Error('Revision must be in draft state.')
      }

      const reviewId = await revisionApi.createRevisionReview(
        specification.id,
        revision.id,
      )
      const assignUserRequests = userIds.map((userId) =>
        revisionApi.createRevisionReviewReviewer(
          specification.id,
          revision.id,
          reviewId,
          userId,
        ),
      )

      // Several API calls are required to create a review request, assign
      // users to review and get the updated review request. The API does not
      // have an endpoint to perform this in a transaction, so we need to
      // account for the case when a review is successfully created but users
      // can't be assigned.
      //
      // We will allow the call to create the revision review to throw an error
      // so the caller can nicely display an error and choose how to proceed.
      // When the users fail to be assigned, we will display a message detailing
      // how many users failed but not throw an error.
      let failedUserAssignments = 0
      await Promise.all(
        assignUserRequests.map(async (promise) => {
          try {
            return await promise
          } catch (error) {
            failedUserAssignments++
            console.error('Failed to assign user to review', error)
            return null
          }
        }),
      )

      if (failedUserAssignments > 0) {
        toastError(
          `Unable to assign ${failedUserAssignments} ${
            failedUserAssignments === 1 ? 'user' : 'users'
          } to review specification`,
          '',
        )
      }

      try {
        const review = await revisionApi.getRevisionReview(
          specification.id,
          revision.id,
          reviewId,
        )

        setPendingReview(review)
      } catch (error) {
        // The error is caught here since we don't want the caller to believe
        // that the review request failed successfully get created.
        console.error('Unable to get specification revision review')
      }

      setSpecification({
        ...specification,
        status: revisionApi.RevisionStatus.REVIEW,
      })
      setRevision({ ...revision, status: revisionApi.RevisionStatus.REVIEW })
    },
    [pendingReview, revision, specification],
  )

  const submitReview = useCallback(
    async (review: EntityReviewReviewerEventOperation) => {
      if (!specification.id || !pendingReview?.id || !userId) {
        toastError('Unable to submit review', '')
        return
      }
      await revisionApi.createRevisionReviewReviewerEvent(
        specification.id,
        revision.id,
        pendingReview?.id,
        userId,
        review,
      )

      setPendingReviewReviewers((prevReviews) =>
        prevReviews.map((review) =>
          review.userId === userId
            ? { ...review, status: EntityReviewReviewerStatus.APPROVED }
            : review,
        ),
      )
    },
    [pendingReview, revision, specification, userId],
  )

  const updateReview = useCallback(
    async (action: EntityReviewEventOperation) => {
      if (!pendingReview?.id) {
        toastError(
          action === EntityReviewEventOperation.DISMISS
            ? 'Unable to recall draft'
            : 'Unable to update pending review state',
          '',
        )
        return
      }

      await revisionApi.createRevisionReviewEvent(
        specification.id,
        revision.id,
        pendingReview?.id,
        action,
      )

      const getNewState = (action) => {
        return action === EntityReviewEventOperation.APPROVE
          ? revisionApi.RevisionStatus.ACTIVE
          : revisionApi.RevisionStatus.DRAFT
      }

      const newStatus = getNewState(action)

      setPendingReview(undefined)
      setRevision((prevRevision) => ({
        ...prevRevision,
        status: newStatus,
      }))
      setSpecification({
        ...specification,
        status: newStatus,
      })
    },
    [pendingReview, revision, specification],
  )

  const createRevision = useCallback(async () => {
    const revision = await revisionApi.createRevision(specification.id)
    setRevision(revision)
    setSpecification({
      ...specification,
      status: revisionApi.RevisionStatus.DRAFT,
    })
  }, [specification])

  const getSpecificationAsset = useCallback(
    async (specId: string, assetId: string) => {
      return await (props.publicTenant
        ? assetApi.publicTenantMethods.getSpecificationAsset
        : assetApi.getSpecificationAsset)(specId, assetId)
    },
    [props.publicTenant],
  )

  const canEditSpecificationIdentifier =
    contentIsEditable && revision.version === 1

  const [submittingNewVersion, setSubmittingNewVersion] =
    useState<boolean>(false)

  const createNewVersion = useCallback(async () => {
    setSubmittingNewVersion(true)
    try {
      await createRevision()
      toastSuccess('New version created')
    } catch (error) {
      console.error('Unable to create new version', error)
      toastError('Unable to create new version', 'Reload page and try again')
    } finally {
      setSubmittingNewVersion(false)
    }
  }, [createRevision])

  return (
    <SpecificationContext.Provider
      value={{
        specification,
        revision,
        document,
        sections,
        setSections,
        requirementIds,
        setRequirementIds,
        isHistoricalRevision,
        pendingReview,
        pendingReviewReviewers,
        matrixViewUIConfig,
        updateMatrixViewUIConfig,
        updateSpecificationName,
        updateSpecification,
        updateRequirement,
        createRequirement,
        deleteOrArchiveRequirement,
        unarchiveRequirement,
        createReview,
        updateReview,
        submitReview,
        createRevision,
        createNewVersion,
        submittingNewVersion,
        isRationaleEnabled,
        publicTenant: props.publicTenant,
        userIsEditor,
        contentIsEditable,
        isDeleteDisabled,
        isArchiveDisabled,
        getSpecificationAsset,
        canEditSpecificationIdentifier,
      }}
    >
      {props.children}
    </SpecificationContext.Provider>
  )
}

export { SpecificationContextProvider, useSpecificationContext }
