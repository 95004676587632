import { ImportExport, Upload } from '@carbon/icons-react'
import { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import readExcel from 'read-excel-file'
import styles from './ImportExcelModal.module.css'
import Modal from './index.tsx'
import { createDocumentSection } from '../../api/v2/documents.ts'
import { createRequirement } from '../../api/v2/requirements.ts'
import { getRevisions } from '../../api/v2/revisions.ts'
import {
  CreateSpecificationResponse,
  createSpecification,
  MatrixColumnId,
  updateMatrixViewUIConfig,
} from '../../api/v2/specifications.ts'
import { useAuth } from '../../context/AuthContext.tsx'
import Button, { BUTTON_COLORS } from '../button/index.tsx'
import { toastSuccess } from '../toast/index.tsx'

enum ImportState {
  Confirming,
  Importing,
  Error,
}

const schema = {
  section_number: {
    prop: 'section_number',
    type: String,
  },
  title: {
    prop: 'title',
    type: String,
  },
  shall_statement: {
    prop: 'shall_statement',
    type: String,
  },
  rationale: {
    prop: 'rationale',
    type: String,
  },
  external_identifier: {
    prop: 'external_identifier',
    type: String,
  },
}

const ImportExcelModal = (props) => {
  const { closeModal } = props

  const fileInput = useRef<HTMLInputElement>(null)

  const [stage, setStage] = useState(ImportState.Confirming)
  const [progress, setProgress] = useState(0)
  const [reqCount, setReqCount] = useState(0)
  const navigate = useNavigate()
  const { reload } = useAuth()

  const importExcel = useCallback(async () => {
    setStage(ImportState.Importing)
    const excelFile = fileInput.current?.files![0]
    const { rows, errors } = await readExcel(excelFile!, { schema })

    if (errors.length) {
      setStage(ImportState.Error)
      return
    }

    setReqCount(rows.length)
    let specification: CreateSpecificationResponse | undefined
    try {
      specification = await createSpecification()
      const revision = (await getRevisions(specification.id))[0]
      const documentSection = await createDocumentSection(
        specification.id,
        revision.documentId,
        null,
      )

      for (const r of rows) {
        await createRequirement(specification.id, documentSection.id, {
          title: r[schema.title.prop] || '',
          shallStatement: r[schema.shall_statement.prop] || '',
          rationale: r[schema.rationale.prop] || '',
          sectionNumber: r[schema.section_number.prop] || '',
          externalIdentifier: r[schema.external_identifier.prop] || '',
          exportControlled: false,
          compliance: null,
          types: [],
          data: {
            delta: {
              shallStatement: JSON.stringify({
                ops: [{ insert: r[schema.shall_statement.prop] || '' }],
              }),
              rationale: JSON.stringify({
                ops: [{ insert: r[schema.rationale.prop] || '' }],
              }),
            },
          },
        })

        setProgress((p) => p + 1)
      }

      reload() // update user permissions
    } catch (e) {
      console.error('Unable to import specification', e)
      setStage(ImportState.Error)
    }

    if (!specification) {
      return
    }

    try {
      await updateMatrixViewUIConfig(specification.id, {
        columnId: MatrixColumnId.ComplianceNotes,
        hidden: false,
      })
    } catch (error) {
      console.error(
        'Could not update displayed matrix view column configuration',
        error,
      )
    }

    toastSuccess('Specification imported')
    navigate(`/specifications/${specification.id}`)
    closeModal()
  }, [closeModal, navigate, reload])

  return (
    <Modal
      title="Import Specification"
      onClose={
        stage === ImportState.Confirming || stage === ImportState.Error
          ? closeModal
          : undefined
      }
      icon={<ImportExport className={styles.importIcon} size={20} />}
    >
      <div className={styles.content}>
        {stage == ImportState.Confirming && (
          <>
            <div className={styles.subtitle}>Instructions</div>
            <div className={styles.subcontent}>
              Stell supports importing requirements from .xlxs files. Please
              download the provided template and ensure your data matches the
              format provided. Once formatted, upload your file below.
            </div>
            <div className={styles.subtitle}>Template</div>
            <div className={styles.templateLink}>
              <a href="/templates/requirement_import_template.xlsx">
                requirement_import_template.xlsx
              </a>
            </div>

            <div className={styles.subcontent}>
              Column names should be: <br />
              section_number, title, shall_statement, rationale,
              external_identifier
            </div>
            <div>
              Entries are classified as requirements only when the
              shall_statement field is populated; otherwise, they will be
              considered as non-requirements.
            </div>
            <div className={styles.uploadBtnWrapper}>
              <Button
                text="Upload file"
                endIcon={<Upload size={14} />}
                color={BUTTON_COLORS.PRIMARY}
                onClick={() => fileInput.current?.click()}
              />
            </div>
          </>
        )}
        {stage === ImportState.Importing && (
          <div className={styles.importing}>
            <div>Importing...</div>
            <div className={styles.progressContainer}>
              <div className={styles.progressTrack}>
                <div
                  className={styles.progressMeter}
                  style={{
                    width: `${
                      progress === 0
                        ? 0
                        : Math.round(100 * (progress / reqCount))
                    }%`,
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {stage === ImportState.Error && (
          <div className={styles.error}>
            <div>Unfortunately this file could not be imported.</div>
            <div>
              Please try again and reach out to{' '}
              <a href="mailto:help@stell-engineering.com">
                help@stell-engineering.com
              </a>{' '}
              if you continue to have trouble importing.
            </div>
          </div>
        )}
        <input
          ref={fileInput}
          style={{ display: 'none' }}
          type="file"
          accept=".xls, .xlsx"
          onChange={importExcel}
        />
      </div>
    </Modal>
  )
}
export default ImportExcelModal
