import { Add, CaretDown, CaretRight, Draggable } from '@carbon/icons-react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  ComponentType,
  CSSProperties,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import AddHeadingBlockAction from './AddHeadingBlockAction.tsx'
import AddImageBlockAction from './AddImageBlockAction.tsx'
import AddRequirementBlockAction from './AddRequirementBlockAction.tsx'
import AddTableBlockAction from './AddTableBlockAction.tsx'
import AddTextBlockAction from './AddTextBlockAction.tsx'
import styles from './BlockActions.module.css'
import ConvertToHeadingBlockAction from './ConvertToHeadingBlockAction.tsx'
import ConvertToRequirementAction from './ConvertToRequirementAction.tsx'
import ConvertBlockToTextBlockAction from './ConvertToTextBlockAction.tsx'
import CopyRequirementIdentifier from './CopyRequirementIdentifier.tsx'
import DeleteBlockAction from './DeleteBlockAction.tsx'
import RequirementDetailsAction from './RequirementDetailsAction.tsx'
import ToggleArchiveAction from './ToggleArchiveAction.tsx'
import { BlockActionItemProps, BlockActionsProps } from './types.ts'
import ViewInMatrixAction from './ViewInMatrixAction.tsx'
import { BlockType } from '../../../api/v2/blocks.ts'
import { useDragDropContext } from '../../../context/DragAndDropContext.tsx'
import { useSpecificationContext } from '../../../context/SpecificationContext.tsx'
import Dropdown from '../../dropdown/index.tsx'
import IconButton from '../../icon-button/IconButton.tsx'

const BLOCK_TYPE_TO_ACTIONS = {
  [BlockType.Text]: [
    ConvertToRequirementAction,
    ConvertToHeadingBlockAction,
    DeleteBlockAction,
  ],
  [BlockType.Heading]: [
    ConvertToRequirementAction,
    ConvertBlockToTextBlockAction,
    DeleteBlockAction,
  ],
  [BlockType.Image]: [DeleteBlockAction],
  [BlockType.Requirement]: [
    ConvertBlockToTextBlockAction,
    DeleteBlockAction,
    ToggleArchiveAction,
    ViewInMatrixAction,
    RequirementDetailsAction,
    CopyRequirementIdentifier,
  ],
  [BlockType.Table]: [DeleteBlockAction],
} as Record<BlockType, ComponentType<BlockActionItemProps>[]>

const ADD_MENU_ACTIONS = [
  AddRequirementBlockAction,
  AddTextBlockAction,
  AddHeadingBlockAction,
  AddImageBlockAction,
  AddTableBlockAction,
] as ComponentType<BlockActionItemProps>[]

const BlockActions = (props: BlockActionsProps) => {
  const { block, isHidden, requirement, children } = props
  const { contentIsEditable } = useSpecificationContext()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Reset the menu to default state when mouse re-enters
    if (!isHidden) {
      setIsDropdownOpen(false)
    }
  }, [isHidden])

  const handleKeydown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault()
      setIsDropdownOpen(false)
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      let nextEl = dropdownRef.current?.contains(e.target as HTMLElement)
        ? document.activeElement?.nextElementSibling
        : dropdownRef.current?.firstChild

      // If at a menu header, move to next button
      if (nextEl && nextEl.nodeName !== 'BUTTON') {
        nextEl = nextEl?.nextSibling
      }

      ;(nextEl as HTMLElement)?.focus()
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (dropdownRef.current?.contains(e.target as HTMLElement)) {
        let previousEl =
          document.activeElement?.previousElementSibling ??
          dropdownRef.current?.parentElement?.previousElementSibling

        // If at a menu header, move to prev button
        if (previousEl && previousEl.nodeName !== 'BUTTON') {
          previousEl = previousEl?.previousElementSibling
        }

        ;(previousEl as HTMLElement)?.focus()
      }
    }
  }

  const {
    active,
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: block.id })
  const { activeDnDId } = useDragDropContext()

  const DnDStyle: CSSProperties = {
    opacity: active && active.id === block.id ? 0.4 : 1,
    transform: transform ? CSS.Translate.toString(transform) : undefined,
    transition,
  }

  useEffect(() => {
    if (activeDnDId) {
      setIsDropdownOpen(false)
    }
  }, [activeDnDId])

  return (
    // Plugin advises this disable for capturing bubbled events
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={setNodeRef}
      className={`${styles.blockActions} ${styles.draggable} ${
        isHidden ? styles.hidden : ''
      }`}
      style={DnDStyle}
      onKeyDown={handleKeydown}
    >
      <div className={styles.menus}>
        {(contentIsEditable || block.type === BlockType.Requirement) &&
          activeDnDId !== block.id && (
            <>
              <IconButton
                onClick={() => {
                  setIsDropdownOpen(!isDropdownOpen)
                }}
              >
                <Add size={20} />
              </IconButton>
              <Dropdown isOpen={isDropdownOpen} className={styles.dropdown}>
                <div ref={dropdownRef}>
                  <div className={styles.menuHeader}>Actions</div>
                  {BLOCK_TYPE_TO_ACTIONS[block.type].map((Action, index) => {
                    return (
                      <Action
                        key={`action-${index}`}
                        block={block}
                        requirement={requirement}
                        closeMenu={() => setIsDropdownOpen(false)}
                      />
                    )
                  })}
                  {contentIsEditable && (
                    <>
                      <div className={styles.sectionDivider} />
                      <div
                        className={`${styles.actionItem} ${styles.menuHeader} ${styles.menuExpandable}`}
                        onMouseEnter={() => setIsSubmenuOpen(true)}
                        onMouseLeave={() => setIsSubmenuOpen(false)}
                      >
                        <span>Create</span>{' '}
                        {isSubmenuOpen ? <CaretDown /> : <CaretRight />}
                      </div>
                      <div
                        className={`${styles.expandableSubmenu} ${
                          isSubmenuOpen ? styles.expanded : ''
                        }`}
                        onMouseEnter={() => setIsSubmenuOpen(true)}
                        onMouseLeave={() => setIsSubmenuOpen(false)}
                      >
                        <div className={styles.expandableSubmenuInner}>
                          {ADD_MENU_ACTIONS.map((Action, index) => {
                            return (
                              <Action
                                key={`action-${index}`}
                                block={block}
                                requirement={requirement}
                                closeMenu={() => setIsDropdownOpen(false)}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Dropdown>
            </>
          )}
        {contentIsEditable && (
          <IconButton
            innerRef={setActivatorNodeRef}
            className={styles.handle}
            {...listeners}
            {...attributes}
            onClick={() => {
              setIsDropdownOpen(false)
            }}
          >
            <Draggable size={20} />
          </IconButton>
        )}
      </div>
      {children}
    </div>
  )
}

export default BlockActions
