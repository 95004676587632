import { AddComment, CaretRight, CheckmarkFilled } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './SharedSpecificationReviewRequirementView.module.css'
import * as sharedSpecificationApi from '../../api/v2/sharedSpecifications.ts'
import Button from '../../components/button'
import Tag, { TAG_COLORS } from '../../components/tag'
import {
  LoadingState,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums.ts'
import SharedRequirementCommentForm from '../shared-specification/SharedRequirementCommentForm.tsx'
import SharedRequirementCommentsView from '../shared-specification/SharedRequirementCommentsView.tsx'

const SharedSpecificationReviewRequirementView = (props: {
  review: sharedSpecificationApi.SharedSpecificationSnapshotReview
  reviewRequirement:
    | sharedSpecificationApi.SharedSpecificationSnapshotReviewRequirement
    | undefined
  requirement: sharedSpecificationApi.SharedSpecificationRequirement
  requirementTypesById: Record<
    string,
    sharedSpecificationApi.SharedSpecificationAttributeValue
  >
  comments: sharedSpecificationApi.ReviewRequirementComment[]
  addCommentToRequirement: (
    requirementId: string,
    comment: string,
  ) => Promise<boolean>
}) => {
  const {
    review,
    reviewRequirement,
    requirement,
    requirementTypesById,
    comments,
    addCommentToRequirement,
  } = props
  const complete = reviewRequirement && reviewRequirement.complete
  const [expandedComments, setExpandedComments] = useState<boolean>()
  const [displayCommentForm, setDisplayCommentForm] = useState<boolean>(false)

  return (
    <div className={styles.requirementCard}>
      <div className={styles.titleRow}>
        <span className={styles.title}>{requirement.title || 'Untitled'}</span>
        <div className={styles.tags}>
          {requirement.types.map((typeId) => {
            const type = requirementTypesById[typeId]
            if (!type) {
              return null
            }

            return (
              <Tag
                key={type.id}
                text={type.name}
                color={{
                  fontColor:
                    type?.metadata.STYLES.COLOR_FONT ||
                    TAG_COLORS.gray3.fontColor,
                  backgroundColor:
                    type?.metadata.STYLES.COLOR_BG ||
                    TAG_COLORS.gray3.backgroundColor,
                }}
              />
            )
          })}
        </div>
      </div>
      <div className={styles.requirementContent}>
        <div>{requirement.shallStatement}</div>
        {requirement.rationale && (
          <div className={styles.rationale}>
            <span className={styles.rationaleTitle}>Rationale: </span>
            <span>{requirement.rationale}</span>
          </div>
        )}
      </div>
      <div>
        <div className={styles.requirementFooter}>
          <div className={`${styles.detail} ${styles.reqComplete}`}>
            <CheckmarkFilled
              className={`${styles.icon} ${complete ? styles.complete : ''}`}
            />
            <span className={styles.text}>
              {complete ? 'Requirement complete' : 'Requirement incomplete'}
            </span>
          </div>
          <div className={styles.detail}>
            <AddComment />
            <span>{`${comments.length} comment${
              comments.length === 1 ? '' : 's'
            }`}</span>
          </div>
          <button
            className={styles.detail}
            onClick={() => setExpandedComments((prevExpanded) => !prevExpanded)}
          >
            {(review.status === SpecificationSnapshotReviewStatus.Submitted ||
              (review.status === SpecificationSnapshotReviewStatus.Complete &&
                comments.length > 0)) && (
              <>
                <span>See details</span>
                <CaretRight
                  className={`${styles.caret} ${
                    expandedComments ? styles.expanded : ''
                  }`}
                />
              </>
            )}
          </button>
        </div>
        <div
          className={`${styles.expandableCommentSection} ${
            expandedComments ? styles.expanded : ''
          }`}
        >
          <div
            className={`${styles.expandableInner} ${
              expandedComments ? styles.expanded : ''
            }`}
          >
            <div className={styles.comments}>
              {comments.length > 0 && (
                <SharedRequirementCommentsView
                  comments={comments}
                  commentsLoadingState={LoadingState.Loaded}
                />
              )}
              {review.status ===
                SpecificationSnapshotReviewStatus.Submitted && (
                <div>
                  {displayCommentForm ? (
                    <SharedRequirementCommentForm
                      addComment={async (comment: string) => {
                        const added = await addCommentToRequirement(
                          requirement.id,
                          comment,
                        )
                        if (added) {
                          setDisplayCommentForm(false)
                        }

                        return added
                      }}
                    />
                  ) : (
                    <Button
                      onClick={() => setDisplayCommentForm(true)}
                      text="Comment"
                      endIcon={<AddComment />}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharedSpecificationReviewRequirementView
