import api, { usingPublicTenant } from './api.ts'
import { pdfImportInProgress, wordImportInProgress } from './elements/index.tsx'
import { RevisionStatus } from './revisions.ts'
import { queryTask, TaskResult, TaskStatus } from './tasks.ts'
import * as usersApi from './users.ts'

import {
  StellToastType,
  toastError,
  toastInfo,
  updateToast,
} from '../../components/toast/index.tsx'
import { EntityRole, ReportColumnId } from '../../types/enums'

const createUrl = (specificationId?: string) =>
  `/api/v2/specifications${specificationId ? `/${specificationId}` : ''}`

const createQueryString: (query: Record<string, any>) => string = (query) =>
  '?'.concat(
    Object.keys(query)
      .map((key) =>
        Array.isArray(query[key])
          ? query[key].map((value) => `${key}=${value}`).join('&')
          : `${key}=${query[key]}`,
      )
      .join('&'),
  )

const withDates: (spec: SpecificationResponse) => Specification = (spec) => ({
  ...spec,
  createdOn: new Date(spec.createdOn),
  lastModifiedOn: new Date(spec.lastModifiedOn),
})

interface SpecificationResponse {
  /** @format guid */
  id: string
  name: string
  specificationIdentifier: string
  external: boolean
  /** @format guid */
  category: string | null
  /** @format guid */
  program: string | null
  /** @format guid */
  createdBy: string
  createdOn: string
  /** @format guid */
  lastModifiedBy: string
  lastModifiedOn: string
  organization: string
  version: string
  requirementCount: number
  status: RevisionStatus | undefined
  phase: string | null
}

export interface Specification
  extends Omit<SpecificationResponse, 'createdOn' | 'lastModifiedOn'> {
  createdOn: Date
  lastModifiedOn: Date
}

export const getAllSpecifications: () => Promise<
  Specification[]
> = async () => {
  const res = await api.get(createUrl())
  return res.specifications.map(withDates)
}

interface GetSpecificationsRequest {
  query: string
  limit?: number
  token?: string | null
}

export interface GetSpecificationsResponse {
  specifications: Specification[]
  token: string
}

export const searchSpecifications: (
  req: GetSpecificationsRequest,
) => Promise<GetSpecificationsResponse> = async ({ query, limit, token }) => {
  const searchParams = new URLSearchParams()
  if (query) {
    searchParams.append('query', query)
  }
  if (limit) {
    searchParams.append('limit', `${limit}`)
  }
  if (token) {
    searchParams.append('token', token)
  }

  const resp = await api.get(`${createUrl()}?${searchParams.toString()}`)
  resp.specifications = resp.specifications.map(withDates)
  return resp as GetSpecificationsResponse
}

export type CreateSpecificationResponse = {
  /** @format guid */
  id: string
  name: string
}

export const createSpecification: (
  name?: string,
) => Promise<CreateSpecificationResponse> = (name = 'Untitled') =>
  api.post(createUrl(), { body: { name } })

export const updateSpecification: (
  specificationId: string,
  update: Partial<Specification>,
) => Promise<Specification> = async (specificationId, update) => {
  const res = await api.patch(createUrl(specificationId), { body: update })
  return withDates(res)
}

export const deleteSpecification: (id: string) => Promise<{ id: string }> = (
  id,
) => api.delete(createUrl(id))

export const getSpecification: (
  specificationId: string,
) => Promise<Specification> = async (specificationId) => {
  const res = await api.get(createUrl(specificationId))
  return withDates(res)
}

export const duplicateSpecification: (id: string) => Promise<Specification> = (
  id,
) => api.post(createUrl(id), { body: {} })

/**
 * Specification origin
 */

export type SpecificationOriginResponse = {
  organization: string
  version: string
}

export const getSpecificationOrigin: (
  id: string,
) => Promise<SpecificationOriginResponse> = (id) =>
  api.get(`${createUrl(id)}/origin`)

export const createSpecificationOrigin: (
  id: string,
  organization?: string,
  version?: string,
) => Promise<SpecificationOriginResponse> = (id, organization, version) =>
  api.post(`${createUrl(id)}/origin`, {
    body: {
      organization,
      version,
    },
  })

export const updateSpecificationOrigin: ({
  id,
  organization,
  version,
}: {
  id: string
  organization?: string
  version?: string
}) => Promise<SpecificationOriginResponse> = ({ id, organization, version }) =>
  api.patch(`${createUrl(id)}/origin`, {
    body: {
      organization,
      version,
    },
  })

export const deleteSpecificationOrigin: (id: string) => Promise<void> = (id) =>
  api.delete(`${createUrl(id)}/origin`)

/**
 * Specification permissions/roles
 */

export const getUserRolesForSpecification: (
  id: string,
) => Promise<Record<string, { role: EntityRole }>> = async (id: string) => {
  const response = await api.get(`${createUrl(id)}/permissions`)
  return response.users
}

export const deleteUserRoleForSpecification: (
  specificationId: string,
  userId: string,
) => Promise<{ specificationId: string }> = (specificationId, userId) =>
  api.delete(`${createUrl(specificationId)}/permissions/users/${userId}`)

export const createUserRoleForSpecification: (
  specificationId: string,
  userId: string,
  role: EntityRole,
) => Promise<{ role: EntityRole }> = (specificationId, userId, role) =>
  api.post(`${createUrl(specificationId)}/permissions/users/${userId}`, {
    body: { role },
  })

/**
 * Specification configuration
 */

export const getSpecificationConfiguration: (
  specificationId: string,
) => Promise<{ requirementRationaleEnabled: boolean }> = (specificationId) =>
  api.get(`${createUrl(specificationId)}/configuration`)

export const getPublicSpecificationConfiguration: (
  specificationId: string,
) => Promise<{ requirementRationaleEnabled: boolean }> = (specificationId) =>
  usingPublicTenant(api.get)(`${createUrl(specificationId)}/configuration`)

export const updateSpecificationConfiguration: ({
  specificationId,
  requirementRationaleEnabled,
}: {
  specificationId: string
  requirementRationaleEnabled: boolean
}) => Promise<{ requirementRationaleEnabled: boolean }> = ({
  specificationId,
  requirementRationaleEnabled,
}) =>
  api.patch(`${createUrl(specificationId)}/configuration`, {
    body: {
      requirementRationaleEnabled,
    },
  })

export const importPdfToSpecification: (
  file: Blob,
  useLlm: boolean,
) => Promise<void> = async (file, useLlm) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('content-type', file.type)
  formData.append('useLlm', useLlm.toString())
  let res
  try {
    res = await api.postRaw(`${createUrl()}/import/pdf`, {
      body: formData,
    })
  } catch (e) {
    toastError('Unable to upload PDF', 'Please refresh and try again')
    return
  }
  const { taskId } = res

  const toastId = toastInfo(pdfImportInProgress, {
    autoClose: false,
    position: 'bottom-right',
    closeOnClick: false,
  })

  const poller = async () => {
    let status: TaskStatus | undefined
    let result: TaskResult | undefined

    try {
      const { status: taskStatus, result: taskResult } = await queryTask(taskId)
      status = taskStatus
      result = taskResult
    } catch (e) {
      updateToast(
        toastId,
        {
          toastType: StellToastType.ERROR,
          toastProps: {
            msg: 'There was an error importing your PDF',
            suggestion: 'Please refresh and try again',
          },
        },
        { autoClose: 5000 },
      )
      return
    }

    if (status === TaskStatus.Completed) {
      try {
        await updateMatrixViewUIConfig(result.specificationId, {
          columnId: MatrixColumnId.ComplianceNotes,
          hidden: false,
        })
      } catch (error) {
        console.error(
          'Could not update displayed matrix view column configuration',
          error,
        )
      }
      updateToast(
        toastId,
        {
          toastType: StellToastType.SUCCESS,
          toastProps: {
            msg: 'Import successful!',
            cta: 'View imported specification',
            ctaUrl: `/specifications/${result.specificationId}`,
          },
        },
        { autoClose: 5000, closeOnClick: true },
      )
      return
    } else if (status === TaskStatus.Failed) {
      updateToast(
        toastId,
        {
          toastType: StellToastType.ERROR,
          toastProps: {
            msg: 'There was an error importing your PDF',
            suggestion: 'Please refresh and try again',
          },
        },
        { autoClose: 5000, closeOnClick: true },
      )
      return
    }

    setTimeout(poller, 1000)
  }

  await poller()
}

export const importWordToSpecification: (
  file: Blob,
  llmEnabled: boolean,
) => Promise<void> = async (file, llmEnabled) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('content-type', file.type)
  formData.append('useLlm', llmEnabled.toString())
  let res: { taskId: string }
  try {
    res = await api.postRaw(`${createUrl()}/import/docx`, {
      body: formData,
    })
  } catch (e) {
    console.error('Unable to upload Word DOCX file', e)
    toastError(
      'Unable to upload Word DOCX file',
      'Please refresh and try again',
    )
    return
  }
  const { taskId } = res

  const toastId = toastInfo(wordImportInProgress, {
    autoClose: false,
    position: 'bottom-right',
    closeOnClick: false,
  })

  const poller = async () => {
    let status: TaskStatus | undefined
    let result: TaskResult | undefined

    try {
      const { status: taskStatus, result: taskResult } = await queryTask(taskId)
      status = taskStatus
      result = taskResult
    } catch (e) {
      updateToast(
        toastId,
        {
          toastType: StellToastType.ERROR,
          toastProps: {
            msg: 'There was an error importing your Word DOCX file',
            suggestion: 'Please refresh and try again',
          },
        },
        { autoClose: 5000 },
      )
      return
    }

    if (status === TaskStatus.Completed) {
      try {
        await updateMatrixViewUIConfig(result.specificationId, {
          columnId: MatrixColumnId.ComplianceNotes,
          hidden: false,
        })
      } catch (error) {
        console.error(
          'Could not update displayed matrix view column configuration',
          error,
        )
      }
      updateToast(
        toastId,
        {
          toastType: StellToastType.SUCCESS,
          toastProps: {
            msg: 'Import successful!',
            cta: 'View imported specification',
            ctaUrl: `/specifications/${result.specificationId}`,
          },
        },
        { autoClose: 5000, closeOnClick: true },
      )
      return
    } else if (status === TaskStatus.Failed) {
      updateToast(
        toastId,
        {
          toastType: StellToastType.ERROR,
          toastProps: {
            msg: 'There was an error importing your Word DOCX file',
            suggestion: 'Please refresh and try again',
          },
        },
        { autoClose: 5000, closeOnClick: true },
      )
      return
    }

    setTimeout(poller, 1000)
  }

  await poller()
}

/**
 * Specification report
 */

export interface CreateSpecificationReportRequest {
  /** @format guid */
  revisionId: string
  name: string
  metadata: {
    columnsToDisplay: ReportColumnId[]
    specificationStatus?: RevisionStatus
  }
}

export const createSpecificationReport: (
  req: CreateSpecificationReportRequest,
  specificationId: string,
) => Promise<{ id: string }> = (req, specificationId) =>
  api.post(`${createUrl(specificationId)}/requirements/reports`, {
    body: req,
  })

/**
 * HISTORY
 */

export enum HistoryEventType {
  UNKNOWN,
  CREATED = 'CREATED',
  STATUS_UPDATED = 'STATUS_UPDATED',
  TEXT_UPDATED = 'TEXT_UPDATED',
  SPEC_VERSION_CREATED = 'SPEC_VERSION_CREATED',
  SPEC_VERSION_APPROVED = 'SPEC_VERSION_APPROVED',
  SPEC_VERSION_RELEASED = 'SPEC_VERSION_RELEASED',
  SPEC_REVISION_RECALLED = 'SPEC_REVISION_RECALLED',
  PARENT_LINK_ADDED = 'PARENT_LINK_ADDED',
  PARENT_LINK_REMOVED = 'PARENT_LINK_REMOVED',
  CHILD_LINK_ADDED = 'CHILD_LINK_ADDED',
  CHILD_LINK_REMOVED = 'CHILD_LINK_REMOVED',
  EVIDENCE_ADDED = 'EVIDENCE_ADDED',
  EVIDENCE_REMOVED = 'EVIDENCE_REMOVED',
}

type createdByType = {
  id: string
  firstName: string
  lastName: string
  email: string
  userName: string
  teamIds: string[]
}

export interface RequirementHistoryEvent {
  id: string
  requirementId: string
  type: HistoryEventType
  data: any
  createdOn: Date
  createdBy: createdByType
}

export const getRequirementHistoryWithUsers: (
  specificationId: string,
  requirementId: string,
) => Promise<RequirementHistoryEvent[]> = async (
  specificationId,
  requirementId,
) => {
  const users = (await usersApi.getAllUsers())?.users || []
  const usersById = users.reduce(
    (byId, user) => ({ ...byId, [user.id]: user }),
    {},
  )
  const history = await api.get(
    `${createUrl(specificationId)}/requirements/${requirementId}/history`,
  )

  const result = (history?.events || []).map((h) => ({
    ...h,
    createdOn: new Date(h.createdOn),
    createdBy: usersById[h.createdBy],
  }))

  return result
}

/**
 * Matrix
 */

export enum MatrixColumnId {
  IncrementingOrder = 'INCREMENTAL_ORDER',
  ID = 'REQUIREMENT_ID',
  SectionNumber = 'SECTION_NUMBER',
  RequirementName = 'REQUIREMENT_TITLE',
  ShallStatement = 'REQUIREMENT_SHALL_STATEMENT',
  Rationale = 'REQUIREMENT_RATIONALE',
  Compliance = 'REQUIREMENT_COMPLIANCE',
  ComplianceNotes = 'REQUIREMENT_COMPLIANCE_NOTES',
  ExternalNumber = 'REQUIREMENT_EXTERNAL_IDENTIFIER',
  Status = 'REQUIREMENT_STATUS',
  Type = 'REQUIREMENT_TYPE',
  ParentRequirements = 'PARENT_REQUIREMENT_LIST',
  ParentRequirementName = 'PARENT_REQUIREMENT_NAMES',
  ParentShallStatement = 'PARENT_REQUIREMENT_SHALL_STATEMENTS',
  ChildRequirements = 'CHILD_REQUIREMENT_LIST',
  ChildRequirementName = 'CHILD_REQUIREMENT_NAMES',
  ChildShallStatement = 'CHILD_REQUIREMENT_SHALL_STATEMENTS',
  Validation = 'EVIDENCE_VALIDATION_LIST',
  ValidationDescriptionOfActivity = 'EVIDENCE_VALIDATION_DESC_OF_ACTIVITIES',
  ValidationRecordTitle = 'EVIDENCE_VALIDATION_RECORD_TITLE',
  Verification = 'EVIDENCE_VERIFICATION_LIST',
  VerificationDescriptionOfActivity = 'EVIDENCE_VERIFICATION_DESC_OF_ACTIVITIES',
  VerificationRecordTitle = 'EVIDENCE_VERIFICATION_RECORD_TITLE',
}

export interface MatrixViewUIConfig {
  columnId: MatrixColumnId
  hidden: boolean
}

export interface MatrixViewUIConfigResponse {
  matrixViewColumnConfigurations: MatrixViewUIConfig[]
}

export const getMatrixViewUIConfig: (
  specificationId: string,
) => Promise<MatrixViewUIConfigResponse> = (specificationId) =>
  api.get(`${createUrl(specificationId)}/ui_configurations/matrix_view`)

export interface UpdateMatrixViewUiConfigRequest {
  columnId: MatrixColumnId
  hidden: boolean
}

export const updateMatrixViewUIConfig: (
  specificationId: string,
  req: UpdateMatrixViewUiConfigRequest,
) => Promise<MatrixViewUIConfigResponse> = (specificationId: string, req) =>
  api.patch(`${createUrl(specificationId)}/ui_configurations/matrix_view`, {
    body: req,
  })

export const publicTenantMethods = {
  getAllSpecifications: async (): Promise<Specification[]> => {
    const res = await usingPublicTenant(api.get)(createUrl())
    return res.specifications.map(withDates)
  },
  getSpecification: async (specificationId: string): Promise<Specification> => {
    const res = await usingPublicTenant(api.get)(createUrl(specificationId))
    return withDates(res)
  },

  getMatrixViewUIConfig: async (
    specificationId: string,
  ): Promise<MatrixViewUIConfigResponse> =>
    usingPublicTenant(api.get)(
      `${createUrl(specificationId)}/ui_configurations/matrix_view`,
    ),
}

/**
 * Requirements to link
 */

export interface GetRequirementToLinkResponse {
  id: string
  documentId: string
  specificationId: string
  title: string
  requirementIdentifier: string
}

export const getRequirementsToLink: ({
  limit,
}) => Promise<GetRequirementToLinkResponse[]> = async ({ limit }) => {
  const searchParams = new URLSearchParams()
  if (limit) {
    searchParams.append('limit', `${limit}`)
  }
  const res = await api.get(
    `${createUrl()}/requirements/link?${searchParams.toString()}`,
  )
  return res.requirementsToLink
}

/**
 * REVISIONS
 */

interface SpecificationLatestRevisionResponse {
  /** @format guid */
  id: string
  /** @format guid */
  specificationId: string
  /** @format guid */
  documentId: string
  version: number
  status: RevisionStatus
  requirementCount: number
  exportControlled: boolean
  /** @format guid */
  createdBy: string
  createdOn: string
  /** @format guid */
  lastModifiedBy: string
  lastModifiedOn: string
}

interface SpecificationRevisionQuery {
  specificationId?: string[]
  documentId?: string[]
}

export const getSpecificationLatestRevisions: (
  query?: SpecificationRevisionQuery,
) => Promise<SpecificationLatestRevisionResponse[]> = async (query = {}) => {
  const { revisions } = await api.get(
    `${createUrl()}/revisions${createQueryString(query)}`,
  )
  return revisions
}
