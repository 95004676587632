import { Close } from '@carbon/icons-react'
import { ComponentType, useEffect, useState } from 'react'
import styles from './FilterComponents.module.css'
import { AppliedFilterProps, FilterKey, FilterMenuProps } from './types'
import * as changeReqApi from '../../api/v2/changeRequests'
import { getEvidenceRecords } from '../../api/v2/evidence'
import { SearchResultStatus } from '../../api/v2/search'
import * as api from '../../api/v2/specifications'
import { useAttributesContext } from '../../context/AttributesContext'
import { useEvidenceActivitiesContext } from '../../context/EvidenceActivitiesContext'
import useUsers from '../../hooks/useUsers'
import {
  EvidenceActivityStatus as EvidenceActivityStatusEnum,
  EvidenceActivityType as EvidenceActivityTypeEnum,
  EvidenceCadence as EvidenceCadenceEnum,
  EvidenceType as EvidenceTypeEnum,
  ReportSource,
} from '../../types/enums'
import Avatar from '../avatar'
import { AvatarSize } from '../avatar/constants'
import Chip from '../chip'
import Tag, {
  EVIDENCE_TYPE_COLORS,
  getCustomColorSetFromAttributeValue,
  TAG_COLORS,
} from '../tag'
import ComplianceTag from '../tag/ComplianceTag'
import EvidenceActivityStatusTag from '../tag/EvidenceActivityStatusTag'
import EvidenceActivityTypeTag from '../tag/EvidenceActivityTypeTag'
import PhaseTag from '../tag/PhaseTag.tsx'

const SpecificationCategory = {
  label: 'Category',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props
    const { categories } = useAttributesContext()
    const category = categories.find((p) => p.id === value)!

    return (
      <Tag
        text={category?.name}
        color={getCustomColorSetFromAttributeValue(category)}
        onCancel={() => onRemove(FilterKey.SpecificationCategory, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const categoryFilters = activeFilters[FilterKey.SpecificationCategory]
    const { categories } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {categories.length > 0
          ? categories.map((tag) => {
              const selected = categoryFilters.includes(tag.id)

              return (
                <button
                  key={tag.id}
                  onClick={() =>
                    onSelectFilter(FilterKey.SpecificationCategory, tag.id)
                  }
                >
                  <div className={styles.item}>
                    <input type="radio" checked={selected} readOnly />
                    <Tag
                      text={tag.name}
                      color={getCustomColorSetFromAttributeValue(tag)}
                    />
                  </div>
                </button>
              )
            })
          : 'No categories found'}
      </div>
    )
  },
}

const SpecificationProgram = {
  label: 'Program',
  AppliedFilter: (props) => {
    const { onRemove, value } = props

    const { programs } = useAttributesContext()
    const program = programs.find((p) => p.id === value)!
    const {
      COLOR_BG: backgroundColor,
      COLOR_FONT: fontColor,
      COLOR_BG_HOVER: hoverBackgroundColor,
    } = program?.metadata.STYLES || {
      COLOR_BG: undefined,
      COLOR_FONT: undefined,
      COLOR_BG_HOVER: undefined,
    }

    return (
      <Tag
        text={program?.name}
        color={{ backgroundColor, fontColor, hoverBackgroundColor }}
        onCancel={() => onRemove(FilterKey.SpecificationProgram, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const programFilters = activeFilters[FilterKey.SpecificationProgram]
    const { programs } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {programs.length > 0
          ? programs.map((tag) => {
              const selected = programFilters.includes(tag.id)
              const tagStyles = {
                fontColor: tag.metadata.STYLES.COLOR_FONT,
                backgroundColor: tag.metadata.STYLES.COLOR_BG,
              }

              return (
                <button
                  key={tag.id}
                  onClick={() =>
                    onSelectFilter(FilterKey.SpecificationProgram, tag.id)
                  }
                >
                  <div className={styles.item}>
                    <input type="radio" checked={selected} readOnly />
                    <Tag text={tag.name} color={tagStyles} />
                  </div>
                </button>
              )
            })
          : 'No programs found'}
      </div>
    )
  },
}

const SpecificationPhase = {
  label: 'Phase',
  AppliedFilter: (props) => {
    const { onRemove, value } = props
    return (
      <PhaseTag
        phaseId={value}
        onCancel={() => onRemove(FilterKey.SpecificationProgram, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const programFilters = activeFilters[FilterKey.SpecificationPhase]
    const { phases } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {phases.length > 0
          ? phases.map((tag) => {
              const selected = programFilters.includes(tag.id)
              return (
                <button
                  key={tag.id}
                  onClick={() =>
                    onSelectFilter(FilterKey.SpecificationPhase, tag.id)
                  }
                >
                  <div className={styles.item}>
                    <input type="radio" checked={selected} readOnly />
                    <PhaseTag phaseId={tag.id} />
                  </div>
                </button>
              )
            })
          : 'No phase found'}
      </div>
    )
  },
}

const SOURCE_COLOR = {
  [ReportSource.SearchRequirementV1]: TAG_COLORS.blue,
  [ReportSource.SpecificationRequirementV1]: TAG_COLORS.purple2,
  [ReportSource.EvidenceRequirementV1]: TAG_COLORS.orange2,
}

const ReportSourceFilter = {
  label: 'Source',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props

    return (
      <Tag
        text={value.label.toUpperCase()}
        color={SOURCE_COLOR[value.source]}
        onCancel={() => onRemove(FilterKey.ReportSource, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { filters, activeFilters, onSelectFilter } = props
    const activeSourceFilters = activeFilters[FilterKey.ReportSource]

    return (
      <div className={styles.menu}>
        {filters &&
          filters?.reportSource.map((option) => {
            const selected = activeSourceFilters.some((s) => s.id === option.id)

            return (
              <button
                key={option.id}
                onClick={() => onSelectFilter(FilterKey.ReportSource, option)}
              >
                <div className={styles.item}>
                  <input type="radio" checked={selected} readOnly />
                  <Tag
                    color={SOURCE_COLOR[option.source]}
                    text={option.label.toUpperCase()}
                  />
                </div>
              </button>
            )
          })}
      </div>
    )
  },
}

const RequirementStatus = {
  label: 'Status',
  AppliedFilter: (props) => {
    const { value, onRemove } = props
    return (
      <Chip
        variant="status"
        value={value}
        onRemove={() => onRemove(FilterKey.RequirementStatus, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    return (
      <div className={styles.menu}>
        {Object.values(SearchResultStatus).map((status) => {
          const selected =
            activeFilters[FilterKey.RequirementStatus].includes(status)

          return (
            <button
              key={status}
              onClick={() =>
                onSelectFilter(FilterKey.RequirementStatus, status)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Chip variant="status" value={status} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const RequirementType = {
  label: 'Type',
  AppliedFilter: (props) => {
    const { value, onRemove } = props
    const { requirementTypes } = useAttributesContext()

    const type = requirementTypes.find((t) => t.id === value)!
    const {
      COLOR_BG: backgroundColor,
      COLOR_FONT: fontColor,
      COLOR_BG_HOVER: hoverBackgroundColor,
    } = type.metadata.STYLES

    return (
      <Tag
        text={type.name}
        color={{ backgroundColor, fontColor, hoverBackgroundColor }}
        onCancel={() => onRemove(FilterKey.RequirementType, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const { requirementTypes } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {requirementTypes.map((type) => {
          const { id, metadata, name } = type
          const selected = activeFilters[FilterKey.RequirementType].includes(id)
          const tagColor = {
            fontColor: metadata.STYLES.COLOR_FONT,
            backgroundColor: metadata.STYLES.COLOR_BG,
            hoverBackgroundColor: metadata.STYLES.COLOR_BG_HOVER,
          }

          return (
            <button
              key={id}
              onClick={() => onSelectFilter(FilterKey.RequirementType, type.id)}
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag color={tagColor} text={name} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const SpecificationName = {
  label: 'Specification',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props

    return (
      <Tag
        text={value.name.toUpperCase()}
        color={TAG_COLORS.white2}
        onCancel={() => onRemove(FilterKey.SpecificationName, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const specificationFilters = activeFilters[FilterKey.SpecificationName]

    const [specifications, setSpecifications] = useState<
      Array<api.Specification>
    >([])
    useEffect(() => {
      const fetchSpecifications = async () => {
        const specs = await api.getAllSpecifications()
        setSpecifications(specs)
      }
      fetchSpecifications()
    }, [])

    return (
      <div className={styles.menu}>
        {specifications.map((spec) => {
          const selected = specificationFilters.some((s) => s.id === spec.id)

          return (
            <button
              key={spec.id}
              onClick={() =>
                onSelectFilter(FilterKey.SpecificationName, {
                  id: spec.id,
                  name: spec.name,
                })
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag color={TAG_COLORS.white2} text={spec.name.toUpperCase()} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const ChangeRequestSpecificationName = {
  label: 'Specification',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props

    return (
      <Tag
        text={value.name}
        color={TAG_COLORS.white2}
        onCancel={() =>
          onRemove(FilterKey.ChangeRequestSpecificationName, value)
        }
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const specificationFilters =
      activeFilters[FilterKey.ChangeRequestSpecificationName]

    const [specifications, setSpecifications] = useState<
      { id: string; name: string }[]
    >([])
    useEffect(() => {
      const loadRelatedSpecifications = async () => {
        const changeRequests = await changeReqApi.getAllChangeRequests()
        const specsMap = changeRequests.reduce((acc, request) => {
          return {
            ...acc,
            [request.specificationId]: {
              id: request.specificationId,
              name: request.specificationName,
            },
          }
        }, {})
        setSpecifications(Object.values(specsMap))
      }
      loadRelatedSpecifications()
    }, [])

    return (
      <div className={styles.menu}>
        {specifications.map((spec) => {
          const selected = specificationFilters.some((s) => s.id === spec.id)

          return (
            <button
              key={spec.id}
              onClick={() =>
                onSelectFilter(FilterKey.ChangeRequestSpecificationName, {
                  id: spec.id,
                  name: spec.name,
                })
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag color={TAG_COLORS.white2} text={spec.name} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const Compliance = {
  label: 'Compliance',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props
    const { getComplianceStyles } = useAttributesContext()

    const compliance = getComplianceStyles(value)

    if (!compliance) {
      return null
    }

    return (
      <ComplianceTag
        complianceName={compliance.name}
        color={compliance.styles}
        onCancel={() => onRemove(FilterKey.Compliance, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const { complianceStates, getComplianceStyles } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {complianceStates.map((cs) => {
          const selected = activeFilters[FilterKey.Compliance].includes(cs.name)
          const compliance = getComplianceStyles(cs.name)

          if (!compliance) {
            return null
          }

          return (
            <button
              key={cs.name}
              onClick={() => onSelectFilter(FilterKey.Compliance, cs.name)}
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <ComplianceTag
                  complianceName={compliance.name}
                  color={compliance.styles}
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EvidenceType = {
  label: 'Type',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props

    const tagColor =
      EVIDENCE_TYPE_COLORS[value] ||
      EVIDENCE_TYPE_COLORS[EvidenceTypeEnum.Unknown]

    return (
      <Tag
        text={value}
        color={tagColor}
        onCancel={() => onRemove(FilterKey.EvidenceType, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const typeFilters = activeFilters[FilterKey.EvidenceType]

    return (
      <div className={styles.menu}>
        {Object.values(EvidenceTypeEnum).map((evidenceType) => {
          const selected = typeFilters.includes(evidenceType)

          return (
            <button
              key={evidenceType}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceType, evidenceType)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag
                  text={evidenceType.toUpperCase()}
                  color={
                    EVIDENCE_TYPE_COLORS[evidenceType] ||
                    EVIDENCE_TYPE_COLORS[EvidenceTypeEnum.Unknown]
                  }
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EvidenceMethod = {
  label: 'Method',
  AppliedFilter: (props) => {
    const { onRemove, value } = props
    const { evidenceMethods } = useAttributesContext()
    const method = evidenceMethods.find((m) => m.id === value)!

    const { COLOR_BG: backgroundColor, COLOR_FONT: fontColor } =
      method.metadata.STYLES

    return (
      <Tag
        text={method.name}
        color={{ backgroundColor, fontColor }}
        onCancel={() => onRemove(FilterKey.EvidenceMethod, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const methodFilters = activeFilters[FilterKey.EvidenceMethod]
    const { evidenceMethods } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {evidenceMethods.map((method) => {
          const selected = methodFilters.includes(method.id)
          const tagStyles = {
            fontColor: method.metadata.STYLES.COLOR_FONT,
            backgroundColor: method.metadata.STYLES.COLOR_BG,
          }

          return (
            <button
              key={method.id}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceMethod, method.id)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag text={method.name} color={tagStyles} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const ValidationEvidenceMethod = {
  label: 'Validation Method',
  AppliedFilter: (props) => {
    const { onRemove, value } = props
    const { evidenceMethods } = useAttributesContext()
    const method = evidenceMethods.find((m) => m.id === value)!

    const { COLOR_BG: backgroundColor, COLOR_FONT: fontColor } =
      method.metadata.STYLES

    return (
      <Tag
        text={method.name}
        color={{ backgroundColor, fontColor }}
        onCancel={() => onRemove(FilterKey.ValidationMethod, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const methodFilters = activeFilters[FilterKey.ValidationMethod]
    const { evidenceMethods } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {evidenceMethods.map((method) => {
          const selected = methodFilters.includes(method.id)
          const tagStyles = {
            fontColor: method.metadata.STYLES.COLOR_FONT,
            backgroundColor: method.metadata.STYLES.COLOR_BG,
          }

          return (
            <button
              key={method.id}
              onClick={() =>
                onSelectFilter(FilterKey.ValidationMethod, method.id)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag text={method.name} color={tagStyles} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const VerificationEvidenceMethod = {
  label: 'Verification Method',
  AppliedFilter: (props) => {
    const { onRemove, value } = props
    const { evidenceMethods } = useAttributesContext()
    const method = evidenceMethods.find((m) => m.id === value)!

    const { COLOR_BG: backgroundColor, COLOR_FONT: fontColor } =
      method.metadata.STYLES

    return (
      <Tag
        text={method.name}
        color={{ backgroundColor, fontColor }}
        onCancel={() => onRemove(FilterKey.VerificationMethod, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const methodFilters = activeFilters[FilterKey.VerificationMethod]
    const { evidenceMethods } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {evidenceMethods.map((method) => {
          const selected = methodFilters.includes(method.id)
          const tagStyles = {
            fontColor: method.metadata.STYLES.COLOR_FONT,
            backgroundColor: method.metadata.STYLES.COLOR_BG,
          }

          return (
            <button
              key={method.id}
              onClick={() =>
                onSelectFilter(FilterKey.VerificationMethod, method.id)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag text={method.name} color={tagStyles} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EvidenceActivityStatus = {
  label: 'Status',
  AppliedFilter: (props) => {
    const { value, onRemove } = props

    return (
      <EvidenceActivityStatusTag
        status={value}
        onCancel={() => onRemove(FilterKey.EvidenceActivityStatus, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const statusFilters = activeFilters[FilterKey.EvidenceActivityStatus]

    return (
      <div className={styles.menu}>
        {Object.values(EvidenceActivityStatusEnum).map((status) => {
          const selected = statusFilters.includes(status)

          return (
            <button
              key={status}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceActivityStatus, status)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <EvidenceActivityStatusTag
                  status={status as EvidenceActivityStatusEnum}
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EvidenceActivityOwner = {
  label: 'Owner',
  AppliedFilter: (props) => {
    const { getUserById } = useUsers()
    const { value, onRemove } = props
    const owner = getUserById(value)

    return (
      <div className={`${styles.userTag} ${styles.basicElevation}`}>
        <Avatar
          firstName={owner?.firstName}
          lastName={owner?.lastName}
          size={AvatarSize.Small}
          showName
        />
        <button
          className={styles.closeBtn}
          onClick={() => onRemove(FilterKey.EvidenceActivityOwner, value)}
        >
          <Close />
        </button>
      </div>
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const ownerFilters = activeFilters[FilterKey.EvidenceActivityOwner]
    const { evidenceActivities } = useEvidenceActivitiesContext()
    const { getUserById } = useUsers()

    const allOwners = evidenceActivities.flatMap((activity) => activity.owners)
    const dedupedOwners = [...new Set(allOwners)]

    return (
      <div className={styles.menu}>
        {dedupedOwners.map((owner) => {
          const currentOwner = getUserById(owner)
          const selected = ownerFilters.includes(owner)

          return (
            <button
              key={owner}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceActivityOwner, owner)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Avatar
                  firstName={currentOwner?.firstName}
                  lastName={currentOwner?.lastName}
                  size={AvatarSize.MediumSmall}
                  showName
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EVIDENCE_CADENCE_COLORS = {
  [EvidenceCadenceEnum.Production]: TAG_COLORS.purple2NoHover,
  [EvidenceCadenceEnum.Qualification]: TAG_COLORS.green3NoHover,
}

const EvidenceCadence = {
  label: 'Cadence',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props
    const { evidenceCadences } = useAttributesContext()
    const cadence = evidenceCadences.find((c) => c.id === value)!

    return (
      <Tag
        text={cadence.name}
        color={EVIDENCE_CADENCE_COLORS[cadence.name]}
        onCancel={() => onRemove(FilterKey.EvidenceCadence, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const cadenceFilters = activeFilters[FilterKey.EvidenceCadence]
    const { evidenceCadences } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {evidenceCadences.map((cadence) => {
          const selected = cadenceFilters.includes(cadence.id)

          return (
            <button
              key={cadence.id}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceCadence, cadence.id)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag
                  text={cadence.name}
                  color={EVIDENCE_CADENCE_COLORS[cadence.name]}
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EvidenceProgram = {
  label: 'Program',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props
    const { programs } = useAttributesContext()
    const program = programs.find((c) => c.id === value)!

    return (
      <Tag
        text={program.name}
        color={{
          fontColor: program?.metadata?.STYLES.COLOR_FONT,
          backgroundColor: program?.metadata?.STYLES.COLOR_BG,
        }}
        onCancel={() => onRemove(FilterKey.EvidenceProgram, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const programFilters = activeFilters[FilterKey.EvidenceProgram]
    const { programs } = useAttributesContext()

    return (
      <div className={styles.menu}>
        {programs.map((program) => {
          const selected = programFilters.includes(program.id)

          return (
            <button
              key={program.id}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceProgram, program.id)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag
                  text={program.name}
                  color={{
                    fontColor: program.metadata.STYLES.COLOR_FONT,
                    backgroundColor: program.metadata.STYLES.COLOR_BG,
                  }}
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EvidenceActivityType = {
  label: 'Type',
  AppliedFilter: (props) => {
    const { value, onRemove } = props

    return (
      <EvidenceActivityTypeTag
        type={value}
        onCancel={() => onRemove(FilterKey.EvidenceActivityType, value)}
      />
    )
  },
  FilterMenu: (props) => {
    const { activeFilters, onSelectFilter } = props
    const typeFilters = activeFilters[FilterKey.EvidenceActivityType]

    return (
      <div className={styles.menu}>
        {Object.values(EvidenceActivityTypeEnum).map((type) => {
          const selected = typeFilters.includes(type)

          return (
            <button
              key={type}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceActivityType, type)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <EvidenceActivityTypeTag
                  type={type as EvidenceActivityTypeEnum}
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const EvidenceActivityParentRecord = {
  label: 'Parent Record',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { onRemove, value } = props
    const { getEvidenceMethodById } = useAttributesContext()
    const method = getEvidenceMethodById(value.method)
    const methodColor = method?.metadata.STYLES.COLOR_BG || ''

    return (
      <Tag
        text={value.name}
        color={TAG_COLORS.white2}
        pipColor={methodColor}
        onCancel={() => onRemove(FilterKey.EvidenceActivityParentRecord, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter } = props
    const recordFilters = activeFilters[FilterKey.EvidenceActivityParentRecord]
    const { getEvidenceMethodById } = useAttributesContext()
    const { evidenceActivities } = useEvidenceActivitiesContext()

    const [records, setRecords] = useState<
      { id: string; name: string; method: string }[]
    >([])

    useEffect(() => {
      const loadRecords = async () => {
        const recordIds = [
          ...new Set(evidenceActivities.map((activity) => activity.recordId)),
        ]
        const records = await getEvidenceRecords(recordIds)
        const recordsMap = records.reduce((acc, record) => {
          return {
            ...acc,
            [record.id]: {
              id: record.id,
              name: record.title,
              method: record.method,
            },
          }
        }, {})
        setRecords(Object.values(recordsMap))
      }
      loadRecords()
    }, [evidenceActivities])

    return (
      <div className={styles.menu}>
        {records.map((record) => {
          const selected = recordFilters.some((r) => r.id === record.id)
          const method = getEvidenceMethodById(record.method)
          const methodColor = method?.metadata.STYLES.COLOR_BG || ''

          return (
            <button
              key={record.id}
              onClick={() =>
                onSelectFilter(FilterKey.EvidenceActivityParentRecord, {
                  id: record.id,
                  name: record.name,
                  method: record.method,
                })
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag
                  color={TAG_COLORS.white2}
                  text={record.name}
                  pipColor={methodColor}
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const SharedRequirementType = {
  label: 'Type',
  AppliedFilter: (props: AppliedFilterProps) => {
    const { value, onRemove, filterData } = props
    const types = filterData?.[FilterKey.SharedRequirementType] || []

    const type = types.find((t) => t.id === value)!

    const {
      COLOR_BG: backgroundColor,
      COLOR_FONT: fontColor,
      COLOR_BG_HOVER: hoverBackgroundColor,
    } = type.metadata.STYLES

    return (
      <Tag
        text={type.name}
        color={{ backgroundColor, fontColor, hoverBackgroundColor }}
        onCancel={() => onRemove(FilterKey.SharedRequirementType, value)}
      />
    )
  },
  FilterMenu: (props: FilterMenuProps) => {
    const { activeFilters, onSelectFilter, filterData } = props
    const types = filterData?.[FilterKey.SharedRequirementType] || []

    return (
      <div className={styles.menu}>
        {types.map((type) => {
          const { id, metadata, name } = type
          const selected =
            activeFilters[FilterKey.SharedRequirementType].includes(id)
          const tagColor = {
            fontColor: metadata.STYLES.COLOR_FONT,
            backgroundColor: metadata.STYLES.COLOR_BG,
            hoverBackgroundColor: metadata.STYLES.COLOR_BG_HOVER,
          }

          return (
            <button
              key={id}
              onClick={() =>
                onSelectFilter(FilterKey.SharedRequirementType, type.id)
              }
            >
              <div className={styles.item}>
                <input type="radio" checked={selected} readOnly />
                <Tag color={tagColor} text={name} />
              </div>
            </button>
          )
        })}
      </div>
    )
  },
}

const FilterComponents: Record<
  FilterKey,
  {
    FilterMenu: ComponentType<FilterMenuProps>
    AppliedFilter: ComponentType<AppliedFilterProps>
    label: string
  }
> = {
  [FilterKey.SpecificationCategory]: SpecificationCategory,
  [FilterKey.SpecificationProgram]: SpecificationProgram,
  [FilterKey.SpecificationPhase]: SpecificationPhase,
  [FilterKey.ReportSource]: ReportSourceFilter,
  [FilterKey.RequirementStatus]: RequirementStatus,
  [FilterKey.RequirementType]: RequirementType,
  [FilterKey.SpecificationName]: SpecificationName,
  [FilterKey.ChangeRequestSpecificationName]: ChangeRequestSpecificationName,
  [FilterKey.Compliance]: Compliance,
  [FilterKey.EvidenceType]: EvidenceType,
  [FilterKey.EvidenceMethod]: EvidenceMethod,
  [FilterKey.EvidenceCadence]: EvidenceCadence,
  [FilterKey.EvidenceProgram]: EvidenceProgram,
  [FilterKey.ValidationMethod]: ValidationEvidenceMethod,
  [FilterKey.VerificationMethod]: VerificationEvidenceMethod,
  [FilterKey.EvidenceActivityType]: EvidenceActivityType,
  [FilterKey.EvidenceActivityParentRecord]: EvidenceActivityParentRecord,
  [FilterKey.EvidenceActivityStatus]: EvidenceActivityStatus,
  [FilterKey.EvidenceActivityOwner]: EvidenceActivityOwner,
  [FilterKey.SharedRequirementType]: SharedRequirementType,
}

export default FilterComponents
