import { CaretDown } from '@carbon/icons-react'
import styles from './ReportTableColumns.module.css'
import {
  ReportContentEvidenceResponse,
  ReportContentLinkedRequirementResponse,
} from '../../api/v2/reports.ts'
import Chip from '../../components/chip'
import QuillContent from '../../components/quill-content/QuillContent.tsx'
import { TableColumn, TableData } from '../../components/table/Table.tsx'
import Tag, { stylesToTagColor, TAG_COLORS } from '../../components/tag'
import ComplianceTag from '../../components/tag/ComplianceTag.tsx'
import { getCssVar } from '../../lib/string.ts'
import {
  AttributeName,
  EvidenceType,
  LinkedRequirementType,
  ReportColumnId,
} from '../../types/enums.ts'
import { COLUMN_ID_TO_NAME } from '../search/SearchTableColumns.tsx'

export interface CollapsibleTableData extends TableData {
  collapsed: boolean
}

interface ReportTableColumn extends TableColumn {
  id: string
  collapsed: boolean
}

export const CollapsibleTextCell = (props) => {
  const { className, text, collapsed, delta } = props

  return (
    <div
      className={`${styles.collapsibleCell} ${
        collapsed ? styles.collapse : ''
      } ${className}`}
    >
      {text && <div className={styles.text}>{text}</div>}
      {delta && (
        <div className={styles.quill}>
          <QuillContent readOnly delta={delta} />
        </div>
      )}
    </div>
  )
}

export const CollapsibleTagsCell = (props: {
  tags: Array<{
    uniqueId: string
    text: string
    color:
      | {
          fontColor: string
          backgroundColor: string
          hoverBackgroundColor?: string | undefined
        }
      | undefined
  }>
  collapsed: any
}) => {
  const { tags, collapsed } = props
  const displayedTags = collapsed ? tags.slice(0, 1) : tags

  return (
    <div
      className={`${styles.collapsibleTagsCell} ${
        collapsed ? styles.collapse : ''
      }`}
    >
      <div className={styles.tagList}>
        {displayedTags.length > 0 &&
          displayedTags.map((tag) => (
            <Tag
              key={tag.uniqueId}
              text={tag.text.toUpperCase()}
              color={tag.color || TAG_COLORS.gray3}
            />
          ))}
        {tags?.length > 1 && collapsed && (
          <span className={styles.overflowCount}>+{tags.length - 1}</span>
        )}
      </div>
    </div>
  )
}

export const IdCell = (props) => {
  const { data } = props

  const requirementIdentifier = `${
    data?.specification?.specificationIdentifier || 'Document Number'
  }-${data?.requirement?.rootRequirementIdentifier || ''}`

  return (
    <CollapsibleTextCell
      className={styles.idCell}
      collapsed={data.collapsed}
      text={requirementIdentifier}
    />
  )
}

export const RequirementSectionNumberCell = (props) => {
  const { data } = props

  return (
    <CollapsibleTextCell
      className={styles.sectionCell}
      collapsed={!data?.expandAll && data.collapsed}
      text={data?.requirement?.sectionNumber}
    />
  )
}

export const RequirementNameCell = (props) => {
  const { data } = props

  return (
    <CollapsibleTextCell
      className={`${styles.requirementNameCell}`}
      collapsed={!data?.expandAll && data.collapsed}
      text={data?.requirement?.title || 'Untitled'}
    />
  )
}

export const ShallStatementCell = (props) => {
  const { data } = props
  return (
    <CollapsibleTextCell
      className={styles.shallStatementCell}
      collapsed={!data?.expandAll && data.collapsed}
      text={data?.requirement?.shallStatement}
    />
  )
}

const ComplianceCell = (props) => {
  const { data } = props
  const compliance: Array<{
    name: string
    metadata: any
  }> = data?.requirement?.attributes?.COMPLIANCE || []
  const complianceName = compliance?.[0]?.name
  const styles = compliance?.[0]?.metadata.STYLES

  return (
    <div>
      {complianceName && (
        <ComplianceTag
          complianceName={complianceName}
          color={stylesToTagColor(styles)}
        />
      )}
    </div>
  )
}

const ComplianceNotesCell = (props) => {
  const { data } = props

  return (
    <CollapsibleTextCell
      className={styles.complianceNotesCell}
      collapsed={!data?.expandAll && data.collapsed}
      text={data?.requirement?.complianceNotes}
    />
  )
}

const RationaleCell = (props) => {
  const { data } = props

  return (
    <CollapsibleTextCell
      className={styles.rationaleCell}
      collapsed={!data?.expandAll && data.collapsed}
      text={data?.requirement?.rationale}
    />
  )
}

export const RequirementTypeCell = (props) => {
  const { data } = props
  const types: Array<{
    name: string
    metadata: any
  }> = data?.requirement?.attributes?.TYPE || []

  const tags = types
    ? types
        .sort((a, b) => {
          return (a.name || '').localeCompare(b.name || '')
        })
        .map((type) => ({
          uniqueId: `${type.name}`,
          text: type.name,
          color: {
            fontColor: type.metadata.STYLES.COLOR_FONT,
            backgroundColor: type.metadata.STYLES.COLOR_BG,
          },
        }))
    : []

  return (
    <CollapsibleTagsCell
      collapsed={!data?.expandAll && data.collapsed}
      tags={tags}
    />
  )
}

export const SpecificationCell = (props) => {
  const { data } = props

  return (
    <div>
      <CollapsibleTextCell
        className={styles.specificationCell}
        collapsed={!data?.expandAll && data.collapsed}
        text={data?.specification?.name}
      />
    </div>
  )
}

export const SpecificationIdentifierCell = (props) => {
  const { data } = props
  return (
    <CollapsibleTextCell
      className={styles.specNumberCell}
      collapsed={data.collapsed}
      text={data?.specification?.specificationIdentifier}
    />
  )
}

export const SpecificationRevisionCell = (props) => {
  const { data } = props
  return (
    <div>
      {data?.revision?.version && (
        <Tag
          text={
            data?.requirement?.externalIdentifier
              ? data?.revision?.version
              : `V${data?.revision?.version}`
          }
          color={{
            fontColor: getCssVar('--text-color-black'),
            backgroundColor: getCssVar('--color-green5'),
          }}
        />
      )}
    </div>
  )
}

export const StatusCell = (props) => {
  const { data } = props
  return (
    <div className={styles.cell}>
      <Chip variant="status" value={data?.requirement?.status} />
    </div>
  )
}

export const ProgramCell = (props) => {
  const { data } = props

  const program = data?.specification?.attributes?.PROGRAM?.[0]
  const fontColor = program?.metadata?.STYLES?.COLOR_FONT
  const backgroundColor = program?.metadata?.STYLES?.COLOR_BG

  return (
    <div className={styles.cell}>
      {program && (
        <Tag
          text={program.name}
          color={
            fontColor && backgroundColor
              ? {
                  fontColor,
                  backgroundColor,
                }
              : TAG_COLORS.gray3
          }
        />
      )}
    </div>
  )
}

export const LinkedRequirementNameCell = (props) => {
  const { data, linkType } = props
  const collapsed = !data?.expandAll && data.collapsed

  const links: ReportContentLinkedRequirementResponse[] =
    linkType === LinkedRequirementType.Parent
      ? data['parentRequirement[]']
      : data['childRequirement[]']

  return (
    <div className={`${styles.inherited} ${collapsed ? '' : styles.expanded}`}>
      {links.length > 0 && (
        <ul>
          {(collapsed ? [links[0]] : links).map((link) => (
            <li key={link.id}>
              <span className={styles.itemText}>{link.name || 'Untitled'}</span>
              {collapsed && links.length > 1 && <CaretDown />}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export const LinkedRequirementShallStatementCell = (props) => {
  const { data, linkType } = props
  const collapsed = !data?.expandAll && data.collapsed

  const links: ReportContentLinkedRequirementResponse[] =
    linkType === LinkedRequirementType.Parent
      ? data['parentRequirement[]']
      : data['childRequirement[]']

  return (
    <div className={`${styles.inherited} ${collapsed ? '' : styles.expanded}`}>
      {links.length > 0 && (
        <ul>
          {(collapsed ? [links[0]] : links).map((link) => (
            <li key={link.id}>
              <span className={styles.itemText}>
                {link.shallStatement || 'Shall Statement'}
              </span>
              {collapsed && links.length > 1 && <CaretDown />}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export const EvidenceTypeValidationCell = (props) => {
  const { data } = props
  const evidences: ReportContentEvidenceResponse[] = data['evidence[]']

  const tags =
    evidences
      ?.filter((evidence) => evidence.type === EvidenceType.Validation)
      .flatMap(
        (evidence) =>
          evidence.attributes[AttributeName.EvidenceMethod]?.map(
            (attribute: {
              name: string
              metadata: { STYLES: { COLOR_FONT: string; COLOR_BG: string } }
            }) => ({
              uniqueId: `${evidence.id}-${attribute.name}`,
              text: attribute.name,
              color: {
                fontColor: attribute.metadata.STYLES.COLOR_FONT,
                backgroundColor: attribute.metadata.STYLES.COLOR_BG,
              },
            }),
          ),
      ) || []

  return (
    <CollapsibleTagsCell
      collapsed={!data?.expandAll && data.collapsed}
      tags={tags}
    />
  )
}

export const RecordTitleCell = (props) => {
  const { data, evidenceType } = props
  const collapsed = !data?.expandAll && data.collapsed

  const evidences: ReportContentEvidenceResponse[] = data['evidence[]'].filter(
    (evidence: ReportContentEvidenceResponse) => evidence.type === evidenceType,
  )

  return (
    <div className={`${styles.inherited} ${collapsed ? '' : styles.expanded}`}>
      {evidences.length > 0 && (
        <ul>
          {(collapsed ? [evidences[0]] : evidences).map((evidence) => (
            <li key={evidence.id}>
              <span className={styles.itemText}>
                {evidence.title || 'Record Title'}
              </span>
              {collapsed && evidences.length > 1 && <CaretDown />}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export const DescriptionofActivityCell = (props) => {
  const { data, evidenceType } = props
  const collapsed = !data?.expandAll && data.collapsed

  const evidences: ReportContentEvidenceResponse[] = data['evidence[]'].filter(
    (evidence: ReportContentEvidenceResponse) => evidence.type === evidenceType,
  )

  return (
    <div className={`${styles.inherited} ${collapsed ? '' : styles.expanded}`}>
      {evidences.length > 0 && (
        <ul>
          {(collapsed ? [evidences[0]] : evidences).map((evidence) => (
            <li key={evidence.id}>
              <span className={styles.itemText}>
                {evidence.description || 'Description Of Activity'}
              </span>
              {collapsed && evidences.length > 1 && <CaretDown />}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export const EvidenceTypeVerificationCell = (props) => {
  const { data } = props
  const evidences: ReportContentEvidenceResponse[] = data['evidence[]']

  const tags =
    evidences
      ?.filter((evidence) => evidence.type === EvidenceType.Verification)
      .flatMap((evidence) => {
        const methods = evidence.attributes[AttributeName.EvidenceMethod]

        return methods?.map(
          (attribute: {
            name: string
            metadata: { STYLES: { COLOR_FONT: string; COLOR_BG: string } }
          }) => ({
            uniqueId: `${evidence.id}-${attribute.name}`,
            text: attribute.name,
            color: {
              fontColor: attribute.metadata.STYLES.COLOR_FONT,
              backgroundColor: attribute.metadata.STYLES.COLOR_BG,
            },
          }),
        )
      }) || []

  return (
    <CollapsibleTagsCell
      collapsed={!data?.expandAll && data.collapsed}
      tags={tags}
    />
  )
}

const REPORT_TABLE_COLUMNS: Array<ReportTableColumn> = [
  {
    id: ReportColumnId.RequirementIdentifier,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementIdentifier],
    width: 'minmax(auto, 200px)',
    Component: IdCell,
  },
  {
    id: ReportColumnId.RequirementSectionNumber,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementSectionNumber],
    Component: RequirementSectionNumberCell,
  },
  {
    id: ReportColumnId.RequirementName,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementName],
    width: 'minmax(350px, max-content)',
    Component: RequirementNameCell,
  },
  {
    id: ReportColumnId.RequirementShallStatement,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementShallStatement],
    width: 'minmax(350px, max-content)',
    Component: ShallStatementCell,
  },
  {
    id: ReportColumnId.RequirementCompliance,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementCompliance],
    Component: ComplianceCell,
  },
  {
    id: ReportColumnId.RequirementComplianceNotes,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementComplianceNotes],
    width: 'minmax(350px, max-content)',
    Component: ComplianceNotesCell,
  },
  {
    id: ReportColumnId.RequirementRationale,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementRationale],
    width: 'minmax(350px, max-content)',
    Component: RationaleCell,
  },
  {
    id: ReportColumnId.RequirementType,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementType],
    Component: RequirementTypeCell,
  },
  {
    id: ReportColumnId.SpecificationName,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationName],
    width: 'minmax(300px, max-content)',
    Component: SpecificationCell,
  },
  {
    id: ReportColumnId.SpecificationIdentifier,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationIdentifier],
    width: 'minmax(175px, max-content)',
    Component: SpecificationIdentifierCell,
  },
  {
    id: ReportColumnId.SpecificationRevision,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationRevision],
    width: 'minmax(100px, max-content)',
    Component: SpecificationRevisionCell,
  },
  {
    id: ReportColumnId.RequirementStatus,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementStatus],
    Component: StatusCell,
  },
  {
    id: ReportColumnId.SpecificationProgramName,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationProgramName],
    Component: ProgramCell,
  },
  {
    id: ReportColumnId.ParentRequirementName,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.ParentRequirementName],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <LinkedRequirementNameCell
        linkType={LinkedRequirementType.Parent}
        {...props}
      />
    ),
  },
  {
    id: ReportColumnId.ParentRequirementShallStatement,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.ParentRequirementShallStatement],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <LinkedRequirementShallStatementCell
        linkType={LinkedRequirementType.Parent}
        {...props}
      />
    ),
  },
  {
    id: ReportColumnId.ChildRequirementName,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.ChildRequirementName],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <LinkedRequirementNameCell
        linkType={LinkedRequirementType.Child}
        {...props}
      />
    ),
  },
  {
    id: ReportColumnId.ChildRequirementShallStatement,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.ChildRequirementShallStatement],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <LinkedRequirementShallStatementCell
        linkType={LinkedRequirementType.Child}
        {...props}
      />
    ),
  },
  {
    id: ReportColumnId.EvidenceTypeValidation,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.EvidenceTypeValidation],
    Component: EvidenceTypeValidationCell,
  },
  {
    id: ReportColumnId.ValidationRecordTitle,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.ValidationRecordTitle],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <RecordTitleCell {...props} evidenceType={EvidenceType.Validation} />
    ),
  },
  {
    id: ReportColumnId.ValidationDescriptionOfActivity,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.ValidationDescriptionOfActivity],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <DescriptionofActivityCell
        {...props}
        evidenceType={EvidenceType.Validation}
      />
    ),
  },
  {
    id: ReportColumnId.EvidenceTypeVerification,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.EvidenceTypeVerification],
    Component: EvidenceTypeVerificationCell,
  },
  {
    id: ReportColumnId.VerificationRecordTitle,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.VerificationRecordTitle],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <RecordTitleCell {...props} evidenceType={EvidenceType.Verification} />
    ),
  },
  {
    id: ReportColumnId.VerificationDescriptionOfActivity,
    collapsed: true,
    label: COLUMN_ID_TO_NAME[ReportColumnId.VerificationDescriptionOfActivity],
    width: 'minmax(350px, max-content)',
    Component: (props) => (
      <DescriptionofActivityCell
        {...props}
        evidenceType={EvidenceType.Verification}
      />
    ),
  },
]

export const getColumnsFromReport = (columnsToDisplay: string[]) => {
  return REPORT_TABLE_COLUMNS.filter((col) => columnsToDisplay.includes(col.id))
}
