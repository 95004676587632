import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLoaderData } from 'react-router-dom'
import { useAuth } from './AuthContext'
import * as api from '../api/v2/sharedSpecifications'
import { SpecificationSnapshotPermission } from '../api/v2/users'
import { toastError } from '../components/toast'
import {
  AttributeName,
  AttributeValueStatus,
  LoadingState,
  SpecificationSnapshotReviewStatus,
} from '../types/enums'
import { ApiError } from '../types/errors'

interface SharedSpecificationCtx {
  specificationSnapshot: api.SharedSpecificationSnapshot
  getRequirementById: (
    requirementId: string,
  ) => api.SharedSpecificationRequirement | null
  getRequirementTypeById: (
    attributeId: string,
  ) => api.SharedSpecificationAttributeValue | null
  requirementTypes: api.SharedSpecificationAttributeValue[]
  review: api.SharedSpecificationSnapshotReview | null
  reviewLoading: LoadingState
  updateReviewStatus: (status: SpecificationSnapshotReviewStatus) => void
  updateRequirementStatus: (
    specificationId: string,
    snapshotId: string,
    reviewId: string,
    requirementId: string,
    complete: boolean,
  ) => void
  userIsSnapshotOwner: boolean
  userIsSnapshotReviewer: boolean
}

const SharedSpecificationContext = createContext<SharedSpecificationCtx>({
  specificationSnapshot: {
    id: '',
    requirementCount: 0,
    revisionVersion: 0,
    specificationId: '',
    specificationName: '',
    project: {
      id: '',
      name: '',
      status: AttributeValueStatus.None,
      metadata: {
        STYLES: {
          COLOR_FONT: '#161616',
          COLOR_BG: '#e0e0e0',
          COLOR_BG_HOVER: '#d1d1d1',
        },
      },
    },
    createdOn: new Date(),
    contents: {
      id: '',
      revision: {
        id: '',
        version: 0,
      },
      specification: {
        id: '',
        name: '',
        identifier: '',
        externalOrigin: null,
      },
      customAttributes: [],
      documentBlocks: [],
      requirements: [],
      version: 0,
    },
  } as api.SharedSpecificationSnapshot,
  getRequirementById: () => null,
  getRequirementTypeById: () => null,
  requirementTypes: [],
  review: null,
  reviewLoading: LoadingState.Loading,
  updateReviewStatus: () => {},
  updateRequirementStatus: () => {},
  userIsSnapshotOwner: false,
  userIsSnapshotReviewer: false,
})

const SharedSpecificationContextProvider = (props) => {
  const specificationSnapshot =
    useLoaderData() as api.SharedSpecificationSnapshot
  const { userPermissions } = useAuth()
  const specificationId = specificationSnapshot.contents.specification.id
  const [review, setReview] =
    useState<api.SharedSpecificationSnapshotReview | null>(null)
  const [reviewLoading, setReviewLoading] = useState<LoadingState>(
    LoadingState.Loading,
  )
  const prevSnapshotId = useRef('')

  const userIsSnapshotOwner = useMemo(
    () =>
      userPermissions?.specificationSnapshots?.[specificationSnapshot.id]
        ?.role === SpecificationSnapshotPermission.Owner,
    [userPermissions?.specificationSnapshots, specificationSnapshot],
  )

  const userIsSnapshotReviewer = useMemo(
    () =>
      userPermissions?.specificationSnapshots?.[specificationSnapshot.id]
        ?.role === SpecificationSnapshotPermission.Viewer,
    [userPermissions?.specificationSnapshots, specificationSnapshot],
  )

  const requirementIdToRequirement =
    specificationSnapshot.contents.requirements.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      }
    }, {})

  const createAttributeValueLookup = (
    values: api.SharedSpecificationAttributeValue[],
  ) => {
    return values.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      }
    }, {})
  }

  const requirementTypes =
    specificationSnapshot.contents.customAttributes.filter(
      (attribute) => attribute.name === AttributeName.RequirementType,
    )[0].values

  const typeIdToType = createAttributeValueLookup(requirementTypes)

  const getRequirementById = useCallback(
    (requirementId: string) => requirementIdToRequirement[requirementId],
    [requirementIdToRequirement],
  )

  const getRequirementTypeById = useCallback(
    (attributeId: string) => typeIdToType[attributeId],
    [typeIdToType],
  )

  const loadReview = useCallback(async () => {
    try {
      return await api.getCurrentSharedSpecificationSnapshotReview(
        specificationId,
        specificationSnapshot.id,
      )
    } catch (error) {
      if (error instanceof ApiError && error.fetchResponse.status === 404) {
        return null
      } else {
        throw new Error('Unable to load existing specification snapshot review')
      }
    }
  }, [specificationId, specificationSnapshot.id])

  const loadOrCreateReview = useCallback(async () => {
    setReviewLoading(LoadingState.Loading)
    try {
      let snapshotReview = await loadReview()
      if (snapshotReview === null) {
        console.warn(
          'Existing specification snapshot review not found. Creating a new review.',
        )
        snapshotReview = await api.createSharedSpecificationSnapshotReview(
          specificationId,
          specificationSnapshot.id,
        )
      }
      setReview(snapshotReview)
      setReviewLoading(LoadingState.Loaded)
    } catch (error) {
      console.error(
        'Unable to load or create specification snapshot review',
        error,
      )
      setReviewLoading(LoadingState.Failed)
    }
  }, [loadReview, specificationId, specificationSnapshot.id])

  useEffect(() => {
    if (
      !userPermissions ||
      userIsSnapshotOwner ||
      prevSnapshotId.current === specificationSnapshot.id
    ) {
      return
    }

    setReview(null)
    prevSnapshotId.current = specificationSnapshot.id
    loadOrCreateReview()
  }, [
    userIsSnapshotOwner,
    loadOrCreateReview,
    specificationSnapshot.id,
    userPermissions,
  ])

  const updateReviewStatus = useCallback(
    (status: SpecificationSnapshotReviewStatus) => {
      setReview((prev) => {
        if (prev) {
          return { ...prev, status }
        }
        return prev
      })
    },
    [],
  )

  const updateRequirementCompleteState = useCallback(
    (requirementId: string, complete: boolean) => {
      setReview((prev) => {
        if (!prev) {
          return prev
        }
        const prevRequirement = prev.requirements[requirementId]
        if (!prevRequirement) {
          console.warn(
            'Cannot update completion state because requirement does not exist in review',
          )
          return prev
        }
        return {
          ...prev,
          requirements: {
            ...prev.requirements,
            [requirementId]: { ...prevRequirement, complete },
          },
        }
      })
    },
    [],
  )

  const updateRequirementStatus = useCallback(
    async (
      specificationId: string,
      snapshotId: string,
      reviewId: string,
      requirementId: string,
      complete: boolean,
    ) => {
      try {
        updateRequirementCompleteState(requirementId, complete)
        await api.updateSharedSpecificationSnapshotReviewRequirement(
          specificationId,
          snapshotId,
          reviewId,
          requirementId,
          complete,
        )
      } catch (error) {
        updateRequirementCompleteState(requirementId, !complete)
        toastError('Failed to update completion status', '')
        console.error('Unable to update requirement completion state', error)
      }
    },
    [updateRequirementCompleteState],
  )

  return (
    <SharedSpecificationContext.Provider
      value={{
        specificationSnapshot,
        getRequirementById,
        getRequirementTypeById,
        requirementTypes,
        review,
        reviewLoading,
        updateReviewStatus,
        updateRequirementStatus,
        userIsSnapshotOwner,
        userIsSnapshotReviewer,
      }}
    >
      {props.children}
    </SharedSpecificationContext.Provider>
  )
}

const useSharedSpecificationContext = () => {
  const ctx = useContext(SharedSpecificationContext)
  if (!ctx) {
    console.error('SharedSpecificationContext has no provider')
  }
  return ctx
}

export { SharedSpecificationContextProvider, useSharedSpecificationContext }
