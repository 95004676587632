import { ChangeRequest } from '../../../api/v2/changeRequests'
import { User } from '../../../api/v2/users.ts'

export type RequirementGraphViewResponse = {
  /** @format guid */
  focusRequirementId: string
  requirements: {
    [key: string]: {
      /** @format guid */
      id: string
      displayId: string
      title?: string
      shallStatement?: string
      /** @format guid */
      specificationId: string
      relations: Array<{
        type: 'CHILD' | 'PARENT'
        /** @format guid */
        entityId: string
      }>
    }
  }
  specifications: {
    [key: string]: {
      /** @format guid */
      id: string
      title: string
    }
  }
}

export interface RequirementChangeRequest
  extends Omit<ChangeRequest, 'createdBy'> {
  createdBy: User
}

export enum RequirementCommentType {
  Comment,
  ChangeRequest,
}
