import { Version } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '.'
import styles from './SpecificationHistoryModal.module.css'
import { getRevisions, Revision } from '../../api/v2/revisions'
import useClickOutside from '../../hooks/useClickOutside'
import { monthLongDayYear } from '../../lib/date'
import LoadingIndicator from '../loading-indicator/LoadingIndicator'
import RevisionStatusTag from '../tag/RevisionStatusTag'

export interface SpecificationHistoryModalProps {
  specificationId: string
}

const SpecificationHistoryModal = (
  props: SpecificationHistoryModalProps & { closeModal: () => void },
) => {
  const { specificationId, closeModal } = props
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [revisions, setRevisions] = useState<Revision[]>([])

  const modalRef = useClickOutside(() => closeModal())

  useEffect(() => {
    const loadRevisions = async () => {
      setLoading(true)
      try {
        const specificationRevisions = await getRevisions(specificationId)
        setRevisions(specificationRevisions)
      } catch (e) {
        console.error('Could not get version history', e)
        setError('Unable to get version history')
      } finally {
        setLoading(false)
      }
    }
    loadRevisions()
  }, [specificationId])

  const generateRevisionRoute = (revision) => {
    let url = `/specifications/${specificationId}`

    const latestRevision = revisions[0]

    if (latestRevision.id !== revision.id) {
      url += `/revisions/${revision.id}/document`
    }

    return url
  }

  return (
    <Modal
      title="Version History"
      icon={<Version />}
      innerRef={modalRef}
      onClose={closeModal}
    >
      <div className={styles.content}>
        {loading && (
          <div className={styles.loading}>
            <LoadingIndicator />
          </div>
        )}
        {error && <div className={styles.error}>{error}</div>}

        {revisions.map((revision) => {
          const { version, status, createdOn, id } = revision

          return (
            <Link
              key={id}
              onClick={closeModal}
              to={generateRevisionRoute(revision)}
            >
              <div className={styles.row}>
                <div className={styles.version}>Version {version}</div>
                <div className={styles.status}>
                  <RevisionStatusTag status={status} />
                </div>
                <div className={styles.createdDate}>
                  Created {monthLongDayYear(createdOn)}
                </div>
              </div>
            </Link>
          )
        })}
      </div>

      {!error && !loading && (
        <div className={styles.linkContent}>
          <Link
            to={`/specifications/${specificationId}/settings/history`}
            onClick={closeModal}
          >
            View detailed specification history
          </Link>
        </div>
      )}
    </Modal>
  )
}

export default SpecificationHistoryModal
