import * as QuillNamespace from 'quill'

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { useDocumentContext } from './DocumentContext.tsx'
import { useSpecificationContext } from './SpecificationContext'
import * as assetApi from '../api/v2/assets'
import * as blockApi from '../api/v2/blocks'
import {
  Block,
  ImageBlockData,
  TableBlockData,
  TextBlockData,
} from '../api/v2/blocks'
import { reorderSectionElements } from '../api/v2/documents'
import { Requirement } from '../api/v2/requirements.ts'
import { createNewTableBlockData } from '../components/block-editor/blocks/table/useTableBlock.ts'
import useSyncStatus from '../components/block-editor/hooks/useSyncStatusOnReviewRelease.tsx'
import useVisibleBlocks, {
  VisibleBlockData,
} from '../components/block-editor/hooks/useVisibleBlocks.tsx'
import {
  deltaToPlaintext,
  EMPTY_DELTA,
  plaintextToDelta,
} from '../lib/string.ts'
import { RequirementStatus } from '../types/enums.ts'

export enum ImageFormat {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
}

type TextBlockType = 'text' | 'header'

export type MergeBlocksPayload = {
  id: string
  type: TextBlockType
}

export const ImageFileExtensions = Object.values(ImageFormat).map((format) =>
  format.replace('image/', '.'),
)

type SectionContext = VisibleBlockData & {
  sectionId: string
  setBlockIdsWithReorder: (blockIds: string[]) => void
  createTextBlock: (
    insertAfterId: string,
    delta?: string,
  ) => Promise<Block<TextBlockData> | void>
  createHeadingBlock: (insertAfterId: string, data?: string) => Promise<void>
  createImageBlock: (insertAfterId: string, image: File) => Promise<void>
  createTableBlock: (insertAfterId: string) => Promise<void>
  setRequirement: (requirementId: string, update: Partial<Requirement>) => void
  updateRequirementBlock: (
    blockId: string,
    update: Partial<Requirement>,
  ) => void
  updateTextBlock: (blockId: string, update: string) => Promise<void>
  updateHeadingBlock: (blockId: string, update: string) => void
  updateImageBlock: (blockId: string, name: string) => void
  updateTableBlock: (blockId: string, data: TableBlockData) => Promise<void>
  deleteBlock: (blockId: string) => Promise<Requirement | void>
  deleteBlocks: (
    blocks: {
      id: string | null | undefined
      requirementVersion: string | null | undefined
    }[],
  ) => Promise<void>
  mergeBlocks: (
    f: () => void,
  ) => (blockIdOne: MergeBlocksPayload, blockIdTwo: MergeBlocksPayload) => void
  convertBlockToRequirement: (
    blockId: string,
    blockType: blockApi.BlockType,
    value: string,
  ) => Promise<void>
  convertBlockToHeadingBlock: (blockId: string, delta: string) => Promise<void>
  convertBlockToTextBlock: (blockId: string, text: string) => Promise<void>
  blockIds: string[]
  setBlockIds: Dispatch<SetStateAction<string[]>>
  activeQuillRefs: React.MutableRefObject<
    Map<string, QuillNamespace.Quill>
  > | null
}

const SectionCtx = createContext<SectionContext>({
  sectionId: '',
  visibleBlocks: {},
  setVisibleBlocks: () => {},
  setBlockIdsWithReorder: () => {},
  createTextBlock: () => Promise.resolve(),
  createHeadingBlock: () => Promise.resolve(),
  createImageBlock: () => Promise.resolve(),
  createTableBlock: () => Promise.resolve(),
  setRequirement: () => {},
  updateRequirementBlock: () => {},
  updateTextBlock: () => Promise.resolve(),
  updateHeadingBlock: () => {},
  updateImageBlock: () => {},
  updateTableBlock: () => Promise.resolve(),
  deleteBlock: () => Promise.resolve(),
  deleteBlocks: () => Promise.resolve(),
  mergeBlocks: () => () => {},
  convertBlockToRequirement: () => Promise.resolve(),
  convertBlockToHeadingBlock: () => Promise.resolve(),
  convertBlockToTextBlock: () => Promise.resolve(),
  onTopReached: () => {},
  onBottomReached: () => {},
  renderStart: 0,
  renderUntil: 0,
  blockIds: [],
  setBlockIds: () => {},
  activeQuillRefs: null,
})

export const useSectionContext = () => {
  return useContext(SectionCtx)
}

export const SectionContextProvider = (props: {
  sectionId: string
  allowEmpty?: boolean
  children: ReactNode
}) => {
  const { sectionId, allowEmpty } = props

  const {
    contentIsEditable,
    document,
    requirementIds,
    specification,
    createRequirement,
    updateRequirement,
    deleteOrArchiveRequirement,
    revision,
    sections,
    setSections,
  } = useSpecificationContext()

  const { scrollToBlockId, setAutoSelectBlockId } = useDocumentContext()

  const blockIds = useMemo(
    () =>
      sections
        .find((section) => section.id === sectionId)
        ?.elements?.map((el) => el.id) ?? [],
    [sections, sectionId],
  )

  const visibleBlockData = useVisibleBlocks(
    blockIds,
    scrollToBlockId,
    specification.id,
    revision.id,
    document?.id,
  )

  const lastDeletedBlock = useRef<string | null>(null)

  const setBlockIds = useCallback(
    (update: string[] | ((prevBlockIds: string[]) => string[])) => {
      setSections((prevSections) =>
        prevSections.map((section) => {
          if (section.id === sectionId) {
            const newBlockIds =
              typeof update === 'function'
                ? update(section.elements.map((el) => el.id))
                : update

            return {
              ...section,
              // Note: this will cause issues if we ever nest sections
              elements: newBlockIds.map((id) => ({ id, type: 'BLOCK' })),
            }
          } else {
            return section
          }
        }),
      )
    },
    [sectionId, setSections],
  )

  const setBlockIdsWithReorder = useCallback(
    async (newBlockIds) => {
      if (!document) {
        return
      }

      await reorderSectionElements(
        specification.id,
        document.id,
        sectionId,
        newBlockIds,
      )

      setBlockIds(newBlockIds)
    },
    [document, sectionId, setBlockIds, specification],
  )

  /* Prevents spamming enter from ruining the block state
      This UX is not amazing, as the focus may not change before enter is allowed again
      If we care about this edge we should come up with a better focus mechanism for
      new block creation, but this protects us from breaking
  */
  const creatingTextBlock = useRef(false)
  const createTextBlock: (
    insertAfterId: string,
    delta?: string,
  ) => Promise<void> = useCallback(
    async (insertAfterId, delta = EMPTY_DELTA) => {
      if (document && !creatingTextBlock.current) {
        creatingTextBlock.current = true
        const textBlock = await blockApi.createBlock(
          specification.id,
          document.id,
          sectionId,
          {
            type: blockApi.BlockType.Text,
            data: { _data: { quillDelta: delta || EMPTY_DELTA } },
          },
        )

        const order = [...blockIds]
        order.splice(order.indexOf(insertAfterId) + 1, 0, textBlock.id)

        await reorderSectionElements(
          specification.id,
          document.id,
          sectionId,
          order,
        )
        setBlockIds((bs) => {
          const firstPart = bs.slice(0, order.indexOf(textBlock.id))
          const lastPart = bs.slice(order.indexOf(textBlock.id))

          return [...firstPart, textBlock.id, ...lastPart]
        })
        setAutoSelectBlockId(textBlock.id)
        creatingTextBlock.current = false
      }
    },
    [
      document,
      specification.id,
      sectionId,
      blockIds,
      setBlockIds,
      setAutoSelectBlockId,
    ],
  )

  const createHeadingBlock = useCallback(
    async (insertAfterId: string, data: string = '') => {
      if (document) {
        const textBlock = await blockApi.createBlock(
          specification.id,
          document.id,
          sectionId,
          {
            type: blockApi.BlockType.Heading,
            data: { _data: { text: data } },
          },
        )

        const order = [...blockIds]
        order.splice(order.indexOf(insertAfterId) + 1, 0, textBlock.id)

        await reorderSectionElements(
          specification.id,
          document.id,
          sectionId,
          order,
        )
        setBlockIds((bs) => {
          const firstPart = bs.slice(0, order.indexOf(textBlock.id))
          const lastPart = bs.slice(order.indexOf(textBlock.id))

          return [...firstPart, textBlock.id, ...lastPart]
        })
        setAutoSelectBlockId(textBlock.id)
      }
    },
    [
      document,
      specification.id,
      sectionId,
      blockIds,
      setBlockIds,
      setAutoSelectBlockId,
    ],
  )

  const createImageBlock = useCallback(
    async (insertAfterId, imageFile) => {
      if (document) {
        const { id: assetId } = await assetApi.createAssetId(specification.id)
        const imageAsset = await assetApi.createImageAsset(
          specification.id,
          assetId,
          imageFile,
        )

        const block = await blockApi.createBlock(
          specification.id,
          document.id,
          sectionId,
          {
            type: blockApi.BlockType.Image,
            data: {
              assetId: imageAsset.id,
              _data: {},
            } as ImageBlockData,
          },
        )

        const order = [...blockIds]
        order.splice(order.indexOf(insertAfterId) + 1, 0, block.id)

        await reorderSectionElements(
          specification.id,
          document.id,
          sectionId,
          order,
        )
        setBlockIds((bs) => {
          bs.splice(bs.findIndex((id) => id === insertAfterId) + 1, 0, block.id)
          return [...bs]
        })
      }
    },
    [blockIds, document, sectionId, setBlockIds, specification.id],
  )

  const createTableBlock: (insertAfterId: string) => Promise<void> =
    useCallback(
      async (insertAfterId) => {
        if (document) {
          const tableBlock = await blockApi.createBlock(
            specification.id,
            document.id,
            sectionId,
            {
              type: blockApi.BlockType.Table,
              data: createNewTableBlockData(),
            },
          )
          const order = [...blockIds]
          order.splice(order.indexOf(insertAfterId) + 1, 0, tableBlock.id)

          await reorderSectionElements(
            specification.id,
            document.id,
            sectionId,
            order,
          )
          setBlockIds((bs) => {
            const firstPart = bs.slice(0, order.indexOf(tableBlock.id))
            const lastPart = bs.slice(order.indexOf(tableBlock.id))

            return [...firstPart, tableBlock.id, ...lastPart]
          })
          setAutoSelectBlockId(tableBlock.id)
        }
      },
      [
        blockIds,
        document,
        sectionId,
        setAutoSelectBlockId,
        setBlockIds,
        specification,
      ],
    )

  const setRequirement = useCallback(
    (requirementId: string, update: Partial<Requirement>) => {
      visibleBlockData.setVisibleBlocks((vbs) => {
        const copy = { ...vbs }
        copy[requirementId].requirement = {
          ...copy[requirementId].requirement!,
          ...update,
        }
        return copy
      })
    },
    [visibleBlockData],
  )

  const updateRequirementBlock = useCallback(
    async (blockId, update) => {
      const updatedReq = await updateRequirement(blockId, update)
      visibleBlockData.setVisibleBlocks((visibleBlocks) => ({
        ...visibleBlocks,
        [blockId]: { ...visibleBlocks[blockId], requirement: updatedReq },
      }))
    },
    [updateRequirement, visibleBlockData],
  )

  const updateImageBlock = useCallback(
    async (blockId: string, name: string) => {
      if (document) {
        await blockApi.updateBlock(
          specification.id,
          document.id,
          sectionId,
          blockId,
          { name } as ImageBlockData,
        )
      }
    },
    [document, sectionId, specification.id],
  )

  const updateTextBlock: (blockId: string, update: string) => Promise<void> =
    useCallback(
      async (blockId, update) => {
        if (document && lastDeletedBlock.current !== blockId) {
          await blockApi.updateBlock(
            specification.id,
            document.id,
            sectionId,
            blockId,
            { _data: { quillDelta: update } },
          )
          visibleBlockData.setVisibleBlocks((visibleBlocks) => ({
            ...visibleBlocks,
            [blockId]: {
              ...visibleBlocks[blockId],
              block: {
                ...visibleBlocks[blockId].block!,
                data: { _data: { quillDelta: update } },
              },
            },
          }))
        }
      },
      [document, sectionId, specification.id, visibleBlockData],
    )

  const updateHeadingBlock = useCallback(
    async (blockId, update) => {
      if (document) {
        await blockApi.updateBlock(
          specification.id,
          document.id,
          sectionId,
          blockId,
          { _data: { text: update } },
        )
        visibleBlockData.setVisibleBlocks((visibleBlocks) => ({
          ...visibleBlocks,
          [blockId]: {
            ...visibleBlocks[blockId],
            block: {
              ...visibleBlocks[blockId].block!,
              data: { _data: { text: update } },
            },
          },
        }))
      }
    },
    [document, sectionId, specification.id, visibleBlockData],
  )

  const updateTableBlock: (
    blockId: string,
    data: TableBlockData,
  ) => Promise<void> = useCallback(
    async (blockId, data) => {
      if (document) {
        visibleBlockData.setVisibleBlocks((visibleBlocks) => {
          blockApi.updateBlock(
            specification.id,
            document.id,
            sectionId,
            blockId,
            data,
          )

          return {
            ...visibleBlocks,
            [blockId]: {
              ...visibleBlocks[blockId],
              block: {
                ...visibleBlocks[blockId].block!,
                data,
              } as Block<TableBlockData>,
            },
          }
        })
      }
    },
    [document, sectionId, specification.id, visibleBlockData],
  )

  const deleteBlock = useCallback(
    async (blockId: string, replaceIfOnlyBlock: boolean = true) => {
      if (document) {
        if (requirementIds.has(blockId)) {
          const req = await deleteOrArchiveRequirement(sectionId, blockId)
          if (!req && blockIds.length === 1 && replaceIfOnlyBlock) {
            const textBlock = await blockApi.createBlock(
              specification.id,
              document.id,
              sectionId,
              {
                type: blockApi.BlockType.Text,
                data: { _data: { quillDelta: EMPTY_DELTA } },
              },
            )
            setBlockIds([textBlock.id])
          }
          return req
        } else {
          await blockApi.deleteBlock(specification.id, document.id, blockId)
          if (blockIds.length === 1 && replaceIfOnlyBlock) {
            const textBlock = await blockApi.createBlock(
              specification.id,
              document.id,
              sectionId,
              {
                type: blockApi.BlockType.Text,
                data: { _data: { quillDelta: EMPTY_DELTA } },
              },
            )
            setBlockIds([textBlock.id])
          } else {
            setBlockIds((bs) => bs.filter((id) => id !== blockId))
          }
        }
        lastDeletedBlock.current = blockId
      }
    },
    [
      document,
      requirementIds,
      deleteOrArchiveRequirement,
      sectionId,
      blockIds.length,
      specification.id,
      setBlockIds,
    ],
  )

  const deleteBlocks = useCallback(
    async (
      blocksToDelete: {
        id: string | null | undefined
        requirementVersion: string | null | undefined
      }[],
    ) => {
      if (document && contentIsEditable) {
        const validBlocks = blocksToDelete.filter((block) => {
          const requirementStatus = block.id
            ? visibleBlockData.visibleBlocks[block.id].requirement?.status
            : undefined
          return requirementStatus !== RequirementStatus.Archived
        })

        if (!validBlocks || validBlocks.length === 0 || blockIds.length === 0) {
          return
        }

        let error = false
        // Delete in serial because backend cannot handle async requests gracefully
        for (const block of validBlocks) {
          if (block && block.id) {
            try {
              await deleteBlock(block.id, !allowEmpty)
              if (Number(block.requirementVersion ?? 0) > 1) {
                setRequirement(block.id, { status: RequirementStatus.Archived })
              }
            } catch (e) {
              error = true
              console.error('Unable to delete block with id:', block.id)
            }
          }
        }
        // We deleted everything, add a text block
        // Length 1 will cause text-block replace to be handled abo ve
        if (
          !allowEmpty &&
          !error &&
          blockIds.length === validBlocks.length &&
          blockIds.length > 1
        ) {
          const textBlock = await blockApi.createBlock(
            specification.id,
            document.id,
            sectionId,
            {
              type: blockApi.BlockType.Text,
              data: { _data: { quillDelta: EMPTY_DELTA } },
            },
          )
          setBlockIds([textBlock.id])
        }
      }
    },
    [
      document,
      contentIsEditable,
      blockIds.length,
      allowEmpty,
      visibleBlockData.visibleBlocks,
      deleteBlock,
      setRequirement,
      specification.id,
      sectionId,
      setBlockIds,
    ],
  )

  const activeQuillRefs = useRef<Map<string, QuillNamespace.Quill>>(new Map())
  const mergeBlocks = useCallback(
    (setAutoSelectBlockId) =>
      async (blockInto: MergeBlocksPayload, blockFrom: MergeBlocksPayload) => {
        if (!document) {
          return
        }
        // can't merge requirements
        if (
          requirementIds.has(blockFrom.id) ||
          requirementIds.has(blockInto.id)
        ) {
          return
        }

        const quillIntoRef = activeQuillRefs.current?.get(blockInto.id)

        const quillFromRef = activeQuillRefs.current?.get(blockFrom.id)

        if (!quillIntoRef || !quillFromRef) {
          return
        }

        const quillIntoRefText = quillIntoRef?.getText()

        const quillFromRefText = quillFromRef?.getText()

        const intoText =
          quillIntoRefText[quillIntoRefText.length - 1] === '\n'
            ? quillIntoRefText.slice(0, quillIntoRefText.length - 1)
            : quillIntoRefText

        const fromText = quillFromRefText

        const updatedText = `${intoText}${fromText}`

        if (blockInto.type === 'text') {
          await blockApi.updateBlock(
            specification.id,
            document.id,
            sectionId,
            blockInto.id,
            { _data: { quillDelta: updatedText } },
          )
          quillIntoRef.setText(updatedText)
          quillIntoRef.setSelection(intoText.length, 0)
        }

        await blockApi.deleteBlock(specification.id, document.id, blockFrom.id)

        lastDeletedBlock.current = blockFrom.id

        const { visibleBlocks } = visibleBlockData
        visibleBlockData.setVisibleBlocks({
          ...visibleBlocks,
          [blockInto.id]: {
            ...visibleBlocks[blockInto.id],
            block: {
              ...visibleBlocks[blockInto.id].block!,
              data: { _data: { quillDelta: updatedText } },
            },
          },
        })
        setBlockIds((bs) => bs.filter((id) => id !== blockFrom.id))
        setAutoSelectBlockId(blockInto.id)
      },
    [
      document,
      requirementIds,
      visibleBlockData,
      specification.id,
      setBlockIds,
      sectionId,
    ],
  )

  const convertBlockToRequirement = useCallback(
    async (blockId: string, blockType: blockApi.BlockType, value: string) => {
      if (document) {
        let newText = ''
        let newDelta = EMPTY_DELTA

        if (blockType === blockApi.BlockType.Text) {
          newDelta = value
          newText = deltaToPlaintext(value)
        }

        if (blockType === blockApi.BlockType.Heading) {
          newDelta = plaintextToDelta(value)
          newText = value
        }

        await createRequirement(sectionId, blockId, {
          shallStatement: newText,
          rationale: '',
          data: {
            delta: {
              shallStatement: newDelta,
              rationale: EMPTY_DELTA,
            },
          },
        })
        await deleteBlock(blockId, false)
      }
    },
    [document, createRequirement, sectionId, deleteBlock],
  )

  const convertBlockToHeadingBlock = useCallback(
    async (blockId: string, delta: string) => {
      if (document) {
        const text = deltaToPlaintext(delta)
        await createHeadingBlock(blockId, text)
        await deleteBlock(blockId, false)
      }
    },
    [createHeadingBlock, deleteBlock, document],
  )

  const convertBlockToTextBlock = useCallback(
    async (blockId: string, text: string) => {
      if (document) {
        await createTextBlock(blockId, text)
        await deleteBlock(blockId, false)
      }
    },
    [createTextBlock, deleteBlock, document],
  )

  useSyncStatus(visibleBlockData.setVisibleBlocks, revision.status)

  return (
    <SectionCtx.Provider
      value={{
        createTextBlock,
        createHeadingBlock,
        createImageBlock,
        createTableBlock,
        setRequirement,
        updateRequirementBlock,
        updateTextBlock,
        updateHeadingBlock,
        updateImageBlock,
        updateTableBlock,
        convertBlockToRequirement,
        convertBlockToHeadingBlock,
        convertBlockToTextBlock,
        deleteBlock,
        deleteBlocks,
        mergeBlocks,
        setBlockIdsWithReorder,
        setBlockIds,
        blockIds,
        sectionId,
        activeQuillRefs,
        ...visibleBlockData,
      }}
    >
      {props.children}
    </SectionCtx.Provider>
  )
}
