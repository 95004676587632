import api from './api'
import {
  AttributeName,
  AttributeValueStatus,
  EntityType,
} from '../../types/enums'

const createUrl: (
  attributeId?: string,
  withValues?: boolean,
  attributeValueId?: string,
) => string = (attributeId, withValues, attributeValueId) =>
  `/api/v2/attributes${attributeId ? `/${attributeId}` : ''}${
    withValues ? '/values' : ''
  }${attributeValueId ? `/${attributeValueId}` : ''}`

interface AttributeResponse {
  /** @format guid */
  id: string
  name: AttributeName
  entityType: EntityType
}

export const getAttributes: () => Promise<{
  attributes: AttributeResponse[]
}> = () => api.get(createUrl())

export type AttributeValueMetadataStylesResponse = {
  COLOR_FONT: string
  COLOR_BG: string
  COLOR_BG_HOVER: string
}

interface AttributeValueMetadataResponse {
  STYLES: AttributeValueMetadataStylesResponse
}

export interface AttributeValueResponse {
  /** @format guid */
  id: string
  name: string
  status: AttributeValueStatus
  entityCount: number
  metadata: AttributeValueMetadataResponse
}

interface AttributeValuesResponse {
  /** @format guid */
  id: string
  name: AttributeName
  entityType: EntityType
  values: AttributeValueResponse[]
}

export const getAttributeValues: (
  attributeId: string,
) => Promise<AttributeValuesResponse> = (attributeId) =>
  api.get(createUrl(attributeId))

interface CreateAttributeRequest {
  name: string
  metadata: AttributeValueMetadataResponse
}

export const createAttribute: (
  attributeId: string,
  attribute: CreateAttributeRequest,
) => Promise<AttributeValueResponse> = (attributeId, attribute) =>
  api.post(createUrl(attributeId, true), {
    body: attribute,
  })

interface UpdateAttributeRequest {
  name?: string
  status?: AttributeValueStatus
  metadata?: AttributeValueMetadataResponse
}

export interface UpdateAttributeResponse
  extends Omit<AttributeValueResponse, 'entityCount'> {}

export const updateAttribute: (
  attributeId: string,
  attributeValueId: string,
  update: UpdateAttributeRequest,
) => Promise<UpdateAttributeResponse> = (
  attributeId,
  attributeValueId,
  update,
) =>
  api.patch(createUrl(attributeId, true, attributeValueId), {
    body: update,
  })

export const archiveAttribute: (
  attributeId: string,
  attributeValueId: string,
  update: UpdateAttributeRequest,
) => Promise<UpdateAttributeResponse> = (attributeId, attributeValueId, req) =>
  updateAttribute(attributeId, attributeValueId, {
    ...req,
    status: AttributeValueStatus.Archived,
  })
