import { CloseOutline, Logout } from '@carbon/icons-react'
import { useCallback } from 'react'
import Modal from './index.tsx'
import styles from './RemoveStellUserModal.module.css'
import * as usersApi from '../../api/v2/users.ts'

import useClickOutside from '../../hooks/useClickOutside.ts'
import Button, { BUTTON_COLORS } from '../button'
import { toastError, toastSuccess } from '../toast'

const RemoveStellUserModal = (props) => {
  const { closeModal, user, onRemoveUser } = props

  const onClickOutside = useCallback(() => {
    closeModal()
  }, [closeModal])

  const containerRef = useClickOutside(onClickOutside)
  const deleteUser = useCallback(async () => {
    try {
      await usersApi.deleteUser(user.id)
      onRemoveUser(user.id)
      closeModal()
      toastSuccess('Successfully removed user.')
    } catch (e) {
      toastError('Failed to remove user.', 'Please retry or refresh the page.')
    }
  }, [closeModal, onRemoveUser, user.id])

  return (
    <Modal
      innerRef={containerRef}
      title="Remove User From Stell"
      onClose={closeModal}
      icon={<Logout />}
    >
      <div className={styles.text}>
        Are you sure you want to remove this user from your organization?
      </div>
      <div className={styles.userinfo}>
        <div>
          <strong>{user.name}</strong>
        </div>
        <div>{user.email}</div>
      </div>
      <div className={styles.subtext}>
        You can also change their organization permissions to guest.
      </div>
      <div className={styles.ctaWrapper}>
        <Button
          className={styles.cta}
          text="Remove User"
          endIcon={<CloseOutline />}
          color={BUTTON_COLORS.PRIMARY}
          onClick={(e) => {
            e.preventDefault()
            deleteUser()
          }}
        />
      </div>
    </Modal>
  )
}

export default RemoveStellUserModal
