import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './ResetPasswordPage.module.css'
import * as usersApi from '../../api/v2/users.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'
import { toastError, toastSuccess } from '../../components/toast'
import { validatePassword } from '../../lib/password-validation.ts'

const ResetPasswordPage = () => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [tokenValid, setTokenValid] = useState(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const { search } = useLocation()
  const tokenParam = new URLSearchParams(search).get('token')
  const token = tokenParam && decodeURIComponent(tokenParam)
  const navigate = useNavigate()
  const [validPassword, setValidPassword] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [canSubmit, setCanSubmit] = useState<boolean>(false)

  useEffect(() => {
    const loadTokenValidity = async () => {
      if (!token) {
        toastError('Unable to reset password', 'Request a new reset link')
        navigate('/forgot-password?error=invalid-token')
        return
      }

      try {
        // 200-range ok response indicates token is valid
        await usersApi.isValidResetPasswordToken(token)
        setTokenValid(true)
      } catch (error) {
        // 404 response indicates token is invalid but we will handle all error
        // responses the same
        setTokenValid(false)
        toastError('Unable to reset password', 'Request a new reset link')
        navigate('/forgot-password?error=invalid-token')
        return
      }
    }

    loadTokenValidity()
  }, [navigate, token])

  useEffect(() => {
    if (!password) {
      setValidPassword(false)
      setErrorMessages(['New password must be present'])
      return
    }

    const validation = validatePassword(password)
    setValidPassword(validation.valid)
    setErrorMessages(validation.errorMessages || [])
  }, [password, confirmPassword])

  useEffect(() => {
    setCanSubmit(
      !!password && !!confirmPassword && validPassword && !submitting,
    )
  }, [password, confirmPassword, validPassword, submitting])

  const sendRequest = useCallback(async () => {
    if (!token || !password) {
      toastError('Unable to reset password', 'Request a new reset link')
      navigate('/forgot-password?error=invalid-token')
      return
    }

    setSubmitting(true)
    try {
      await usersApi.resetPasswordWithToken(token, password)
      toastSuccess('Password reset', 'Sign in to continue.')
      navigate('/login')
    } catch (error) {
      console.error('Unable to reset password', error)
      toastError('Unable to reset password', 'Request a new reset link')
      setTokenValid(false)
      navigate('/forgot-password?error=invalid-token')
    } finally {
      setSubmitting(false)
    }
  }, [token, password, navigate])

  return (
    <div className={styles.resetPasswordContent}>
      <div className={styles.header}>
        <img
          className={styles.stellWordLogo}
          src="/images/stell-word-logo-green.png"
          alt="Stell Logo"
        />
        <span className={styles.pageTitle}>Password reset</span>
      </div>
      {!tokenValid && (
        <div className={styles.loading}>
          <LoadingIndicator />
        </div>
      )}
      {tokenValid && (
        <div className={styles.formContent}>
          <div className={styles.copy}>
            Please set a new password that meets the following requirements:
            <ul>
              <li>Minimum 10 characters</li>
              <li>1 upper case character</li>
              <li>1 lower case character</li>
              <li>1 number</li>
              <li>1 special character</li>
            </ul>
          </div>
          <form className={styles.form}>
            <label className={styles.fieldPassword}>
              <span className={styles.label}>New password</span>
              <input
                className={styles.input}
                type="password"
                value={password}
                onChange={(e) => {
                  e.preventDefault()
                  setPassword(e.target.value)
                }}
              />
            </label>
            <div className={styles.errorMessages}>
              {password &&
                errorMessages.map((error: string, index: number) => (
                  <div key={index}>{error}</div>
                ))}
            </div>

            <label className={styles.fieldPassword}>
              <span className={styles.label}>Confirm new password</span>
              <input
                className={styles.input}
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  e.preventDefault()
                  setConfirmPassword(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && canSubmit) {
                    e.preventDefault()
                    sendRequest()
                  }
                }}
              />
            </label>
            <div className={styles.errorMessages}>
              {password && confirmPassword && password !== confirmPassword && (
                <div>Confirmed password must match new password.</div>
              )}
            </div>

            <Button
              className={styles.submitButton}
              onClick={(e) => {
                e.preventDefault()
                sendRequest()
              }}
              text={'Reset password'}
              color={BUTTON_COLORS.GREEN_WHITE_TEXT}
              disabled={!canSubmit}
              fontSize="16px"
            />
          </form>
        </div>
      )}
      <Link className={styles.linkToSignIn} to={'/login'}>
        Return to sign in
      </Link>
    </div>
  )
}

export default ResetPasswordPage
