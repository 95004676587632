import { Close, TrashCan } from '@carbon/icons-react'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { addNodeToTree, removeNodeFromTree } from './specificationTreeUtils.ts'
import styles from './TreeBuilderNode.module.css'
import TreeBuilderNodeDropdown from './TreeBuilderNodeDropdown.tsx'
import * as api from '../../../api/v2/programs.ts'
import {
  createSpecificationNode,
  deleteSpecificationNode,
  SpecificationNodeData,
  SpecificationTreeNode,
} from '../../../api/v2/programs.ts'
import Button from '../../../components/button'
import Dropdown from '../../../components/dropdown'
import IconButton from '../../../components/icon-button/IconButton.tsx'
import Tag, { TAG_COLORS_LEVELS } from '../../../components/tag'
import { toastError } from '../../../components/toast'
import useClickOutside from '../../../hooks/useClickOutside.ts'

interface TreeBuilderNodeProps {
  treeNode?: SpecificationTreeNode | null
  setTree: Dispatch<SetStateAction<api.SpecificationTreeNode | null>>
  specOptions: SpecificationNodeData[]
  onAddSpec: (
    createdNode: SpecificationTreeNode,
    parentNode?: SpecificationTreeNode | null,
  ) => void
  onDeleteSpec?: (nodeId: string) => void
  isStarterNode?: boolean
  programId: string
  depth: number
}

const TreeBuilderNode = ({
  depth,
  treeNode,
  ...nodeProps
}: TreeBuilderNodeProps) => {
  const {
    setTree,
    specOptions,
    onAddSpec,
    onDeleteSpec,
    isStarterNode = false,
    programId,
  } = nodeProps
  const nodeColor = TAG_COLORS_LEVELS[depth % 10]
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteRef = useClickOutside(() => {
    if (displayDeleteConfirmation) {
      setDisplayDeleteConfirmation(false)
    }
  })

  const deleteNode = useCallback(
    async (node: SpecificationTreeNode) => {
      try {
        if (!onDeleteSpec) {
          return
        }

        setIsDeleting(true)
        await deleteSpecificationNode(
          programId,
          node.id,
          node.specificationTreeId,
        )

        setTree((prevTree) => {
          if (!prevTree) {
            return prevTree
          }
          return removeNodeFromTree(prevTree, node.id)
        })

        onDeleteSpec(node.id)
      } catch (error) {
        console.error('Unable to delete node', error)
        toastError('Unable to delete node', 'Try again later')
      } finally {
        setDisplayDeleteConfirmation(false)
        setIsDeleting(false)
      }
    },
    [onDeleteSpec, programId, setTree],
  )

  const addNode = useCallback(
    async (
      selectedSpec: SpecificationNodeData,
      parentNode?: SpecificationTreeNode | null,
    ) => {
      try {
        const createdNode = await createSpecificationNode(
          programId,
          selectedSpec.id,
          parentNode?.id,
          parentNode?.specificationTreeId,
        )

        const newNode = {
          id: createdNode.id,
          specificationTreeId: createdNode.specificationTreeId,
          specification: {
            identifier: selectedSpec.identifier,
            id: selectedSpec.id,
            name: selectedSpec.name,
          },
          children: [],
        }

        setTree((prevNode) => {
          if (!prevNode) {
            return {
              id: 'placeholder',
              specificationTreeId: '',
              specification: {
                id: '',
                name: '',
                identifier: '',
              },
              children: [newNode],
            }
          }

          return addNodeToTree(prevNode, newNode, parentNode?.id)
        })

        onAddSpec(createdNode, parentNode)
      } catch (error) {
        console.error('Unable to add specification node', error)
        toastError('Unable to add specification node', 'Try again later')
      }
    },
    [onAddSpec, programId, setTree],
  )

  return (
    <div className={styles.nodeGroup}>
      <div className={`${styles.node} ${styles.basicElevation}`}>
        <div className={styles.row}>
          <div className={styles.rowGroup}>
            <Tag text={`Level ${depth}`} color={nodeColor} />
            {treeNode && (
              <span className={styles.identifier}>
                {treeNode?.specification.identifier || 'Document Number'}
              </span>
            )}
          </div>
          {treeNode && (
            <div className={styles.deleteContainer} ref={deleteRef}>
              <IconButton
                onClick={() =>
                  treeNode.children.length > 0
                    ? setDisplayDeleteConfirmation(true)
                    : deleteNode(treeNode)
                }
                className={styles.deleteButton}
              >
                <Close size={20} />
              </IconButton>
              <Dropdown
                className={styles.deleteConfirmation}
                isOpen={displayDeleteConfirmation}
              >
                <span className={styles.confirmationText}>
                  Are you sure you want to remove this specification node?
                  Removing will also remove its descendants.
                </span>
                <Button
                  text="Remove"
                  onClick={() => deleteNode(treeNode)}
                  frontIcon={<TrashCan />}
                  disabled={isDeleting}
                />
              </Dropdown>
            </div>
          )}
        </div>
        <div className={styles.row}>
          {isStarterNode ? (
            <span className={styles.starterName}>
              Add level 0 specification
            </span>
          ) : (
            <div className={styles.name}>
              {treeNode?.specification.name || 'Untitled'}
            </div>
          )}
          <TreeBuilderNodeDropdown
            specOptions={specOptions}
            onSelect={(option) => addNode(option, treeNode)}
            positionRelative={isStarterNode}
          />
        </div>
      </div>
      {!isStarterNode &&
        treeNode?.children.map((child) => (
          <TreeBuilderNode
            key={child.id}
            treeNode={child}
            depth={depth + 1}
            {...nodeProps}
          />
        ))}
    </div>
  )
}

export default TreeBuilderNode
