import { OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './SharedRequirementCommentsView.module.css'
import * as api from '../../api/v2/sharedSpecifications.ts'
import { SharedSpecificationSnapshotReview } from '../../api/v2/sharedSpecifications.ts'
import Avatar from '../../components/avatar'
import { AvatarSize } from '../../components/avatar/constants.ts'
import { toastError } from '../../components/toast'
import { useAuth } from '../../context/AuthContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { formatTime, monthLongDayYear } from '../../lib/date.ts'
import {
  LoadingState,
  SpecificationSnapshotReviewStatus,
} from '../../types/enums.ts'

const SharedRequirementCommentsView = (props: {
  comments: api.ReviewRequirementComment[]
  commentsLoadingState: LoadingState
  requirementId?: string
  review?: SharedSpecificationSnapshotReview
  onDelete?: (requirementId: string, commentId: string) => Promise<boolean>
}) => {
  const { comments, commentsLoadingState, requirementId, review, onDelete } =
    props
  const [actionsOpenCommentId, setActionsOpenCommentId] = useState('')
  const [disabledDeleteCommentId, setDisabledDeleteCommentId] = useState('')
  const ref = useClickOutside(() => setActionsOpenCommentId(''))

  const { userDetails } = useAuth()
  const userId = userDetails?.id

  return (
    <div className={styles.commentsView}>
      {commentsLoadingState === LoadingState.Failed && (
        <div className={styles.commentsFailed}>Unable to load comments</div>
      )}
      {commentsLoadingState === LoadingState.Loaded &&
        comments &&
        comments.length > 0 && (
          <div className={styles.commentsList}>
            {comments.map((comment) => (
              <div className={styles.commentCard} key={comment.id}>
                <div>
                  <Avatar
                    firstName={comment.createdBy.firstName}
                    lastName={comment.createdBy.lastName}
                    size={AvatarSize.Medium}
                  />
                </div>
                <div className={styles.commentBody}>{comment.body}</div>
                {userId === comment.createdByUserId &&
                  review?.status === SpecificationSnapshotReviewStatus.Draft &&
                  onDelete &&
                  requirementId && (
                    <div ref={ref} className={styles.actionMenu}>
                      <button
                        onClick={() => setActionsOpenCommentId(comment.id)}
                      >
                        <OverflowMenuVertical
                          size={20}
                          className={styles.icon}
                        />
                      </button>
                      <div
                        className={`${styles.dropdown} ${
                          styles.basicElevation
                        } ${
                          actionsOpenCommentId === comment.id ? styles.open : ''
                        }`}
                      >
                        <button
                          onClick={async () => {
                            setDisabledDeleteCommentId(comment.id)
                            const deleted = await onDelete(
                              requirementId,
                              comment.id,
                            )
                            setDisabledDeleteCommentId('')
                            if (!deleted) {
                              toastError('Unable to delete comment', '')
                            }
                          }}
                          disabled={disabledDeleteCommentId === comment.id}
                        >
                          <TrashCan size={16} />
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                <div className={styles.author}>
                  <span>
                    {comment.createdBy.firstName} {comment.createdBy.lastName}
                  </span>
                  <span>
                    {`${monthLongDayYear(comment.createdOn)} · ${formatTime(
                      comment.createdOn,
                    )}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  )
}

export default SharedRequirementCommentsView
