import api from './api.ts'

const createUrl = () => `/api/v2/tokens`

/**
 * TOKENS
 */

export interface LoginRequest {
  email?: string
  password?: string
}

export interface LoginResponse {
  /** @format guid */
  userId?: string
}

export interface VerifyMfaResponse {
  refreshToken?: string
  token?: string
}

export const login = async (
  request: LoginRequest,
  headers?: Record<string, string>,
): Promise<LoginResponse | VerifyMfaResponse> =>
  api.postUnauthenticated(createUrl(), { body: request, headers })

export interface RefreshTokenResponse {
  accessToken: string
  refreshToken: string
}

export const refreshToken = async (
  refreshToken: string,
): Promise<RefreshTokenResponse> =>
  await api.post(createUrl() + '/refresh', {
    body: {
      refreshToken,
    },
    skipRefresh: true,
  })

export interface VerifyMfaRequest {
  /** @format guid */
  userId?: string
  mfaCode?: string
}

export const verifyMfa = async (
  request: VerifyMfaRequest,
): Promise<VerifyMfaResponse> => {
  return api.postUnauthenticated(createUrl() + '/verify-mfa', {
    body: request,
  })
}

export interface ResendMfaRequest {
  /** @format guid */
  userId?: string
}

export const resendMfaCode = async (request: ResendMfaRequest): Promise<void> =>
  api.postUnauthenticated(createUrl() + '/resend-mfa', { body: request })
