import { Report, RuleTest } from '@carbon/icons-react'
import { useState } from 'react'
import Modal from './index.tsx'
import styles from './ShareReviewModal.module.css'
import * as api from '../../api/v2/sharedSpecifications.ts'
import { SharedSpecificationSnapshotReview } from '../../api/v2/sharedSpecifications.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { monthLongDayYear } from '../../lib/date.ts'
import { SpecificationSnapshotReviewStatus } from '../../types/enums.ts'
import Button, { BUTTON_COLORS } from '../button'
import { toastError, toastSuccess } from '../toast'

export interface ShareReviewModalProps {
  review: SharedSpecificationSnapshotReview
  specificationId: string
  specificationSnapshotId: string
  updateReviewStatus: (status: SpecificationSnapshotReviewStatus) => void
}

const ShareReviewModal = (
  props: ShareReviewModalProps & { closeModal: () => void },
) => {
  const {
    review,
    specificationId,
    specificationSnapshotId,
    updateReviewStatus,
    closeModal,
  } = props
  const ref = useClickOutside(() => closeModal())
  const [name, setName] = useState(
    `${review.specificationName} Review ${monthLongDayYear(new Date())}`,
  )
  const [submittingReview, setSubmittingReview] = useState(false)

  const submitReview = async () => {
    try {
      setSubmittingReview(true)
      const status = SpecificationSnapshotReviewStatus.Submitted
      await api.updateSharedSpecificationSnapshotReview(
        specificationId,
        specificationSnapshotId,
        review.id,
        {
          status,
          name,
        },
      )
      updateReviewStatus(status)
      toastSuccess(
        `Your review was shared with ${review.viewerTenantName}.`,
        'View in your shared space',
        `/workspaces/${review.workspaceId}/projects/${review.project.id}`,
      )
      closeModal()
    } catch (error) {
      console.error(
        'Unable to update specification snapshot review status and name',
        error,
      )
      toastError('Failed to share review', '')
    } finally {
      setSubmittingReview(false)
    }
  }

  return (
    <Modal
      title="Share review"
      icon={<Report />}
      onClose={closeModal}
      innerRef={ref}
    >
      <div className={styles.content}>
        <input
          className={styles.input}
          placeholder="Review title"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <Button
          className={styles.completeButton}
          text="Complete"
          endIcon={<RuleTest />}
          color={BUTTON_COLORS.PRIMARY}
          onClick={() => {
            submitReview()
          }}
          disabled={submittingReview}
        />
      </div>
    </Modal>
  )
}

export default ShareReviewModal
