import { useCallback, useState } from 'react'
import styles from './SpecificationPermissionsUserRow.module.css'
import * as api from '../../api/v2/specifications.ts'
import Avatar from '../../components/avatar'
import { AvatarSize } from '../../components/avatar/constants.ts'
import DropdownSelection, {
  OptionType,
} from '../../components/dropdown/DropdownSelection.tsx'
import { toastError, toastSuccess } from '../../components/toast'
import { EntityRole, UserRole } from '../../types/enums.ts'

export type SpecificationPermissionsUser = {
  userId: string
  firstName: string
  lastName: string
  fullName: string
  initials: string
  email: string
  specRole?: EntityRole
  orgRole?: UserRole
}

const VIEWER_OPTION: OptionType = { id: 'VIEWER', label: 'Viewer' }

const SPEC_ROLE_OPTIONS: OptionType[] = [
  { id: EntityRole.OWNER, label: 'Owner' },
  { id: EntityRole.EDITOR, label: 'Editor' },
  { id: EntityRole.COMMENTER, label: 'Commenter' },
  VIEWER_OPTION,
]

const ADMIN_OPTION: OptionType = { id: UserRole.ADMIN, label: 'Admin' }

const SpecificationPermissionsUserRow = (props: {
  specificationId: string
  user: SpecificationPermissionsUser
  onSpecRoleChange: (specRole?: EntityRole) => void
  readOnly: boolean
}) => {
  const { specificationId, user, onSpecRoleChange, readOnly } = props
  const [submitting, setSubmitting] = useState<boolean>(false)
  const adminUser =
    user.orgRole && [UserRole.ROOT, UserRole.ADMIN].includes(user.orgRole)
  const initialSelection = adminUser
    ? ADMIN_OPTION
    : SPEC_ROLE_OPTIONS.find(
        (option) => option.id === (user.specRole || VIEWER_OPTION.id),
      )

  const updateUserRole = useCallback(
    async (selected: OptionType) => {
      if (selected.id === ADMIN_OPTION.id) {
        console.warn('User cannot be set to admin from spec permissions.')
        return
      }

      setSubmitting(true)
      try {
        if (selected.id === VIEWER_OPTION.id) {
          await api.deleteUserRoleForSpecification(specificationId, user.userId)
        } else {
          await api.createUserRoleForSpecification(
            specificationId,
            user.userId,
            selected.id as EntityRole,
          )
        }
        onSpecRoleChange(
          selected.id === VIEWER_OPTION.id
            ? undefined
            : (selected.id as EntityRole),
        )
        toastSuccess(`${user.fullName}'s role has been updated.`)
      } catch (error) {
        toastError(`${user.fullName}'s role could not be updated.`, '')
        console.error('Could not set specification role for user.', error)
      } finally {
        setSubmitting(false)
      }
    },
    [specificationId, user.userId, onSpecRoleChange, user.fullName],
  )

  return (
    <tr className={styles.userRow}>
      <td className={styles.avatar}>
        <Avatar fullName={user.fullName} size={AvatarSize.Medium} />
      </td>
      <td className={styles.name}>{user.fullName}</td>
      <td className={styles.email}>{user.email}</td>
      <td className={styles.role}>
        <DropdownSelection
          initialSelection={initialSelection}
          options={SPEC_ROLE_OPTIONS.map((option) => ({
            ...option,
            disabled: initialSelection && option.id === initialSelection.id,
          }))}
          onSelect={updateUserRole}
          disabled={readOnly || adminUser || submitting}
        />
      </td>
    </tr>
  )
}

export default SpecificationPermissionsUserRow
