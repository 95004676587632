import api from './api'
import { ChangeRequestStatus } from '../../types/enums'

const createUrl = (
  specificationId?: string,
  requirementId?: string,
  changeRequestId?: string,
) =>
  `/api/v2/specifications${
    specificationId ? `/${specificationId}` : ''
  }/requirements${requirementId ? `/${requirementId}` : ''}/change_requests${
    changeRequestId ? `/${changeRequestId}` : ''
  }`

interface ChangeRequestResponse {
  /** @format guid */
  id: string
  /** @format guid */
  requirementId: string
  displayId: string
  current: string
  proposed: string
  memo: string
  status: ChangeRequestStatus
  /** @format guid */
  createdBy: string
  createdOn: string
  /** @format guid */
  lastModifiedBy: string
  lastModifiedOn: string
  /** @format guid */
  specificationId: string
  specificationName: string
  requirementTitle: string
  createdByName: string
}

export interface ChangeRequest
  extends Omit<ChangeRequestResponse, 'createdOn' | 'lastModifiedOn'> {
  createdOn: Date
  lastModifiedOn: Date
}

const withDates: (changeReq: ChangeRequestResponse) => ChangeRequest = (
  changeReq,
) => ({
  ...changeReq,
  createdOn: new Date(changeReq.createdOn),
  lastModifiedOn: new Date(changeReq.lastModifiedOn),
})

export const getAllChangeRequests: () => Promise<
  ChangeRequest[]
> = async () => {
  const res = await api.get(createUrl())
  return res.changeRequests.map(withDates)
}

export const getRequirementChangeRequests: (
  specificationId: string,
  requirementId: string,
) => Promise<ChangeRequest[]> = async (specificationId, requirementId) => {
  const res = await api.get(createUrl(specificationId, requirementId))
  return res.changeRequests.map(withDates)
}

export const updateChangeRequest: (
  specificationId: string,
  requirementId: string,
  changeRequestId: string,
  update: Partial<ChangeRequest>,
) => Promise<ChangeRequest> = async (
  specificationId,
  requirementId,
  changeRequestId,
  update,
) => {
  const res = await api.patch(
    createUrl(specificationId, requirementId, changeRequestId),
    { body: update },
  )
  return withDates(res)
}

export const createChangeRequest: (
  specificationId: string,
  requirementId: string,
  changeRequest: {
    current: string
    proposed: string
    memo: string
  },
) => Promise<ChangeRequest> = async (
  specificationId,
  requirementId,
  changeRequest,
) => {
  const res = await api.post(createUrl(specificationId, requirementId), {
    body: changeRequest,
  })
  return withDates(res)
}

export const deleteChangeRequest: (
  specificationId: string,
  requirementId: string,
  changeRequestId: string,
) => Promise<{ id: string }> = (
  specificationId,
  requirementId,
  changeRequestId,
) => api.delete(createUrl(specificationId, requirementId, changeRequestId))
