import { Link as LinkIcon, Unlink } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '.'
import styles from './LinkedRequirementViewModal.module.css'
import { Requirement, getRequirement } from '../../api/v2/requirements'
import { Specification, getSpecification } from '../../api/v2/specifications'
import useClickOutside from '../../hooks/useClickOutside'
import { useModals } from '../../hooks/useModals.ts'
import { extractShallStatementDelta } from '../../lib/requirement.ts'
import { truncateString } from '../../lib/string'
import { LinkedRequirementType } from '../../types/enums'
import Button, { BUTTON_COLORS } from '../button'
import { DRAWER_TAB } from '../drawer/enums.ts'
import QuillContent from '../quill-content/QuillContent.tsx'
import { toastError } from '../toast'

export interface LinkedRequirementViewModalProps {
  linkType?: LinkedRequirementType
  linkedRequirementData: {
    specificationId: string
    requirementId: string
  }
  userCanEdit: boolean
  onUnlink: () => void
}

const LinkedRequirementViewModal = (
  props: LinkedRequirementViewModalProps & { closeModal: () => void },
) => {
  const {
    linkType,
    linkedRequirementData: { specificationId, requirementId },
    userCanEdit,
    onUnlink,
    closeModal,
  } = props

  const modalRef = useClickOutside(() => closeModal())
  const [specification, setSpecification] = useState<Specification>()
  const [requirement, setRequirement] = useState<Requirement>()
  const [loading, setLoading] = useState(true)
  const { openRequirementDrawer } = useModals()

  const unlink = async () => {
    try {
      onUnlink()
      closeModal()
    } catch (err) {
      toastError('Unable to unlink requirement', '')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const spec = await getSpecification(specificationId)
        const req = await getRequirement(specificationId, requirementId)
        setSpecification(spec)
        setRequirement(req)
        setLoading(false)
      } catch (err) {
        toastError('Unable to load linked requirement', 'Refresh and try again')
        closeModal()
      }
    }

    fetchData()
  }, [specificationId, requirementId, closeModal])

  if (loading) {
    return null // Don't render the modal until data is loaded
  }

  return (
    <Modal
      innerRef={modalRef}
      title={`${truncateString(requirement?.title || 'No Title', 25)} · ${
        specification?.specificationIdentifier || 'Document Number'
      }-${requirement?.context.requirementIdentifier || ''}`}
      icon={<LinkIcon />}
      onClose={closeModal}
    >
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            Linked {linkType} requirement
          </div>
          <div className={styles.shallStatement}>
            <QuillContent
              readOnly
              delta={extractShallStatementDelta(requirement)}
            />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>Specification</div>
          <Link
            className={styles.link}
            to={`/specifications/${specificationId}/matrix/${requirementId}`}
            onClick={closeModal}
            target="_blank"
          >
            {specification?.name || 'Untitled'}
          </Link>
        </div>
        <div className={styles.actions}>
          {requirement && (
            <button
              className={styles.graphLink}
              onClick={() =>
                openRequirementDrawer({
                  initialTab: DRAWER_TAB.GRAPH,
                  specificationId,
                  requirement,
                })
              }
            >
              View in graph
            </button>
          )}
          {userCanEdit && (
            <Button
              className={styles.removeLinkButton}
              color={BUTTON_COLORS.PRIMARY}
              onClick={unlink}
              text="Remove Link"
              endIcon={<Unlink size={16} />}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default LinkedRequirementViewModal
