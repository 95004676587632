import { ReactNode, useRef } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Selecto from 'react-selecto'
import styles from './PrimaryLayout.module.css'
import { useAuth } from '../../context/AuthContext.tsx'
import { useBreadcrumbsContext } from '../../context/BreadcrumbsContext.tsx'
import { useDragDropContext } from '../../context/DragAndDropContext.tsx'
import { useDragSelectContext } from '../../context/DragSelectContext.tsx'
import { LoadingState } from '../../types/enums.ts'
import Avatar from '../avatar'
import { AvatarSize } from '../avatar/constants.ts'
import {
  BLOCK_IS_SELECTED_CLASSNAME,
  SELECTABLE_BLOCK_CLASSNAME,
} from '../block-editor/SelectableBlock.tsx'
import LoadingIndicator from '../loading-indicator/LoadingIndicator.tsx'
import NavDrawer from '../nav-drawer/NavDrawer'
import NotificationIcon from '../notifications/NotificationDropdown.tsx'

type BreadcrumbItem = {
  linkTo: string
  text: string
}

type PrimaryLayoutProps = {
  disableContentScroll?: boolean
  requireSignIn?: boolean
  breadcrumbs?: Array<BreadcrumbItem>
  children?: ReactNode
}

const PrimaryLayout = (props: PrimaryLayoutProps) => {
  const { disableContentScroll } = props
  const { userDetails } = useAuth()
  const { breadcrumbs } = useBreadcrumbsContext()
  const { activeDnDId } = useDragDropContext()
  const { isDragSelectEnabled } = useDragSelectContext()
  const scrollerRef = useRef<HTMLDivElement>(null)
  const selectoRef = useRef<Selecto>(null)
  const { userTenantLoading } = useAuth()

  return (
    <div className={styles.root}>
      {userTenantLoading === LoadingState.Loading ? (
        <div className={styles.loading}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {!activeDnDId && isDragSelectEnabled && (
            <Selecto
              className={styles.selecto}
              ref={selectoRef}
              dragContainer={window}
              selectableTargets={[`.${SELECTABLE_BLOCK_CLASSNAME}`]}
              onDragStart={(e) => {
                if (
                  e.inputEvent.target.nodeName === 'BUTTON' ||
                  e.inputEvent.target.nodeName === 'svg' ||
                  e.inputEvent.target.nodeName === 'path'
                ) {
                  return false
                }
                return true
              }}
              onSelect={(e) => {
                e.added.forEach((el) => {
                  el.classList.add(BLOCK_IS_SELECTED_CLASSNAME)
                })
                e.removed.forEach((el) => {
                  el.classList.remove(BLOCK_IS_SELECTED_CLASSNAME)
                })
              }}
              onScroll={({ direction }) => {
                scrollerRef.current!.scrollBy(
                  direction[0] * 10,
                  direction[1] * 10,
                )
              }}
              onInnerScroll={({ container, direction }) => {
                container.scrollBy(direction[0] * 10, direction[1] * 10)
              }}
              scrollOptions={{
                container: scrollerRef,
                getScrollPosition: () => {
                  return [
                    scrollerRef.current!.scrollLeft,
                    scrollerRef.current!.scrollTop,
                  ]
                },
                throttleTime: 30,
                threshold: 0,
              }}
              innerScrollOptions={true}
              hitRate={0}
              selectByClick={false}
              selectFromInside={false}
              toggleContinueSelect={['shift']}
              ratio={0}
            />
          )}
          <NavDrawer />
          <div className={styles.page}>
            <div className={styles.header}>
              <div className={styles.breadcrumb}>
                {breadcrumbs.map((crumb) => (
                  <span key={crumb.text} className={styles.crumbLink}>
                    <Link to={crumb.linkTo}>{crumb.text}</Link>
                  </span>
                ))}
              </div>
              <div className={styles.rightAlign}>
                <NotificationIcon />
                <Avatar
                  size={AvatarSize.MediumLarge}
                  firstName={userDetails?.firstName}
                  lastName={userDetails?.lastName}
                  dropdown
                />
              </div>
            </div>
            <div
              className={`${styles.content} ${
                disableContentScroll ? styles.noScroll : ''
              }`}
              ref={scrollerRef}
            >
              <Outlet context={{ scrollerRef }} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default PrimaryLayout
