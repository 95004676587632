import api from './api'

const createUrl = (notificationId?: string) =>
  `/api/v2/notifications${notificationId ? `/${notificationId}` : ''}`

const withDates: (
  spec: NotificationResponse<NotificationData>,
) => Notification<NotificationData> = (notification) => ({
  ...notification,
  createdOn: new Date(notification.createdOn),
})

interface NotificationResponse<D extends NotificationData> {
  /** @format guid */
  id: string
  type: NotificationType
  read: boolean
  createdOn: string
  data: D
  /** @format guid */
  toUserId: string
  toTenantId: string
  /** @format guid */
  fromUserId: string
  fromTenantId: string
}

export interface Notification<D extends NotificationData>
  extends Omit<NotificationResponse<D>, 'createdOn'> {
  data: D
  createdOn: Date
}

export enum NotificationType {
  CHANGE_REQUEST_ADDED = 'CHANGE_REQUEST_ADDED',
  COMMENT_ADDED = 'COMMENT_ADDED',
  EVIDENCE_RECORD_LINKED = 'EVIDENCE_RECORD_LINKED',
  PARENT_CHILD_LINK_ADDED = 'PARENT_CHILD_LINK_ADDED',
  REQUIREMENT_COMPLIANCE_CHANGED = 'REQUIREMENT_COMPLIANCE_CHANGED',
  REQUIREMENT_TEXT_CHANGED = 'REQUIREMENT_TEXT_CHANGED',
  REVIEW_REQUESTED = 'REVIEW_REQUESTED',
  SPECIFICATION_IMPORTED = 'SPECIFICATION_IMPORTED',
  SPECIFICATION_SNAPSHOT_REVIEW_SUBMITTED = 'SPECIFICATION_SNAPSHOT_REVIEW_SUBMITTED',
  SPECIFICATION_SNAPSHOT_SHARED = 'SPECIFICATION_SNAPSHOT_SHARED',
}

export interface ChangeRequestAddedData {
  userFullName: string
  requirementId: string
  requirementTitle: string
  specificationId: string
}

export interface CommentAddedData {
  userFullName: string
  requirementId: string
  requirementTitle: string
  specificationId: string
}

export interface EvidenceRecordLinkedData {
  userFullName: string
  specificationId: string
  specificationName: string
  requirementId: string
  requirementTitle: string
}

export interface ParentChildLinkAddedData {
  userFullName: string
  specificationId: string
  specificationName: string
  requirementId: string
  requirementTitle: string
}

export interface RequirementComplianceChangedData {
  specificationId: string
  specificationName: string
  requirementId: string
  requirementTitle: string
}

export interface RequirementTextChangedData {
  userFullName: string
  specificationId: string
  specificationName: string
  requirementId: string
  requirementTitle: string
}

export interface ReviewRequestedData {
  userFullName: string
  specificationName: string
  specificationId: string
}

export interface SpecificationImportedData {
  specificationId: string
}

export interface SpecificationSnapshotReviewSubmittedData {
  tenantName: string
  specificationName: string
  specificationId: string
  workspaceId: string
  projectId: string
}

export interface SpecificationSnapshotSharedData {
  tenantName: string
  snapshotId: string
  specificationId: string
  specificationName: string
  projectId: string
  workspaceId: string
  version: string
}

export type NotificationData =
  | ChangeRequestAddedData
  | CommentAddedData
  | EvidenceRecordLinkedData
  | ParentChildLinkAddedData
  | RequirementTextChangedData
  | RequirementComplianceChangedData
  | ReviewRequestedData
  | SpecificationImportedData
  | SpecificationSnapshotReviewSubmittedData
  | SpecificationSnapshotSharedData

export const getAllNotifications: (
  limit,
) => Promise<Notification<NotificationData>[]> = async (limit) => {
  const searchParams = new URLSearchParams()
  if (limit) {
    searchParams.append('limit', `${limit}`)
  }

  const res = await api.get(`${createUrl()}?${searchParams.toString()}`)
  return res.notifications
    .map(withDates)
    .sort(
      (a, b) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    )
}

export const updateNotification: (
  notificationId: string,
  read: boolean,
) => Promise<{ id: string; read: boolean }> = async (notificationId, read) => {
  const res = await api.patch(createUrl(notificationId), {
    body: { read: read },
  })
  return withDates(res)
}
