import { Link as LinkIcon } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import styles from './AddLinkedRequirementModal.module.css'
import Modal from './index.tsx'
import { ExtendedRequirementExceptionLink } from '../../api/v2/exceptions.ts'
import {
  Requirement,
  getRequirement,
  getRequirements,
} from '../../api/v2/requirements.ts'
import { getRevisions } from '../../api/v2/revisions.ts'
import {
  getAllSpecifications,
  getRequirementsToLink,
  GetRequirementToLinkResponse,
} from '../../api/v2/specifications.ts'
import Button, { BUTTON_COLORS } from '../button/index.tsx'
import InputDropdownSelection, {
  OptionType,
} from '../dropdown/InputDropdown.tsx'

export interface AddLinkedRequirementExceptionProps {
  existingLinks: ExtendedRequirementExceptionLink[]
  addLinkedRequirement: (linkToAdd: ExtendedRequirementExceptionLink) => void
}

const AddLinkedRequirementExceptionModal = (
  props: AddLinkedRequirementExceptionProps & { closeModal: () => void },
) => {
  const { existingLinks, addLinkedRequirement, closeModal } = props

  const [selectedSpecOption, setSelectedSpecOption] = useState<OptionType>()
  const [selectedReqOption, setSelectedReqOption] = useState<OptionType>()

  const [specOptions, setSpecOptions] = useState<OptionType[]>([])
  const [reqOptions, setReqOptions] = useState<OptionType[]>([])
  const [filteredReqOptions, setFilteredReqOptions] = useState<OptionType[]>([])

  useEffect(() => {
    const fetchSpecsAndRequirements = async () => {
      const allSpecs = await getAllSpecifications()
      const fetchedSpecOptions = allSpecs.map((spec) => ({
        id: spec.id,
        label: spec.name,
        sublabel: spec.specificationIdentifier,
        data: {
          specificationId: spec.id,
          specificationName: spec.name,
        },
      }))
      const specMap = Object.fromEntries(
        fetchedSpecOptions.map((spec) => [spec.id, spec.sublabel]),
      )
      setSpecOptions(fetchedSpecOptions)

      const requirements: GetRequirementToLinkResponse[] =
        await getRequirementsToLink({ limit: 5000 })

      const fetchedReqOptions = requirements.map((req) => ({
        id: req.id,
        label: req.title || '(No Title)',
        sublabel: `${specMap[req.specificationId] || 'Document Number'}-${
          req.requirementIdentifier
        }`,
        data: {
          documentId: req.documentId,
          specificationId: req.specificationId,
          specificationTitle: specMap[req.specificationId],
        },
      }))

      setReqOptions(fetchedReqOptions)
      setFilteredReqOptions(fetchedReqOptions)
    }

    fetchSpecsAndRequirements()
  }, [])

  useEffect(() => {
    const filterRequirements = async () => {
      if (!selectedSpecOption) {
        const activeSpecIds = new Set(specOptions.map((spec) => spec.id))

        setFilteredReqOptions(
          reqOptions.filter(
            (req) =>
              !existingLinks.some((link) => req.id === link.requirementId) &&
              activeSpecIds.has(req.data.specificationId),
          ),
        )
        return
      }

      const [latestRevision] = await getRevisions(selectedSpecOption.id)

      const reqs: Requirement[] = await getRequirements(selectedSpecOption.id, {
        revisionIds: [latestRevision.id],
      })

      setFilteredReqOptions(
        reqs
          .map((req) => ({
            id: req.id,
            label: req.title || '(No Title)',
            sublabel: `${selectedSpecOption.sublabel || 'Document Number'}-${
              req.context.requirementIdentifier
            }`,
            data: {
              documentId: latestRevision.documentId,
              specificationId: selectedSpecOption.id,
              specificationTitle: selectedSpecOption.label,
            },
          }))
          .filter((req) => req.data.specificationId === selectedSpecOption.id)
          .filter(
            (req) =>
              !existingLinks.some((link) => req.id === link.requirementId),
          ),
      )
    }
    filterRequirements()
  }, [selectedSpecOption, reqOptions, existingLinks, specOptions])

  return (
    <Modal title="Add Requirement" icon={<LinkIcon />} onClose={closeModal}>
      <div className={styles.content}>
        <div className={styles.subheader}></div>
        <InputDropdownSelection
          placeholder="Search by specification name"
          selected={selectedSpecOption}
          options={specOptions}
          onSelect={(option) => {
            if (!option) {
              setSelectedSpecOption(undefined)
            } else {
              setSelectedSpecOption(option)
            }
          }}
          onQueryOptions={(query, options) => {
            if (!query) {
              return options
            }

            return options.filter(
              (opt) =>
                opt.label &&
                opt.label.toLowerCase().includes(query.toLowerCase()),
            )
          }}
        />
        <div className={styles.info}>
          Most recent results listed. Type to filter or select a specification.
        </div>
        <div>
          <InputDropdownSelection
            placeholder="Search by requirement name or ID"
            selected={selectedReqOption}
            options={filteredReqOptions}
            onSelect={(option) => {
              setSelectedReqOption(option)
            }}
            onQueryOptions={(query, options) => {
              if (!query) {
                return options
              }

              const queryLabelMatch = (opt, query) =>
                opt?.label &&
                opt.label.toLowerCase().includes(query.toLowerCase())

              const querySublabelMatch = (opt, query) =>
                opt?.sublabel &&
                opt.sublabel.toLowerCase().includes(query.toLowerCase())

              const exactMatchSortToFront = (a: OptionType) =>
                a.sublabel.toLowerCase() === query.toLowerCase() ? -1 : 0

              const matchingOptions = options
                .filter(
                  (opt) =>
                    queryLabelMatch(opt, query) ||
                    querySublabelMatch(opt, query),
                )
                .sort(exactMatchSortToFront)

              return matchingOptions
            }}
          />
        </div>
        <Button
          className={styles.addLink}
          disabled={!selectedReqOption}
          color={BUTTON_COLORS.PRIMARY}
          text="Create Link"
          onClick={async () => {
            try {
              const requirementDetails = await getRequirement(
                selectedReqOption!.data.specificationId,
                selectedReqOption!.id,
              )

              const linkToAdd: ExtendedRequirementExceptionLink = {
                linkId: 0, // Populated when the link is created
                exceptionId: '', // Populated when the exception is created
                specificationId: selectedReqOption!.data.specificationId,
                documentId: selectedReqOption!.data.documentId,
                requirementId: selectedReqOption!.id,
                title: selectedReqOption!.label,
                specificationTitle: selectedReqOption!.data.specificationTitle,
                text: requirementDetails.shallStatement,
              }
              addLinkedRequirement(linkToAdd)
              closeModal()
            } catch (error) {
              console.error('Failed to fetch requirement details:', error)
            }
          }}
          endIcon={<LinkIcon size={16} />}
        />
      </div>
    </Modal>
  )
}

export default AddLinkedRequirementExceptionModal
