import { Add } from '@carbon/icons-react'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styles from './EvidenceActivities.module.css'
import EvidenceActivityForm from './EvidenceActivityForm.tsx'
import * as api from '../../api/v2/evidence.ts'
import { EvidenceActivity } from '../../api/v2/evidence.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import { toastError } from '../../components/toast'
import Tooltip from '../../components/tooltip/Tooltip.tsx'

interface EvidenceActivitiesProps {
  evidenceId?: string
}

const EvidenceActivities = (props: EvidenceActivitiesProps) => {
  const { evidenceId } = props
  const { activityId } = useParams()
  const [urlParams] = useSearchParams()
  const [activities, setActivities] = useState<
    (EvidenceActivity | undefined)[]
  >([])
  const isNavCreate = urlParams.get('createActivity') === 'true'

  const isAddingDisabled =
    activities.length > 0 && activities[activities.length - 1] === undefined

  useEffect(() => {
    const loadActivities = async () => {
      if (!evidenceId) {
        return
      }

      try {
        if (isNavCreate) {
          setActivities((prevActivities) => [...prevActivities, undefined])
        } else {
          const activities = await api.getEvidenceActivities(evidenceId)
          setActivities([
            ...activities.sort(
              (a, b) => a.createdOn.getTime() - b.createdOn.getTime(),
            ),
          ])
        }
      } catch (error) {
        console.error('Unable to load activities', error)
        toastError('Unable to load activities', '')
      }
    }
    loadActivities()
  }, [evidenceId, isNavCreate])

  const addCreateActivityForm = useCallback(() => {
    if (!isAddingDisabled) {
      setActivities((prevActivities) => [...prevActivities, undefined])
    }
  }, [isAddingDisabled])

  return (
    <div className={styles.evidenceActivities}>
      <div className={styles.header}>
        <h1>Activities</h1>
      </div>
      <div className={styles.activitiesContainer}>
        {evidenceId &&
          activities.length > 0 &&
          activities.map((activity: EvidenceActivity | undefined, i) => (
            <div key={activity?.id || i}>
              <EvidenceActivityForm
                evidenceId={evidenceId}
                activity={activity}
                setActivities={setActivities}
                autoScroll={activityId === activity?.id}
              />
            </div>
          ))}
        <div>
          <Tooltip
            content={
              !evidenceId
                ? 'Evidence record must be created'
                : isAddingDisabled
                  ? 'Attach activity before adding a new one'
                  : undefined
            }
            placement="right"
          >
            <Button
              text={'Add activity'}
              onClick={addCreateActivityForm}
              endIcon={<Add size={16} />}
              color={BUTTON_COLORS.SECONDARY}
              disabled={!evidenceId || isAddingDisabled}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default EvidenceActivities
