import {
  CheckmarkFilled,
  Link,
  ListChecked,
  TextWrap,
  Unlink,
} from '@carbon/icons-react'
import { ReactNode, useEffect, useState } from 'react'
import styles from './RequirementHistoryEvents.module.css'
import {
  getRequirementHistoryWithUsers,
  HistoryEventType,
  RequirementHistoryEvent,
} from '../../api/v2/specifications.ts'
import { formatTime, monthLongDayYear } from '../../lib/date.ts'
import { LoadingState, RequirementStatus } from '../../types/enums.ts'
import LoadingIndicator from '../loading-indicator/LoadingIndicator.tsx'
import RequirementStatusTag from '../tag/RequirementStatusTag.tsx'

interface HistoryEventRowProps {
  createdOn: Date
  showBorder?: boolean
  children: ReactNode
}

const HistoryEventRow = (props: HistoryEventRowProps) => {
  const { createdOn, showBorder, children } = props
  return (
    <div
      className={`${styles.updatedEvent} ${showBorder ? styles.border : ''}`}
    >
      <div className={styles.eventMetadata}>
        <span className={styles.eventDate}>{monthLongDayYear(createdOn)}</span>
        <span className={styles.eventTime}>{formatTime(createdOn)}</span>
      </div>
      <div className={styles.eventChange}>{children}</div>
    </div>
  )
}

const EVENTS = {
  [HistoryEventType.UNKNOWN]: () => null,
  [HistoryEventType.CREATED]: ({ event }) =>
    event.data.previousRequirementId === null ? (
      <div className={styles.requirementCreatedEvent}>
        <HistoryEventRow createdOn={event.createdOn} showBorder>
          <span className={styles.tag}>
            <RequirementStatusTag status={RequirementStatus.Draft} />
          </span>
          <span className={styles.eventDetails}>
            <span className={styles.eventText}>Requirement created</span>
            <span className={styles.eventUserName}>
              {event.createdBy.firstName + ' ' + event.createdBy.lastName}
            </span>
          </span>
        </HistoryEventRow>
        {event.data.initialText && (
          <div className={styles.paper}>
            <div className={styles.diffBlock}>
              <span className={styles.newTextLabel}>Initial Text</span>
              <span className={styles.newTextValue}>
                {event.data.initialText}
              </span>
            </div>
          </div>
        )}
      </div>
    ) : null,
  [HistoryEventType.STATUS_UPDATED]: ({ event }) => {
    const { previousStatus, status } = event.data
    return status === RequirementStatus.Draft &&
      previousStatus === RequirementStatus.Review ? null : (
      <HistoryEventRow createdOn={event.createdOn} showBorder>
        <span className={styles.tag}>
          <RequirementStatusTag status={status} />
        </span>
        <span className={styles.eventDetails}>
          <span className={styles.eventText}>
            {status === RequirementStatus.Active
              ? 'Requirement released'
              : 'Requirement status changed'}
          </span>
          <span className={styles.eventUserName}>
            {event.createdBy.firstName + ' ' + event.createdBy.lastName}
          </span>
        </span>
      </HistoryEventRow>
    )
  },
  [HistoryEventType.TEXT_UPDATED]: ({ event }) => {
    return (
      event.data.previousValue && (
        <div className={styles.updatedTextEvent}>
          <HistoryEventRow createdOn={event.createdOn}>
            <span className={styles.tag}>
              <TextWrap size={24} />
            </span>
            <span className={styles.eventDetails}>
              <span className={styles.eventText}>Requirement text changed</span>
              <span className={styles.eventUserName}>
                {event.createdBy.firstName + ' ' + event.createdBy.lastName}
              </span>
            </span>
          </HistoryEventRow>
          <div className={styles.paper}>
            <div className={styles.diffBlock}>
              <span className={styles.newTextLabel}>New Text</span>
              <span className={styles.newTextValue}>
                {event.data.updatedValue}
              </span>
            </div>
            <div className={styles.diffBlock}>
              <span className={styles.originalTextLabel}>Original Text</span>
              <span className={styles.originalTextValue}>
                {event.data.previousValue}
              </span>
            </div>
          </div>
        </div>
      )
    )
  },
  [HistoryEventType.SPEC_VERSION_CREATED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <span className={styles.version}>V{event.data.specificationVersion}</span>
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Specification version created</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.SPEC_VERSION_RELEASED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <span className={styles.version}>V{event.data.specificationVersion}</span>
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Specification released</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.SPEC_VERSION_APPROVED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <CheckmarkFilled size={24} className={styles.filledCheck} />
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Specification approved</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.SPEC_REVISION_RECALLED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <span className={styles.tag}>
        <RequirementStatusTag status={RequirementStatus.Draft} />
      </span>
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>
          Specification recalled to draft
        </span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.PARENT_LINK_ADDED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <Link size={24} />
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Parent link added</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.PARENT_LINK_REMOVED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <Unlink size={24} />
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Parent link removed</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.CHILD_LINK_ADDED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <Link size={24} />
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Child link added</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.CHILD_LINK_REMOVED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <Unlink size={24} />
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Child link removed</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.EVIDENCE_ADDED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <ListChecked size={24} />
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Evidence added</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
  [HistoryEventType.EVIDENCE_REMOVED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <ListChecked size={24} />
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>Evidence removed</span>
        <span className={styles.eventUserName}>
          {event.createdBy.firstName + ' ' + event.createdBy.lastName}
        </span>
      </span>
    </HistoryEventRow>
  ),
}

const RequirementHistoryEvents = (props: {
  requirementId: string
  specificationId: string
}) => {
  const { requirementId, specificationId } = props
  const [loading, setLoading] = useState(LoadingState.Loading)
  const [history, setHistory] = useState<RequirementHistoryEvent[]>([])

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(LoadingState.Loading)
      try {
        const res = await getRequirementHistoryWithUsers(
          specificationId,
          requirementId,
        )
        let previousStatus = 'DRAFT'

        const historyEvents = res.map((event) => {
          if (event.type === HistoryEventType.STATUS_UPDATED) {
            const withPrevious = {
              ...event,
              data: { ...event.data, previousStatus },
            }
            previousStatus = event.data.status
            return withPrevious
          }
          return event
        })

        setHistory(historyEvents)
        setLoading(LoadingState.Loaded)
      } catch (error) {
        console.error('Unable to load requirement history', error)
        setLoading(LoadingState.Failed)
      }
    }
    fetchHistory()
  }, [specificationId, requirementId])

  return (
    <div className={styles.historyContainer}>
      {loading === LoadingState.Loaded && (
        <div className={styles.historyList}>
          {history.map((historyEvent) => {
            const Component =
              EVENTS[historyEvent.type] || EVENTS[HistoryEventType.UNKNOWN]
            return <Component key={historyEvent.id} event={historyEvent} />
          })}
        </div>
      )}
      {loading === LoadingState.Loading && (
        <div className={styles.center}>
          <LoadingIndicator />
        </div>
      )}
      {loading === LoadingState.Failed && (
        <div className={styles.center}>Unable to load requirement history</div>
      )}
    </div>
  )
}

export default RequirementHistoryEvents
