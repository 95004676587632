import { CheckmarkOutline, Edit, Upload } from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import styles from './EvidenceActivityForm.module.css'
import { Asset } from '../../api/v2/assets.ts'
import * as evidenceApi from '../../api/v2/evidence.ts'
import { EvidenceActivity } from '../../api/v2/evidence.ts'
import {
  createEvidenceActivityAsset,
  getEvidenceActivityAsset,
} from '../../api/v2/evidenceActivityAssets.ts'
import { getJiraConnection, getJiraIssue } from '../../api/v2/integrations.ts'
import * as usersApi from '../../api/v2/users.ts'
import Avatar from '../../components/avatar'
import { AvatarSize } from '../../components/avatar/constants.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import MultiSelectInputDropdown, {
  OptionType,
} from '../../components/dropdown/MultiSelectInputDropdown.tsx'
import EvidenceActivityStatusSelect from '../../components/evidence/EvidenceActivityStatusSelect.tsx'
import EvidenceActivityTypeSelect from '../../components/evidence/EvidenceActivityTypeSelect.tsx'
import LoadingIndicator, {
  LoaderSize,
} from '../../components/loading-indicator/LoadingIndicator.tsx'
import EvidenceActivityStatusTag from '../../components/tag/EvidenceActivityStatusTag.tsx'
import EvidenceActivityTypeTag from '../../components/tag/EvidenceActivityTypeTag.tsx'
import { toastError } from '../../components/toast'
import { useAuth } from '../../context/AuthContext.tsx'
import { monthDayYear, yearMonthDay } from '../../lib/date.ts'
import {
  EvidenceActivityStatus,
  EvidenceActivityType,
} from '../../types/enums.ts'

const EvidenceActivityForm = (props: {
  evidenceId: string
  activity?: EvidenceActivity
  setActivities: Dispatch<SetStateAction<(EvidenceActivity | undefined)[]>>
  autoScroll: boolean
}) => {
  const { evidenceId, activity, setActivities, autoScroll } = props
  const { userTenantId } = useAuth()
  const [urlParams] = useSearchParams()
  const scrollRef = useRef<HTMLDivElement>(null)

  const [isLocked, setIsLocked] = useState<boolean>(!!activity)
  const [isEditing, setIsEditing] = useState(false)
  const [owners, setOwners] = useState<OptionType[]>([])

  const [inputTitle, setInputTitle] = useState(activity?.title || '')
  const [inputMemo, setInputMemo] = useState(activity?.memo || '')
  const [inputExternalUrl, setInputExternalUrl] = useState(
    activity?.externalUrl || '',
  )
  const [inputOwners, setInputOwners] = useState<OptionType[]>([])
  const [inputDueDate, setInputDueDate] = useState(
    yearMonthDay(activity?.dueDate || new Date(Date.now())),
  )
  const [inputStatus, setInputStatus] = useState<EvidenceActivityStatus | ''>(
    activity?.status || '',
  )
  const [inputType, setInputType] = useState<EvidenceActivityType | ''>(
    activity?.type || '',
  )
  const [userOptions, setUserOptions] = useState<OptionType[]>([])

  const [inputJiraIssueId, setInputJiraIssueId] = useState(
    activity?.jiraIssueId || '',
  )
  const [jiraIssueDetails, setJiraIssueDetails] = useState<{
    statusName: string
    summary: string
    description: string
    updated: string
  } | null>(null)
  const [hasJiraConnection, setHasJiraConnection] = useState(false)
  const [jiraConnectionUrl, setJiraConnectionUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const enableJiraIssue = useFeatureFlagEnabled('jira-issue')

  const fileInput = useRef<HTMLInputElement>(null)
  const [attachment, setAttachment] = useState<{
    file: File
    filename: string
  }>()
  const [isAttachmentChanged, setIsAttachmentChanged] = useState(false)
  const [attachmentError, setAttachmentError] = useState('')

  const openFilePicker = () => {
    fileInput.current?.click()
  }

  useEffect(() => {
    const loadFile = async () => {
      if (!activity || !activity.assetId) {
        return
      }

      try {
        const asset = await getEvidenceActivityAsset(
          evidenceId,
          activity.id,
          activity.assetId,
        )

        setAttachment({ file: asset.data, filename: asset.filename })
        setAttachmentError('')
      } catch (error) {
        console.error('Unable to load file', error)
        setAttachmentError('Error loading attached file')
      }
    }
    loadFile()
  }, [activity, evidenceId])

  useEffect(() => {
    const isNavEdit = urlParams.get('editActivity') === 'true'

    if (autoScroll && isNavEdit) {
      setIsEditing(true)
      setIsLocked(false)
    }
  }, [autoScroll, urlParams])

  useEffect(() => {
    if (activity && autoScroll && scrollRef?.current) {
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({ block: 'start' })
      }, 0)
    }
  }, [activity, autoScroll])

  const importFile = useCallback(async () => {
    const [file] = fileInput.current?.files ?? []

    if (!file) {
      console.warn('No file selected')
      return
    }

    if (file.size > 26214400) {
      toastError('File too large', 'Only files less than 25MB can be uploaded')
      return
    }

    setAttachment({ file, filename: file.name })
    setIsAttachmentChanged(true)
  }, [])

  useEffect(() => {
    const loadOwners = async () => {
      if (!activity) {
        return
      }

      let ownerIds: string[]
      try {
        ownerIds = await evidenceApi.getEvidenceActivityOwners(
          evidenceId,
          activity.id,
        )
      } catch (error) {
        console.error('Unable to load owners', error)
        toastError('Unable to load owners', '')
        return
      }

      const ownerUserObjects = await Promise.all(
        ownerIds.map(async (userId: string) => {
          try {
            const user = await usersApi.getUser(userId)
            return { id: user.id, label: `${user.firstName} ${user.lastName}` }
          } catch (error) {
            console.error('Unable to load owner', error)
          }
          return null
        }),
      )
      const filteredOwners = ownerUserObjects.filter(
        (owner) => owner !== null,
      ) as OptionType[]

      setOwners(filteredOwners)
      setInputOwners(filteredOwners)
    }

    loadOwners()
  }, [activity, evidenceId])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUsers = (await usersApi.getAllUsers())?.users || []
        setUserOptions(
          allUsers.map((user) => ({
            id: user.id,
            label: `${user.firstName} ${user.lastName}`,
          })),
        )
      } catch (error) {
        console.error('Unable to load users', error)
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    const checkJiraConnection = async () => {
      try {
        const response = await getJiraConnection(userTenantId)
        if (response) {
          setHasJiraConnection(true)
          setJiraConnectionUrl(response.url)
        } else {
          setHasJiraConnection(false)
          setJiraConnectionUrl('')
        }
      } catch (error) {
        console.error('Error checking JIRA connection', error)
        setHasJiraConnection(false)
        setJiraConnectionUrl('')
      }
    }

    checkJiraConnection()
  }, [userTenantId])

  const isActivityValid = () => {
    if (!inputType) {
      toastError('Type is required', '')
      return false
    }
    if (!inputStatus) {
      toastError('Status is required', '')
      return false
    }
    return true
  }

  const createActivity = async () => {
    if (!isActivityValid()) {
      return
    }

    let evidenceActivity: EvidenceActivity
    try {
      evidenceActivity = await evidenceApi.createEvidenceActivity(evidenceId, {
        title: inputTitle,
        memo: inputMemo,
        externalUrl: inputExternalUrl,
        dueDate: inputDueDate,
        status: inputStatus as EvidenceActivityStatus,
        type: inputType as EvidenceActivityType,
        jiraIssueId: inputJiraIssueId,
      })
    } catch (error) {
      console.error('Error creating activity', error)
      toastError('Unable to create activity', '')
      return
    }

    let attachedFile: Asset | undefined
    if (evidenceActivity) {
      attachedFile = await saveAttachedFile(evidenceActivity.id)
    }

    try {
      await Promise.all(
        inputOwners.map(async (owner) =>
          evidenceApi.updateEvidenceActivityOwner(
            evidenceId,
            evidenceActivity.id,
            owner.id,
          ),
        ),
      )
      setActivities((prev) => {
        const definedActivities = prev.filter((activity) => activity)
        if (evidenceActivity) {
          const updatedActivity = {
            ...evidenceActivity,
            assetId: attachedFile ? attachedFile.id : evidenceActivity.assetId,
          }
          return [...definedActivities, updatedActivity]
        } else {
          return definedActivities
        }
      })
      setIsLocked(true)
    } catch (error) {
      console.error('Error updating owners for activity', error)
      toastError('Unable to add owner(s) to activity', '')
    }
  }

  const saveAttachedFile = useCallback(
    async (activityId: string) => {
      if (!activityId || !attachment?.file) {
        return
      }

      try {
        return await createEvidenceActivityAsset(
          evidenceId,
          activityId,
          attachment.file,
        )
      } catch (error) {
        console.error('Unable to upload document', error)
        toastError('Unable to upload document', '')
      } finally {
        setIsAttachmentChanged(false)
      }
    },
    [attachment, evidenceId],
  )

  const updateActivity = async () => {
    if (!isActivityValid() || !activity) {
      return
    }

    let updatedEvidenceActivity: EvidenceActivity
    try {
      updatedEvidenceActivity = await evidenceApi.updateEvidenceActivity(
        evidenceId,
        activity?.id ?? '',
        {
          title: inputTitle,
          memo: inputMemo,
          externalUrl: inputExternalUrl,
          dueDate: inputDueDate,
          status: inputStatus as EvidenceActivityStatus,
          type: inputType as EvidenceActivityType,
          jiraIssueId: inputJiraIssueId,
        },
      )
    } catch (error) {
      console.error('Error updating activity', error)
      toastError('Unable to update activity', '')
      return
    }

    let attachedFile: Asset | undefined
    if (isAttachmentChanged) {
      attachedFile = await saveAttachedFile(activity.id)
    }

    try {
      const ownersToRemove = owners.filter(
        (owner) =>
          !inputOwners.find((inputOwner) => inputOwner.id === owner.id),
      )
      const ownersToAdd = inputOwners.filter(
        (inputOwner) => !owners.some((owner) => owner.id === inputOwner.id),
      )
      await Promise.all(
        ownersToRemove.map((owner) =>
          evidenceApi.deleteEvidenceActivityOwner(
            evidenceId,
            activity?.id ?? '',
            owner.id,
          ),
        ),
      )
      await Promise.all(
        ownersToAdd.map((owner) =>
          evidenceApi.updateEvidenceActivityOwner(
            evidenceId,
            activity?.id ?? '',
            owner.id,
          ),
        ),
      )

      setActivities((prev) =>
        prev.map((act) =>
          act?.id === activity?.id
            ? {
                ...act,
                ...updatedEvidenceActivity,
                assetId: attachedFile
                  ? attachedFile.id
                  : updatedEvidenceActivity.assetId,
              }
            : act,
        ),
      )
      setIsLocked(true)
    } catch (error) {
      console.error('Error updating owners for activity', error)
      toastError('Unable to update owner(s) for activity', '')
    }
  }

  const fetchJiraIssue = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await getJiraIssue(inputJiraIssueId)
      setJiraIssueDetails(response)

      // TODO: Handle custom-named JIRA statuses gracefully once we get those from customer
      if (response.statusName === 'To Do') {
        setInputStatus(EvidenceActivityStatus.Todo)
      } else if (response.statusName === 'In Progress') {
        setInputStatus(EvidenceActivityStatus.InProgress)
      } else if (response.statusName === 'Done') {
        setInputStatus(EvidenceActivityStatus.Complete)
      }
    } catch (error) {
      console.error('Error fetching JIRA issue details', error)
      toastError('Unable to fetch JIRA issue details', '')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.activity} ref={scrollRef}>
      {evidenceId && activity && isLocked && (
        <>
          <div className={styles.editAction}>
            <button
              onClick={() => {
                setIsLocked(!isLocked)
                setIsEditing(true)
              }}
            >
              <Edit size={16} />
            </button>
          </div>
          <div className={styles.lockedForm}>
            <div className={styles.row}>
              <span>{activity.title || 'No Activity Title'}</span>
              <EvidenceActivityStatusTag status={activity.status} />
            </div>
            <div className={styles.item}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Type
              </div>
              <EvidenceActivityTypeTag type={activity.type} />
            </div>
            <div className={styles.item}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Memo
              </div>
              <div>{activity.memo}</div>
            </div>
            {enableJiraIssue && jiraConnectionUrl && (
              <div className={styles.item}>
                <div className={`${styles.formItemLabel} ${styles.bold}`}>
                  Link to JIRA
                </div>
                {inputJiraIssueId && (
                  <Link
                    className={styles.link}
                    to={`${jiraConnectionUrl}/browse/${inputJiraIssueId}`}
                  >
                    {`${jiraConnectionUrl}/browse/${inputJiraIssueId}`}
                  </Link>
                )}
              </div>
            )}
            <div className={styles.item}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Attached file
              </div>
              {attachmentError && <span>{attachmentError}</span>}
              {attachment && (
                <a
                  href={URL.createObjectURL(attachment.file)}
                  download={attachment.filename}
                  className={styles.link}
                >
                  {attachment.filename || 'Attached document'}
                </a>
              )}
            </div>
            <div className={styles.item}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Link to external
              </div>
              <div className={styles.externalUrl}>{activity.externalUrl}</div>
            </div>
            <div className={styles.item}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Owner
              </div>
              <div className={styles.row}>
                {owners.map((owner) => (
                  <div key={owner.id} className={styles.owner}>
                    <Avatar
                      fullName={owner.label}
                      showName
                      size={AvatarSize.Medium}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.item}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Due Date
              </div>
              <div>{monthDayYear(activity.dueDate)}</div>
            </div>
          </div>
        </>
      )}
      {!isLocked && (
        <form className={styles.form}>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Type
              </div>
              <EvidenceActivityTypeSelect
                value={inputType as EvidenceActivityType}
                onSelect={(type: EvidenceActivityType) => setInputType(type)}
              />
            </div>
            <label className={styles.formItem}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Title
              </div>
              <input
                className={`${styles.basicElevation} ${styles.input}`}
                type="text"
                value={inputTitle}
                onChange={(e) => setInputTitle(e.target.value)}
              />
            </label>
            <label className={styles.formItem}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Memo
              </div>
              <input
                className={`${styles.basicElevation} ${styles.input}`}
                type="textarea"
                value={inputMemo}
                onChange={(e) => setInputMemo(e.target.value)}
              />
            </label>
            <div className={styles.formItem}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Status
              </div>
              <div className={styles.statusAndJira}>
                <EvidenceActivityStatusSelect
                  value={inputStatus as EvidenceActivityStatus}
                  onSelect={(status) => {
                    setInputStatus(status)
                  }}
                />
                {enableJiraIssue && hasJiraConnection && (
                  <>
                    <div className={styles.inputButtonGroup}>
                      <input
                        className={`${styles.basicElevation} ${styles.inputJiraIssue}`}
                        type="text"
                        value={inputJiraIssueId}
                        placeholder="JIRA Issue ID, e.g. KAN-1"
                        onChange={(e) => setInputJiraIssueId(e.target.value)}
                        disabled={!hasJiraConnection}
                      />
                      <Button
                        text="Sync JIRA"
                        onClick={fetchJiraIssue}
                        disabled={!hasJiraConnection || !inputJiraIssueId}
                        className={styles.jiraButton}
                      />
                    </div>
                    {loading ? (
                      <LoadingIndicator size={LoaderSize.SMALL} />
                    ) : (
                      jiraIssueDetails && (
                        <div className={styles.jiraDetails}>
                          <div>
                            <strong>JIRA:</strong> {jiraIssueDetails.statusName}
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
              {enableJiraIssue && jiraConnectionUrl && (
                <div className={styles.formItem}>
                  <div className={`${styles.formItemLabel} ${styles.bold}`}>
                    Link to JIRA
                  </div>
                  {inputJiraIssueId && (
                    <Link
                      className={styles.link}
                      to={`${jiraConnectionUrl}/browse/${inputJiraIssueId}`}
                    >
                      {`${jiraConnectionUrl}/browse/${inputJiraIssueId}`}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.item}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Attach supporting document
              </div>
              <div className={styles.import}>
                <Button
                  text="Upload Document"
                  endIcon={<Upload />}
                  onClick={(e) => {
                    e.preventDefault()
                    openFilePicker()
                  }}
                  color={BUTTON_COLORS.SECONDARY}
                />
                <input
                  ref={fileInput}
                  style={{ display: 'none' }}
                  type="file"
                  onChange={importFile}
                />
                {attachmentError && <span>{attachmentError}</span>}
                {attachment && (
                  <a
                    href={URL.createObjectURL(attachment.file)}
                    download={attachment.filename}
                    className={styles.link}
                  >
                    {attachment.filename || 'Attached document'}
                  </a>
                )}
              </div>
            </div>
            <label className={styles.formItem}>
              <div className={`${styles.formItemLabel} ${styles.bold}`}>
                Link to external
              </div>
              <input
                className={`${styles.basicElevation} ${styles.input}`}
                type="text"
                placeholder="https://..."
                value={inputExternalUrl}
                onChange={(e) => setInputExternalUrl(e.target.value)}
              />
            </label>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <div className={styles.owners}>
                <div className={`${styles.formItemLabel} ${styles.bold}`}>
                  Owner
                </div>
                <MultiSelectInputDropdown
                  options={userOptions}
                  selected={inputOwners}
                  placeholder="Search for a name"
                  onSelect={(selectedOptions) => {
                    setInputOwners(selectedOptions)
                  }}
                />
              </div>
              <label className={styles.formItem}>
                <div className={`${styles.formItemLabel} ${styles.bold}`}>
                  Due date
                </div>
                <input
                  className={`${styles.basicElevation} ${styles.input}`}
                  type="date"
                  value={inputDueDate}
                  onChange={(e) => {
                    setInputDueDate(e.target.value)
                  }}
                />
              </label>
            </div>
          </div>
          <Button
            text={isEditing ? 'Update activity' : 'Attach activity'}
            onClick={(e) => {
              e.preventDefault()
              isEditing ? updateActivity() : createActivity()
            }}
            endIcon={<CheckmarkOutline size={16} />}
            color={BUTTON_COLORS.PRIMARY}
          />
        </form>
      )}
    </div>
  )
}

export default EvidenceActivityForm
