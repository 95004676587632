import { TextBlockData } from '../../../api/v2/blocks.ts'
import { SharedBlock } from '../../../api/v2/sharedSpecifications.ts'
import QuillContent from '../../../components/quill-content/QuillContent.tsx'
import { EMPTY_DELTA } from '../../../lib/string.ts'

const SharedTextBlock = (props: { block: SharedBlock<TextBlockData> }) => {
  const { block } = props
  const quillDelta = block?.data?._data?.quillDelta ?? EMPTY_DELTA
  return (
    <QuillContent
      delta={quillDelta}
      readOnly
      block={block}
      placeholder=""
      style={{ maxWidth: '514px' }}
    />
  )
}

export default SharedTextBlock
