import { Add, CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './AttributeValueSelect.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { AttributeName } from '../../types/enums.ts'
import Dropdown from '../dropdown'
import Tag from '../tag'

interface AttributeValueSelectProps {
  attributeValues: AttributeValueResponse[]
  activeAttributeValues: AttributeValueResponse[]
  attributeGroupName?: AttributeName
  className?: string
  displayType: DisplayType
  onlyDisplayOnHover?: boolean
  readOnly?: boolean
  selectedValueId?: string
  onCancel?: (program: AttributeValueResponse | null) => void
  onSelect: (program: AttributeValueResponse | null) => void
}

export enum DisplayType {
  Table,
  Form,
}

const getTagColors = (value: AttributeValueResponse) => ({
  fontColor: value.metadata.STYLES.COLOR_FONT,
  backgroundColor: value.metadata.STYLES.COLOR_BG,
})

const AttributeValueSelect = (props: AttributeValueSelectProps) => {
  const {
    attributeValues,
    activeAttributeValues,
    attributeGroupName,
    className,
    displayType,
    onlyDisplayOnHover,
    readOnly,
    onCancel,
    onSelect,
    selectedValueId,
  } = props
  const selectedValue = attributeValues.find((p) => p.id === selectedValueId)
  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen(false)
  }
  const ref = useClickOutside(closeMenu)

  return (
    <div
      ref={ref}
      className={`${styles.attributeValue} ${
        onlyDisplayOnHover ? '' : styles.displayAddBtn
      }  ${className}`}
    >
      {(displayType === DisplayType.Table || readOnly) && selectedValue && (
        <Tag
          text={selectedValue.name}
          color={getTagColors(selectedValue)}
          onCancel={
            readOnly || !onCancel ? undefined : () => onCancel(selectedValue)
          }
        />
      )}
      {displayType === DisplayType.Form && !readOnly && (
        <button
          className={`${styles.selectBtn} ${styles.basicElevation}`}
          onClick={() => {
            setOpen(!open)
          }}
        >
          {selectedValue ? (
            <Tag
              text={selectedValue.name}
              color={getTagColors(selectedValue)}
            />
          ) : (
            <span className={styles.placeholder}>
              Select {attributeGroupName?.toLowerCase()}
            </span>
          )}
          <CaretDown size={20} />
        </button>
      )}
      {!readOnly && (
        <>
          {displayType === DisplayType.Table && (
            <button
              className={selectedValue ? '' : styles.addBtn}
              onClick={() => setOpen(!open)}
            >
              {selectedValue ? <CaretDown size={20} /> : <Add />}
            </button>
          )}
          <Dropdown
            className={`${styles.dropdown} ${
              displayType === DisplayType.Form ? styles.formDropdown : ''
            }`}
            isOpen={open}
          >
            {activeAttributeValues.length === 0 ? (
              <div className={styles.dropdownText}>No options available</div>
            ) : (
              <>
                {displayType === DisplayType.Form &&
                  selectedValue &&
                  onCancel && (
                    <button
                      onClick={() => {
                        closeMenu()
                        onCancel(selectedValue)
                      }}
                      className={`${styles.dropdownText} ${styles.option}`}
                    >
                      Clear selection
                    </button>
                  )}
                {activeAttributeValues
                  .filter((attrValue) => attrValue.id !== selectedValue?.id)
                  .map((attrValue) => {
                    return (
                      <button
                        key={attrValue.id}
                        className={styles.option}
                        onClick={() => {
                          closeMenu()
                          onSelect(attrValue)
                        }}
                      >
                        <Tag
                          text={attrValue?.name}
                          color={getTagColors(attrValue)}
                        />
                      </button>
                    )
                  })}
              </>
            )}
          </Dropdown>
        </>
      )}
    </div>
  )
}

export default AttributeValueSelect
