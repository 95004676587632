import { useState } from 'react'
import styles from './SharedRequirementCommentForm.module.css'
import Avatar from '../../components/avatar'
import { AvatarSize } from '../../components/avatar/constants.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import { toastError } from '../../components/toast'
import { useAuth } from '../../context/AuthContext.tsx'

const SharedRequirementCommentForm = (props: {
  addComment: (comment: string) => Promise<boolean>
}) => {
  const { addComment } = props
  const { userDetails } = useAuth()
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false)
  const [comment, setComment] = useState('')

  return (
    <div className={styles.commentForm}>
      <div className={`${styles.commentBodyInput} ${styles.basicElevation}`}>
        <div className={styles.authorAvatar}>
          <Avatar
            firstName={userDetails?.firstName}
            lastName={userDetails?.lastName}
            size={AvatarSize.Medium}
          />
        </div>
        <div className={styles.content}>
          <textarea
            placeholder="Share your thoughts..."
            rows={5}
            value={comment}
            maxLength={1000}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className={styles.charsRemaining}>{comment.length}/1000</div>
      </div>

      <div className={styles.submitButton}>
        <Button
          onClick={async () => {
            setDisabledSubmit(true)
            const added = await addComment(comment)
            setDisabledSubmit(false)
            if (!added) {
              toastError('Unable to add comment.', '')
              return
            }
            setComment('')
          }}
          text="Add Comment"
          disabled={disabledSubmit || comment.length === 0}
          color={BUTTON_COLORS.PRIMARY}
        />
      </div>
    </div>
  )
}

export default SharedRequirementCommentForm
