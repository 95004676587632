import { Copy } from '@carbon/icons-react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from '.'
import styles from './DuplicateSpecificationModal.module.css'
import * as api from '../../api/v2/specifications'
import { useAuth } from '../../context/AuthContext'
import Button, { BUTTON_COLORS } from '../button'
import LoadingIndicator from '../loading-indicator/LoadingIndicator'

enum DuplicateState {
  Confirming,
  Duplicating,
  Success,
  Error,
}

const getStageText = (stage, specName) => {
  switch (stage) {
    case DuplicateState.Duplicating: {
      return `Duplicating ${specName}...`
    }
    case DuplicateState.Success: {
      return `Successfully duplicated ${specName}!`
    }
    default:
      return ''
  }
}

const DuplicateSpecificationModal = (props) => {
  const { closeModal, specification } = props
  const [stage, setStage] = useState(DuplicateState.Confirming)
  const navigate = useNavigate()
  const { reload } = useAuth()

  const duplicate = useCallback(async () => {
    setStage(DuplicateState.Duplicating)

    try {
      const createdSpec = await api.duplicateSpecification(specification.id)
      reload()
      await new Promise((r) => setTimeout(r, 500))
      setStage(DuplicateState.Success)
      setTimeout(() => {
        navigate(`/specifications/${createdSpec.id}`)
        closeModal()
      }, 500)
    } catch (e) {
      setStage(DuplicateState.Error)
    }
  }, [specification.id, reload, navigate, closeModal])

  return (
    <Modal
      title="Duplicate Specification"
      icon={<Copy size={24} />}
      onClose={
        stage === DuplicateState.Confirming || stage === DuplicateState.Error
          ? closeModal
          : undefined
      }
    >
      <div className={styles.content}>
        <div
          className={`${styles.confirm} ${
            stage === DuplicateState.Confirming ? '' : styles.hidden
          }`}
        >
          <div className={styles.details}>
            Click Confirm to duplicate {specification.name}. A copy will be
            added to your workspace.
          </div>
          <div className={styles.actions}>
            <Button
              frontIcon={<Copy size={20} />}
              text="Confirm"
              color={BUTTON_COLORS.PRIMARY}
              onClick={duplicate}
            />
          </div>
        </div>
        {(stage === DuplicateState.Duplicating ||
          stage === DuplicateState.Success) && (
          <div>{getStageText(stage, specification.name)}</div>
        )}
        {stage === DuplicateState.Duplicating && (
          <div className={styles.center}>
            <LoadingIndicator />
          </div>
        )}
        {stage === DuplicateState.Error && (
          <div>There was an error duplicating {specification.name}</div>
        )}
      </div>
    </Modal>
  )
}

export default DuplicateSpecificationModal
