import { ShareKnowledge } from '@carbon/icons-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '.'
import styles from './ShareSpecificationModal.module.css'
import * as projectApi from '../../api/v2/projects.ts'
import * as api from '../../api/v2/sharedSpecifications.ts'
import { ShareSnapshotResponse } from '../../api/v2/sharedSpecifications.ts'
import { Specification } from '../../api/v2/specifications.ts'
import { useAuth } from '../../context/AuthContext.tsx'
import useClickOutside from '../../hooks/useClickOutside'
import {
  EntityRole,
  LoadingState,
  PermissionEntityType,
} from '../../types/enums.ts'
import Button, { BUTTON_COLORS } from '../button/index.tsx'
import Checkbox from '../input/Checkbox.tsx'
import LoadingIndicator from '../loading-indicator/LoadingIndicator.tsx'
import { toastError } from '../toast'

export interface ShareSpecificationModalProps {
  specification: Specification
}

enum Step {
  SelectProject,
  Success,
}

const ShareSpecificationModal = (
  props: ShareSpecificationModalProps & { closeModal: () => void },
) => {
  const { specification, closeModal } = props
  const { userTenantId, updateUserPermissions } = useAuth()
  const ref = useClickOutside(() => closeModal())
  const [step, setStep] = useState<Step>(Step.SelectProject)
  const [projects, setProjects] = useState<projectApi.Project[]>()
  const [projectsLoading, setProjectsLoading] = useState<LoadingState>(
    LoadingState.Loading,
  )
  const [projectSearchQuery, setProjectSearchQuery] = useState<string>('')
  const [selectedProject, setSelectedProject] =
    useState<projectApi.Project | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [sharedSpecificationSnapshot, setSharedSpecificationSnapshot] =
    useState<ShareSnapshotResponse>()

  useEffect(() => {
    const loadProjects = async () => {
      setProjectsLoading(LoadingState.Loading)
      try {
        const sharedProjects = await projectApi.getProjects()
        const tenantOwnedProjects = sharedProjects.filter(
          (project) => project.createdByTenant.id === userTenantId,
        )
        setProjects(tenantOwnedProjects)
        setProjectsLoading(LoadingState.Loaded)
      } catch (error) {
        console.warn('Unable to load projects', error)
        setProjectsLoading(LoadingState.Failed)
      }
    }
    loadProjects()
  }, [userTenantId])

  const filteredProjects = useMemo(
    () =>
      projects?.filter(
        (project) =>
          project.name
            .toLowerCase()
            .includes(projectSearchQuery.toLowerCase()) ||
          project.sharedWithTenant.name
            .toLowerCase()
            .includes(projectSearchQuery.toLowerCase()),
      ) || [],
    [projects, projectSearchQuery],
  )

  const onSelectProjectChange = (
    project: projectApi.Project,
    selected: boolean,
  ) => setSelectedProject(selected ? project : null)

  const shareSpecification = useCallback(async () => {
    if (selectedProject === null) {
      toastError('A project must be selected to share this specification', '')
      return
    }
    setSubmitting(true)
    try {
      const sharedSpec = await api.shareSpecification(
        specification.id,
        selectedProject.id,
      )
      updateUserPermissions(
        PermissionEntityType.SpecificationSnapshots,
        sharedSpec.id,
        EntityRole.OWNER,
      )
      setSharedSpecificationSnapshot(sharedSpec)
      setStep(Step.Success)
    } catch (error) {
      console.error('Unable to share specification', error)
      toastError('Unable to share specification', 'Try again later')
    } finally {
      setSubmitting(false)
    }
  }, [selectedProject, specification.id, updateUserPermissions])

  return (
    <Modal
      title="Share specification"
      icon={<ShareKnowledge />}
      onClose={closeModal}
      innerRef={ref}
    >
      <div className={styles.content}>
        {step !== Step.Success && (
          <div className={styles.subtitle}>Instructions</div>
        )}
        {step === Step.Success && (
          <div className={styles.success}>Success!</div>
        )}
        <div className={styles.subcontent}>
          {step === Step.SelectProject && (
            <>
              Select an organization to share this specification with. You'll be
              able to see the specification and begin collaborating in your
              shared project space.
            </>
          )}
          {step === Step.Success && (
            <>
              <span>Your specification was shared in </span>
              <span className={styles.bold}>
                {selectedProject?.name} ·{' '}
                {selectedProject?.sharedWithTenant.name}
              </span>
              <span>. View your data in your shared projects page.</span>
            </>
          )}
        </div>
        {step === Step.SelectProject && (
          <div className={styles.selectList}>
            <input
              className={styles.input}
              placeholder="Type to find projects"
              onChange={(event) => setProjectSearchQuery(event.target.value)}
            />
            <div className={styles.checkboxes}>
              {projectsLoading === LoadingState.Loading && <LoadingIndicator />}
              {projectsLoading === LoadingState.Loaded && !projects?.length && (
                <div className={styles.error}>
                  It looks like your organization does not have any projects
                  with external connections yet. Create a project from the
                  shared projects page.
                </div>
              )}
              {projectsLoading === LoadingState.Failed && !projects?.length && (
                <div className={styles.error}>
                  Unable to display existing projects.
                </div>
              )}
              {filteredProjects.map((project) => (
                <div className={styles.option} key={project.id}>
                  <Checkbox
                    checked={selectedProject?.id === project.id}
                    onChange={(checked: boolean) =>
                      onSelectProjectChange(project, checked)
                    }
                  />
                  <div className={styles.text}>
                    {project.name} · {project?.sharedWithTenant.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={styles.actions}>
          {step === Step.SelectProject && (
            <Button
              onClick={() => shareSpecification()}
              text="Share specification"
              endIcon={<ShareKnowledge />}
              color={BUTTON_COLORS.PRIMARY}
              disabled={submitting || selectedProject === null}
            />
          )}
          {step === Step.Success && sharedSpecificationSnapshot && (
            <Link
              to={`/projects/${sharedSpecificationSnapshot.projectId}`}
              className={styles.link}
              onClick={() => closeModal()}
            >
              View shared projects
            </Link>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ShareSpecificationModal
