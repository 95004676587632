import { useMemo, useEffect } from 'react'
import { useLoaderData } from 'react-router-dom'
import SharedProjectsCard from './SharedProjectsCard.tsx'
import styles from './SharedWorkspacesPage.module.css'
import { SharedWorkspace } from '../../api/v2/sharedWorkspaces.ts'
import { useAuth } from '../../context/AuthContext.tsx'
import { monthDayYear } from '../../lib/date'

const SharedWorkspacesPage = () => {
  const workspaces = useLoaderData() as SharedWorkspace[]
  const { isAdmin } = useAuth()

  const projectExists = useMemo(
    () => workspaces.some((workspace) => workspace.projects.length > 0),
    [workspaces],
  )

  const contactExists = useMemo(
    () => workspaces.some((workspace) => workspace.contact),
    [workspaces],
  )

  useEffect(() => {
    window.document.title = 'Shared Projects'
  }, [])

  return (
    <div className={styles.root}>
      <h1>Shared Projects</h1>
      <div className={styles.content}>
        <div className={styles.projects}>
          {projectExists ? (
            workspaces.map(
              (workspace) =>
                workspace.projects.length > 0 && (
                  <SharedProjectsCard
                    key={workspace.contact.id}
                    workspace={workspace}
                  />
                ),
            )
          ) : (
            <div className={styles.description}>No shared projects</div>
          )}
        </div>
        <div className={styles.connections}>
          <h2>Connections</h2>
          {contactExists && !isAdmin && (
            <div className={styles.description}>
              Only your organization's administrator can remove a connection.
              Please contact them for support.
            </div>
          )}
          {!contactExists && (
            <div className={styles.description}>No connections</div>
          )}
          {workspaces.map((workspace) => (
            <div
              className={`${styles.basicElevation} ${styles.connection}`}
              key={workspace.id}
            >
              <img
                className={styles.logo}
                src="/images/pink-gradient.png"
                alt=""
                draggable="false"
              />
              <span className={styles.details}>
                {workspace.contact.name} · Connected{' '}
                {monthDayYear(workspace.contact.connectedOn)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SharedWorkspacesPage
