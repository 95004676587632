import api, { usingPublicTenant } from './api'

const createUrl = (
  specificationId: string,
  documentId: string,
  blockId?: string,
) =>
  `/api/v2/specifications/${specificationId}/documents/${documentId}/blocks${
    blockId ? `/${blockId}` : ''
  }`

export enum BlockType {
  Text = 'FREE_TEXT',
  Heading = 'HEADING',
  Image = 'IMAGE',
  Requirement = 'SPECIFICATION_REQUIREMENT',
  Table = 'TABLE',
}

export interface TextBlockData {
  _data: {
    quillDelta: string
  }
}

export interface HeadingBlockData {
  _data: {
    text: string
  }
}

export interface ImageBlockData {
  assetId: string
  name: string
  _data: Record<string, never>
}

export interface ColumnData {
  id: string
  title?: string
  width?: number
}

export interface RowData {
  // column id to delta
  content: Record<string, string>
}

export interface TableBlockData {
  name: string
  _data: {
    columns: ColumnData[]
    rows: RowData[]
  }
}

export interface RequirementBlockData {
  _data: {
    delta: {
      shallStatement: string
      rationale: string
    }
  }
}

export type BlockData =
  | TextBlockData
  | HeadingBlockData
  | ImageBlockData
  | TableBlockData
  | RequirementBlockData

export interface CreateBlockRequest {
  type: BlockType
  data: BlockData
}

export interface Block<D extends BlockData> {
  /** @format guid */
  id: string
  /** @format guid */
  sectionId: string
  /** @format guid */
  documentId: string
  type: BlockType
  version: number
  data: D
}

export const createBlock: (
  /** @format guid */
  specificationId: string,
  /** @format guid */
  documentId: string,
  /** @format guid */
  sectionId: string,
  request: CreateBlockRequest,
) => Promise<Block<BlockData>> = (
  specificationId,
  documentId,
  sectionId,
  request,
) =>
  api.post(createUrl(specificationId, documentId), {
    body: { sectionId, ...request },
  })

export const getBlock: (
  specificationId: string,
  documentId: string,
  blockId: string,
) => Promise<Block<BlockData>> = (specificationId, documentId, blockId) =>
  api.get(createUrl(specificationId, documentId, blockId))

/**
 * Block data will need to be complete when sent via this endpoint as it's blob stored
 * Requirements should be updated using the requirements endpoint
 */
export const updateBlock: (
  specificationId: string,
  documentId: string,
  sectionId: string,
  blockId: string,
  data: BlockData,
) => Promise<Block<BlockData>> = (
  specificationId,
  documentId,
  sectionId,
  blockId,
  data,
) =>
  api.patch(createUrl(specificationId, documentId, blockId), {
    body: { sectionId, data },
  })

export const deleteBlock: (
  specificationId: string,
  documentId: string,
  blockId: string,
) => Promise<{ id: string }> = (specificationId, documentId, blockId) =>
  api.delete(createUrl(specificationId, documentId, blockId))

/**
 * This is a convenience endpoint for getting a filtered set of blocks
 * and won't be used for assembling documents
 */
export const getFilteredBlocks: (
  specificationId: string,
  documentId: string,
  filters: {
    id?: Array<string>
    type?: Array<BlockType>
  },
) => Promise<{ blocks: Array<Block<BlockData>> }> = async (
  specificationId,
  documentId,
  filters,
) => {
  const qIds = (filters?.id || []).map((id) => `id=${id}`).join('&')
  const qTypes = (filters?.type || []).map((type) => `type=${type}`)
  const query = `?${qIds}${qIds && qTypes ? '&' : ''}${qTypes}`

  return await api.get(`${createUrl(specificationId, documentId)}${query}`)
}

export interface GetImageBlockResponse {
  blocks: {
    id: string
    sectionId: string
    documentId: string
    contextId: string
    type: BlockType
    data: ImageBlockData
    specification: {
      id: string
      identifier: string
    }
  }[]
  token: string
}

export interface GetTableBlockResponse {
  blocks: {
    id: string
    sectionId: string
    documentId: string
    contextId: string
    type: BlockType
    data: TableBlockData
    specification: {
      id: string
      identifier: string
    }
  }[]
  token: string
}

export const getImageBlocks: (filters: {
  id?: Array<string>
  name?: string
}) => Promise<GetImageBlockResponse> = async (filters) => {
  const query: string[] = []

  query.push(...(filters?.id || []).map((id) => `id=${id}`))

  if (filters?.name) {
    query.push(`name=${filters.name}`)
  }

  return await api.get(
    `/api/v2/specifications/documents/blocks/images${
      !query?.length ? '' : `?${query.join('&')}`
    }`,
  )
}

export const getTableBlocks: (filters: {
  id?: Array<string>
  name?: string
}) => Promise<GetTableBlockResponse> = async (filters) => {
  const query: string[] = []

  query.push(...(filters?.id || []).map((id) => `id=${id}`))

  if (filters?.name) {
    query.push(`name=${filters.name}`)
  }

  return await api.get(
    `/api/v2/specifications/documents/blocks/tables${
      !query?.length ? '' : `?${query.join('&')}`
    }`,
  )
}

export const publicTenantMethods = {
  getFilteredBlocks: async (
    specificationId: string,
    documentId: string,
    filters: {
      id?: Array<string>
      type?: Array<BlockType>
    },
  ): Promise<{ blocks: Array<Block<BlockData>> }> => {
    const qIds = (filters?.id || []).map((id) => `id=${id}`).join('&')
    const qTypes = (filters?.type || []).map((type) => `type=${type}`)
    const query = `?${qIds}${qIds && qTypes ? '&' : ''}${qTypes}`

    return await usingPublicTenant(api.get)(
      `${createUrl(specificationId, documentId)}${query}`,
    )
  },
}
