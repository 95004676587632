import { useEffect } from 'react'
import styles from './SharedProjectsPage.module.css'

const SharedProjectsPage = () => {
  useEffect(() => {
    window.document.title = 'Shared Projects'
  }, [])

  return (
    <div className={styles.page}>
      <h1>Shared Projects</h1>
      <div>Coming soon!</div>
    </div>
  )
}

export default SharedProjectsPage
