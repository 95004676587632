import { Document, OverflowMenuHorizontal } from '@carbon/icons-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './SharedRequirementActions.module.css'
import { SharedSpecificationRequirement } from '../../api/v2/sharedSpecifications'
import Dropdown from '../../components/dropdown'
import useClickOutside from '../../hooks/useClickOutside'

export enum LinkTo {
  Document = 'document',
  Requirements = 'requirements',
}

const SharedRequirementActions = (props: {
  requirement: SharedSpecificationRequirement
  linkTo: LinkTo
}) => {
  const { requirement, linkTo } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const ref = useClickOutside(() => setIsOpen(false), isOpen)

  return (
    <div ref={ref} className={styles.container}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <OverflowMenuHorizontal />
      </button>
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        <Link className={styles.link} to={`../${linkTo}/${requirement.id}`}>
          <Document />
          View in {linkTo}
        </Link>
      </Dropdown>
    </div>
  )
}

export default SharedRequirementActions
