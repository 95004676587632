import { useEffect, useState } from 'react'
import styles from './SpecificationTreeBuilder.module.css'
import TreeBuilderNode from './TreeBuilderNode.tsx'
import { AttributeValueResponse } from '../../../api/v2/attributes.ts'
import * as api from '../../../api/v2/programs.ts'
import { SpecificationTreeNode } from '../../../api/v2/programs.ts'
import { getAllSpecifications } from '../../../api/v2/specifications.ts'

const SpecificationTreeBuilder = (props: {
  program: AttributeValueResponse
  treeData: api.SpecificationTreeNode | null
  onAddSpec: (
    createdNode: SpecificationTreeNode,
    parentNode?: SpecificationTreeNode | null,
  ) => void
  onDeleteSpec: (nodeId: string) => void
}) => {
  const { program, treeData, onAddSpec, onDeleteSpec } = props
  const [tree, setTree] = useState<api.SpecificationTreeNode | null>(treeData)
  const [specOptions, setSpecOptions] = useState<api.SpecificationNodeData[]>(
    [],
  )

  useEffect(() => {
    const loadSpecOptions = async () => {
      try {
        const allSpecs = await getAllSpecifications()
        const specIdsInTree = tree ? getTreeSpecIds(tree) : []
        const filteredSpecs = allSpecs
          .filter(
            (spec) =>
              spec.program === program.id && !specIdsInTree.includes(spec.id),
          )
          .map((spec) => ({
            id: spec.id,
            name: spec.name,
            identifier: spec.specificationIdentifier,
          }))
        setSpecOptions(filteredSpecs)
      } catch (error) {
        console.error('Unable to load specification options', error)
      }
    }
    loadSpecOptions()
  }, [program.id, tree])

  const getTreeSpecIds = (initialNode: api.SpecificationTreeNode) => {
    if (!initialNode) {
      return []
    }

    const treeSpecIds: string[] = []

    const addSpecId = (node: api.SpecificationTreeNode) => {
      treeSpecIds.push(node.specification.id)

      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => addSpecId(child))
      }
    }

    addSpecId(initialNode)
    return treeSpecIds
  }

  const nodeProps = {
    setTree: setTree,
    specOptions: specOptions,
    onAddSpec: onAddSpec,
    programId: program.id,
    depth: 0,
  }

  return (
    <div className={styles.tree}>
      <TreeBuilderNode isStarterNode {...nodeProps} />
      {tree?.children.map((child) => (
        <TreeBuilderNode
          key={child.id}
          treeNode={child}
          onDeleteSpec={onDeleteSpec}
          {...nodeProps}
        />
      ))}
    </div>
  )
}

export default SpecificationTreeBuilder
