import api from './api'

export interface GetJiraConnectionResponse {
  /** @format guid */
  id: string
  email: string
  url: string
  tenantId: string
  createdOn: string
  lastModifiedOn: string
}

export interface JiraIssue {
  statusName: string
  summary: string
  description: string
  updated: string
}

export const getJiraConnection: (
  tenantId?: string,
) => Promise<GetJiraConnectionResponse> = async (tenantId) => {
  const searchParams = new URLSearchParams()
  if (tenantId) {
    searchParams.append('tenantId', tenantId)
  }

  const queryString = searchParams.toString()
  const res = await api.get(
    `/api/v2/integrations/jira/connections${
      queryString ? `?${queryString}` : ''
    }`,
  )
  return res
}

export const getJiraIssue: (issueId: string) => Promise<JiraIssue> = (
  issueId,
) => api.get(`/api/v2/integrations/jira/data/issue/${issueId}`)
