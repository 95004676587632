import { ResetAlt } from '@carbon/icons-react'
import {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { TableColumn } from './Table'
import styles from './Table.module.css'
import { ColumnResizer } from './useResizableColumns'
import useClickOutside from '../../hooks/useClickOutside'
import useScrollObserver, { EDGE } from '../../hooks/useScrollObserver'
import IconButton from '../icon-button/IconButton'

const PAGE_SIZE = 60

interface VirtualTableItem {
  id: string
  isExpanded?: boolean
}

type VirtualTableProps = {
  rowItems: Array<VirtualTableItem>
  columns?: Array<TableColumn>
  columnResizer?: ColumnResizer
  expandAll?: boolean
  scrollToIndex?: number
  clearScrollToIndex?: () => void
  RowContextProvider?: ComponentType<{
    item: any
    children: ReactNode
  }>
}

const getInitialWindow = (index, length) => {
  let start = 0
  let until = Math.min(PAGE_SIZE, length)

  while (index > until) {
    until = Math.min(until + PAGE_SIZE, length)
  }
  start = until - PAGE_SIZE
  return [start, until]
}

const VirtualTable = (props: VirtualTableProps) => {
  const {
    rowItems,
    columns: columnsProp,
    columnResizer,
    expandAll,
    scrollToIndex,
    clearScrollToIndex,
    RowContextProvider,
  } = props

  const [multiSelected, setMultiSelected] = useState<string[]>([])

  const columns = useMemo(
    () => (columnResizer ? columnResizer.columns : columnsProp ?? []),
    [columnResizer, columnsProp],
  )

  const [[renderStart, renderUntil], setWindow] = useState(
    getInitialWindow(scrollToIndex, rowItems.length),
  )

  const [expandedRow, setExpandedRow] = useState(-1)
  useEffect(() => {
    setExpandedRow(-1)
  }, [rowItems.length])

  const expandedRef = useClickOutside(() => {
    setExpandedRow(-1)
  }, expandedRow >= 0)

  const scrollRef = useRef<any>(null)
  useEffect(() => {
    if (scrollToIndex && scrollToIndex >= 0) {
      scrollRef.current?.scrollIntoView?.({ block: 'center' })
    }
  }, [scrollToIndex])

  const onTopReached = useCallback(() => {
    if (renderStart === 0) {
      return
    }
    const top = Math.max(0, renderStart - PAGE_SIZE / 2)
    const bottom = Math.min(top + PAGE_SIZE, rowItems.length)
    setWindow([top, bottom])
  }, [renderStart, rowItems.length])

  const onBottomReached = useCallback(() => {
    const bottom = Math.min(renderUntil + PAGE_SIZE / 2, rowItems.length)
    const top = Math.max(bottom - PAGE_SIZE, 0)
    setWindow([top, bottom])
  }, [renderUntil, rowItems.length])

  const topRef = useScrollObserver(onTopReached, EDGE.Top)
  const bottomRef = useScrollObserver(onBottomReached, EDGE.Bottom)

  const columnTemplate = columns
    .map((col) => col.width || 'max-content')
    .join(' ')

  const RowWrapper = useMemo(
    () => RowContextProvider ?? ((props) => props.children),
    [RowContextProvider],
  )

  return (
    <table
      className={styles.tableRoot}
      style={{ gridTemplateColumns: columnTemplate }}
      cellPadding={0}
      cellSpacing={0}
    >
      <thead>
        <tr>
          {columns.map(
            ({ label, prettyLabel, transparent, HeaderAction }, index) => {
              const { onResizeColumn, resizeRefs, wasResized, clearSizing } =
                columnResizer ?? {}
              return (
                <th
                  ref={(ref) => {
                    if (ref && resizeRefs?.current) {
                      resizeRefs.current![index] = ref
                    }
                  }}
                  className={`${styles.headerCell} ${
                    transparent ? styles.headerTransparent : ''
                  }`}
                  key={label}
                >
                  {label && (
                    <span className={styles.labelAndAction}>
                      {prettyLabel === undefined ? label : prettyLabel}
                      {prettyLabel === '' && multiSelected.length === 0 && (
                        <div style={{ width: '12px' }}></div>
                      )}
                      {multiSelected.length > 0 && HeaderAction && (
                        <HeaderAction
                          multiSelected={multiSelected}
                          setMultiSelected={setMultiSelected}
                          rows={rowItems.map((item) => item.id)}
                        />
                      )}
                    </span>
                  )}
                  {onResizeColumn && resizeRefs?.current && !transparent && (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className={styles.resize}
                      onMouseDown={() => onResizeColumn(index)}
                    />
                  )}
                  {transparent && wasResized && (
                    <IconButton title="Reset column size" onClick={clearSizing}>
                      <ResetAlt />
                    </IconButton>
                  )}
                </th>
              )
            },
          )}
        </tr>
      </thead>
      <tbody>
        {rowItems.map((rowItem, index) => {
          const isVirtual =
            index < renderStart - PAGE_SIZE / 2 ||
            index > renderUntil + PAGE_SIZE / 2

          const isTransparentCol = columns[index]?.transparent

          return (
            <RowWrapper key={rowItem.id} item={rowItem}>
              {index === renderStart && index !== 0 && (
                <tr>
                  <td
                    ref={topRef}
                    className={isTransparentCol ? styles.cellTransparent : ''}
                    style={{
                      gridColumnStart: `span ${columns.length}`,
                      height: 0,
                      padding: 0,
                    }}
                  />
                </tr>
              )}
              {index === scrollToIndex && (
                <tr>
                  <td
                    ref={scrollRef}
                    className={isTransparentCol ? styles.cellTransparent : ''}
                    style={{
                      gridColumnStart: `span ${columns.length}`,
                      height: 0,
                      padding: 0,
                    }}
                  />
                </tr>
              )}
              <tr
                ref={(el) => {
                  if (expandedRow === index) {
                    expandedRef.current = el
                  }
                }}
                className={`${isVirtual ? styles.virtualRow : ''} ${
                  index === scrollToIndex ? styles.highlight : ''
                }`}
              >
                {columns.map((col) => {
                  const { Component, label } = col

                  return (
                    <td
                      key={label}
                      className={`${styles.tableCell} ${
                        isVirtual ? styles.virtualCell : ''
                      } ${col.transparent ? styles.cellTransparent : ''} ${
                        multiSelected.includes(rowItem.id)
                          ? styles.selected
                          : ''
                      }
                      `}
                      onAnimationEnd={clearScrollToIndex}
                    >
                      {isVirtual ? null : (
                        <Component
                          data={{ ...rowItem, index }}
                          isExpanded={expandAll || expandedRow === index}
                          onExpand={() => {
                            if (!expandAll) {
                              setExpandedRow(index)
                            }
                          }}
                          multiSelect={{
                            select: (s) => {
                              const deduped = [
                                ...new Set([...multiSelected, ...s]),
                              ]
                              setMultiSelected(deduped)
                            },
                            remove: (r) => {
                              const afterFilter = multiSelected.filter(
                                (sel) => !r.includes(sel),
                              )
                              setMultiSelected(afterFilter)
                            },
                            selected: multiSelected.includes(rowItem.id),
                          }}
                        />
                      )}
                    </td>
                  )
                })}
              </tr>
              {index === renderUntil && renderUntil < rowItems.length && (
                <tr>
                  <td
                    ref={bottomRef}
                    className={isTransparentCol ? styles.cellTransparent : ''}
                    style={{
                      gridColumnStart: `span ${columns.length}`,
                      height: 0,
                      padding: 0,
                    }}
                  />
                </tr>
              )}
            </RowWrapper>
          )
        })}
      </tbody>
    </table>
  )
}

export default VirtualTable
