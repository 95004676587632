import { OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './CommentCard.module.css'
import { RequirementComment } from '../../../../api/v2/requirements.ts'
import Avatar from '../../../../components/avatar'
import { AvatarSize } from '../../../../components/avatar/constants.ts'
import { useAuth } from '../../../../context/AuthContext.tsx'
import useClickOutside from '../../../../hooks/useClickOutside.ts'
import { formatTime, monthLongDayYear } from '../../../../lib/date.ts'

const CommentCard = (props: {
  comment: RequirementComment
  requirementVersion?: string
  onDelete?: () => void
}) => {
  const { comment, requirementVersion, onDelete } = props
  const [actionsOpen, setActionsOpen] = useState(false)
  const ref = useClickOutside(() => setActionsOpen(false), actionsOpen)

  const { userDetails } = useAuth()
  const userId = userDetails?.id

  return (
    <div className={`${styles.commentCard} ${styles.basicElevation}`}>
      <div className={styles.authorAvatar}>
        <Avatar
          firstName={comment.createdBy.firstName}
          lastName={comment.createdBy.lastName}
          size={AvatarSize.Medium}
        />
      </div>
      <div className={styles.content}>{comment.body}</div>
      {userId === comment.createdBy.id && onDelete && (
        <div ref={ref} className={styles.actionMenu}>
          <button onClick={() => setActionsOpen(!actionsOpen)}>
            <OverflowMenuVertical
              size={20}
              color={'var(--text-color-gray-light)'}
            />
          </button>
          <div
            className={`${styles.dropdown} ${styles.basicElevation} ${
              actionsOpen ? styles.open : ''
            }`}
          >
            <button onClick={onDelete}>
              <TrashCan size={16} />
              Delete
            </button>
          </div>
        </div>
      )}
      <div className={styles.author}>
        {requirementVersion && (
          <span className={styles.version}>Version {requirementVersion}</span>
        )}
        <span className={styles.authorName}>
          {comment.createdBy.firstName} {comment.createdBy.lastName}
        </span>
        <div className={styles.createDate}>
          <span>{monthLongDayYear(comment.createdOn)}</span>
          <span className={styles.time}>{formatTime(comment.createdOn)}</span>
        </div>
      </div>
    </div>
  )
}

export default CommentCard
