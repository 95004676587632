import { Add, Subtract } from '@carbon/icons-react'
import { Group } from '@visx/group'
import { hierarchy } from '@visx/hierarchy'
import { useEffect, useState, useMemo, useCallback } from 'react'
import styles from './Graph.module.css'
import GraphSection from './GraphSection'
import { ExpandedNode } from './types'
import useZoom from './useZoom'
import { getRequirementGraphView } from '../../../api/v2/requirements'
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator'
import { RequirementGraphViewResponse } from '../../../types/api/v2/requirements'

interface GraphProps {
  width: number
  height: number
  requirementId: string
  specificationId: string
  closeModal: () => void
}

interface Relation {
  type: 'CHILD' | 'PARENT'
  entityId: string
}

interface Requirement {
  id: string
  displayId: string
  title?: string
  shallStatement?: string
  specificationId: string
  relations: Relation[]
}

const Graph = (props: GraphProps) => {
  const { width, height, requirementId, specificationId, closeModal } = props

  const [data, setData] = useState<RequirementGraphViewResponse>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [rootHeight, setRootHeight] = useState(50)
  const [expandedNodeHeight, setExpandedNodeHeight] = useState(32)
  const [expandedNode, setExpandedNode] = useState({
    position: '',
    index: -1,
    requirementId: '',
    level: -1,
    nodeId: '',
  })
  const [isDragging, setIsDragging] = useState(false)
  const { zoomRef, zoomIn, zoomOut } = useZoom({
    width,
    height,
    setIsDragging,
    initialZoom: 0.73,
  })
  const rootConnectorRange = 145
  const rootEdgePadding = 15

  useEffect(() => {
    const getRequirementGraphViewData = async () => {
      setLoading(true)
      try {
        const graphViewData = await getRequirementGraphView(
          specificationId,
          requirementId,
        )
        setData(graphViewData)
      } catch (err) {
        setError('Unable to get requirement graph information')
      } finally {
        setLoading(false)
      }
    }
    getRequirementGraphViewData()
  }, [requirementId, specificationId])

  const getParentNode = useCallback(
    (
      data: RequirementGraphViewResponse,
      expandedNode: ExpandedNode,
      req: Requirement,
      level = 0,
      index = 0,
      parentCount = 0,
      parentId = 'root',
    ) => {
      const nodeId = `${req.id}|${parentId}`

      const parentIds = req.relations
        .filter((relation) => relation.type === 'PARENT')
        .map((relation) => relation.entityId)

      // Only add/display parents up to level 2 and
      // if at root, node is expanded, or parent node is expanded
      const parents =
        level < 2
          ? parentIds.map((parentId, parentIndex) => {
              const parentReq = data.requirements[parentId] as Requirement
              return getParentNode(
                data,
                expandedNode,
                parentReq,
                level + 1,
                parentIndex,
                parentIds.length,
                nodeId,
              )
            })
          : []

      const nextLevelIds = parents.map((parent) => parent.nodeId)

      const areParentsVisible =
        level === 0 ||
        (level === 1 && nodeId === expandedNode.nodeId) ||
        nextLevelIds.includes(expandedNode.nodeId)

      const reqNode = {
        displayId: req.displayId,
        requirementId: req.id,
        shallStatement: req.shallStatement,
        specificationId: req.specificationId,
        name: req.title,
        specificationName: data.specifications?.[req.specificationId].title,
        pathSourceOffset:
          rootEdgePadding +
          (rootConnectorRange / (parentCount + 1)) * (index + 1) -
          rootConnectorRange,
        position: level > 0 ? 'left' : 'root',
        parents: areParentsVisible ? parents : [],
        level,
        nodeId: nodeId,
      }

      return reqNode
    },
    [],
  )

  const getChildNode = useCallback(
    (
      data: RequirementGraphViewResponse,
      expandedNode: ExpandedNode,
      req: Requirement,
      level = 0,
      index = 0,
      childCount = 0,
      parentId = 'root',
    ) => {
      const nodeId = `${req.id}|${parentId}`

      const childIds = req.relations
        .filter((relation) => relation.type === 'CHILD')
        .map((relation) => relation.entityId)

      const children =
        level < 2
          ? childIds.map((childId, childIndex) => {
              const childReq = data.requirements[childId] as Requirement
              return getChildNode(
                data,
                expandedNode,
                childReq,
                level + 1,
                childIndex,
                childIds.length,
                nodeId,
              )
            })
          : []

      const nextLevelIds = children.map((child) => child.nodeId)

      const areChildrenVisible =
        level === 0 ||
        (level === 1 && nodeId === expandedNode.nodeId) ||
        nextLevelIds.includes(expandedNode.nodeId)

      const reqNode = {
        displayId: req.displayId,
        requirementId: req.id,
        shallStatement: req.shallStatement,
        specificationId: req.specificationId,
        name: req.title,
        specificationName: data.specifications?.[req.specificationId].title,
        pathSourceOffset:
          rootEdgePadding +
          (rootConnectorRange -
            (rootConnectorRange / (childCount + 1)) * (index + 1)) -
          rootConnectorRange,
        position: level > 0 ? 'right' : 'root',
        children: areChildrenVisible ? children : [],
        level,
        nodeId,
      }

      return reqNode
    },
    [],
  )

  const graphData = useMemo(() => {
    if (!data) return null

    const parentData = getParentNode(
      data,
      expandedNode,
      data.requirements[data.focusRequirementId],
    )
    const childData = getChildNode(
      data,
      expandedNode,
      data.requirements[data.focusRequirementId],
    )
    return { ...parentData, children: childData.children }
  }, [data, expandedNode, getChildNode, getParentNode])

  return (
    <>
      {loading && (
        <div className={styles.center}>
          <LoadingIndicator />
        </div>
      )}
      {error && <div className={styles.center}>{error}</div>}
      {!error && !loading && (
        <div
          className={styles.graph}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
          <div className={styles.relative}>
            <svg
              className={styles.svg}
              width={width}
              height={height}
              ref={zoomRef}
            >
              <Group top={height / 2} left={width / 1.5}>
                <GraphSection
                  position={'left'}
                  root={hierarchy(graphData, (d: any) => d.parents)}
                  rootHeight={rootHeight}
                  setRootHeight={setRootHeight}
                  expandedNodeHeight={expandedNodeHeight}
                  setExpandedNodeHeight={setExpandedNodeHeight}
                  expandedNode={expandedNode}
                  setExpandedNode={setExpandedNode}
                  closeModal={closeModal}
                />
                <GraphSection
                  position={'right'}
                  root={hierarchy(graphData, (d: any) => d.children)}
                  rootHeight={rootHeight}
                  setRootHeight={setRootHeight}
                  expandedNodeHeight={expandedNodeHeight}
                  setExpandedNodeHeight={setExpandedNodeHeight}
                  expandedNode={expandedNode}
                  setExpandedNode={setExpandedNode}
                  closeModal={closeModal}
                />
              </Group>
            </svg>
            <div className={styles.controls} style={{ cursor: 'auto' }}>
              <button className={styles.btnZoom} onClick={() => zoomIn()}>
                <Add size={20} />
              </button>
              <button className={styles.btnZoom} onClick={() => zoomOut()}>
                <Subtract size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Graph
