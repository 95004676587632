import { useEffect, useState } from 'react'
import styles from './EvidenceActivityDrawer.module.css'
import Drawer from './index.tsx'
import { Evidence } from '../../api/v2/evidence.ts'
import { getEvidenceActivityAsset } from '../../api/v2/evidenceActivityAssets.ts'
import { LoadingState } from '../../types/enums.ts'
import EvidenceActivityDetails, {
  ActivityWithOwners,
} from '../evidence/EvidenceActivityDetails.tsx'

export interface EvidenceActivityDrawerProps {
  activity: ActivityWithOwners
  parentRecord?: Evidence | null
  parentRecordColor?: string | null
  jiraConnectionUrl?: string | null
}

const EvidenceActivityDrawer = (
  props: EvidenceActivityDrawerProps & { closeModal: () => void },
) => {
  const { closeModal, activity, parentRecord, parentRecordColor } = props
  const [attachment, setAttachment] = useState<{
    data: File
    filename: string
  }>()
  const [loadingAttachment, setLoadingAttachment] = useState<LoadingState>()

  useEffect(() => {
    const loadFile = async () => {
      if (!activity.assetId) {
        return
      }

      setLoadingAttachment(LoadingState.Loading)
      try {
        const asset = await getEvidenceActivityAsset(
          activity.recordId,
          activity.id,
          activity.assetId,
        )
        setAttachment(asset)
        setLoadingAttachment(LoadingState.Loaded)
      } catch (error) {
        setLoadingAttachment(LoadingState.Failed)
        console.error('Unable to load file', error)
      }
    }
    loadFile()
  }, [activity])

  return (
    <Drawer className={styles.drawer} onClose={closeModal}>
      <EvidenceActivityDetails
        activity={activity}
        parentRecord={parentRecord}
        parentRecordColor={parentRecordColor}
        loadingAttachment={loadingAttachment}
        attachment={attachment}
        jiraConnectionUrl={props.jiraConnectionUrl}
        closeModal={closeModal}
      />
    </Drawer>
  )
}

export default EvidenceActivityDrawer
