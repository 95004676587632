import {
  Add,
  AddComment,
  AddFilled,
  CaretDown,
  Document,
  IncompleteCancel,
  ListBoxes,
  Maximize,
  OverflowMenuHorizontal,
} from '@carbon/icons-react'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './MatrixColumns.module.css'
import { ComplianceState } from '../../api/v2/requirements.ts'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import { MatrixColumnId } from '../../api/v2/specifications.ts'
import Chip from '../../components/chip'
import { DRAWER_TAB } from '../../components/drawer/enums'
import Dropdown from '../../components/dropdown'
import EditableSpan from '../../components/editable-span/EditableSpan'
import Checkbox from '../../components/input/Checkbox.tsx'
import LoadingIndicator, {
  LoaderSize,
} from '../../components/loading-indicator/LoadingIndicator.tsx'
import QuillContent from '../../components/quill-content/QuillContent.tsx'
import DeleteRequirementMenuButton from '../../components/requirement/DeleteRequirementMenuButton.tsx'
import { HeaderAction, TableColumn } from '../../components/table/Table'
import Tag, { TAG_COLORS } from '../../components/tag'
import ComplianceTag from '../../components/tag/ComplianceTag.tsx'
import Tooltip from '../../components/tooltip/Tooltip.tsx'
import { useAttributesContext } from '../../context/AttributesContext'
import { useAuth } from '../../context/AuthContext.tsx'
import {
  EntityLinkWithEvidence,
  useMatrixContext,
} from '../../context/MatrixContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext'
import useClickOutside from '../../hooks/useClickOutside'
import { useModals } from '../../hooks/useModals'
import {
  extractRationaleDelta,
  extractShallStatementDelta,
} from '../../lib/requirement.ts'
import {
  AttributeValueStatus,
  EvidenceType,
  LinkedRequirementType,
  RequirementStatus,
} from '../../types/enums'

const ActionsCell = ({ userHasPermissions, data }) => {
  const { openRequirementDrawer } = useModals()
  const {
    specification,
    revision,
    deleteOrArchiveRequirement,
    unarchiveRequirement,
    createRequirement,
    isArchiveDisabled,
    requirementIds,
  } = useSpecificationContext()
  const { blockOrder, bodySectionId } = useMatrixContext()
  const navigate = useNavigate()
  const { userIsCommenter } = useAuth()
  const userCanComment = userIsCommenter(specification.id)
  const [open, setOpen] = useState(false)
  const openDrawerToTab = (initialTab: DRAWER_TAB) => {
    openRequirementDrawer({
      requirement: data,
      initialTab,
      specificationId: specification.id,
    })
    setOpen(false)
  }

  const ref = useClickOutside(
    useCallback(() => setOpen(false), []),
    open,
  )

  return (
    <div ref={ref} className={styles.actions}>
      <button onClick={() => setOpen(!open)}>
        <OverflowMenuHorizontal />
      </button>
      <Dropdown className={styles.dropdown} isOpen={open}>
        {userHasPermissions && revision.status === RevisionStatus.DRAFT && (
          <button
            className={styles.actionItem}
            onClick={() => {
              const reqIdsAsArray = Array.from(requirementIds)
              const insertAfterId =
                reqIdsAsArray[reqIdsAsArray.length - 1] === data?.id
                  ? blockOrder.slice(-1)[0]
                  : data?.id

              createRequirement(bodySectionId, insertAfterId, {})
              setOpen(false)
            }}
          >
            <ListBoxes />
            Add requirement
          </button>
        )}
        {userHasPermissions && revision.status === RevisionStatus.DRAFT && (
          <button
            className={styles.actionItem}
            disabled={isArchiveDisabled(data?.context?.requirementVersionCount)}
            onClick={() => {
              if (data?.status === RequirementStatus.Archived) {
                unarchiveRequirement(data?.id)
              } else {
                deleteOrArchiveRequirement(bodySectionId, data?.id)
              }
              setOpen(false)
            }}
          >
            <IncompleteCancel />
            {data?.status === RequirementStatus.Archived
              ? 'Unarchive requirement'
              : 'Archive requirement'}
          </button>
        )}
        {userHasPermissions &&
          revision.status === RevisionStatus.DRAFT &&
          data?.context?.requirementVersionCount === 1 && (
            <DeleteRequirementMenuButton
              className={styles.actionItem}
              specificationId={specification.id}
              revisionId={revision.id}
              requirement={data}
              onDelete={() => setOpen(false)}
            />
          )}
        <button
          className={styles.actionItem}
          onClick={() => {
            navigate(
              `/specifications/${specification?.id}/document/${data?.id}`,
            )
          }}
        >
          <Document />
          View in document
        </button>
        <button
          className={styles.actionItem}
          onClick={() => openDrawerToTab(DRAWER_TAB.HISTORY)}
        >
          <Maximize />
          Open details
        </button>
        {userCanComment && (
          <button
            className={styles.actionItem}
            onClick={() => openDrawerToTab(DRAWER_TAB.COMMENTS)}
          >
            <AddComment />
            Comment
          </button>
        )}
      </Dropdown>
    </div>
  )
}

const SectionNumberCell = ({ data, isExpanded }) => {
  return (
    <div
      className={`${styles.sectionNumber} ${isExpanded ? styles.expanded : ''}`}
    >
      {data.sectionNumber}
    </div>
  )
}
const RequirementIdCell = ({ data, isExpanded }) => {
  const {
    specification: { specificationIdentifier, id: specificationId },
  } = useSpecificationContext()
  const id = `${specificationIdentifier || 'Document Number'}-${
    data?.context?.requirementIdentifier || ''
  }`

  return (
    <Tooltip
      className={isExpanded ? styles.expanded : ''}
      placement="right"
      content={
        <div className={`${styles.jumpToTooltip} ${styles.basicElevation}`}>
          <Link
            to={`/${
              data?.publicTenant ? 'public-specifications' : 'specifications'
            }/${specificationId}/document/${data?.id}`}
          >
            Jump to document mode
          </Link>
        </div>
      }
    >
      <div className={styles.requirementId}>{id}</div>
    </Tooltip>
  )
}

const IncrementalOrderCell = ({ data, multiSelect }) => {
  const { index, id } = data
  return (
    <div className={styles.multiSelectCell}>
      <label>
        <input
          type="checkbox"
          checked={multiSelect?.selected}
          onChange={(e) => {
            e.target.checked
              ? multiSelect?.select([id])
              : multiSelect?.remove([id])
          }}
        />
        <span>{index + 1}</span>
      </label>
    </div>
  )
}

const IncrementalOrderHeader = ({
  multiSelected,
  setMultiSelected,
  rows,
}: HeaderAction) => {
  if (!setMultiSelected || !rows) {
    return null
  }

  const allSelected = multiSelected.length === rows?.length

  return (
    <div style={{ fontSize: '12px' }}>
      {/* header font size causing checked box to 'jump' */}
      <Checkbox
        checked={allSelected}
        onChange={() => {
          if (allSelected) {
            setMultiSelected([])
          } else {
            setMultiSelected(rows)
          }
        }}
      ></Checkbox>
    </div>
  )
}

const RequirementNameCell = ({ data, canEditDraftOnly, isExpanded }) => {
  const { updateRequirementRow } = useMatrixContext()

  return (
    <div
      className={`${styles.requirementName} ${
        isExpanded ? styles.expanded : ''
      }`}
    >
      <EditableSpan
        readOnly={!canEditDraftOnly}
        value={data?.title}
        onValueChange={(title) => {
          if (title !== data?.title) {
            updateRequirementRow(data?.id, { title })
          }
        }}
        style={{ color: 'var(--text-color-black)', fontWeight: 600 }}
        placeholder="Requirement Name"
      />
    </div>
  )
}

const RequirementStatusCell = ({ data, isExpanded }) => (
  <div className={isExpanded ? styles.expanded : ''}>
    <Chip variant="status" value={data?.status || ''} />
  </div>
)

const RequirementTypeMultiSelectCell = ({ data, isExpanded, canEdit }) => {
  const { requirementTypes, getRequirementTypeById } = useAttributesContext()
  const { updateRequirementRow } = useMatrixContext()

  const activeTypes = requirementTypes.filter(
    (type) => type.status === AttributeValueStatus.Active,
  )

  const types = data?.types?.map((typeGuid) => {
    const type = getRequirementTypeById(typeGuid)
    return {
      text: type?.name ?? '',
      color: {
        fontColor: type?.metadata.STYLES.COLOR_FONT,
        backgroundColor: type?.metadata.STYLES.COLOR_BG,
      },
    }
  })

  const [open, setOpen] = useState(false)
  const [selectionsDropdownOpen, setSelectionsDropdownOpen] = useState(false)

  const closeMenu = () => {
    if (open) {
      setOpen(false)
    }
  }

  const multiSelectRef = useClickOutside(closeMenu, open)
  const viewSelectionsRef = useClickOutside(
    () => setSelectionsDropdownOpen(false),
    selectionsDropdownOpen,
  )
  return (
    <div
      className={`${styles.typeList} ${
        types?.length === 0 ? styles.noTags : ''
      } ${isExpanded ? styles.expanded : ''}`}
    >
      <div className={styles.selections}>
        <div className={styles.tags}>
          {types?.length === 1 && (
            <Tag
              text={types[0].text}
              color={types[0].color || TAG_COLORS.gray3}
            />
          )}
          {types?.length > 1 && (
            <div ref={viewSelectionsRef}>
              <button
                className={styles.viewSelectionsBtn}
                onClick={() =>
                  setSelectionsDropdownOpen(!selectionsDropdownOpen)
                }
              >
                <Tag
                  key={types[0].text}
                  text={types[0].text}
                  color={types[0].color || TAG_COLORS.gray3}
                />
                <span className={styles.overflowCount}>
                  +{types?.length - 1}
                </span>
                <CaretDown size={16} />
              </button>
              <Dropdown
                className={styles.dropdown}
                isOpen={selectionsDropdownOpen}
              >
                {types.map((tag) => (
                  <Tag
                    key={tag.text}
                    text={tag.text}
                    color={tag.color || TAG_COLORS.gray3}
                  />
                ))}
              </Dropdown>
            </div>
          )}
        </div>
        {canEdit && activeTypes?.length > 0 && (
          <>
            <button className={styles.add} onClick={() => setOpen(!open)}>
              <Add size={16} />
            </button>
            <div className={styles.multiSelect} ref={multiSelectRef}>
              <Dropdown className={styles.dropdown} isOpen={open}>
                {activeTypes
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((type) => {
                    const selected = data?.types?.includes(type.id)
                    const { COLOR_FONT: fontColor, COLOR_BG: backgroundColor } =
                      type.metadata.STYLES

                    return (
                      <button
                        key={type.id}
                        className={styles.typeItem}
                        onClick={() => {
                          const update = data?.types?.includes(type.id)
                            ? data?.types.filter((t) => t !== type.id)
                            : [...data.types, type.id]
                          updateRequirementRow(data.id, { types: update })
                        }}
                      >
                        <input type="radio" checked={selected} readOnly />
                        <Tag
                          text={type?.name}
                          color={{ fontColor, backgroundColor }}
                        />
                      </button>
                    )
                  })}
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ShallStatementCell = ({
  data,
  isExpanded,
  onExpand,
  canEditDraftOnly,
}) => {
  const { updateRequirementRow } = useMatrixContext()
  const style = {
    overflow: 'hidden',
    margin: isExpanded ? '0' : '8px 0',
    ...(isExpanded
      ? {}
      : {
          maxHeight: '18px',
        }),
  }

  const displayEllipses = !isExpanded && data?.shallStatement?.length > 30

  return (
    <div
      // Position relative for references modal in quill content comp
      style={{ position: 'relative' }}
      className={isExpanded ? styles.expanded : ''}
    >
      <QuillContent
        className={styles.quillShallStatement}
        style={style}
        readOnly={!canEditDraftOnly}
        placeholder="Shall Statement"
        delta={extractShallStatementDelta(data)}
        onFocus={onExpand}
        onValueChange={(delta, str) => {
          if (delta === data?.data?.delta?.shallStatement) {
            return
          }

          updateRequirementRow(data.id, {
            shallStatement: str,
            data: {
              delta: {
                rationale: data?.data?.delta?.rationale,
                shallStatement: delta,
              },
            },
          })
        }}
      />
      {displayEllipses && (
        <button
          className={styles.manualEllipses}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onExpand()
          }}
        >
          ...
        </button>
      )}
    </div>
  )
}

const ComplianceCell = ({ data, canEdit, isExpanded }) => {
  const { compliance: complianceName } = data
  const { activeComplianceStates, getComplianceStyles } = useAttributesContext()
  const { updateRequirementRow } = useMatrixContext()

  const compliance = getComplianceStyles(complianceName)

  const [open, setOpen] = useState(false)
  const ref = useClickOutside(() => setOpen(false), open)

  return (
    <div
      ref={ref}
      className={`${styles.compliance} ${open ? styles.open : ''} ${
        isExpanded ? styles.expanded : ''
      }`}
    >
      {compliance && (
        <ComplianceTag
          complianceName={compliance.name}
          color={compliance.styles}
          onCancel={
            canEdit
              ? () =>
                  updateRequirementRow(data.id, {
                    compliance: null,
                  })
              : undefined
          }
        />
      )}
      {canEdit && (
        <>
          <button
            className={compliance ? '' : styles.addBtn}
            onClick={() => setOpen(!open)}
          >
            {compliance ? <CaretDown size={20} /> : <Add />}
          </button>
          <Dropdown className={styles.dropdown} isOpen={open}>
            {activeComplianceStates
              .filter((cs) => cs.name !== complianceName)
              .map((cs) => {
                const stateStyles = getComplianceStyles(cs.name)!
                return (
                  <button
                    key={cs.id}
                    onClick={() => {
                      updateRequirementRow(data.id, {
                        compliance: cs.name as ComplianceState,
                      })
                      setOpen(false)
                    }}
                  >
                    <ComplianceTag
                      complianceName={stateStyles.name}
                      color={stateStyles.styles}
                    />
                  </button>
                )
              })}
          </Dropdown>
        </>
      )}
    </div>
  )
}

const ComplianceHeaderAction = ({ multiSelected }) => {
  const { activeComplianceStates, getComplianceStyles } = useAttributesContext()
  const { updateListOfRequirementRows } = useMatrixContext()

  const [open, setOpen] = useState(false)

  return (
    <button onClick={() => setOpen(!open)}>
      <AddFilled className={styles.filled} size={16} />
      <Dropdown
        className={`${styles.dropdown} ${styles.inHeader}`}
        isOpen={open}
      >
        {activeComplianceStates.map((cs) => {
          const stateStyles = getComplianceStyles(cs.name)!
          return (
            <button
              key={cs.id}
              className={styles.complianceSelectButton}
              onClick={async () => {
                const { name } = cs
                await updateListOfRequirementRows(multiSelected, {
                  compliance: name as ComplianceState,
                })

                setOpen(false)
              }}
            >
              <ComplianceTag
                complianceName={stateStyles.name}
                color={stateStyles.styles}
              />
            </button>
          )
        })}
      </Dropdown>
    </button>
  )
}

const ComplianceNotesCell = ({
  data,
  canEditDraftOnly,
  onExpand,
  isExpanded,
}) => {
  const { updateRequirementRow } = useMatrixContext()
  const style: CSSProperties = isExpanded
    ? {}
    : {
        overflow: 'hidden',
        maxHeight: '18px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }

  return (
    <div
      className={`${styles.complianceNotes} ${
        isExpanded ? styles.expanded : ''
      }`}
    >
      <EditableSpan
        readOnly={!canEditDraftOnly}
        value={data.complianceNotes}
        onValueChange={(complianceNotes) => {
          if (complianceNotes !== data?.complianceNotes) {
            updateRequirementRow(data.id, { complianceNotes })
          }
        }}
        placeholder="Compliance Notes"
        style={style}
        onFocus={onExpand}
      />
    </div>
  )
}

const RationaleCell = ({ data, isExpanded, onExpand, canEditDraftOnly }) => {
  const { updateRequirementRow } = useMatrixContext()
  const style = {
    overflow: 'hidden',
    margin: isExpanded ? '0' : '8px 0',
    ...(isExpanded
      ? {}
      : {
          maxHeight: '18px',
        }),
  }

  const displayEllipses = !isExpanded && data?.rationale?.length > 30

  return (
    <div
      // Position relative for references modal in quill content comp
      style={{ position: 'relative' }}
      className={isExpanded ? styles.expanded : ''}
    >
      <QuillContent
        className={styles.quillRationale}
        style={style}
        readOnly={!canEditDraftOnly}
        placeholder="Rationale"
        delta={extractRationaleDelta(data)}
        onFocus={onExpand}
        onValueChange={(delta, str) => {
          if (delta === data?.data?.delta?.rationale) {
            return
          }

          updateRequirementRow(data.id, {
            rationale: str,
            data: {
              delta: {
                shallStatement: data?.data?.delta?.shallStatement,
                rationale: delta,
              },
            },
          })
        }}
      />
      {displayEllipses && (
        <button
          className={styles.manualEllipses}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onExpand()
          }}
        >
          ...
        </button>
      )}
    </div>
  )
}

const ExternalNumberCell = ({ data, canEditDraftOnly, isExpanded }) => {
  const { updateRequirementRow } = useMatrixContext()
  return (
    <div
      className={`${styles.externalNo} ${isExpanded ? styles.expanded : ''}`}
    >
      <EditableSpan
        readOnly={!canEditDraftOnly}
        value={data.externalIdentifier}
        onValueChange={(externalIdentifier) => {
          if (externalIdentifier !== data?.externalIdentifier) {
            updateRequirementRow(data.id, { externalIdentifier })
          }
        }}
        maxLength={17}
        placeholder="External #"
      />
    </div>
  )
}

type LinkRequirementsArgs = [
  {
    specificationId: string
    documentId: string
    blockId: string
  },
  {
    specificationId: string
    documentId: string
    blockId: string
  },
]

const LinkedRequirementsCell = ({ data, linkType, canEdit, isExpanded }) => {
  const {
    specification: { id: specificationId },
    revision: { documentId },
  } = useSpecificationContext()
  const {
    linkRequirements,
    unlinkRequirements,
    requirementLinks,
    requirements,
  } = useMatrixContext()
  const { openAddLinkedRequirementModal, openLinkedRequirementViewModal } =
    useModals()

  const links = useMemo(
    () =>
      requirementLinks.filter((reqLink) =>
        linkType === LinkedRequirementType.Parent
          ? reqLink.child.id === data?.id
          : reqLink.parent.id === data?.id,
      ),
    [data.id, linkType, requirementLinks],
  )

  const noLinks = links.length === 0

  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(() => setIsOpen(false), isOpen)

  const pips = links.slice(0, 6)
  const hiddenPips = links.slice(6).length

  return (
    <div
      ref={ref}
      className={`${styles.linkedRequirements} ${
        noLinks ? styles.noLinks : ''
      } ${isExpanded ? styles.expanded : ''}`}
    >
      {!noLinks && (
        <>
          <button onClick={() => setIsOpen(!isOpen)}>
            <div className={styles.links}>
              {pips.map((link) => (
                <div
                  key={link.linkId}
                  className={`${styles.pip} ${styles[linkType]}`}
                />
              ))}
              {hiddenPips > 0 && (
                <div className={styles.hiddenCount}>+{hiddenPips}</div>
              )}
              <CaretDown size={16} />
            </div>
          </button>
          <Dropdown className={styles.dropdown} isOpen={isOpen}>
            <span className={styles.dropdownLabel}>
              Linked {linkType} requirements
            </span>
            <div className={styles.linkedItems}>
              {links.map((link) => {
                const onUnlink = () => {
                  unlinkRequirements(link.linkId)
                }

                const { specification, ...linkedRequirement } =
                  linkType === LinkedRequirementType.Parent
                    ? link.parent
                    : link.child

                const requirement =
                  requirements.find((r) => r.id === linkedRequirement.id) ??
                  linkedRequirement

                return (
                  <button
                    key={link.linkId}
                    onClick={() => {
                      openLinkedRequirementViewModal({
                        linkType,
                        linkedRequirementData: {
                          specificationId: specification.id,
                          requirementId: requirement.id,
                        },
                        userCanEdit: canEdit,
                        onUnlink,
                      })
                      setIsOpen(!isOpen)
                    }}
                  >
                    <div className={styles.linkedItem}>
                      <span className={styles.title}>
                        {requirement.title || 'No Title'}
                      </span>
                      <span className={styles.reqId}>{`${
                        specification.specificationIdentifier ||
                        'Document Number'
                      }-${
                        requirement.context.requirementIdentifier || ''
                      }`}</span>
                    </div>
                  </button>
                )
              })}
            </div>
          </Dropdown>
        </>
      )}
      {canEdit && (
        <button
          className={styles.add}
          onClick={() => {
            openAddLinkedRequirementModal({
              linkType,
              requirement: data,
              specificationId: specificationId,
              existingLinks: requirementLinks,
              linkRequirements: (linkTarget) => {
                const linkData = {
                  specificationId,
                  documentId,
                  blockId: data?.id,
                }
                const args =
                  linkType === LinkedRequirementType.Parent
                    ? [linkTarget, linkData]
                    : [linkData, linkTarget]

                linkRequirements(...(args as LinkRequirementsArgs))
              },
            })
          }}
        >
          <Add size={16} />
        </button>
      )}
    </div>
  )
}

const LinkedRequirementNameCell = ({
  data,
  isExpanded,
  onExpand,
  linkType,
}) => {
  const { requirementLinks } = useMatrixContext()
  const links = useMemo(
    () =>
      requirementLinks
        .filter((reqLink) =>
          linkType === LinkedRequirementType.Parent
            ? reqLink.child.id === data?.id
            : reqLink.parent.id === data?.id,
        )
        .map((link) =>
          linkType === LinkedRequirementType.Parent ? link.parent : link.child,
        ),
    [data.id, linkType, requirementLinks],
  )

  return links.length > 0 ? (
    <button
      onClick={onExpand}
      className={`${styles.inherited} ${isExpanded ? styles.expanded : ''}`}
    >
      <ul>
        {(isExpanded ? links : [links[0]]).map((link) => (
          <li key={link.id}>{link.title || 'Untitled'}</li>
        ))}
      </ul>
    </button>
  ) : (
    <div className={styles.inherited} />
  )
}

const LinkedRequirementShallStatementCell = ({
  data,
  isExpanded,
  onExpand,
  linkType,
}) => {
  const { requirementLinks } = useMatrixContext()
  const links = useMemo(
    () =>
      requirementLinks
        .filter((reqLink) =>
          linkType === LinkedRequirementType.Parent
            ? reqLink.child.id === data?.id
            : reqLink.parent.id === data?.id,
        )
        .map((link) =>
          linkType === LinkedRequirementType.Parent ? link.parent : link.child,
        ),
    [data.id, linkType, requirementLinks],
  )

  return links.length > 0 ? (
    <button
      onClick={onExpand}
      className={`${styles.inherited} ${isExpanded ? styles.expanded : ''}`}
    >
      <ul>
        {(isExpanded ? links : [links[0]]).map((link) => (
          <li key={link.id}>{link.shallStatement || 'Shall Statement'}</li>
        ))}
      </ul>
    </button>
  ) : (
    <div className={styles.inherited} />
  )
}

const EvidenceCell = ({ data, isExpanded, evidenceType, canEdit }) => {
  const {
    linkEvidence,
    unlinkEvidence,
    getEntityLinksWithRecords,
    validationLinks,
    verificationLinks,
    hasLoadedEntityLinks,
  } = useMatrixContext()

  const [linkWRecords, setLinkWRecords] = useState<EntityLinkWithEvidence[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasLoadedAll, setHasLoadedAll] = useState(false)
  const { specification } = useSpecificationContext()
  const { openLinkedEvidenceViewModal } = useModals()
  const { getEvidenceMethodById, getProgramById } = useAttributesContext()

  const filteredEvidenceLinks = (
    evidenceType === EvidenceType.Verification
      ? verificationLinks
      : validationLinks
  ).filter((link) => link.contextId === data?.context?.id)

  // Using the links count by default, if we dynamically fetch, use the count of records fetched to keep track of on page changes like adding\removing links
  const count = hasLoadedAll
    ? linkWRecords.length
    : filteredEvidenceLinks.length

  const loadFirstEvidence = useCallback(() => {
    const fetchRecord = async () => {
      if (
        !data?.context?.id ||
        hasLoaded ||
        !evidenceType ||
        !hasLoadedEntityLinks
      ) {
        return
      }

      const lwr = await getEntityLinksWithRecords(
        data?.context?.id,
        evidenceType,
        true,
      )

      if (lwr) {
        setLinkWRecords(lwr)
      }

      setHasLoaded(true)
    }

    fetchRecord()
  }, [
    data,
    evidenceType,
    getEntityLinksWithRecords,
    hasLoaded,
    hasLoadedEntityLinks,
  ])

  const loadAllEvidence = useCallback(() => {
    const fetchRecord = async () => {
      if (!data?.context?.id || hasLoadedAll || !hasLoadedEntityLinks) {
        return
      }

      const lwr = await getEntityLinksWithRecords(
        data?.context?.id,
        evidenceType,
        false,
        filteredEvidenceLinks.map((l) => l.linkId),
      )

      if (lwr) {
        setLinkWRecords((prev) => {
          const newState = [...prev]
          lwr.forEach((r) => {
            if (newState.findIndex((e) => e.linkId === r.linkId) < 0) {
              newState.push(r)
            }
          })
          return newState
        })
      }

      setHasLoadedAll(true)
    }

    fetchRecord()
  }, [
    data,
    evidenceType,
    filteredEvidenceLinks,
    getEntityLinksWithRecords,
    hasLoadedAll,
    hasLoadedEntityLinks,
  ])

  const [isOpen, setIsOpen] = useState(false)
  const [selectionsDropdownOpen, setSelectionsDropdownOpen] = useState(false)
  const addEvidenceRef = useClickOutside(() => setIsOpen(false), isOpen)
  const viewSelectionsRef = useClickOutside(
    () => setSelectionsDropdownOpen(false),
    selectionsDropdownOpen,
  )
  const { openLinkEvidenceModal, openCreateEvidenceModal } = useModals()
  const firstMethod = linkWRecords[0]?.evidence?.method
    ? getEvidenceMethodById(linkWRecords[0]?.evidence?.method)
    : null
  const firstProgram = linkWRecords[0]?.evidence?.program
    ? getProgramById(linkWRecords[0].evidence.program)
    : null

  useEffect(() => {
    loadFirstEvidence()
  }, [loadFirstEvidence])

  useEffect(() => {
    if (isExpanded) {
      loadAllEvidence()
    }
  }, [isExpanded, loadAllEvidence])

  return (
    <>
      {!hasLoaded && <LoadingIndicator size={LoaderSize.XSMALL} />}
      {hasLoaded && (
        <div
          className={`${styles.evidenceList} ${
            count === 0 ? styles.noTags : ''
          } ${isExpanded ? styles.expanded : ''}`}
        >
          <div className={styles.selections}>
            <div className={styles.tags}>
              {count === 1 && (
                <Tag
                  className={styles.tag}
                  text={firstMethod?.name ?? ''}
                  color={
                    firstMethod
                      ? {
                          fontColor: firstMethod.metadata.STYLES.COLOR_FONT,
                          backgroundColor: firstMethod.metadata.STYLES.COLOR_BG,
                          hoverBackgroundColor:
                            firstMethod.metadata.STYLES.COLOR_BG_HOVER,
                        }
                      : TAG_COLORS.gray3
                  }
                  onClick={() => {
                    openLinkedEvidenceViewModal({
                      linkedEvidence: linkWRecords[0].evidence,
                      method: firstMethod,
                      program: firstProgram,
                      userCanEdit: canEdit,
                      unlinkEvidence: () => {
                        unlinkEvidence(linkWRecords[0].linkId)
                        setLinkWRecords((linkWRecords) =>
                          linkWRecords.filter(
                            (l) => l.linkId !== linkWRecords[0].linkId,
                          ),
                        )
                      },
                    })
                  }}
                />
              )}
              {count > 1 && (
                <>
                  <button
                    className={styles.viewSelectionsBtn}
                    onClick={() => {
                      loadAllEvidence()
                      setSelectionsDropdownOpen(!selectionsDropdownOpen)
                    }}
                  >
                    <Tag
                      text={firstMethod?.name ?? ''}
                      color={
                        firstMethod
                          ? {
                              fontColor: firstMethod.metadata.STYLES.COLOR_FONT,
                              backgroundColor:
                                firstMethod.metadata.STYLES.COLOR_BG,
                              hoverBackgroundColor:
                                firstMethod.metadata.STYLES.COLOR_BG_HOVER,
                            }
                          : TAG_COLORS.gray3
                      }
                    />
                    <span className={styles.overflowCount}>+{count - 1}</span>
                    <CaretDown size={16} />
                  </button>
                  <div ref={viewSelectionsRef}>
                    <Dropdown
                      className={styles.viewSelectionsDropdown}
                      isOpen={selectionsDropdownOpen}
                    >
                      {!hasLoadedAll && (
                        <LoadingIndicator size={LoaderSize.SMALL} />
                      )}
                      {hasLoadedAll &&
                        linkWRecords.map((link) => {
                          const method = getEvidenceMethodById(
                            link.evidence.method,
                          )
                          const program = getProgramById(link.evidence.program)
                          return (
                            <Tag
                              className={styles.tag}
                              key={link.linkId}
                              text={method?.name ?? ''}
                              color={
                                method
                                  ? {
                                      fontColor:
                                        method.metadata.STYLES.COLOR_FONT,
                                      backgroundColor:
                                        method.metadata.STYLES.COLOR_BG,
                                      hoverBackgroundColor:
                                        method.metadata.STYLES.COLOR_BG_HOVER,
                                    }
                                  : TAG_COLORS.gray3
                              }
                              onClick={() => {
                                setSelectionsDropdownOpen(
                                  !selectionsDropdownOpen,
                                )
                                openLinkedEvidenceViewModal({
                                  linkedEvidence: link.evidence,
                                  method: method,
                                  program: program,
                                  userCanEdit: canEdit,
                                  unlinkEvidence: () => {
                                    unlinkEvidence(link.linkId)
                                    setLinkWRecords((prev) =>
                                      prev.filter(
                                        (l) => l.linkId !== link.linkId,
                                      ),
                                    )
                                  },
                                })
                              }}
                            />
                          )
                        })}
                    </Dropdown>
                  </div>
                </>
              )}
            </div>
            {canEdit && (
              <>
                <div ref={addEvidenceRef}>
                  <button
                    onClick={() => {
                      loadAllEvidence()
                      setIsOpen(!isOpen)
                    }}
                    className={styles.add}
                  >
                    <Add size={16} />
                  </button>
                  <Dropdown className={styles.dropdown} isOpen={isOpen}>
                    <button
                      onClick={() => {
                        openLinkEvidenceModal({
                          requirement: data,
                          evidenceType,
                          onLink: async (evidenceId) => {
                            const newEvidence = await linkEvidence(
                              data?.id,
                              evidenceId,
                              specification.id,
                            )

                            if (newEvidence) {
                              setLinkWRecords((prev) => [...prev, newEvidence])
                            }
                          },
                        })
                        setIsOpen(false)
                      }}
                    >
                      Link to existing record
                    </button>
                    <button
                      onClick={() => {
                        openCreateEvidenceModal({
                          evidenceType,
                          onCreate: async (evidence) => {
                            const newEvidence = await linkEvidence(
                              data?.id,
                              evidence.id,
                              specification.id,
                            )

                            if (newEvidence) {
                              setLinkWRecords((prev) => [...prev, newEvidence])
                            }
                          },
                          errorOnCreate: {
                            msg: 'Error linking evidence',
                            suggestion:
                              'Your evidence artifact was created, but not linked to your requirement',
                          },
                        })
                        setIsOpen(false)
                      }}
                    >
                      Create new record
                    </button>
                  </Dropdown>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const RecordTitleCell = ({ data, isExpanded, onExpand, evidenceType }) => {
  const { fetchedEvidenceRecords } = useMatrixContext()

  const evidence = fetchedEvidenceRecords.filter(
    (link) =>
      link.contextId === data?.context?.id &&
      link.evidence.type === evidenceType,
  )

  return evidence.length > 0 ? (
    <button
      onClick={onExpand}
      className={`${styles.inherited} ${isExpanded ? styles.expanded : ''}`}
    >
      <ul>
        {(isExpanded ? evidence : [evidence[0]]).map((link) => (
          <li key={link.linkId}>{link?.evidence?.title || 'Record Title'}</li>
        ))}
      </ul>
    </button>
  ) : (
    <div className={styles.inherited} />
  )
}

const DescriptionOfActivityCell = ({
  data,
  isExpanded,
  onExpand,
  evidenceType,
}) => {
  const { fetchedEvidenceRecords } = useMatrixContext()
  const evidence = fetchedEvidenceRecords.filter(
    (link) =>
      link.contextId === data?.context?.id &&
      link.evidence.type === evidenceType,
  )

  return evidence.length > 0 ? (
    <button
      onClick={onExpand}
      className={`${styles.inherited} ${isExpanded ? styles.expanded : ''}`}
    >
      <ul>
        {(isExpanded ? evidence : [evidence[0]]).map((link) => (
          <li key={link.linkId}>
            {link.evidence.description || 'Description Of Activity'}
          </li>
        ))}
      </ul>
    </button>
  ) : (
    <div className={styles.inherited} />
  )
}

const withCanEdit = (Component) => (props) => {
  const { data } = props
  const { userIsEditor } = useAuth()
  const { specification, revision, isHistoricalRevision } =
    useSpecificationContext()

  const canEdit =
    userIsEditor(specification.id) &&
    !isHistoricalRevision &&
    data?.status !== RequirementStatus.Archived &&
    !data.publicTenant

  const canEditDraftOnly =
    canEdit && revision.status === RevisionStatus.DRAFT && !data.publicTenant

  const userHasPermissions = userIsEditor(specification.id)

  return (
    <Component
      {...{ ...props, canEdit, canEditDraftOnly, userHasPermissions }}
    />
  )
}

export enum MatrixColumnLabels {
  IncrementingOrder = '',
  ID = 'ID',
  SectionNumber = 'Section Number',
  RequirementName = 'Requirement Name',
  ShallStatement = 'Shall Statement',
  Compliance = 'Compliance',
  ComplianceNotes = 'Compliance Notes',
  Rationale = 'Rationale',
  ExternalNumber = 'External #',
  Status = 'Status',
  Type = 'Type',
  ParentRequirements = 'Parent Requirements',
  ParentRequirementName = 'Parent Requirement Name',
  ParentShallStatement = 'Parent Shall Statement',
  ChildRequirements = 'Child Requirements',
  ChildRequirementName = 'Child Requirement Name',
  ChildShallStatement = 'Child Shall Statement',
  Validation = 'Validation',
  ValidationDescriptionOfActivity = 'Validation Description Of Activity',
  ValidationRecordTitle = 'Validation Record Title',
  Verification = 'Verification',
  VerificationDescriptionOfActivity = 'Verification Description Of Activity',
  VerificationRecordTitle = 'Verification Record Title',
}

export const MatrixColumnPrettyLabels = {
  ValidationDescriptionOfActivity: 'Description Of Activity',
  VerificationDescriptionOfActivity: 'Description Of Activity',
  DescriptionOfActivity: 'Description Of Activity',
  RecordTitle: 'Record Title',
}

const MatrixColumns: TableColumn[] = [
  { label: '', transparent: true, Component: ActionsCell },
  {
    label: MatrixColumnId.IncrementingOrder,
    prettyLabel: MatrixColumnLabels.IncrementingOrder,
    Component: IncrementalOrderCell,
    HeaderAction: IncrementalOrderHeader,
  },
  {
    label: MatrixColumnId.ID,
    prettyLabel: MatrixColumnLabels.ID,
    minWidth: 32,
    Component: RequirementIdCell,
  },
  {
    label: MatrixColumnId.SectionNumber,
    prettyLabel: MatrixColumnLabels.SectionNumber,
    Component: SectionNumberCell,
  },
  {
    label: MatrixColumnId.RequirementName,
    prettyLabel: MatrixColumnLabels.RequirementName,
    Component: RequirementNameCell,
  },
  {
    label: MatrixColumnId.ShallStatement,
    prettyLabel: MatrixColumnLabels.ShallStatement,
    width: 'minmax(300px, 1fr)',
    Component: ShallStatementCell,
  },
  {
    label: MatrixColumnId.Compliance,
    prettyLabel: MatrixColumnLabels.Compliance,
    minWidth: 188,
    Component: ComplianceCell,
    HeaderAction: ComplianceHeaderAction,
  },
  {
    label: MatrixColumnId.ComplianceNotes,
    prettyLabel: MatrixColumnLabels.ComplianceNotes,
    width: 'minmax(300px, 1fr)',
    Component: ComplianceNotesCell,
  },
  {
    label: MatrixColumnId.Rationale,
    prettyLabel: MatrixColumnLabels.Rationale,
    width: 'minmax(300px, 1fr)',
    Component: RationaleCell,
  },
  {
    label: MatrixColumnId.ExternalNumber,
    prettyLabel: MatrixColumnLabels.ExternalNumber,
    Component: ExternalNumberCell,
  },
  {
    label: MatrixColumnId.Status,
    prettyLabel: MatrixColumnLabels.Status,
    Component: RequirementStatusCell,
  },
  {
    label: MatrixColumnId.Type,
    prettyLabel: MatrixColumnLabels.Type,
    minWidth: 132,
    Component: RequirementTypeMultiSelectCell,
  },
  {
    label: MatrixColumnId.ParentRequirements,
    prettyLabel: MatrixColumnLabels.ParentRequirements,
    Component: (props) => (
      <LinkedRequirementsCell
        linkType={LinkedRequirementType.Parent}
        {...props}
      />
    ),
  },
  {
    label: MatrixColumnId.ParentRequirementName,
    prettyLabel: MatrixColumnLabels.ParentRequirementName,
    Component: (props) => (
      <LinkedRequirementNameCell
        linkType={LinkedRequirementType.Parent}
        {...props}
      />
    ),
  },
  {
    label: MatrixColumnId.ParentShallStatement,
    prettyLabel: MatrixColumnLabels.ParentShallStatement,
    Component: (props) => (
      <LinkedRequirementShallStatementCell
        linkType={LinkedRequirementType.Parent}
        {...props}
      />
    ),
  },
  {
    label: MatrixColumnId.ChildRequirements,
    prettyLabel: MatrixColumnLabels.ChildRequirements,
    Component: (props) => (
      <LinkedRequirementsCell
        linkType={LinkedRequirementType.Child}
        {...props}
      />
    ),
  },
  {
    label: MatrixColumnId.ChildRequirementName,
    prettyLabel: MatrixColumnLabels.ChildRequirementName,
    Component: (props) => (
      <LinkedRequirementNameCell
        linkType={LinkedRequirementType.Child}
        {...props}
      />
    ),
  },
  {
    label: MatrixColumnId.ChildShallStatement,
    prettyLabel: MatrixColumnLabels.ChildShallStatement,
    Component: (props) => (
      <LinkedRequirementShallStatementCell
        linkType={LinkedRequirementType.Child}
        {...props}
      />
    ),
  },
  {
    label: MatrixColumnId.Validation,
    prettyLabel: MatrixColumnLabels.Validation,
    minWidth: 132,
    Component: (props) => (
      <EvidenceCell {...{ ...props, evidenceType: EvidenceType.Validation }} />
    ),
  },
  {
    label: MatrixColumnId.ValidationRecordTitle,
    prettyLabel: MatrixColumnPrettyLabels.RecordTitle,
    Component: (props) => (
      <RecordTitleCell {...props} evidenceType={EvidenceType.Validation} />
    ),
  },
  {
    label: MatrixColumnId.ValidationDescriptionOfActivity,
    prettyLabel: MatrixColumnPrettyLabels.ValidationDescriptionOfActivity,
    Component: (props) => (
      <DescriptionOfActivityCell
        {...props}
        evidenceType={EvidenceType.Validation}
      />
    ),
  },
  {
    label: MatrixColumnId.Verification,
    prettyLabel: MatrixColumnLabels.Verification,
    minWidth: 132,
    Component: (props) => (
      <EvidenceCell
        {...{ ...props, evidenceType: EvidenceType.Verification }}
      />
    ),
  },
  {
    label: MatrixColumnId.VerificationRecordTitle,
    prettyLabel: MatrixColumnPrettyLabels.RecordTitle,
    Component: (props) => (
      <RecordTitleCell {...props} evidenceType={EvidenceType.Verification} />
    ),
  },
  {
    label: MatrixColumnId.VerificationDescriptionOfActivity,
    prettyLabel: MatrixColumnPrettyLabels.VerificationDescriptionOfActivity,
    Component: (props) => (
      <DescriptionOfActivityCell
        {...props}
        evidenceType={EvidenceType.Verification}
      />
    ),
  },
].map((col) => ({ ...col, Component: withCanEdit(col.Component) }))

export const PublicSpecMatrixColumns = MatrixColumns.filter((col) =>
  [
    MatrixColumnId.ID,
    MatrixColumnId.RequirementName,
    MatrixColumnId.ShallStatement,
    MatrixColumnId.SectionNumber,
    // Leaving out rationale for now - not sure how we're referencing the config in matrix
    // MatrixColumnId.Rationale,
  ].includes(col.label as MatrixColumnId),
)

export default MatrixColumns
