import { Add } from '@carbon/icons-react'
import { useState } from 'react'
import Modal from '.'
import styles from './CreateEvidenceModal.module.css'
import * as evidenceApi from '../../api/v2/evidence'
import { useAttributesContext } from '../../context/AttributesContext'
import { EvidenceType } from '../../types/enums'
import Button, { BUTTON_COLORS } from '../button'
import EvidenceMethodSelect from '../evidence/EvidenceMethodSelect'
import EvidenceProgramSelect from '../evidence/EvidenceProgramSelect'
import Checkbox from '../input/Checkbox'
import { toastError, toastSuccess } from '../toast'

interface CreateEvidenceModalProps {
  evidenceType?: EvidenceType
  onCreate?: (evidence: evidenceApi.Evidence) => void | Promise<void>
  errorOnCreate?: { msg: string; suggestion: string }
}

const CreateEvidenceModal = (
  props: CreateEvidenceModalProps & { closeModal: () => void },
) => {
  const { evidenceType, onCreate, errorOnCreate, closeModal } = props

  const { evidenceCadences, evidenceMethods } = useAttributesContext()

  const [selectedType] = useState(evidenceType)
  const [title, setTitle] = useState('')
  const [method, setMethod] = useState(evidenceMethods[0].id)
  const [program, setProgram] = useState('')
  const [description, setDescription] = useState('')
  const [complianceStatement, setComplianceStatement] = useState('')
  const [cadences, setCadences] = useState<string[]>([])
  const toggleCadence = (cadence) => {
    if (cadences.includes(cadence)) {
      setCadences(cadences.filter((c) => c !== cadence))
    } else {
      setCadences([...cadences, cadence])
    }
  }

  const onAdd = async () => {
    if (!selectedType) {
      toastError('Evidence type is required', '')
      return
    }

    const evidence: evidenceApi.CreateEvidenceRequest = {
      title,
      type: selectedType,
      description,
      method,
      program,
      cadences,
      complianceStatement,
    }

    let createSuccess = false
    try {
      const newEvidence = await evidenceApi.createEvidenceRecord(evidence)

      createSuccess = true
      if (onCreate) {
        await onCreate(newEvidence)
      }
      toastSuccess('Successfully created evidence')
      closeModal()
    } catch (e) {
      const msg = createSuccess
        ? errorOnCreate?.msg || 'An error occurred'
        : 'Error creating evidence'

      const suggestion =
        (createSuccess && errorOnCreate?.suggestion) ||
        'Please refresh and try again'

      toastError(msg, suggestion)
    }
  }

  return (
    <Modal
      title={
        selectedType === EvidenceType.Validation
          ? 'New Validation Record'
          : 'New Verification Record'
      }
      icon={<Add size={24} />}
      onClose={closeModal}
    >
      {/* TODO: Move this to form and improve accessibility */}
      <div className={styles.root}>
        <div className={styles.inputs}>
          <span className={styles.label}>Method</span>
          <div className={styles.methodSelect}>
            <EvidenceMethodSelect value={method} onSelect={setMethod} />
          </div>
          <span className={styles.label}>Program</span>
          <div className={styles.methodSelect}>
            <EvidenceProgramSelect value={program} onSelect={setProgram} />
          </div>

          <span className={styles.label}>Record title</span>
          <input
            placeholder="Record title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={45}
          />
          <span className={styles.label}>Evidence cadence</span>
          <div className={styles.optionGroup}>
            {evidenceCadences.map((cadence) => (
              <div key={cadence.id} className={styles.option}>
                <Checkbox
                  size={16}
                  checked={cadences.includes(cadence.id)}
                  onChange={() => toggleCadence(cadence.id)}
                />
                <span className={styles.optionText}>
                  {cadence.name.toLowerCase()}
                </span>
              </div>
            ))}
          </div>
          <span className={styles.label}>Description of activity</span>
          <textarea
            rows={5}
            placeholder="Description of activity"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={524288}
          />
          <span className={styles.label}>Compliance statment</span>
          <textarea
            rows={5}
            placeholder="Compliance statement"
            value={complianceStatement}
            onChange={(e) => setComplianceStatement(e.target.value)}
            maxLength={524288}
          />
        </div>
        <div className={styles.actions}>
          <Button
            text="Add evidence"
            endIcon={<Add size={20} />}
            onClick={onAdd}
            color={BUTTON_COLORS.PRIMARY}
          />
        </div>
      </div>
    </Modal>
  )
}
export default CreateEvidenceModal
