import {
  AddComment,
  DataShare,
  OverflowMenuVertical,
} from '@carbon/icons-react'
import { useCallback, useState } from 'react'
import styles from './CommentForm.module.css'
import { createChangeRequest } from '../../../../api/v2/changeRequests.ts'
import * as api from '../../../../api/v2/requirements.ts'
import Avatar from '../../../../components/avatar'
import { AvatarSize } from '../../../../components/avatar/constants.ts'
import Button, { BUTTON_COLORS } from '../../../../components/button'
import { toastError } from '../../../../components/toast'
import { useAuth } from '../../../../context/AuthContext.tsx'
import useClickOutside from '../../../../hooks/useClickOutside.ts'

export enum CommentMode {
  Comment = 'comment',
  ChangeRequest = 'change request',
}

const CommentForm = (props: {
  specificationId: string
  requirementId: string
  commentMode: CommentMode
  toggleCommentMode: () => void
  onCreate: () => void
}) => {
  const {
    specificationId,
    requirementId,
    commentMode,
    toggleCommentMode,
    onCreate,
  } = props
  const { userDetails } = useAuth()

  // Comment data
  const [comment, setComment] = useState<string>('')

  // Change Request data
  const [current, setCurrent] = useState<string>('')
  const [proposed, setProposed] = useState<string>('')
  const [memo, setMemo] = useState<string>('')

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [actionsOpen, setActionsOpen] = useState(false)

  const actionsRef = useClickOutside(() => setActionsOpen(false), actionsOpen)
  const disableSubmit =
    submitting ||
    (commentMode === CommentMode.Comment && !comment) ||
    (commentMode === CommentMode.ChangeRequest &&
      (!current || !proposed || !memo))

  const resetForm = () => {
    setComment('')
    setCurrent('')
    setProposed('')
    setMemo('')
  }

  const submit = useCallback(async () => {
    setSubmitting(true)
    try {
      if (commentMode === CommentMode.ChangeRequest) {
        await createChangeRequest(specificationId, requirementId, {
          current,
          proposed,
          memo,
        })
      } else if (commentMode === CommentMode.Comment)
        await api.createComment(specificationId, requirementId, comment)
      onCreate()
      resetForm()
    } catch (error) {
      const er = `Unable to create ${commentMode}`
      toastError(er, 'Refresh page and try again')
      console.error(er, error)
    } finally {
      setSubmitting(false)
    }
  }, [
    commentMode,
    specificationId,
    requirementId,
    comment,
    onCreate,
    current,
    proposed,
    memo,
  ])

  return (
    <>
      <div className={`${styles.formComment} ${styles.basicElevation}`}>
        <div className={styles.authorAvatar}>
          <Avatar
            firstName={userDetails?.firstName}
            lastName={userDetails?.lastName}
            size={AvatarSize.Medium}
          />
        </div>
        <div className={styles.content}>
          {commentMode === CommentMode.ChangeRequest && (
            <>
              <div className={styles.header}>
                <div className={styles.orb}></div>
                <span className={styles.headerText}>Change request</span>
              </div>
              <div className={styles.textRow}>
                <div>
                  <span className={`${styles.blockLabel} ${styles.was}`}>
                    WAS
                  </span>
                </div>
                <div>
                  <textarea
                    className={styles.wasText}
                    rows={3}
                    placeholder="Was..."
                    value={current}
                    onChange={(e) => setCurrent(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.textRow}>
                <div>
                  <span className={`${styles.blockLabel} ${styles.is}`}>
                    IS
                  </span>
                </div>
                <div>
                  <textarea
                    className={styles.isText}
                    rows={3}
                    placeholder="Is..."
                    value={proposed}
                    onChange={(e) => setProposed(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <textarea
            placeholder={
              commentMode === CommentMode.Comment
                ? 'Share your thoughts...'
                : 'Memo...'
            }
            rows={5}
            value={commentMode === CommentMode.Comment ? comment : memo}
            maxLength={commentMode === CommentMode.Comment ? 1000 : 3000}
            onChange={(e) => {
              if (commentMode === CommentMode.Comment) {
                setComment(e.target.value)
              } else {
                setMemo(e.target.value)
              }
            }}
          />
        </div>
        <div ref={actionsRef} className={styles.actionMenu}>
          <button onClick={() => setActionsOpen(!actionsOpen)}>
            <OverflowMenuVertical
              size={20}
              color="var(--text-color-gray-light)"
            />
          </button>
          <div
            className={`${styles.dropdown} ${styles.basicElevation} ${
              actionsOpen ? styles.open : ''
            }`}
          >
            <button
              onClick={() => {
                setActionsOpen(false)
                toggleCommentMode()
              }}
            >
              {commentMode === CommentMode.Comment && (
                <>
                  <DataShare size={16} />
                  Add change request
                </>
              )}
              {commentMode === CommentMode.ChangeRequest && (
                <>
                  <AddComment size={16} />
                  Add comment
                </>
              )}
            </button>
          </div>
        </div>
        <div className={styles.charsRemaining}>
          {commentMode === CommentMode.Comment ? comment.length : memo.length}/
          {commentMode === CommentMode.Comment ? 1000 : 3000}
        </div>
      </div>

      <div className={styles.submitButton}>
        <Button
          onClick={submit}
          disabled={disableSubmit}
          text={
            commentMode === CommentMode.Comment
              ? 'Add comment'
              : 'Request change'
          }
          endIcon={
            commentMode === CommentMode.Comment ? undefined : (
              <DataShare size={20} />
            )
          }
          color={BUTTON_COLORS.PRIMARY}
        />
      </div>
    </>
  )
}

export default CommentForm
