import api from './api'
import { convertToLocalDate } from '../../lib/date'
import {
  EvidenceActivityStatus,
  EvidenceActivityType,
  EvidenceType,
  ReportColumnId,
} from '../../types/enums'

const createUrl: (
  evidenceId?: string,
  withActivities?: boolean,
  activityId?: string,
  withOwners?: boolean,
  ownerId?: string,
) => string = (evidenceId, withActivities, activityId, withOwners, ownerId) =>
  `/api/v2/evidences${evidenceId ? `/${evidenceId}` : ''}${
    withActivities ? '/activities' : ''
  }${activityId ? `/${activityId}` : ''}${withOwners ? '/owners' : ''}${
    ownerId ? `/${ownerId}` : ''
  }`

const evidenceWithDates: (evidence: EvidenceResponse) => Evidence = (
  evidence,
) => ({
  ...evidence,
  createdOn: new Date(evidence.createdOn),
  lastModifiedOn: new Date(evidence.lastModifiedOn),
})

const evidenceActivityWithDates: (
  evidence: EvidenceActivityResponse & { recordId: string },
) => EvidenceActivity = (evidenceActivity) => ({
  ...evidenceActivity,
  dueDate: convertToLocalDate(evidenceActivity.dueDate),
  createdOn: new Date(evidenceActivity.createdOn),
  lastModifiedOn: new Date(evidenceActivity.lastModifiedOn),
})

interface EvidenceResponse {
  /** @format guid */
  id: string
  title: string
  description: string
  complianceStatement: string
  type: EvidenceType
  /** @format guid */
  method: string
  /** @format guid */
  program: string
  /** @format guid */
  cadences: string[]
  /** @format guid */
  createdBy: string
  createdOn: string
  /** @format guid */
  lastModifiedBy: string
  lastModifiedOn: string
}

export interface Evidence
  extends Omit<EvidenceResponse, 'createdOn' | 'lastModifiedOn'> {
  createdOn: Date
  lastModifiedOn: Date
}

export interface CreateEvidenceRequest {
  title: string
  type: EvidenceType
  description?: string
  complianceStatement?: string
  /** @format guid */
  method: string
  /** @format guid */
  program: string
  /** @format guid */
  cadences?: string[]
}

export interface EvidenceActivityResponse {
  /** @format guid */
  id: string
  type: EvidenceActivityType
  title: string
  memo: string
  status: EvidenceActivityStatus
  /** @format guid */
  assetId: string
  externalUrl: string
  jiraIssueId: string
  dueDate: string
  /** @format guid */
  createdBy: string
  createdOn: string
  /** @format guid */
  lastModifiedBy: string
  lastModifiedOn: string
}

export interface EvidenceActivity
  extends Omit<
    EvidenceActivityResponse,
    'dueDate' | 'createdOn' | 'lastModifiedOn'
  > {
  dueDate: Date
  createdOn: Date
  lastModifiedOn: Date
  recordId: string
}

export interface CreateOrUpdateEvidenceActivityRequest {
  type: EvidenceActivityType
  title: string
  memo?: string
  status: EvidenceActivityStatus
  /** @format guid */
  assetId?: string
  externalUrl?: string
  dueDate?: string
  jiraIssueId?: string
}

export const getEvidenceRecords: (
  ids?: string[],
) => Promise<Evidence[]> = async (ids) => {
  const idQueryString =
    ids && ids.length > 0 ? `?${ids.map((id) => `id=${id}`).join('&')}` : ''
  const res = await api.get(`${createUrl()}${idQueryString}`)
  return res.evidences.map(evidenceWithDates)
}

export const getEvidenceRecord: (
  evidenceId: string,
) => Promise<Evidence> = async (evidenceId) => {
  const res = await api.get(createUrl(evidenceId))
  return evidenceWithDates(res)
}

export const createEvidenceRecord: (
  evidence: CreateEvidenceRequest,
) => Promise<Evidence> = async (evidence) => {
  const res = await api.post(createUrl(), { body: evidence })
  return evidenceWithDates(res)
}

export const updateEvidenceRecord: (
  evidenceId: string,
  update: Partial<Evidence>,
) => Promise<Evidence> = async (evidenceId, update) => {
  const res = await api.patch(createUrl(evidenceId), { body: update })
  return evidenceWithDates(res)
}

export const deleteEvidenceRecord: (
  evidenceId: string,
) => Promise<{ id: string }> = (evidenceId) => api.delete(createUrl(evidenceId))

export const getEvidenceActivities: (
  evidenceId: string,
) => Promise<EvidenceActivity[]> = async (evidenceId) => {
  const res = await api.get(createUrl(evidenceId, true))
  return res.activities.map((activity: EvidenceActivityResponse) =>
    evidenceActivityWithDates({ ...activity, recordId: evidenceId }),
  )
}

export const getEvidenceActivity: (
  evidenceId: string,
  activityId: string,
) => Promise<EvidenceActivity> = async (evidenceId, activityId) => {
  const res = await api.get(createUrl(evidenceId, true, activityId))
  return evidenceActivityWithDates({ ...res, recordId: evidenceId })
}

export const createEvidenceActivity: (
  evidenceId: string,
  activity: CreateOrUpdateEvidenceActivityRequest,
) => Promise<EvidenceActivity> = async (evidenceId, activity) => {
  const res = await api.post(createUrl(evidenceId, true), { body: activity })
  return evidenceActivityWithDates(res)
}

export const updateEvidenceActivity: (
  evidenceId: string,
  activityId: string,
  update: CreateOrUpdateEvidenceActivityRequest,
) => Promise<EvidenceActivity> = async (evidenceId, activityId, update) => {
  const res = await api.patch(createUrl(evidenceId, true, activityId), {
    body: update,
  })
  return evidenceActivityWithDates(res)
}

export const deleteEvidenceActivity: (
  evidenceId: string,
  activityId: string,
) => Promise<{ id: string }> = (evidenceId, activityId) =>
  api.delete(createUrl(evidenceId, true, activityId))

export const getEvidenceActivityOwners: (
  evidenceId: string,
  activityId: string,
) => Promise<string[]> = async (evidenceId, activityId) => {
  const res = await api.get(createUrl(evidenceId, true, activityId, true))
  return res.owners
}

export const updateEvidenceActivityOwner: (
  evidenceId: string,
  activityId: string,
  ownerId: string,
) => Promise<void> = (evidenceId, activityId, ownerId) =>
  api.put(createUrl(evidenceId, true, activityId, true, ownerId))

export const deleteEvidenceActivityOwner: (
  evidenceId: string,
  activityId: string,
  ownerId: string,
) => Promise<void> = (evidenceId, activityId, ownerId) =>
  api.delete(createUrl(evidenceId, true, activityId, true, ownerId))

export interface CreateEvidenceComplianceReportRequest {
  /** @format guid */
  evidenceId: string
  name: string
  metadata: {
    columnsToDisplay: ReportColumnId[]
  }
}

export const createEvidenceComplianceReport: (
  req: CreateEvidenceComplianceReportRequest,
  evidenceId: string,
) => Promise<{ id: string }> = (req, evidenceId) =>
  api.post(`${createUrl(evidenceId)}/requirements/reports`, {
    body: req,
  })
