import { CloseFilled, OverflowMenuVertical } from '@carbon/icons-react'
import { useCallback, useEffect, useState } from 'react'
import styles from './MembersSection.module.css'
import * as teamsApi from '../../api/v2/teams'
import { getMultipleUserTeams, Team, UserTeams } from '../../api/v2/teams.ts'
import { getAllUsers, User } from '../../api/v2/users.ts'
import Avatar from '../../components/avatar'
import Dropdown from '../../components/dropdown'
import DropdownSelection, {
  OptionType,
} from '../../components/dropdown/DropdownSelection.tsx'
import { toastError, toastSuccess } from '../../components/toast'
import { useAuth } from '../../context/AuthContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { useModals } from '../../hooks/useModals.ts'
import { getUserRole, isAdminCheck } from '../../lib/auth.ts'
import { monthDayYear } from '../../lib/date.ts'
import { sortByFirstThenLastName } from '../../lib/user.ts'
import { UserRole } from '../../types/enums.ts'

interface MemberWithTeamsAndRole extends UserTeams {
  role: UserRole
  teams: Team[]
}

interface MemberRoles {
  [memberId: string]: MemberWithTeamsAndRole
}

const formatOption = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

const MEMBER_ROLE_OPTIONS = [
  {
    id: UserRole.MEMBER,
    label: formatOption(UserRole.MEMBER),
  },
  {
    id: UserRole.ADMIN,
    label: formatOption(UserRole.ADMIN),
  },
  {
    id: UserRole.ROOT,
    label: formatOption(UserRole.ROOT),
    disabled: true,
  },
]

const AdminActionsDropdown = (props) => {
  const { member, onRemoveUser } = props

  const { openRemoveStellUserModal } = useModals()
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useClickOutside(() => {
    setIsOpen(false)
  })

  return (
    <div ref={containerRef} className={styles.dropdownBtn}>
      <button
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <OverflowMenuVertical size={24} />
      </button>
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        <button
          className={styles.removeMember}
          onClick={(e) => {
            e.preventDefault()
            setIsOpen(false)
            openRemoveStellUserModal(member, onRemoveUser)
          }}
        >
          <CloseFilled className={styles.icon} /> Remove From Stell
        </button>
      </Dropdown>
    </div>
  )
}

const MembersSection = () => {
  const { isAdmin, userDetails } = useAuth()
  const [members, setMembers] = useState<User[]>([])
  const [memberRoles, setMemberRoles] = useState<MemberRoles>({})
  const currentUserId = userDetails?.id

  const onRemoveUser = (userId: string) => {
    setMembers(
      members.filter((member) => {
        return member.id !== userId
      }),
    )
  }

  const fetchUsers = async () => {
    try {
      const members = (await getAllUsers())?.users || []
      setMembers(members.sort(sortByFirstThenLastName))
    } catch (e) {
      console.error('Failed to fetch members', e)
    }
  }

  const onRoleChange = useCallback(
    async (option: OptionType, memberId: string, memberRole: UserRole) => {
      const selectedUserRole = option.id as UserRole

      // Ignore if no role change is taking place
      if (selectedUserRole === memberRole) {
        return
      }

      // Get teamId from any admin user in members, since user may not have any teamIds on them if they are a member
      const allAdminTeams = Object.values(memberRoles)
        .map((entry) => entry.teams)
        .flat()
        .filter((teams) => teams.name === UserRole.ADMIN)

      const teamId = allAdminTeams[0].id

      const updateMemberRole = async () => {
        try {
          // If changing to admin, add them to admin team
          if (isAdminCheck({ userRole: selectedUserRole })) {
            await teamsApi.addUserToTeam(teamId, memberId)
            toastSuccess('Successfully updated user role')
            return true
          }

          // If changing to member, remove from admin team
          if (option.id === UserRole.MEMBER) {
            await teamsApi.removeUserFromTeam(teamId, memberId)
            toastSuccess('Successfully updated user role')
            return true
          }
        } catch (e) {
          toastError('Failed to update user', 'Please refresh and try again')
        }
      }

      const memberWasUpdated = await updateMemberRole()

      if (memberWasUpdated) {
        fetchUsers()
      }
    },
    [memberRoles],
  )

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    const fetchMemberRoles = async () => {
      let newMemberRoles = {}

      const resps = await getMultipleUserTeams(
        members.map((member) => member.id),
      )

      resps.map((resp) => {
        newMemberRoles = {
          ...newMemberRoles,
          [resp.userId]: {
            role: getUserRole(resp.teams),
            teams: resp.teams,
          },
        }
      })

      setMemberRoles(newMemberRoles)
    }

    fetchMemberRoles()
  }, [members])

  return (
    <div className={styles.members}>
      <div className={styles.description}>
        This list includes all members in your organization. For questions about
        accounts please reach out to
        <span className={styles.green}> admin@stell-engineering.com.</span>
      </div>
      <table>
        <tbody>
          {members.map((member) => {
            const memberId = member.id
            const memberRole = memberRoles?.[memberId]?.role

            // Root user cannot be changed,
            // Must be admin to change role,
            // Users cannot update their own role
            const disabledAdminAction =
              memberRole === UserRole.ROOT ||
              !isAdmin ||
              currentUserId === memberId

            return (
              <tr key={memberId}>
                <td>
                  {!disabledAdminAction && (
                    <div className={styles.edit}>
                      <AdminActionsDropdown
                        member={member}
                        onRemoveUser={onRemoveUser}
                      />
                    </div>
                  )}
                </td>
                <td>
                  <div className={styles.avatar}>
                    <Avatar
                      firstName={member.firstName}
                      lastName={member.lastName}
                    />
                  </div>
                </td>
                <td>
                  <div
                    className={styles.name}
                  >{`${member.firstName} ${member.lastName}`}</div>
                </td>
                <td>
                  <div className={styles.title}>{member.jobTitle}</div>
                </td>
                <td>
                  <div className={styles.email}>{member.email}</div>
                </td>
                <td>
                  <div className={styles.dateJoined}>
                    <span>Date Joined: </span>
                    {monthDayYear(member.dateJoined)}
                  </div>
                </td>
                <td>
                  <div>
                    <DropdownSelection
                      initialSelection={MEMBER_ROLE_OPTIONS.find(
                        (option) => option.id === memberRole,
                      )}
                      options={disabledAdminAction ? [] : MEMBER_ROLE_OPTIONS}
                      onSelect={(option) =>
                        onRoleChange(option, memberId, memberRole)
                      }
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default MembersSection
