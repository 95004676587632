import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import * as blockApi from '../../../api/v2/blocks'
import * as requirementApi from '../../../api/v2/requirements'
import { useSpecificationContext } from '../../../context/SpecificationContext'

export interface VisibleBlock {
  block?: blockApi.Block<blockApi.BlockData>
  requirement?: requirementApi.Requirement
}

export interface VisibleBlockData {
  visibleBlocks: Record<string, VisibleBlock>
  setVisibleBlocks: Dispatch<SetStateAction<Record<string, VisibleBlock>>>
  onTopReached: () => void
  onBottomReached: () => void
  renderStart: number
  renderUntil: number
}

const SHOW_COUNT = 180

const getInitialWindow: (index: number) => [number, number] = (index) => {
  let start = 0
  let until = SHOW_COUNT

  while (index > until) {
    until = until + SHOW_COUNT
  }
  start = Math.max(until - SHOW_COUNT, 0)
  return [start, until]
}

const useVisibleBlocks: (
  blockIds: string[],
  scrollToBlockId: string | null,
  specificationId: string,
  revisionId: string,
  documentId?: string,
) => VisibleBlockData = (
  blockIds,
  scrollToBlockId,
  specificationId,
  revisionId,
  documentId,
) => {
  const { publicTenant } = useSpecificationContext()
  const [[renderStart, renderUntil], setWindow] = useState([0, SHOW_COUNT])

  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (blockIds.length > 0 && !initialized) {
      setInitialized(true)
      setWindow(getInitialWindow(blockIds.indexOf(scrollToBlockId!)))
    }
  }, [blockIds, initialized, scrollToBlockId])

  const [visibleBlocks, setVisibleBlocks] = useState<
    Record<string, VisibleBlock>
  >({})
  useEffect(() => {
    const { getFilteredBlocks, getRequirements } = publicTenant
      ? {
          ...blockApi.publicTenantMethods,
          ...requirementApi.publicTenantMethods,
        }
      : { ...blockApi, ...requirementApi }

    const fetchVisibleBlocks = async () => {
      if (documentId && blockIds.length > 0) {
        const { blocks } = await getFilteredBlocks(
          specificationId,
          documentId,
          {
            id: blockIds.filter(
              (_id, index) => index >= renderStart && index < renderUntil,
            ),
          },
        )
        const requirementIds = blocks
          .filter((b) => b.type === blockApi.BlockType.Requirement)
          .map((b) => b.id)

        const requirements = await getRequirements(specificationId, {
          ids: requirementIds,
          revisionIds: [revisionId],
        })

        const data = blocks.reduce(
          (record, block) => ({
            ...record,
            [block.id]: {
              block,
              requirement: requirements.find((r) => r.id === block.id),
            },
          }),
          {},
        )
        setVisibleBlocks(data)
      }
    }
    fetchVisibleBlocks()
  }, [
    blockIds,
    revisionId,
    documentId,
    renderStart,
    renderUntil,
    specificationId,
    publicTenant,
  ])

  const onTopReached = useCallback(() => {
    if (renderStart === 0) {
      return
    }
    const top = Math.max(0, renderStart - SHOW_COUNT / 3)
    const bottom = top + SHOW_COUNT
    setWindow([top, bottom])
  }, [renderStart])

  const onBottomReached = useCallback(() => {
    if (renderUntil >= blockIds.length) {
      return
    }
    const bottom = renderUntil + SHOW_COUNT / 3
    const top = bottom - SHOW_COUNT
    setWindow([top, bottom])
  }, [renderUntil, blockIds])

  return {
    visibleBlocks,
    setVisibleBlocks,
    onTopReached,
    onBottomReached,
    renderStart,
    renderUntil,
  }
}

export default useVisibleBlocks
