import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import BlockActions from './block-actions/BlockActions.tsx'
import styles from './BlockEditor.module.css'

import HeadingBlock from './blocks/HeadingBlock.tsx'
import ImageBlock from './blocks/ImageBlock.tsx'
import RequirementBlock from './blocks/RequirementBlock.tsx'
import TableBlock from './blocks/table/TableBlock.tsx'
import TextBlock from './blocks/TextBlock.tsx'
import {
  BLOCK_IS_SELECTED_CLASSNAME,
  DATA_VALUE_BLOCK_ID,
  DATA_VALUE_REQUIREMENT_VERSION,
} from './SelectableBlock.tsx'
import { Block, BlockData } from '../../api/v2/blocks.ts'
import { BlockType } from '../../api/v2/blocks.ts'
import { Requirement } from '../../api/v2/requirements.ts'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import { useDocumentContext } from '../../context/DocumentContext.tsx'
import { useSectionContext } from '../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext.tsx'
import globalStyles from '../../global.module.css'
import useScrollObserver, { EDGE } from '../../hooks/useScrollObserver.ts'

import { RequirementStatus } from '../../types/enums.ts'

export const ALL_BLOCKS = {
  [BlockType.Text]: TextBlock,
  [BlockType.Image]: ImageBlock,
  [BlockType.Requirement]: RequirementBlock,
  [BlockType.Table]: TableBlock,
  [BlockType.Heading]: HeadingBlock,
}

interface BlockProps {
  // RequirementBlock props
  isCommentsHidden?: boolean
  commentCount?: number
  requirement?: Requirement
  updateRequirement?: (update: Partial<Requirement>) => void
}

export const BlockToElement = (props: {
  block: Block<BlockData>
  blockProps: BlockProps
}) => {
  const { block, blockProps } = props

  if (!block) {
    return null
  }

  const BlockComponent = ALL_BLOCKS[block.type]
  // @ts-expect-error - Typescript is being smart and is correctly unhappy that we can't formally define the props as one of the block props. Ignoring, but can also be resolved by making BlockProps more declarative.
  return <BlockComponent block={block} {...blockProps} />
}

const BlockWithActions = (props: {
  block: Block<BlockData>
  blockProps: BlockProps
  autoScroll: boolean
  disableRequirementComments: boolean
}) => {
  const { block, blockProps, autoScroll } = props
  const { publicTenant } = useSpecificationContext()
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const commentCount = blockProps.requirement?.commentAndChangeRequestCount || 0

  const isActionsVisible = !publicTenant && (isFocused || isHovered)

  const scrollRef = useRef<HTMLDivElement>(null)
  const hasScrolled = useRef(false)

  useEffect(() => {
    if (!hasScrolled.current && block && autoScroll && scrollRef?.current) {
      hasScrolled.current = true
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({ block: 'center' })
      }, 0)
    }
  }, [block, autoScroll])

  const expandedBlockProps = useMemo(() => {
    return {
      ...blockProps,
      isActionsVisible,
      commentCount,
      isHovered,
    }
  }, [blockProps, commentCount, isActionsVisible, isHovered])

  return (
    <div
      className={`${styles.blockWrapper} ${
        autoScroll ? globalStyles.scrollHighlight : ''
      }`}
      ref={scrollRef}
      onFocus={() =>
        setIsFocused(!!scrollRef.current?.contains(document.activeElement))
      }
      onBlur={() => {
        setIsFocused(!!scrollRef.current?.contains(document.activeElement))
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {block && (
        <>
          <BlockActions
            block={block}
            isHidden={!isActionsVisible}
            {...expandedBlockProps}
          >
            <BlockToElement block={block} blockProps={expandedBlockProps} />
          </BlockActions>
        </>
      )}
    </div>
  )
}

const BlockEditor = (props: { className?: string }) => {
  const { className } = props
  const { revision, publicTenant } = useSpecificationContext()
  const { scrollToBlockId } = useDocumentContext()
  const {
    blockIds,
    deleteBlocks,
    visibleBlocks,
    renderStart,
    renderUntil,
    onTopReached,
    onBottomReached,
  } = useSectionContext()

  const topRef = useScrollObserver(onTopReached, EDGE.Top)
  const bottomRef = useScrollObserver(onBottomReached, EDGE.Bottom)

  const disableRequirementComments = !!publicTenant

  const onDelete: (event: KeyboardEvent) => Promise<void> = useCallback(
    async (event) => {
      if (event.key === 'Backspace') {
        const selectedBlocks = Array.from(
          document.getElementsByClassName(BLOCK_IS_SELECTED_CLASSNAME),
        ).map((blockEle) => ({
          id: blockEle.getAttribute(DATA_VALUE_BLOCK_ID),
          requirementVersion: blockEle.getAttribute(
            DATA_VALUE_REQUIREMENT_VERSION,
          ),
        }))

        if (selectedBlocks.every(({ id }) => blockIds.includes(id!))) {
          await deleteBlocks(selectedBlocks)
        }
      }
    },
    [blockIds, deleteBlocks],
  )

  useEffect(() => {
    document.addEventListener('keydown', onDelete, false)

    return () => {
      document.removeEventListener('keydown', onDelete, false)
    }
  }, [onDelete])

  return (
    <div className={`${styles.blockEditor} ${className ?? ''}`}>
      <div className={styles.section}>
        {blockIds.map((blockId: string, index: number) => {
          const { requirement, block } = visibleBlocks[blockId] ?? {}
          const hidden =
            revision.status === RevisionStatus.ACTIVE &&
            requirement &&
            requirement.status === RequirementStatus.Archived

          const getElement = () => {
            if (hidden) {
              return null
            }
            if (!block) {
              return <div style={{ width: '100%', height: 40 }} />
            }
            return (
              <BlockWithActions
                key={blockId}
                autoScroll={scrollToBlockId === blockId}
                block={block}
                blockProps={{ requirement }}
                disableRequirementComments={disableRequirementComments}
              />
            )
          }

          return (
            <Fragment key={blockId}>
              {index === renderStart + 20 && (
                <div ref={topRef} className={styles.scrollDetect} />
              )}
              {getElement()}
              {index === renderUntil - 20 && (
                <div ref={bottomRef} className={styles.scrollDetect} />
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default BlockEditor
