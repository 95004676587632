import { CaretRight } from '@carbon/icons-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SharedProjectsCard.module.css'
import { SharedWorkspace } from '../../api/v2/sharedWorkspaces.ts'
import Tag, { stylesToTagColor } from '../../components/tag'
import { useAuth } from '../../context/AuthContext.tsx'
import { monthDayYear } from '../../lib/date.ts'

interface ContactCardProps {
  workspace: SharedWorkspace
}

const SharedProjectsCard = (props: ContactCardProps) => {
  const { workspace } = props
  const { userTenant } = useAuth()
  const navigate = useNavigate()

  const [expanded, setExpanded] = useState(false)

  return (
    <div className={`${styles.contactCard} ${styles.basicElevation}`}>
      <div className={styles.row}>
        <div>
          <button
            className={styles.row}
            onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
          >
            <span className={styles.names}>
              {workspace.contact.name} · {userTenant?.name}
            </span>
            <CaretRight
              className={`${styles.caret} ${expanded ? styles.expanded : ''}`}
              size={24}
            />
          </button>
          <div className={styles.date}>
            Connected on {monthDayYear(workspace.contact.connectedOn)}
          </div>
        </div>
      </div>
      <div
        className={`${styles.expandableProjects} ${
          expanded ? styles.expanded : ''
        }`}
      >
        <div>
          <hr className={styles.divider} />
          <div className={styles.projects}>
            <div>Projects</div>
            {workspace.projects.map((project) => (
              <Tag
                key={project.id}
                text={project.name}
                color={stylesToTagColor(project.metadata.STYLES)}
                onClick={() =>
                  navigate(`/workspaces/${workspace.id}/projects/${project.id}`)
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharedProjectsCard
