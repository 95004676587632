import { Close } from '@carbon/icons-react'
import { ReactNode } from 'react'
import styles from './index.module.css'
import {
  AttributeValueMetadataStylesResponse,
  AttributeValueResponse,
} from '../../api/v2/attributes'
import { getCssVar } from '../../lib/string.ts'
import { EvidenceType } from '../../types/enums'

export type CustomColorSet = {
  fontColor: string
  backgroundColor: string
  hoverBackgroundColor?: string
  border?: string
}

interface TagType {
  text?: string
  color?: CustomColorSet
  className?: string
  onCancel?: () => void
  uppercase?: boolean
  pipColor?: string
  onClick?: () => void
  children?: ReactNode
}

export const stylesToTagColor: (
  style?: AttributeValueMetadataStylesResponse,
) => CustomColorSet = (style) =>
  style
    ? {
        fontColor: style.COLOR_FONT,
        backgroundColor: style.COLOR_BG,
        hoverBackgroundColor: style.COLOR_BG_HOVER,
      }
    : TAG_COLORS.gray3

export const getCustomColorSetFromAttributeValue = (
  attributeValue: AttributeValueResponse,
): CustomColorSet => {
  return stylesToTagColor(attributeValue?.metadata.STYLES)
}

const Tag = (props: TagType) => {
  const {
    className,
    color: colorProp,
    text,
    onCancel,
    uppercase = true,
    pipColor,
    children,
    onClick,
  } = props
  const color = colorProp || TAG_COLORS.primary

  return (
    // TODO - STE-1411 - chore-better-tag-onclick-handling
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`${styles.tag}  ${uppercase ? styles.uppercase : ''} ${
        className || ''
      } ${onClick ? styles.clickable : ''}`}
      style={{
        color: color.fontColor,
        backgroundColor: color.backgroundColor,
        border: color.border,
        // @ts-expect-error: Dynamically sets background color on hover by setting css var
        '--hover-background-color':
          color.hoverBackgroundColor || color.backgroundColor,
      }}
      onClick={onClick}
    >
      {pipColor && (
        <span
          className={styles.pip}
          style={{ backgroundColor: pipColor }}
        ></span>
      )}
      <span>{text}</span>
      {children}
      {onCancel && (
        <button className={styles.closeBtn} onClick={() => onCancel()}>
          <Close />
        </button>
      )}
    </div>
  )
}

export const TAG_COLORS: { [key: string]: CustomColorSet } = {
  white2: {
    backgroundColor: getCssVar('--color-white2'),
    fontColor: getCssVar('---text-color-gray-dark'),
    hoverBackgroundColor: getCssVar('--color-white'),
  },
  gray3: {
    backgroundColor: getCssVar('--ui-color-gray-mid'),
    fontColor: '#161616',
    hoverBackgroundColor: '#d1d1d1',
  },
  gray3NoHover: {
    backgroundColor: getCssVar('--ui-color-gray-mid'),
    fontColor: '#161616',
  },
  orange: {
    backgroundColor: getCssVar('--color-orange'),
    fontColor: getCssVar('--color-orange3'),
    hoverBackgroundColor: '#ffcdb8',
  },
  orange2: {
    backgroundColor: getCssVar('--color-orange2'),
    fontColor: '#b49703',
    hoverBackgroundColor: '#ffebb8',
  },
  yellow: {
    backgroundColor: getCssVar('--color-yellow'),
    fontColor: '#8fa409',
    hoverBackgroundColor: '#f5ffb8',
  },
  green: {
    backgroundColor: getCssVar('--color-green'),
    fontColor: '#29ce00',
    hoverBackgroundColor: '#d7ffb8',
  },
  greenNoHover: {
    backgroundColor: getCssVar('--color-green'),
    fontColor: '#29ce00',
  },
  green2: {
    backgroundColor: getCssVar('--color-green2'),
    fontColor: '#00ce6b',
    hoverBackgroundColor: '#b8ffbf',
  },
  green3: {
    backgroundColor: getCssVar('--color-green3'),
    fontColor: '#0baa8d',
    hoverBackgroundColor: '#b8ffe5',
  },
  green3NoHover: {
    backgroundColor: getCssVar('--color-green3'),
    fontColor: '#0baa8d',
  },
  green5: {
    backgroundColor: getCssVar('--color-green5'),
    fontColor: '#0baa8d',
    hoverBackgroundColor: getCssVar('--color-green6'),
  },

  green12: {
    backgroundColor: getCssVar('--color-green12'),
    fontColor: getCssVar('--color-green13'),
    hoverBackgroundColor: '#74e792',
  },
  green12NoHover: {
    backgroundColor: getCssVar('--color-green12'),
    fontColor: getCssVar('--color-green13'),
  },

  blue: {
    backgroundColor: getCssVar('--color-blue'),
    fontColor: '#00c2ce',
    hoverBackgroundColor: '#b8ffff',
  },

  blue2: {
    backgroundColor: getCssVar('--color-blue2'),
    fontColor: '#0090ce',
    hoverBackgroundColor: '#b8eaff',
  },
  blue3: {
    backgroundColor: getCssVar('--color-blue3'),
    fontColor: '#0043ce',
    hoverBackgroundColor: '#b8d3ff',
  },
  blue3NoHover: {
    backgroundColor: getCssVar('--color-blue3'),
    fontColor: '#0043ce',
  },

  purple: {
    backgroundColor: getCssVar('--color-purple'),
    fontColor: '#4e00ce',
    hoverBackgroundColor: '#c6b8ff',
  },

  purple2: {
    backgroundColor: getCssVar('--color-purple2'),
    fontColor: '#8c00ce',
    hoverBackgroundColor: '#e8b8ff',
  },
  purple2NoHover: {
    backgroundColor: getCssVar('--color-purple2'),
    fontColor: '#8c00ce',
  },
  purple3: {
    backgroundColor: getCssVar('--color-purple3'),
    fontColor: '#ca00ce',
    hoverBackgroundColor: '#f9b8ff',
  },
  purple3NoHover: {
    backgroundColor: getCssVar('--color-purple3'),
    fontColor: '#ca00ce',
  },
  red: {
    backgroundColor: '#ffd0ec',
    fontColor: '#ce0094',
    hoverBackgroundColor: '#ffb8de',
  },
  red2: {
    backgroundColor: '#ffd0d6',
    fontColor: '#ce0057',
    hoverBackgroundColor: '#ffb8c9',
  },
  red2NoHover: {
    backgroundColor: '#ffd0d6',
    fontColor: '#ce0057',
  },
  primary: {
    fontColor: getCssVar('--text-color-gray-dark'),
    backgroundColor: getCssVar('--color-green11'),
  },
  secondary: {
    fontColor: getCssVar('--text-color-gray-dark'),
    backgroundColor: getCssVar('--color-white2'),
  },
}

export const EVIDENCE_TYPE_COLORS = {
  [EvidenceType.Validation]: TAG_COLORS.blue3NoHover,
  [EvidenceType.Verification]: TAG_COLORS.red2NoHover,
  [EvidenceType.Unknown]: TAG_COLORS.gray3NoHover,
}

export const TAG_COLORS_LEVELS = [
  TAG_COLORS.green,
  TAG_COLORS.green2,
  TAG_COLORS.green3,
  TAG_COLORS.blue2,
  TAG_COLORS.blue3,
  TAG_COLORS.purple2,
  TAG_COLORS.purple3,
  TAG_COLORS.red,
  TAG_COLORS.orange,
  TAG_COLORS.orange2,
]

const TAG_COLORS_RANDOM = [
  TAG_COLORS.orange,
  TAG_COLORS.orange2,
  TAG_COLORS.yellow,
  TAG_COLORS.green,
  TAG_COLORS.green2,
  TAG_COLORS.green3,
  TAG_COLORS.green5,
  TAG_COLORS.green12,
  TAG_COLORS.blue,
  TAG_COLORS.blue2,
  TAG_COLORS.blue3,
  TAG_COLORS.purple,
  TAG_COLORS.purple2,
  TAG_COLORS.purple3,
  TAG_COLORS.red,
  TAG_COLORS.red2,
]

export const getRandomTagColor = (): CustomColorSet => {
  const keys = Object.keys(TAG_COLORS_RANDOM)
  return TAG_COLORS_RANDOM[keys[(keys.length * Math.random()) << 0]]
}

export default Tag
