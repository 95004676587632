import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon'
import { RefObject, ReactElement } from 'react'
import styles from './IconButton.module.css'

interface IconButtonProps
  extends Omit<React.ComponentProps<'button'>, 'children'> {
  innerRef?: ((element: HTMLElement | null) => void) | RefObject<any>
  children: ReactElement<CarbonIconType>
}

const IconButton = (props: IconButtonProps) => {
  const { children, className, innerRef, ...otherProps } = props

  return (
    <button
      className={`${styles.button} ${className ? className : ''}`}
      ref={innerRef}
      {...otherProps}
    >
      {children}
    </button>
  )
}

export default IconButton
