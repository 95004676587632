import { useEffect, useState } from 'react'
import * as assetApi from '../../../api/v2/assets'
import LoadingIndicator, {
  LoaderSize,
} from '../../../components/loading-indicator/LoadingIndicator'

const SpecificationPdfViewer = (props: {
  specificationId: string
  assetId: string
}) => {
  const { specificationId, assetId } = props

  const [pdfFile, setPdfFile] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const asset = await assetApi.getSpecificationAsset(
          specificationId,
          assetId,
        )
        const pdfFileUrl = URL.createObjectURL(asset)

        setPdfFile(pdfFileUrl)
        setLoading(false)
        return () => URL.revokeObjectURL(pdfFileUrl)
      } catch (error) {
        console.error('Error fetching PDF:', error)
        setLoading(false)
      }
    }

    fetchAsset()
  }, [specificationId, assetId])

  return (
    <div style={{ height: '100vh' }}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '40px',
          }}
        >
          <LoadingIndicator size={LoaderSize.LARGE} />
        </div>
      ) : (
        <iframe
          title="View Original PDF"
          src={pdfFile}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </div>
  )
}

export default SpecificationPdfViewer
