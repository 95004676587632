export enum UserRole {
  ROOT = 'ROOT',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum EntityType {
  Specification = 'SPECIFICATION',
  Requirement = 'REQUIREMENT',
  Evidence = 'EVIDENCE',
  Exception = 'EXCEPTION',
}

export enum EntityRole {
  OWNER = 'OWNER',
  EDITOR = 'EDITOR',
  COMMENTER = 'COMMENTER',
}

export enum RequirementStatus {
  Draft = 'DRAFT',
  Review = 'REVIEW',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum AttributeValueStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  None = 'NONE',
}

export enum AttributeName {
  SpecificationCategory = 'CATEGORY',
  EvidenceMethod = 'METHOD',
  RequirementCompliance = 'COMPLIANCE',
  RequirementType = 'TYPE',
  SpecificationProgram = 'PROGRAM',
}

export enum ReportSource {
  SearchRequirementV1 = 'SEARCH/REQUIREMENTS:1',
  SpecificationRequirementV1 = 'SPECIFICATION/REQUIREMENTS:1',
  EvidenceRequirementV1 = 'EVIDENCE/REQUIREMENTS:1',
}

export enum ReportColumnId {
  RequirementIdentifier = 'REQUIREMENT_IDENTIFIER',
  RequirementSectionNumber = 'REQUIREMENT_SECTION_NUMBER',
  RequirementName = 'REQUIREMENT_NAME',
  RequirementShallStatement = 'REQUIREMENT_SHALL_STATEMENT',
  RequirementCompliance = 'REQUIREMENT_COMPLIANCE',
  RequirementComplianceNotes = 'REQUIREMENT_COMPLIANCE_NOTES',
  RequirementRationale = 'REQUIREMENT_RATIONALE',
  RequirementStatus = 'REQUIREMENT_STATUS',
  RequirementType = 'REQUIREMENT_TYPE',
  SpecificationName = 'SPECIFICATION_NAME',
  SpecificationIdentifier = 'SPECIFICATION_IDENTIFIER',
  SpecificationRevision = 'SPECIFICATION_REVISION',
  SpecificationProgramName = 'SPECIFICATION_PROGRAM_NAME',
  ParentRequirementName = 'PARENT_REQUIREMENT_NAME',
  ParentRequirementShallStatement = 'PARENT_REQUIREMENT_SHALL_STATEMENT',
  ChildRequirementName = 'CHILD_REQUIREMENT_NAME',
  ChildRequirementShallStatement = 'CHILD_REQUIREMENT_SHALL_STATEMENT',
  EvidenceTypeValidation = 'EVIDENCE_TYPE_VALIDATION',
  ValidationRecordTitle = 'EVIDENCE_VALIDATION_RECORD_TITLE',
  ValidationDescriptionOfActivity = 'EVIDENCE_VALIDATION_DESC_OF_ACTIVITY',
  EvidenceTypeVerification = 'EVIDENCE_TYPE_VERIFICATION',
  VerificationRecordTitle = 'EVIDENCE_VERIFICATION_RECORD_TITLE',
  VerificationDescriptionOfActivity = 'EVIDENCE_VERIFICATION_DESC_OF_ACTIVITY',
}

export enum EvidenceType {
  Unknown = 'UNKNOWN',
  Validation = 'VALIDATION',
  Verification = 'VERIFICATION',
}

export enum LinkedRequirementType {
  Parent = 'parent',
  Child = 'child',
}

export enum EvidenceStatus {
  Passed = 'PASSED',
  InReview = 'IN_REVIEW',
  Planned = 'PLANNED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  FailedWithComments = 'FAILED_WITH_COMMENTS',
  PassedWithComments = 'PASSED_WITH_COMMENTS',
}

export enum EvidenceCadence {
  Production = 'PRODUCTION',
  Qualification = 'QUALIFICATION',
}

export enum ChangeRequestStatus {
  Requested = 'REQUESTED',
  InReview = 'IN_REVIEW',
  Implemented = 'IMPLEMENTED',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
}

export enum EvidenceActivityType {
  Meeting = 'MEETING',
  Document = 'DOCUMENT',
  Review = 'REVIEW',
  Test = 'TEST',
}

export enum EvidenceActivityStatus {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Canceled = 'CANCELLED',
}

export const EVIDENCE_ACTIVITY_STATUS_TO_NAME: Record<
  EvidenceActivityStatus,
  string
> = {
  [EvidenceActivityStatus.Todo]: 'TODO',
  [EvidenceActivityStatus.InProgress]: 'IN PROGRESS',
  [EvidenceActivityStatus.Complete]: 'COMPLETE',
  [EvidenceActivityStatus.Canceled]: 'CANCELED',
}

export enum LoadingState {
  Loading,
  Failed,
  Loaded,
  Start,
}

export enum SpecificationSnapshotReviewStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Complete = 'COMPLETE',
}

export const SNAPSHOT_REVIEW_STATUS_TO_NAME: Record<
  SpecificationSnapshotReviewStatus,
  string
> = {
  [SpecificationSnapshotReviewStatus.Draft]: 'IN PROGRESS',
  [SpecificationSnapshotReviewStatus.Submitted]: 'OUTSTANDING',
  [SpecificationSnapshotReviewStatus.Complete]: 'COMPLETE',
}

export enum PermissionEntityType {
  Specifications = 'specifications',
  SpecificationSnapshots = 'specificationSnapshots',
}

export enum ExceptionType {
  Waiver = 'WAIVER',
  Deviation = 'DEVIATION',
}

export enum ExceptionStatus {
  Requested = 'REQUESTED',
  InReview = 'IN_REVIEW',
  Accepted = 'ACCEPTED',
}

export const EXCEPTION_STATUS_TO_NAME: Record<ExceptionStatus, string> = {
  [ExceptionStatus.Requested]: 'Requested',
  [ExceptionStatus.InReview]: 'In Review',
  [ExceptionStatus.Accepted]: 'Accepted',
}
