import { Suspense, useMemo } from 'react'
import styles from './index.module.css'
import {
  MODAL_ID_GENERATE_REPORT,
  MODAL_ID_CONFIRMATION,
  MODAL_ID_DUPLICATE_SPECIFICATION,
  MODAL_ID_IMPORT_PDF,
  MODAL_ID_IMPORT_EXCEL,
  MODAL_ID_REMOVE_STELL_USER,
  MODAL_ID_SEARCH_REQUIREMENT,
  MODAL_ID_REQUIREMENT_DRAWER,
  MODAL_ID_SPECIFICATION_HISTORY,
  MODAL_ID_SPECIFICATION_REVIEWERS,
  MODAL_ID_CREATE_EVIDENCE,
  MODAL_ID_LINK_EVIDENCE,
  MODAL_ID_LINKED_REQUIREMENT,
  MODAL_ID_ADD_LINKED_REQUIREMENT,
  MODAL_ID_ADD_LINKED_REQUIREMENT_EXCEPTION,
  MODAL_ID_LINKED_EVIDENCE,
  MODAL_ID_CHANGE_REQUEST_DRAWER,
  MODEL_ID_EVIDENCE_RECORD,
  MODEL_ID_EVIDENCE_ACTIVITY_DRAWER,
  MODAL_ID_SHARE_SPECIFICATION,
  MODAL_ID_SPECIFICATION_PDF_VIEWER,
  MODAL_ID_SHARE_REVIEW,
  MODAL_ID_NEW_SPECIFICATION,
  MODAL_ID_IMPORT_WORD,
  MODAL_ID_SPECIFICATION_TREE_BUILDER,
  MODAL_ID_KEYBOARD_SHORTCUTS,
} from '../../context/GlobalModalContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { useModals } from '../../hooks/useModals'
import LoadingIndicator from '../loading-indicator/LoadingIndicator.tsx'
import {
  ImportPDFModal,
  ImportExcelModal,
  ImportWordModal,
  RequirementDrawer,
  SearchRequirementDrawer,
  ChangeRequestDrawer,
  GenerateReportModal,
  DuplicateSpecificationModal,
  ConfirmationModal,
  RemoveStellUserModal,
  SpecificationHistoryModal,
  SpecificationReviewersModal,
  CreateEvidenceModal,
  LinkEvidenceModal,
  AddLinkedRequirementModal,
  LinkedRequirementViewModal,
  LinkedEvidenceViewModal,
  EvidenceRecordDrawer,
  EvidenceActivityDrawer,
  ShareSpecificationModal,
  SpecificationPdfViewerModal,
  ShareReviewModal,
  NewSpecificationModal,
  SpecificationTreeBuilderModal,
  KeyboardShortcutsModal,
  AddLinkedRequirementExceptionModal,
} from '../modal'

const MODALS = {
  [MODAL_ID_IMPORT_PDF]: ImportPDFModal,
  [MODAL_ID_IMPORT_EXCEL]: ImportExcelModal,
  [MODAL_ID_IMPORT_WORD]: ImportWordModal,
  [MODAL_ID_REQUIREMENT_DRAWER]: RequirementDrawer,
  [MODAL_ID_SEARCH_REQUIREMENT]: SearchRequirementDrawer,
  [MODAL_ID_CHANGE_REQUEST_DRAWER]: ChangeRequestDrawer,
  [MODAL_ID_GENERATE_REPORT]: GenerateReportModal,
  [MODAL_ID_DUPLICATE_SPECIFICATION]: DuplicateSpecificationModal,
  [MODAL_ID_CONFIRMATION]: ConfirmationModal,
  [MODAL_ID_REMOVE_STELL_USER]: RemoveStellUserModal,
  [MODAL_ID_SPECIFICATION_HISTORY]: SpecificationHistoryModal,
  [MODAL_ID_SPECIFICATION_REVIEWERS]: SpecificationReviewersModal,
  [MODAL_ID_CREATE_EVIDENCE]: CreateEvidenceModal,
  [MODAL_ID_LINK_EVIDENCE]: LinkEvidenceModal,
  [MODAL_ID_ADD_LINKED_REQUIREMENT_EXCEPTION]:
    AddLinkedRequirementExceptionModal,
  [MODAL_ID_ADD_LINKED_REQUIREMENT]: AddLinkedRequirementModal,
  [MODAL_ID_LINKED_REQUIREMENT]: LinkedRequirementViewModal,
  [MODAL_ID_LINKED_EVIDENCE]: LinkedEvidenceViewModal,
  [MODEL_ID_EVIDENCE_RECORD]: EvidenceRecordDrawer,
  [MODEL_ID_EVIDENCE_ACTIVITY_DRAWER]: EvidenceActivityDrawer,
  [MODAL_ID_SHARE_SPECIFICATION]: ShareSpecificationModal,
  [MODAL_ID_SPECIFICATION_PDF_VIEWER]: SpecificationPdfViewerModal,
  [MODAL_ID_SHARE_REVIEW]: ShareReviewModal,
  [MODAL_ID_NEW_SPECIFICATION]: NewSpecificationModal,
  [MODAL_ID_SPECIFICATION_TREE_BUILDER]: SpecificationTreeBuilderModal,
  [MODAL_ID_KEYBOARD_SHORTCUTS]: KeyboardShortcutsModal,
}

const ModalManager = () => {
  const { currentModalId, currentModalProps, isOpen, closeModal } = useModals()

  const CurrentModal = useMemo(
    () => (currentModalId ? MODALS[currentModalId] : undefined),
    [currentModalId],
  )
  const modalRef = useClickOutside(() => closeModal(), isOpen, 100)

  return CurrentModal && isOpen ? (
    <div ref={modalRef} className={styles.pageOverlay}>
      <Suspense fallback={<LoadingIndicator />}>
        <CurrentModal
          isOpen={isOpen}
          closeModal={closeModal}
          {...currentModalProps}
        />
      </Suspense>
    </div>
  ) : null
}

export default ModalManager
