import { Fragment } from 'react'
import styles from './AppliedFilters.module.css'
import CommonFilters from './FilterComponents'
import { FilterSet, FilterKey, FilterData } from './types'

type AppliedFiltersProps = {
  filters: FilterSet
  onRemove: (key: FilterKey, filter: any) => void
  filterKeys: Array<FilterKey>
  filterData?: FilterData
}

const FilterKeyToDisplayName = {
  [FilterKey.SpecificationCategory]: 'Category',
  [FilterKey.SpecificationProgram]: 'Program',
  [FilterKey.ReportSource]: 'Source',
  [FilterKey.RequirementStatus]: 'Status',
  [FilterKey.RequirementType]: 'Type',
  [FilterKey.SpecificationName]: 'Specification',
  [FilterKey.EvidenceType]: 'Type',
  [FilterKey.EvidenceMethod]: 'Method',
  [FilterKey.EvidenceCadence]: 'Cadence',
  [FilterKey.EvidenceProgram]: 'Program',
  [FilterKey.Compliance]: 'Compliance',
  [FilterKey.ValidationMethod]: 'Validation method',
  [FilterKey.VerificationMethod]: 'Verification method',
  [FilterKey.EvidenceActivityType]: 'Type',
  [FilterKey.EvidenceActivityParentRecord]: 'Parent record',
  [FilterKey.EvidenceActivityStatus]: 'Status',
  [FilterKey.EvidenceActivityOwner]: 'Owner',
  [FilterKey.SharedRequirementType]: 'Type',
}

const AppliedFilters = (props: AppliedFiltersProps) => {
  const { filters, onRemove, filterKeys, filterData } = props
  return (
    <div className={styles.appliedFilters}>
      {filterKeys.map((key) => {
        const { AppliedFilter } = CommonFilters[key]
        return (
          <Fragment key={key}>
            {filters[key].length > 0 && (
              <div className={styles.filterGroup}>
                <div className={styles.filterName}>
                  {FilterKeyToDisplayName[key]}
                </div>
                {filters[key].map((filter) => {
                  const filterKey =
                    typeof filter === 'object' ? filter.id : filter
                  return (
                    <AppliedFilter
                      key={filterKey}
                      onRemove={() => onRemove(key, filter)}
                      value={filter}
                      filterData={filterData}
                    />
                  )
                })}
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default AppliedFilters
