import { CaretDown, OverflowMenuVertical } from '@carbon/icons-react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './ExceptionRow.module.css'
import { updateException, deleteException } from '../../api/v2/exceptions'
import Dropdown from '../../components/dropdown'
import ExceptionStatusTag from '../../components/tag/ExceptionStatusTag'
import ExceptionTypeTag from '../../components/tag/ExceptionTypeTag'
import ProgramTag from '../../components/tag/ProgramTag'
import { toastError, toastSuccess } from '../../components/toast'
import { useAuth } from '../../context/AuthContext'
import useClickOutside from '../../hooks/useClickOutside'
import { useModals } from '../../hooks/useModals'
import { monthLongDayYear } from '../../lib/date'
import { ExceptionStatus, ExceptionType } from '../../types/enums'

const ExceptionStatusCell = ({ status, id, reload }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onClose = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }
  const statusRef = useClickOutside(onClose)

  const selectableStatuses: ExceptionStatus[] = [
    ExceptionStatus.Requested,
    ExceptionStatus.InReview,
    ExceptionStatus.Accepted,
  ]

  const updateStatus = async (newStatus: ExceptionStatus) => {
    await updateException(id, { status: newStatus })
    reload()
  }

  return (
    <div
      className={`${styles.item} ${styles.status}`}
      onClick={() => {
        setIsOpen(!isOpen)
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setIsOpen(!isOpen)
        }
      }}
      ref={statusRef}
      role="button"
      tabIndex={0}
    >
      <ExceptionStatusTag status={status} />
      <CaretDown size={16} />
      <Dropdown isOpen={isOpen} className={styles.statusDropdown}>
        {selectableStatuses.map((status, index) => {
          return (
            <button
              onClick={() => {
                updateStatus(status)
              }}
              key={`${id}-${index}`}
            >
              <ExceptionStatusTag status={status} />
            </button>
          )
        })}
      </Dropdown>
    </div>
  )
}

interface ExceptionRowProps {
  type: ExceptionType
  program?: string
  title: string
  createdBy: string
  createdOn: Date
  status: string
  users: any[]
  id: string
  reload: () => void
}

const ExceptionRow = (props: ExceptionRowProps) => {
  const {
    type,
    program,
    title,
    createdBy,
    createdOn,
    status,
    users,
    id,
    reload,
  } = props

  const navigate = useNavigate()
  const statusCellRef = useRef<HTMLDivElement>(null)
  const { userDetails } = useAuth()
  const { openConfirmationModal } = useModals()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const hoverTimeoutRef = useRef<number | undefined>(undefined)

  const canDelete = createdBy === userDetails?.id
  const user = users.find((u) => u.id === createdBy)

  const closeMenu = () => {
    if (deleteModalIsOpen) {
      setDeleteModalIsOpen(false)
    }
  }

  const deleteDropdownRef = useClickOutside(closeMenu)

  const handleRowClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (statusCellRef.current && event.target instanceof Node) {
      if (!statusCellRef.current.contains(event.target)) {
        navigate(`/exceptions/${id}`)
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      navigate(`/exceptions/${id}`)
    }
  }

  const handleDelete = () => {
    setDeleteModalIsOpen(false)
    if (canDelete) {
      openConfirmationModal({
        title: 'Delete Exception',
        promptText: `Are you sure you want to delete "${title}"?`,
        onConfirm: async () => {
          try {
            await deleteException(id)
            reload()
            toastSuccess('Exception deleted')
          } catch (error) {
            toastError('Error deleting exception', 'Please try again')
          }
        },
      })
    }
  }

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeoutRef.current)
    setDeleteModalIsOpen(true)
  }

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeoutRef.current)
    hoverTimeoutRef.current = window.setTimeout(() => {
      setDeleteModalIsOpen(false)
    }, 400)
  }

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeoutRef.current)
    }
  }, [])

  return (
    <div className={styles.containerRow}>
      <div className={styles.overflowMenuWrapper}>
        <button
          onClick={() => setDeleteModalIsOpen(!deleteModalIsOpen)}
          onMouseLeave={handleMouseLeave}
        >
          <OverflowMenuVertical size={24} className={styles.overflowMenu} />
        </button>
        <Dropdown isOpen={deleteModalIsOpen} className={styles.dropdown}>
          <button
            ref={deleteDropdownRef}
            className={`${styles.actionItem} ${styles.delete}`}
            onClick={() => {
              handleDelete()
              setDeleteModalIsOpen(!deleteModalIsOpen)
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setDeleteModalIsOpen(false)}
            disabled={!canDelete}
          >
            Delete
          </button>
        </Dropdown>
      </div>
      <div
        className={styles.row}
        onClick={handleRowClick}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <ExceptionTypeTag type={type} />
        {program ? (
          <ProgramTag programId={program} />
        ) : (
          <div className={styles.placeholderLarge}></div>
        )}
        <div className={styles.title}>{title}</div>
        <div className={styles.item}>{monthLongDayYear(createdOn)}</div>
        <div className={styles.item}>
          {user ? `${user?.firstName} ${user?.lastName}` : ''}
        </div>
        <div ref={statusCellRef}>
          <ExceptionStatusCell status={status} id={id} reload={reload} />
        </div>
      </div>
    </div>
  )
}

export default ExceptionRow
