import { Copy } from '@carbon/icons-react'
import { useState, useCallback } from 'react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { useSpecificationContext } from '../../../context/SpecificationContext'
import tooltipStyles from '../../tooltip/Tooltip.module.css'

const CopyRequirementIdentifier = (props: BlockActionItemProps) => {
  const { requirement, closeMenu } = props
  const { specification } = useSpecificationContext()
  const [copySuccess, setCopySuccess] = useState('')
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const toolTipDisplayTime = 1500

  const copyIdentifierToClipboard = useCallback(async () => {
    const identifierNumber = requirement?.context.requirementIdentifier
    if (identifierNumber && specification.specificationIdentifier) {
      try {
        await navigator.clipboard.writeText(
          `${specification.specificationIdentifier}-${identifierNumber}`,
        )
        setCopySuccess(
          `'${specification.specificationIdentifier}-${identifierNumber}' Copied!`,
        )
        setIsTooltipVisible(true)
        setTimeout(() => {
          setIsTooltipVisible(false)
          closeMenu()
        }, toolTipDisplayTime)
      } catch (err) {
        setCopySuccess('Failed to copy')
        setIsTooltipVisible(true)
        setTimeout(() => {
          setIsTooltipVisible(false)
        }, toolTipDisplayTime)
      }
    }
  }, [requirement, specification, closeMenu])

  return (
    <div className={tooltipStyles.tooltipContainer}>
      <button className={styles.actionItem} onClick={copyIdentifierToClipboard}>
        <Copy style={{ transform: 'scaleX(-1)' }} />
        Copy Requirement ID
      </button>
      {isTooltipVisible && (
        <div className={`${tooltipStyles.tooltip} ${tooltipStyles.placeRight}`}>
          <div className={tooltipStyles.textContent}>{copySuccess}</div>
        </div>
      )}
    </div>
  )
}

export default CopyRequirementIdentifier
